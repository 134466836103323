import React, { useState } from 'react';
import { Dialog, DialogContent, DialogDescription, DialogFooter, DialogHeader, DialogTrigger } from 'src/components/ui/dialog';
import { DialogTitle } from '@radix-ui/react-dialog';
import { Button } from 'src/components/ui/button';
import { BadgeInfo } from 'lucide-react';
import { Separator } from 'src/components/ui/separator';

export default function ServiceProviderView({ serviceprovider }) {
  const [open, setOpen] = useState(false);

  return (
    <div>
      <Dialog open={open} onOpenChange={setOpen}>
        <DialogTrigger asChild>
          <BadgeInfo className="text-stay/90 py-0 size-[2vh] hover:text-stay" />
        </DialogTrigger>
        <DialogContent className="w-[80vw] lg:w-[50vw]">
          <DialogHeader>
            <DialogTitle className="mb-2">Service Provider Details</DialogTitle>
            <Separator />
            <div className="grid gap-3">
              <div className='grid gap-2 text-sm lg:grid-cols-3 md:grid-cols-3 sm:grid-cols-2'>
                <div>Name: {serviceprovider.name}</div>
                <div>Contact Number: {serviceprovider.phoneNumber}</div>
                <div>Email: {serviceprovider.emailId}</div>
                <div>Branch: {serviceprovider.branch}</div>
              </div>
              <Separator />
              <DialogDescription>Address Details</DialogDescription>
              {serviceprovider.address?.map((address, index) => (
                <div key={index} className='grid gap-2 text-sm lg:grid-cols-4 md:grid-cols-3 sm:grid-cols-2'>
                  <div>Address Line: {address.addressLine}</div>
                  <div>Country: {address.country}</div>
                  <div>State: {address.state}</div>
                  <div>City: {address.city}</div>
                  <div>Type: {address.type}</div>
                </div>
              ))}
              <Separator />
              <DialogDescription>Bank Details</DialogDescription>
              {serviceprovider.bankDetails?.map((bank, index) => (
                <div key={index} className='grid gap-2 text-sm lg:grid-cols-3 md:grid-cols-3 sm:grid-cols-2'>
                  <div>Account Name: {bank.accountName}</div>
                  <div>Bank Name: {bank.bankName}</div>
                  <div>Branch: {bank.branch}</div>
                  <div>Account Type: {bank.accountType}</div>
                  <div>IFSC Code: {bank.ifscCode}</div>
                  <div>Role: {bank.role}</div>
                </div>
              ))}
            </div>
            <DialogFooter>
              <Button type="button" className="ml-2" onClick={() => setOpen(false)}>Close</Button>
            </DialogFooter>
          </DialogHeader>
        </DialogContent>
      </Dialog>
    </div>
  );
}
