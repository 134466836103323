import { Button } from 'src/components/ui/button'
import { Command, CommandEmpty, CommandGroup, CommandInput, CommandItem, CommandList } from 'src/components/ui/command';
import { Popover, PopoverContent, PopoverTrigger } from 'src/components/ui/popover'
import { cn } from 'src/lib/utils';
import { Check, ChevronsDownUp } from 'lucide-react';
import React from 'react'
import { useParams } from 'react-router-dom';

export default function InvoiceSelect({ onInvoiceSelect, value, isVisible, isError, disable, subType }) {
    const { creditType } = useParams();
    const [open, setOpen] = React.useState(false)
    const [loading, setLoading] = React.useState(false);
    const [invoices, setInvoice] = React.useState([]);
    const [selectedInvoice, setSelectedInvoice] = React.useState(null);
    const getInvoices = async () => {
        setLoading(true);
        try {
            let domesticData = [];
            let internationalData = [];
            let combinedData = [];
            if(subType){
                const domesticResponse = await fetch(`${(process.env.REACT_APP_API_HOST_INV === undefined) ? "" : process.env.REACT_APP_API_HOST_INV}/amigo-lekha-api/invoiceManagement/v1/invoice?state=Submitted&subType=${creditType}-domestic`, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                });
                if (!domesticResponse.ok) {
                    throw new Error('Failed to fetch data');
                }
                domesticData = await domesticResponse.json();
                const internationalResponse = await fetch(`${(process.env.REACT_APP_API_HOST_INV === undefined) ? "" : process.env.REACT_APP_API_HOST_INV}/amigo-lekha-api/invoiceManagement/v1/invoice?state=Submitted&subType=${creditType}-international`, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                });
                if (!internationalResponse.ok) {
                    throw new Error('Failed to fetch data');
                }
                internationalData = await internationalResponse.json();
            } else {
                const response = await fetch(`${(process.env.REACT_APP_API_HOST_INV === undefined) ? "" : process.env.REACT_APP_API_HOST_INV}/amigo-lekha-api/invoiceManagement/v1/invoice?state=Submitted`, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                });
                if (!response.ok) {
                    throw new Error('Failed to fetch invoices');
                }
                combinedData = await response.json();
            }
            if(domesticData.length !== 0 || internationalData.length !== 0){
                combinedData = [...domesticData, ...internationalData];
            }
            setInvoice(combinedData);
        } catch (error) {
            console.error("Error fetching data:", error);
        } finally {
            setLoading(false);
        }
    }
    const handleSelectInvoice = async (invoiceId) => {
        const invoice = invoices.find((inv) => inv.invoiceId === invoiceId);
        setSelectedInvoice(invoice);
        onInvoiceSelect(invoice);
        setOpen(false);
    };
    React.useEffect(() => {
        if (value) {
            const invoice = invoices.find((inv) => inv.invoiceId === value);
            if (invoice) {
                setSelectedInvoice(invoice);
            }
        }
    }, [value, invoices, onInvoiceSelect]);

    React.useEffect(() => {
        if (isVisible) {
            getInvoices();
        }
    }, [isVisible]);
    return (
        <Popover open={open} onOpenChange={setOpen} modal={true}>
            <PopoverTrigger asChild onClick={getInvoices}>
                <Button
                    variant="outline"
                    role="combobox"
                    aria-expanded={open}
                    className={`lg:w-[40vh] w-[45vh] bg-muted h-7 justify-between ${isError && "border-destructive"}`}
                    disabled={disable}
                >
                    {selectedInvoice?.invoiceId || `Select invoice...`}
                    <ChevronsDownUp className="w-4 h-4 ml-2 opacity-50 shrink-0" />
                </Button>
            </PopoverTrigger>
            <PopoverContent className="w-[28vh] h-[30vh] p-0">
                <Command>
                    <CommandInput placeholder="Search invoice..." className="h-8" />
                    <CommandList>
                        <CommandEmpty>{loading ? "loading..." : `No invoice found.`}</CommandEmpty>
                        <CommandGroup>
                            {invoices.map((invoice) => (
                                <CommandItem
                                    key={invoice.invoiceId}
                                    value={invoice.name}
                                    onSelect={() => handleSelectInvoice(invoice.invoiceId)}
                                >
                                    {invoice.invoiceId}
                                    <Check
                                        className={cn(
                                            "ml-auto h-4 w-4",
                                            selectedInvoice?.invoiceId === invoice.invoiceId ? "opacity-100" : "opacity-0"
                                        )}
                                    />
                                </CommandItem>
                            ))}
                        </CommandGroup>
                    </CommandList>
                </Command>
            </PopoverContent>
        </Popover>
    )
}
