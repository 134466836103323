import { Trash2 } from 'lucide-react';
import { useState } from 'react';
import { toast } from 'sonner';
import { Button } from 'src/components/ui/button'
import { Dialog, DialogContent, DialogDescription, DialogFooter, DialogHeader, DialogTitle, DialogTrigger } from 'src/components/ui/dialog'

export default function Deletedialog({ Id, onDeleteSuccess, type, deleteCall, disable }) {
    const [loading, setLoading] = useState(false);
    const [open, setOpen] = useState(false);

    const handleDelete = async () => {
        setLoading(true);
        try {
            const response = await fetch(`${(process.env.REACT_APP_API_HOST_INV === undefined) ? "" : process.env.REACT_APP_API_HOST_INV}/amigo-lekha-api/${type}Management/v1/${type}/${Id}`, {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json'
                },
            });
            if (!response.ok) {
                throw new Error(`Failed to delete ${type}`);
            }
            setOpen(false);
            toast.success(`${type} with ID - ${Id} deleted successfuly!`);
            onDeleteSuccess();
        } catch (error) {
            console.error(`Error deleting ${type}:`, error);
            toast.error(`Failed to delete ${type}`);
        } finally {
            setLoading(false);
        }
    };

    return (
        <div>
            <Dialog open={open} onOpenChange={setOpen}>
                <DialogTrigger className='flex ' asChild>
                    {deleteCall === "Inside" ? (
                        <Button variant="destructive" size="sm" disabled={disable} className="mr-10">Delete</Button>
                    ) : (
                        <Trash2 className="text-destructive size-[2vh]" />
                    )}
                </DialogTrigger>
                <DialogContent className="max-w-lg">
                    <DialogHeader className='gap-3'>
                        <DialogTitle>Are you absolutely sure?</DialogTitle>
                        <DialogDescription>
                            This will delete your {type} with ID - {Id}.
                        </DialogDescription>
                    </DialogHeader>
                    <DialogFooter className="sm:justify-start">
                        <Button className="bg-destructive/80 hover:bg-destructive/100 text-slate-100" onClick={handleDelete} disabled={loading || disable}>
                            {loading ? 'Deleting...' : 'Delete'}
                        </Button>
                    </DialogFooter>
                </DialogContent>
            </Dialog>
        </div>
    )
}

