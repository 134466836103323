import { Sheet, SheetContent, SheetFooter, SheetHeader, SheetTitle, SheetTrigger } from 'src/components/ui/sheet'
import React, { useState } from 'react'
import { Button } from 'src/components/ui/button'
import { Zap } from 'lucide-react'
import { Controller, useForm } from "react-hook-form"
import { Input } from "src/components/ui/input"
import { toast } from 'sonner'
import { Label } from 'src/components/ui/label'
import { DatePicker } from '../../../components/datepicker'
import { SearchCustomer } from './search-select'


export function QckInvSlider({ onSuccess }) {
    const { register, handleSubmit, setValue, control } = useForm({
        defaultValues: {
            dueDate: "",
            invoiceId: "",
            createdBy: "",
            state: "Draft",
            agent: "",
            printInName: "",
            manualBillNo: "",
            referredBy: "",
            action: "add",
            productDetails: [
                {
                    id: "",
                    code: "",
                    name: "",
                    netAmount: 0,
                    product: {
                        gds: "",
                        gdsNo: "",
                        airline: "",
                        airlinePNR: "",
                        requisitionNo: "",
                        requisitionBy: "",
                        requisitionDate: "",
                        validationDate: "",
                    },
                    state: "Draft",
                },
            ],
            customerRefOrValue: {
                id: "",
                name: "",
                address: "",
                email: "",
                phone: "",
                panNo: "",
                gstNo: "",
            },
        }
    });
    const [loading, setLoading] = useState(false);
    const [open, setOpen] = useState(false);

    const onSubmit = async (data) => {
        setLoading(true);
        try {
            const response = await fetch(`${(process.env.REACT_APP_API_HOST_INV === undefined) ? "" : process.env.REACT_APP_API_HOST_INV}/amigo-lekha-api/invoiceManagement/v1/invoice`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(data),
            });
            if (!response.ok) {
                throw new Error('Failed to create invoice');
            }
            setOpen(false);
            toast.success("Invoice created successfully!");
            onSuccess();
        } catch (error) {
            console.error("Error creating invoice:", error);
            toast.error("Failed to create invoice");
        } finally {
            setLoading(false);
        }
    }

    const handleCustomerSelect = (customer) => {
        setValue('customerRefOrValue.id', customer.id || '');
        setValue('customerRefOrValue.name', customer.entityName || '');
        setValue('customerRefOrValue.phone', customer.contactNumber || '');
        setValue('customerRefOrValue.email', customer.emailId || '');
        setValue('customerRefOrValue.address', customer.addressId[0]?.addressLine || '');
        setValue('customerRefOrValue.panNo', customer.panNumber || '');
        setValue('customerRefOrValue.gstNo', customer.gstNumber || '');
    };

    return (
        <Sheet open={open} onOpenChange={setOpen}>
            <SheetTrigger asChild >
                <Button size="sm" className="h-10 gap-1 px-4 ml-2">
                    <Zap className="size-4" />
                    <span className="sr-only lg:not-sr-only md:sr-only sm:whitespace-nowrap">
                        Quick Invoice
                    </span>
                </Button>
            </SheetTrigger>
            <SheetContent className="mx-auto rounded-lg max-w-7xl" onOpenAutoFocus={(e) => e.preventDefault()}>
                <SheetHeader>
                    <SheetTitle>New Invoice</SheetTitle>
                </SheetHeader>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="grid gap-4 px-5 py-2 max-w-7xl">
                        <div className="grid grid-cols-1 gap-4 md:grid-cols-2 lg:grid-cols-3">
                            <div className="flex flex-row items-center gap-2">
                                    <Label >Customer Name</Label>
                                    <SearchCustomer onCustomerSelect={handleCustomerSelect} />
                                </div>
                            <div className="flex flex-row items-center gap-2">
                                <Label className="w-40 lg:w-32">PNR Number</Label>
                                <Input name="airlinePNR" {...register("productDetails[0].product.airlinePNR", { required: true })} className="h-7 bg-muted/70" />
                            </div>
                            <div className="flex flex-row items-center gap-2">
                                <Label className="w-40 lg:w-20">Due Date</Label>
                                <Controller control={control} name="dueDate" rules={{ required: true }}
                                    render={({ field }) => (
                                        <DatePicker value={field.value} onChange={(date) => field.onChange(date)}/>
                                    )}
                                />
                            </div>
                        </div>
                        <SheetFooter>
                            <Button disabled={loading} type="submit">Create Invoice</Button>
                        </SheetFooter>
                    </div>
                </form>
            </SheetContent>
        </Sheet>
    )
}
