import React, { useEffect, useState } from 'react'
import { Card, CardContent, CardTitle } from 'src/components/ui/card';
import { Select, SelectContent, SelectGroup, SelectItem, SelectTrigger, SelectValue } from 'src/components/ui/select';
import Chart from "react-apexcharts";
import { useTheme } from 'next-themes';

export default function InvPayCreditGraph() {
    const { theme } = useTheme();
    const [key, setKey] = useState(0);

    useEffect(() => {
        setChartOptions(prevOptions => ({
            ...prevOptions,
            theme: {
                mode: theme,
            }
        }));
        setKey(prevKey => prevKey + 1);
    }, [theme]);
    const [chartSeries, setChartSeries] = useState([5, 2, 3, 1]);
    const [chartOptions, setChartOptions] = useState({
        chart: {
            background: 'transparent',
        },
        labels: ['Invoice', 'Credit Note', 'Payment', 'Receipt'],
        plotOptions: {
            pie: {
                expandOnClick: false
            }
        },
        theme: {
            mode: theme,
        },
        colors: ["#3277cc", "#5fb68a", "#e98b43", "#d2493f"],
        legend: {
            labels: {
                colors: ["#3277cc", "#5fb68a", "#e98b43", "#d2493f"]
            },
            fontFamily: "Nunito, sans-serif",
            fontSize: 14,
            markers: {
                width: 8,
                height: 8,
                offSetY: -1,
            }
        },
        tooltip: {
            style: {
                fontFamily: "Nunito, sans-serif",
            },
        }
    })

    return (
        <Card>
            <CardContent className="px-4">
                <CardTitle className="flex w-full py-2 pl-4 mt-3 rounded-md bg-secondary">Type count %<div className='ml-auto mr-4'>
                    <Select defaultValue="draft">
                        <SelectTrigger className="w-full h-7 focus:ring-transparent">
                            <SelectValue />
                        </SelectTrigger>
                        <SelectContent>
                            <SelectGroup>
                                <SelectItem value="draft">Type count</SelectItem>
                                <SelectItem value="submitted">Invoice status</SelectItem>
                            </SelectGroup>
                        </SelectContent>
                    </Select></div></CardTitle>
                <Chart
                    key={key}
                    options={chartOptions}
                    series={chartSeries}
                    type="pie"
                    width="100%"
                    height="300px"
                />
            </CardContent>
        </Card>
    )
}
