import React, { useState } from 'react';
import { Routes, Route, Navigate, useParams } from 'react-router-dom';
import InvoicePage from './invoicePage/invoices';
import Navbar from '../components/navbar';
import SidebarMenu from '../components/sidebar';
import { Copyright } from 'lucide-react';
import ProfilePage from '../profile/profile';
import MasterPage from './masterPage/masters';
import CustomerList from './masterPage/customerMast/customer-list';
import CustomerForm from './masterPage/customerMast/customer-form';
import GenericList from './masterPage/genericMast/generic-list';
import ServiceProviderList from './masterPage/serviceProvideMast/service-pro-list';
import ServiceProviderForm from './masterPage/serviceProvideMast/service-pro-form';
import ProductChargeConfigForm from './masterPage/cpccMast/cpcc-form';
import AnalyticPage from './analyticPage/analytics';
import AdminPage from './adminPage/admin-page';
import ProductChargeConfigList from './masterPage/cpccMast/cpcc-list';
import GenericForm from './masterPage/genericMast/generic-form';
import CreditNotePage from './creditNotePage/credit-notes';
import PaymentPage from './paymentPage/payments';
import ReceiptPage from './receiptPage/receipts';
import SupplierForm from './masterPage/supplierMast/supplier-form';
import SupplierList from './masterPage/supplierMast/supplier-list';
import InvAirlineForm from './invoicePage/airline/invoice-airline-form';
import CnoteAirlineForm from './creditNotePage/airline/creditnote-airline-form';
import InvCabForm from './invoicePage/cab/invoice-cab-form';
import InvHotelForm from './invoicePage/hotel/invoice-hotel-form';
import InvRailwayForm from './invoicePage/railway/invoice-railway-form';
import InvBusForm from './invoicePage/bus/invoice-bus-form';
import InvEURailForm from './invoicePage/eurail/invoice-eurail-form';
import InvCruiseForm from './invoicePage/cruise/invoice-cruise-form';
import InvInsuranceForm from './invoicePage/insurance/invoice-insurance-form';
import InvPassportForm from './invoicePage/passport/invoice-passport-form';
import InvVisaForm from './invoicePage/visa/invoice-visa-form';
import InvMiscForm from './invoicePage/miscellaneouss/invoice-misc-form';
import InvTourPackageForm from './invoicePage/tourPackage/invoice-tourpackage-form';
import CnoteCabForm from './creditNotePage/cab/creditnote-cab-form';
import SmInv from './splitMergePage/invoiceSM/split-inv';
import PymtBankForm from './paymentPage/bank/payment-bank-form';
import RcptBankForm from './receiptPage/receipt-bank-form';
import CnoteHotelForm from './creditNotePage/hotel/creditnote-hotel-form';
import PrivateRoute from 'src/helper/private-routes';

const MasterTypeComponent = () => {
  const { masterType } = useParams();
  switch (masterType) {
    case 'customer':
      return <CustomerList />;
    case 'serviceprovider':
      return <ServiceProviderList />;
    case 'productchargeconfig':
      return <ProductChargeConfigList />;
    case 'supplier':
      return <SupplierList />;
    default:
      return <GenericList />;
  }
};
const MasterFormComponent = () => {
  const { masterType } = useParams();
  switch (masterType) {
    case 'customer':
      return <CustomerForm />;
    case 'serviceprovider':
      return <ServiceProviderForm />;
    case 'productchargeconfig':
      return <ProductChargeConfigForm />;
    case 'supplier':
      return <SupplierForm />;
    default:
      return <GenericForm />;
  }
};
const InvoiceFormComponent = () => {
  const { invoiceType } = useParams();
  switch (invoiceType) {
    case 'airline':
      return <InvAirlineForm />;
    case 'cab':
      return <InvCabForm />;
    case 'hotel':
      return <InvHotelForm />;
    case 'railway':
      return <InvRailwayForm />;
    case 'bus':
      return <InvBusForm />;
    case 'eurail':
      return <InvEURailForm />;
    case 'cruise':
      return <InvCruiseForm />;
    case 'insurance':
      return <InvInsuranceForm />;
    case 'passport':
      return <InvPassportForm />;
    case 'visa':
      return <InvVisaForm />;
    case 'miscellaneous':
      return <InvMiscForm />;
    case 'tour-package':
      return <InvTourPackageForm />;
    default:
      return <InvAirlineForm />;
  }
};
const CnoteFormComponent = () => {
  const { creditType } = useParams();
  switch (creditType) {
    case 'airline':
      return <CnoteAirlineForm />;
    case 'cab':
      return <CnoteCabForm />;
    case 'hotel':
      return <CnoteHotelForm />;
    // case 'railway':
    //   return <InvRailwayForm />;
    // case 'bus':
    //   return <InvBusForm />;
    // case 'eurail':
    //   return <InvEURailForm />;
    // case 'cruise':
    //   return <InvCruiseForm />;
    // case 'insurance':
    //   return <InvInsuranceForm />;
    // case 'passport':
    //   return <InvPassportForm />;
    // case 'visa':
    //   return <InvVisaForm />;
    // case 'miscellaneous':
    //   return <InvMiscForm />;
    // case 'tour-package':
    //   return <InvTourPackageForm />;
    default:
      return <CnoteAirlineForm />;
  }
};
const PaymentFormComponent = () => {
  const { paymentType } = useParams();
  switch (paymentType) {
    case 'bank':
      return <PymtBankForm />;
    // case 'cash':
    //   return <PymtCashForm />;
    default:
      return <PymtBankForm />;
  }
};
const ReceiptFormComponent = () => {
  const { receiptType } = useParams();
  switch (receiptType) {
    case 'bank':
      return <RcptBankForm />;
    // case 'cash':
    //   return <RcptCashForm />;
    default:
      return <RcptBankForm />;
  }
};

function DashboardLayout() {
  const [isSidebarOpen, setSidebarOpen] = useState(false);
  const toggleSidebar = () => {
    setSidebarOpen(!isSidebarOpen);
  };
  return (
    <>
      <Navbar toggleSidebar={toggleSidebar} />
      <div className='lg:sr-only md:sr-only'>
        <SidebarMenu isOpen={isSidebarOpen} />
      </div>
      <div className="flex">
        <SidebarMenu isOpen={isSidebarOpen} />
        <div className="flex-grow">
          {/* <div className='flex-col'> */}
          <Routes>
            <Route path="/" element={<Navigate to="analytics" />} />
            <Route path="analytics" element={<AnalyticPage />} />
            <Route path="admin" element={<AdminPage />} />

            <Route path="invoices/:invoiceType" element={<InvoicePage />} />
            <Route path="invoices/:invoiceType/:productType" element={<InvoicePage />} />
            <Route path="invoices/:invoiceType/add" element={<InvoiceFormComponent />} />
            <Route path="invoices/:invoiceType/:productType/add" element={<InvoiceFormComponent />} />
            <Route path="invoices/:invoiceType/edit/:id" element={<InvoiceFormComponent />} />
            <Route path="invoices/:invoiceType/:productType/edit/:id" element={<InvoiceFormComponent />} />

            <Route path="credit-note/:creditType" element={<CreditNotePage />} />
            <Route path="credit-note/:creditType/add" element={<CnoteFormComponent />} />
            <Route path="credit-note/:creditType/edit/:id" element={<CnoteFormComponent />} />

            <Route path="payment/:paymentType" element={<PaymentPage />} />
            <Route path="payment/:paymentType/add" element={<PaymentFormComponent />} />
            <Route path="payment/:paymentType/edit/:id" element={<PaymentFormComponent />} />

            <Route path="receipt/:receiptType" element={<ReceiptPage />} />
            <Route path="receipt/:receiptType/add" element={<ReceiptFormComponent />} />
            <Route path="receipt/:receiptType/edit/:id" element={<ReceiptFormComponent />} />

            <Route path="masters" element={<MasterPage />} >
              <Route path=':masterType' element={<MasterTypeComponent />} />
            </Route>
            <Route path="masters/:masterType/add" element={<MasterFormComponent />} />
            <Route path="masters/:masterType/edit" element={<MasterFormComponent />} />

            <Route path="split-merge/:type" element={<SmInv />} />
            <Route path="profile" element={<ProfilePage />} />
          </Routes>
          {/* <div className="flex flex-row items-center justify-end gap-1 mr-[5vh] text-sm font-bold text-muted-foreground"><Copyright className='size-5' /><span>All Rights Reserved, Amigo Nexus Technology 2024</span></div>
          </div> */}
        </div>
      </div>
      <div className="absolute z-[-10] bottom-0 mb-3 right-[5vh] flex flex-row items-center gap-1 text-sm font-bold text-muted-foreground"><Copyright className='size-5' /><span>All Rights Reserved, Amigo Nexus Technology 2024</span></div>
    </>
  );
}

export default DashboardLayout;
