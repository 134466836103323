import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import LoginPage from './pages/auth/login';
import { Toaster } from "./components/ui/sonner.jsx";
import DashboardLayout from './pages/dashboard/dashboardLayout.jsx';
import { ThemeProvider } from "src/pages/components/theme-provider.jsx"
import { ReactKeycloakProvider } from '@react-keycloak/web';
import keycloak from './keycloak.js';
import PrivateRoute from './helper/private-routes.js';

function App() {
  return (
    <ThemeProvider
      attribute="class"
      defaultTheme="light"
      enableSystem
      disableTransitionOnChange
    >
      <div>
      {/* <ReactKeycloakProvider authClient={keycloak} initOptions={{onLoad: 'login-required'}}> */}
        <BrowserRouter>
          <Routes>
            <Route path='/login' element={<LoginPage/>} />
            {/* <Route path='/dashboard/*' element={<PrivateRoute><DashboardLayout/></PrivateRoute>} />
            <Route path="*" element={<Navigate to="/dashboard" replace />} /> */}
            <Route path='/dashboard/*' element={<DashboardLayout/>} />
            <Route path="*" element={<Navigate to="/login" replace />} />
          </Routes>
        </BrowserRouter>
        {/* </ReactKeycloakProvider> */}
        <Toaster />
      </div>
    </ThemeProvider>

  );
}

export default App;