import { Button } from 'src/components/ui/button'
import React, { useEffect, useState } from 'react'
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom'
import { CirclePlus, Edit, Info, Trash2, Undo2 } from 'lucide-react'
import { Controller, useForm } from 'react-hook-form'
import { Label } from 'src/components/ui/label'
import { DatePicker } from 'src/pages/components/datepicker'
import { Separator } from 'src/components/ui/separator'
import { SearchCustomer } from '../components/search-select'
import { toast } from 'sonner'
import { Input } from 'src/components/ui/input'
import { Textarea } from 'src/components/ui/textarea'
import SupplierSelect from 'src/pages/components/supplier-select'
import { Card, CardContent, CardDescription, CardFooter } from 'src/components/ui/card'
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from 'src/components/ui/table'
import { Select, SelectContent, SelectGroup, SelectItem, SelectTrigger, SelectValue } from 'src/components/ui/select'
import { ScrollArea } from 'src/components/ui/scroll-area'
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from 'src/components/ui/tooltip'
import { Checkbox } from 'src/components/ui/checkbox'
import { DateTimePicker } from 'src/components/ui/dateTimePicker/datetime-picker'
import GenericAmountSeparator from 'src/pages/components/genric-amountseperator'

export default function InvHotelForm() {
  const { id } = useParams();
  const { productType } = useParams();
  const location = useLocation();
  const data = location.state?.item;
  const navigate = useNavigate();
  const [invoiceId, setInvoiceId] = useState('');
  const [customerForm, setCustomerForm] = useState(false);
  const [loading, setLoading] = useState("");
  const [isVisible, setIsVisible] = useState(false);
  const [isError, setIsError] = useState({
    customer: false,
    supplier: false,
    passenger: false,
    hotelDetails: false,
  });
  const { register, handleSubmit, control, reset, watch, setValue, getValues, trigger, formState: { errors }, } = useForm({
    defaultValues: {
      category: `hotel-${productType}`,
      agent: "",
      createdBy: "amigo2",
      hotelProductDetails: [
        {
          id: "",
          code: "",
          name: "hotel",
          category: productType,
          netAmount: 0,
          state: "",
        },
      ],
      customerRefOrValue: {
        id: "",
        name: "",
        address: "",
        email: "",
        phone: "",
        panNo: "",
        gstNo: "",
      },
    }
  });
  const [titleItems, setTitleItems] = useState([]);
  const [paxTypeItems, setPaxTypeItems] = useState([]);
  const [genderItems, setGenderItems] = useState([]);
  const [roomTypeItems, setRoomTypeItems] = useState([]);
  const [mealPlanItems, setMealPlanItems] = useState([]);
  const [occupancyItems, setOccupancyItems] = useState([]);
  const [passengerForm, setPassengerForm] = useState([]);
  const [editingPassengerForm, setEditingPassengerForm] = useState([]);
  const [passengerFormEdit, setpassengerFormEdit] = useState({ visible: false, editingIndex: null });
  const [hotelForm, setHotelForm] = useState([]);
  const [editingHotelForm, setEditingHotelForm] = useState([]);
  const [hotelFormEdit, sethotelFormEdit] = useState({ visible: false, editingIndex: null });
  const [custChargeForm, setCustChargeForm] = useState([
    {
      chargeCodeName: "Service Charge",
      calculationMethod: "",
      value: 0,
      totalValue: 0,
      tax: [],
      taxTotal: null,
    },
    {
      chargeCodeName: "Miscellaneous Charges",
      calculationMethod: "",
      value: 0,
      totalValue: 0,
      tax: [],
      taxTotal: null,
    },
    {
      chargeCodeName: "Documentation Charge",
      calculationMethod: "",
      value: 0,
      totalValue: 0,
      tax: [],
      taxTotal: null,
    },
    {
      chargeCodeName: "Management Fees",
      calculationMethod: "",
      value: 0,
      totalValue: 0,
      tax: [],
      taxTotal: null,
    }
  ]);
  const [suppChargeForm, setSuppChargeForm] = useState([
    {
      chargeCodeName: "Commission Charge",
      calculationMethod: "",
      value: 0,
      totalValue: 0,
      tax: [],
      taxTotal: null,
    },
    {
      chargeCodeName: "Miscellaneous Charges",
      calculationMethod: "",
      value: 0,
      totalValue: 0,
      tax: [],
      taxTotal: null,
    },
    {
      chargeCodeName: "Documentation Charge",
      calculationMethod: "",
      value: 0,
      totalValue: 0,
      tax: [],
      taxTotal: null,
    },
    {
      chargeCodeName: "Management Fees",
      calculationMethod: "",
      value: 0,
      totalValue: 0,
      tax: [],
      taxTotal: null,
    }
  ])
  const CUST_CHARGE_TYPES = [
    "Service Charge",
    "Miscellaneous Charges",
    "Documentation Charge",
    "Management Fees"
  ];
  const [currentRemark, setCurrentRemark] = useState({ name: '', charge: '' });
  const [remarks, setRemarks] = useState([]);
  const agentComm = watch("agent");

  useEffect(() => {
    getLists();
    setValue('invoiceDate', new Date().toISOString());
    if (data && id) {
      setInvoiceId(data.invoiceId);
      reset(data);
      if (data.hotelProductDetails && data.hotelProductDetails.length > 0 && data.hotelProductDetails[0].product && data.hotelProductDetails[0].product.passenger) {
        const passengerData = data.hotelProductDetails[0].product.passenger;
        setPassengerForm(passengerData);
        setEditingPassengerForm(passengerData);
      } else {
        setPassengerForm([
          {
            title: "",
            firstName: "",
            lastName: "",
            paxType: "",
            ticketNo: "",
          }
        ]);
      }
      if (data.hotelProductDetails && data.hotelProductDetails.length > 0 && data.hotelProductDetails[0].product && data.hotelProductDetails[0].product.carriers) {
        const hotelDetailsData = data.hotelProductDetails[0].product.carriers;
        setHotelForm(hotelDetailsData);
        setEditingHotelForm(hotelDetailsData);
      } else {
        setHotelForm([
          {
            hotelDetail: "",
            roomType: "",
            occupancy: "",
            checkinDate: "",
            checkoutDate: "",
            noOfAdults: "",
            noOfChildrens: "",
            mealPlan: "",
            fare: {},
          }
        ]);
      }
      if (data.hotelProductDetails && data.hotelProductDetails.length > 0 && data.hotelProductDetails[0].product && data.hotelProductDetails[0].product.charges) {
        const chargeData = data.hotelProductDetails[0].product.charges;
        if (chargeData.length === 0) {
          setCustChargeForm(custChargeForm);
        } else {
          setCustChargeForm(chargeData);
        }
      } else {
        setCustChargeForm([
          {
            chargeCodeName: "",
            calculationMethod: "",
            value: 0,
            totalValue: 0,
            tax: [],
            taxTotal: 0,
          }
        ]);
      }
      if (data.hotelProductDetails && data.hotelProductDetails.length > 0 && data.hotelProductDetails[0].product && data.hotelProductDetails[0].product.supplierCharges) {
        const suppChargeData = data.hotelProductDetails[0].product.supplierCharges;
        setSuppChargeForm(suppChargeData);
      }
      setIsVisible(true);
    }
  }, [data, id, reset, setValue]);

  const getLists = async () => {
    try {
      const response = await fetch(`${(process.env.REACT_APP_API_HOST_MSTR === undefined) ? "" : process.env.REACT_APP_API_HOST_MSTR}/amigo-lekha-api/listManagement/v1/list`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json'
        },
      });
      if (!response.ok) {
        throw new Error('Failed to fetch data');
      }
      const fetchedData = await response.json();
      fetchedData.forEach(item => {
        switch (item.name) {
          case 'Title':
            setTitleItems(item.items);
            break;
          case 'PaxType':
            setPaxTypeItems(item.items);
            break;
          case 'RoomType':
            setRoomTypeItems(item.items);
            break;
          case 'MealPlan':
            setMealPlanItems(item.items);
            break;
          case 'Occupancy':
            setOccupancyItems(item.items);
            break;
          case 'Gender':
            setGenderItems(item.items);
            break;
          default:
            break;
        }
      });
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  }

  const calculateTaxForCharge = (totalValue, chargeTaxes, taxValues) => {
    let taxTotal = 0;
    const updatedTaxes = chargeTaxes?.map(chargeTax => {
      const matchingTaxValue = taxValues.find(taxValue => taxValue.id === chargeTax.taxCode);
      if (matchingTaxValue) {
        const taxAmount = totalValue * (matchingTaxValue.value / 100);
        taxTotal += taxAmount;
        return { ...chargeTax, value: taxAmount };
      }
      return chargeTax;
    });
    return { tax: updatedTaxes, taxTotal };
  };

  const [taxValues, setTaxValues] = useState([]);

  const handleCustomerSelect = async (customer, customerCharges) => {
    const chrgForm = [{
      chargeCodeName: "Service Charge",
      calculationMethod: "",
      value: 0,
      totalValue: 0,
      tax: [],
      taxTotal: null,
    },
    {
      chargeCodeName: "Miscellaneous Charges",
      calculationMethod: "",
      value: 0,
      totalValue: 0,
      tax: [],
      taxTotal: null,
    },
    {
      chargeCodeName: "Documentation Charge",
      calculationMethod: "",
      value: 0,
      totalValue: 0,
      tax: [],
      taxTotal: null,
    },
    {
      chargeCodeName: "Management Fees",
      calculationMethod: "",
      value: 0,
      totalValue: 0,
      tax: [],
      taxTotal: null,
    }]
    if (!customerCharges || !customerCharges.configDetails || customerCharges.configDetails.length === 0) {
      toast.error(`No charges for ${customer.customerCode}`);
      setValue("hotelProductDetails[0].product.charges", chrgForm);
    }
    const hotelCharges = customerCharges?.configDetails?.filter(charge => charge.ProductRef.id === "Hotel");
    if (hotelCharges?.length === 0) {
      toast.error(`No hotel charges for ${customer.customerCode}`);
      setValue("hotelProductDetails[0].product.charges", chrgForm);
    }
    setIsError(prevState => ({ ...prevState, customer: false }));
    setValue('customerRefOrValue.id', customer.customerCode || '');
    setValue('customerRefOrValue.name', customer.fullName || '');
    setValue('customerRefOrValue.phone', customer.contactNumber || '');
    setValue('customerRefOrValue.email', customer.emailId || '');
    setValue('customerRefOrValue.address', customer.addressId[0]?.addressLine || '');
    setValue('customerRefOrValue.panNo', customer.panNumber || '');
    setValue('customerRefOrValue.gstNo', customer.gstNumber || '');
    setCustChargeForm([]);
    const updatedChargeForm = custChargeForm.map(charge => ({
      ...charge,
      value: 0,
      calculationMethod: '',
      totalValue: 0,
      tax: [{
        taxCode: "",
        value: 0,
      }],
      taxTotal: 0,
    }));
    let fetchedTaxValues;
    const hasHotelCharge = customerCharges.configDetails?.some(charge => charge.ProductRef.id === "Hotel");
    if (hasHotelCharge) {
      fetchedTaxValues = await getTaxValues(chrgForm, customerCharges?.configDetails);
    }
    const currentDate = new Date();
    customerCharges?.configDetails
      ?.filter((charge) => charge.ProductRef.id === "Hotel")
      .forEach((charge) => {
        const matchingCharge = updatedChargeForm.find(
          (c) => c.chargeCodeName === charge.ledger.id
        );
        if (matchingCharge) {
          matchingCharge.value = charge.amount || 0;
          matchingCharge.calculationMethod = charge.calculationMethod;
          const isWithinDateRange = (
            (charge.effectStartDate === null && charge.effectEndDate === null) ||
            (charge.effectStartDate === null && new Date(charge.effectEndDate) >= currentDate) ||
            (charge.effectEndDate === null && new Date(charge.effectStartDate) <= currentDate) ||
            (new Date(charge.effectStartDate) <= currentDate && new Date(charge.effectEndDate) >= currentDate)
          );
          if (isWithinDateRange) {
            matchingCharge.tax = charge.taxes?.map(taxCode => ({
              taxCode,
              value: 0
            })) || [];
          } else {
            matchingCharge.tax = [];
          }
        }
      });
    setCustChargeForm(updatedChargeForm);
    if (fetchedTaxValues) {
      calcCharge(updatedChargeForm, passengerForm.length, fetchedTaxValues);
    }
  };
  const customerRefOrValue = watch("customerRefOrValue");
  let pLength = 0;

  const getTaxValues = async (chargeForm, configDetails) => {
    let taxIds;
    if (configDetails !== null) {
      taxIds = configDetails
        ?.filter((charge) => charge.ProductRef.id === "Hotel")
        ?.flatMap((charge) => charge.taxes?.map((tax) => tax) || [])
        .filter((id, index, self) => self.indexOf(id) === index);
    } else {
      taxIds = chargeForm
        .flatMap((charge) => charge.tax?.map((tax) => tax.taxCode) || [])
        .filter((id, index, self) => self.indexOf(id) === index);
    }
    const taxValues = await Promise.all(
      taxIds?.map(async (taxId) => {
        try {
          const response = await fetch(`${(process.env.REACT_APP_API_HOST_MSTR === undefined) ? "" : process.env.REACT_APP_API_HOST_MSTR}/amigo-lekha-api/taxTypeManagement/v1/taxType/${taxId}`);
          if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
          }
          const data = await response.json();
          return { id: taxId, value: data.valueDetails[0].value };
        } catch (error) {
          console.error(`Error fetching tax data for ID ${taxId}:`, error);
          return { id: taxId, value: 0 };
        }
      })
    );
    if (taxValues.length > 0) {
      calcCharge(chargeForm, pLength, taxValues);
    }
    setTaxValues(taxValues);
    return taxValues;
  };
  const calcCharge = (updatedChargeForm, length, taxValues) => {
    if (taxValues.length === 0 && customerRefOrValue.name) {
      getTaxValues(custChargeForm, null);
    }
    if (!length) { length = 0 };
    const charges = updatedChargeForm.map((charge) => {
      let totalValue;
      switch (charge.calculationMethod) {
        case "PerTicket":
          totalValue = charge.value;
          break;
        case "PerPassenger":
          const multi = length === 0 ? 1 : length;
          totalValue = multi * charge.value;
          break;
        default:
          totalValue = charge.value;
      }
      return {
        ...charge,
        totalValue,
      };
    });
    const chargesWithTax = charges?.map((charge) => {
      const { tax, taxTotal } = calculateTaxForCharge(charge.totalValue, charge.tax, taxValues);
      const roundedTax = tax?.map(t => ({
        ...t,
        value: Math.round(t.value * 100) / 100
      }));
      const roundedTaxTotal = Math.round(taxTotal * 100) / 100;
      return { ...charge, tax: roundedTax, taxTotal: roundedTaxTotal };
    });
    setCustChargeForm(chargesWithTax);
    setValue("hotelProductDetails[0].product.charges", chargesWithTax);
  };

  const handleChargeChange = (index, field, value) => {
    if (index === null) {
      index = 0;
    }
    setCustChargeForm((prevCharges) => {
      const updatedCharges = [...prevCharges];
      updatedCharges[index] = {
        ...updatedCharges[index],
        [field]: value
      };
      return updatedCharges;
    });
  };
  const handleChargeFormSave = (e) => {
    e.preventDefault();
    setValue('hotelProductDetails[0].product.charges', custChargeForm);
    calcCharge(custChargeForm, editingPassengerForm.length, taxValues);
  };

  const handleSupplierSelect = (supplier) => {
    setIsError(prevState => ({ ...prevState, supplier: false }));
    setValue("hotelProductDetails[0].product.agentRefOrValue.name", supplier.fullName);
    setValue("hotelProductDetails[0].product.agentRefOrValue.id", supplier.supplierCode);
  }
  const supplierName = watch("hotelProductDetails[0].product.agentRefOrValue") || '';

  const isValidPhone = (phone) => {
    const phoneRegex = /^[0-9]{10}$/;
    return phoneRegex.test(phone);
  };
  const isValidEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };
  const clearPassengerErrors = () => {
    setPassengerForm(prevForm =>
      prevForm.map(passenger => ({
        ...passenger,
        errors: {}
      }))
    );
  };

  // passenger handle
  const handlePChange = (index, field, value) => {
    if (index === null) {
      index = editingPassengerForm.length;
    }
    setPassengerForm((prevPassengers) => {
      const updatedPassengers = [...prevPassengers];
      let errorMessage = '';
      if (field === 'mobile') {
        errorMessage = isValidPhone(value) ? '' : 'Invalid phone number';
      } else if (field === 'email') {
        errorMessage = isValidEmail(value) ? '' : 'Invalid email address';
      }
      updatedPassengers[index] = {
        ...updatedPassengers[index],
        [field]: value,
        errors: {
          ...updatedPassengers[index]?.errors,
          [field]: errorMessage
        }
      };
      if (field === 'firstName' || field === 'middleName' || field === 'lastName') {
        const firstName = field === 'firstName' ? value : updatedPassengers[index].firstName || '';
        const middleName = field === 'middleName' ? value : updatedPassengers[index].middleName || '';
        const lastName = field === 'lastName' ? value : updatedPassengers[index].lastName || '';
        updatedPassengers[index].fullName = `${firstName} ${lastName} ${middleName}`.trim();
      }
      return updatedPassengers;
    });
  };
  const handlePassengerFormSave = (e) => {
    e.preventDefault();
    const isPassengerEmpty = (passenger) =>
      Object.values(passenger).every(value => value === "");
    if (passengerForm.every(isPassengerEmpty)) { return; }
    const hasErrors = passengerForm.some(passenger =>
      passenger.errors && Object.values(passenger.errors).some(error => error !== '')
    );
    if (hasErrors) {
      toast.error("Please correct the errors before saving.");
      return;
    }
    setValue('hotelProductDetails[0].product.passenger', passengerForm);
    setEditingPassengerForm(passengerForm);
    setIsError(prevState => ({ ...prevState, passenger: false }));
    calcCharge(custChargeForm, passengerForm.length, taxValues);
    setRemarks([]);
    setpassengerFormEdit({ visible: false, editingIndex: null });
  };

  const handleDeleteP = (index) => {
    const updatedPassenger = editingPassengerForm.filter((_, i) => i !== index);
    setRemarks([]);
    if (updatedPassenger.length <= 1) { setValue("split", false) };
    setValue("passenger", updatedPassenger)
    setEditingPassengerForm(updatedPassenger);
    setPassengerForm(updatedPassenger);
  };

  // Hotel handle
  const handleHChange = (index, field, value) => {
    if (index === null) {
      index = editingHotelForm.length;
    }
    setHotelForm((prevHotel) => {
      const updatedHotel = [...prevHotel];
      if (field.includes('.')) {
        const [parentField, childField] = field.split('.');
        updatedHotel[index] = {
          ...updatedHotel[index],
          [parentField]: {
            ...updatedHotel[index]?.[parentField],
            [childField]: value
          }
        };
      } else {
        updatedHotel[index] = {
          ...updatedHotel[index],
          [field]: value
        };
      }
      if (['fare.hotelTarrif', 'fare.hotelTaxes', 'fare.additionalCharges'].includes(field)) {
        const fare = updatedHotel[index].fare || {};
        const hotelTarrif = parseFloat(fare.hotelTarrif) || 0;
        const hotelTaxes = parseFloat(fare.hotelTaxes) || 0;
        const additionalCharges = parseFloat(fare.additionalCharges) || 0;
        const totalFare = hotelTarrif + hotelTaxes + additionalCharges;
        updatedHotel[index].fare = {
          ...fare,
          netAmount: totalFare
        };
      }
      return updatedHotel;
    });
  };

  const handleHotelFormSave = (e) => {
    e.preventDefault();
    const isHotelEmpty = (hotelDetails) =>
      Object.values(hotelDetails).every(value => value === "");
    if (hotelForm.every(isHotelEmpty)) { return; }
    setValue('hotelProductDetails[0].product.carriers', hotelForm);
    setEditingHotelForm(hotelForm);
    calcFare(hotelForm);
    setIsError(prevState => ({ ...prevState, hotelDetails: false }));
    sethotelFormEdit({ visible: false, editingIndex: null });
  };

  const handleDeleteH = (index) => {
    const updatedHotel = editingHotelForm.filter((_, i) => i !== index);
    setValue("hotelProductDetails[0].product.carriers", updatedHotel)
    setEditingHotelForm(updatedHotel);
    setHotelForm(updatedHotel);
    calcFare(updatedHotel);
  };

  const updateCurrentRemark = (field, value) => {
    setCurrentRemark(prev => ({ ...prev, [field]: value }));
  };
  const addRemark = (index) => {
    if (index === null) {
      index = editingPassengerForm.length;
    }
    if (currentRemark.name || currentRemark.charge) {
      if (!remarks.some(r => r.name === currentRemark.name && r.charge === currentRemark.charge)) { setRemarks(prev => [...prev, currentRemark]); }
      setPassengerForm(prevForm => {
        const updatedForm = [...prevForm];
        if (!updatedForm[index]) {
          updatedForm[index] = {};
        }
        if (!updatedForm[index].remark) {
          updatedForm[index].remark = [];
        }
        if (!updatedForm[index].remark.some(r => r.name === currentRemark.name && r.charge === currentRemark.charge)) {
          updatedForm[index].remark.push(currentRemark);
        }
        return updatedForm;
      });
      setCurrentRemark({ name: '', charge: '' });
    }
  };
  const deleteRemark = (remarkIndex, passengerIndex) => {
    if (passengerIndex === null) {
      passengerIndex = editingPassengerForm.length;
    }
    setRemarks(prevRemarks => prevRemarks.filter((_, index) => index !== remarkIndex));
    setPassengerForm(prevForm => {
      const updatedForm = [...prevForm];
      if (updatedForm[passengerIndex] && updatedForm[passengerIndex].remark) {
        updatedForm[passengerIndex].remark = updatedForm[passengerIndex].remark.filter((_, index) => index !== remarkIndex);
      }
      return updatedForm;
    });
  };

  const calcFare = (hotelForm) => {
    let totalHotelTariff = 0;
    let totalHotelTaxes = 0;
    let totalAdditionalCharges = 0;

    hotelForm.forEach(hotel => {
      totalHotelTariff += parseFloat(hotel.fare?.hotelTarrif) || 0;
      totalHotelTaxes += parseFloat(hotel.fare?.hotelTaxes) || 0;
      totalAdditionalCharges += parseFloat(hotel.fare?.additionalCharges) || 0;
    });
    const totalNetAmount = totalHotelTariff + totalHotelTaxes + totalAdditionalCharges + parseFloat(watch("hotelProductDetails[0].product.fare[0].markup"));
    const updatedFare = [
      {
        hotelTarrif: totalHotelTariff,
        hotelTaxes: totalHotelTaxes,
        markup: parseFloat(watch("hotelProductDetails[0].product.fare[0].markup")) || 0,
        netAmount: totalNetAmount,
        additionalCharges: totalAdditionalCharges,
      }
    ];
    setValue('hotelProductDetails[0].product.fare', updatedFare);
  }

  const hotelTarrif = watch("hotelProductDetails[0].product.fare[0].hotelTarrif", 0);
  const markup = watch("hotelProductDetails[0].product.fare[0].markup", 0);
  const hotelTaxes = watch("hotelProductDetails[0].product.fare[0].hotelTaxes", 0);
  const additionalCharges = watch("hotelProductDetails[0].product.fare[0].additionalCharges", 0);

  useEffect(() => {
    const parsedHotelTarrif = parseFloat(hotelTarrif) || 0;
    const parsedMarkup = parseFloat(markup) || 0;
    const parsedHotelTaxes = parseFloat(hotelTaxes) || 0;
    const parsedAdditionalCharges = parseFloat(additionalCharges) || 0;
    const netAmount = parsedHotelTarrif + parsedMarkup + parsedHotelTaxes + parsedAdditionalCharges;
    setValue("hotelProductDetails[0].product.fare[0].netAmount", netAmount);
  }, [hotelTarrif, markup, hotelTaxes, setValue, additionalCharges]);

  const handleSplitInv = (checked) => {
    setValue('split', checked);
  };

  const [formState, setFormState] = useState('Draft');
  const updateValidationRules = (state) => {
    if (state === 'Submitted') {
      setValue('state', 'Submitted');
      setValue('hotelProductDetails[0].state', 'Submitted');
    } else {
      setValue('state', 'Draft');
      setValue('hotelProductDetails[0].state', 'Draft');
    }
    trigger();
  }

  const onSave = () => {
    setFormState('Draft');
    updateValidationRules('Draft');
    const data = getValues();
    setIsError(prevState => ({ ...prevState, passenger: false, fare: false, supplier: false }))
    if (!data.customerRefOrValue?.id) {
      setIsError(prevState => ({ ...prevState, customer: true }));
      return;
    }
  }

  const onCreate = (state) => {
    setFormState('Submitted');
    updateValidationRules('Submitted');
    const data = getValues();
    if (state) {
      setValue('state', state);
      setValue('hotelProductDetails[0].state', state);
    }
    if (!data.customerRefOrValue?.id) {
      setIsError(prevState => ({ ...prevState, customer: true }));
      if (!data.hotelProductDetails[0]?.product.agentRefOrValue?.name) {
        setIsError(prevState => ({ ...prevState, supplier: true }));
      }
      return;
    }
    if (!data.hotelProductDetails[0]?.product.agentRefOrValue?.name) {
      setIsError(prevState => ({ ...prevState, supplier: true }));
      return;
    }
    const passengers = data.hotelProductDetails[0]?.product?.passenger || [];
    if (passengers.length === 0 && !passengers[0]?.firstName) {
      setIsError(prevState => ({ ...prevState, passenger: true }));
      setpassengerFormEdit({ visible: true, editingIndex: null })
      toast.error("Passenger is required");
      return;
    }
    const hotel = data.hotelProductDetails[0]?.product?.carriers || [];
    if (hotel.length === 0 && !hotel[0]?.hotelDetail) {
      setIsError(prevState => ({ ...prevState, hotelDetails: true }));
      sethotelFormEdit({ visible: true, editingIndex: null })
      toast.error("Hotel details is required");
      return;
    }
  }

  const onSubmit = (data) => {
    // if (data.hotelProductDetails?.[0]?.product?.fare?.[0]) {
    //   const fare = data.hotelProductDetails[0].product.fare[0];
    //   if (!data.agent) {
    //     fare.agentCommissionAmount = 0;
    //   } else {
    //     fare.netAmount += (parseFloat(fare.agentCommissionAmount) || 0);
    //   }
    // }
    const netAmt = calcNet(data);
    if (data.hotelProductDetails && data.hotelProductDetails[0]) {
      data.hotelProductDetails[0].netAmount = netAmt;
      data.netAmount = netAmt;
    }
    if (data.invoiceId) {
      handleEditInv(data);
    } else {
      handleCreateInv(data);
    }
  };

  const handleCreateInv = async (data) => {
    const { id, ...requestData } = data;
    if (Object.values(isError).some(error => error)) {
      return;
    }
    setLoading("Create");
    try {
      const response = await fetch(`${(process.env.REACT_APP_API_HOST_INV === undefined) ? "" : process.env.REACT_APP_API_HOST_INV}/amigo-lekha-api/invoiceManagement/v1/invoice`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(requestData),
      });
      if (!response.ok) {
        throw new Error('Failed to create invoice');
      }
      const res = await response.json();
      const id = encodeURIComponent(res.invoiceId);
      reset();
      toast.success("Invoice created successfuly!", { duration: "5000", position: "top-right", description: `Invoice Id - ${id}` });
      if (res.state === "Draft") {
        navigate(`/dashboard/invoices/hotel/${productType}/edit/${id}`, { state: { item: res } });
      } else {
        navigate(`/dashboard/invoices/hotel/${productType}`);
      }
    } catch (error) {
      console.error("Error creating invoice:", error);
      toast.error("Failed to create invoice");
    } finally {
      setLoading("");
    }
  };

  const handleEditInv = async (data) => {
    if (Object.values(isError).some(error => error)) {
      return;
    }
    if (data.state === "Draft") {
      setLoading("Edit");
    } else {
      setLoading("Create");
    }
    try {
      const response = await fetch(`${(process.env.REACT_APP_API_HOST_INV === undefined) ? "" : process.env.REACT_APP_API_HOST_INV}/amigo-lekha-api/invoiceManagement/v1/invoice/${data.invoiceId}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(data),
      });
      if (!response.ok) {
        throw new Error('Failed to edit invoice');
      }
      const res = await response.json();
      const id = encodeURIComponent(res.invoiceId);
      toast.success(`Invoice ${res.state === "Draft" ? "Edited" : "Submitted"} Successfuly!`, { duration: "5000", position: "top-right", description: `Invoice Id - ${id}` });
      reset(res);
      if (res.state === "Draft") {
        navigate(`/dashboard/invoices/hotel/${productType}/edit/${id}`, { state: { item: res } });
      } else {
        navigate(`/dashboard/invoices/hotel/${productType}`);
      }
    } catch (error) {
      console.error("Error editing invoice:", error);
      toast.error("Failed to edit invoice");
    } finally {
      setLoading("");
    }
  };
  const calcNet = (data) => {
    let fareTotal = data.hotelProductDetails[0]?.product?.fare[0]?.netAmount;
    if (isNaN(fareTotal) || fareTotal === undefined) { fareTotal = 0; }
    const chrgTax = data.hotelProductDetails[0].product.charges;
    let totalValue = 0;
    let taxTotal = 0;
    if (Array.isArray(chrgTax)) {
      chrgTax.forEach(chargeItem => {
        totalValue += parseFloat(chargeItem.totalValue) || 0;
        taxTotal += parseFloat(chargeItem.taxTotal) || 0;
      });
    }
    const remarks = data.hotelProductDetails?.[0]?.product?.passenger.filter(passenger => passenger?.remark?.length > 0 && passenger.remark.some(remark => remark.name && remark.charge));
    let remarksTotal = 0;
    remarks?.forEach(passenger => {
      passenger.remark.forEach(remark => {
        remarksTotal += parseFloat(remark.charge) || 0;
      });
    });
    const netAmount = fareTotal + totalValue + taxTotal + remarksTotal;
    return netAmount;
  }

  return (
    <div className='mx-8 my-5'>
      <Link to={`/dashboard/invoices/hotel/${productType}`} className='absolute mt-1 ml-5'><Button variant="outline" size="icon"><Undo2 className='size-4' /></Button></Link>
      <div className="flex items-center justify-center w-full mb-5 text-[3vh] font-bold">{id ? "Edit" : "New"} Invoice (hotel)</div>
      {invoiceId && <div className='flex mx-5 my-0'><div>Invoice Id - <span className='text-primary/70'>{invoiceId}</span></div><div className='ml-auto mr-2'>Total NetAmount : ₹{data?.hotelProductDetails?.[0].netAmount}&ensp;|</div><div>Created By : {data?.createdBy}</div></div>}
      <Separator />
      <form onSubmit={handleSubmit(onSubmit)} className='mt-2'>
        <ScrollArea className="h-[calc(100vh-200px)] lg:h-[70vh]">
          <div className="grid gap-4 px-5 py-2">
            <div className="grid grid-cols-1 gap-4 md:grid-cols-2 lg:grid-cols-4">
              <div className="flex flex-row items-center gap-2">
                <Label className="flex w-40 lg:w-44">Invoice Date{errors.invoiceDate && <p className="ml-1 text-destructive">*</p>}</Label>
                <Controller control={control} name="invoiceDate" rules={{ required: true }} {...register("invoiceDate")}
                  render={({ field }) => (
                    <DatePicker value={field.value} onChange={(date) => field.onChange(date)} disableDate={false} current={true} error={errors.invoiceDate} />
                  )} />
              </div>
              <div className="flex gap-2">
                <div className="flex flex-row items-center gap-2">
                  <Label className="flex w-32">Customer Name{isError.customer && <p className="ml-1 text-destructive">*</p>}</Label>
                  <SearchCustomer onCustomerSelect={handleCustomerSelect} value={customerRefOrValue?.id} isVisible={isVisible} isError={isError.customer} />
                  {/* {isError.customer && <p className="text-xs text-red-500">Select Customer!</p>} */}
                </div>
                <Button variant='secondary' className="w-20 h-7" onClick={(e) => { setCustomerForm(!customerForm); e.preventDefault() }}>{customerForm ? "Hide" : "More"} Info</Button>
              </div>
            </div>
            {customerForm && (
              <div className="grid grid-cols-1 gap-2 md:grid-cols-2 lg:grid-cols-4">
                <div className="flex flex-row items-center gap-2">
                  <Label className="w-40">Phone No</Label>
                  <Input className="h-7 bg-muted/50" disabled={true} name="phone" value={customerRefOrValue?.phone} {...register("customerRefOrValue.phone")} />
                </div>
                <div className="flex flex-row items-center gap-2">
                  <Label className="w-40">Email</Label>
                  <Input className="h-7 bg-muted/50" disabled={true} name="email" value={customerRefOrValue?.email} {...register("customerRefOrValue.email")} />
                </div>
                <div className="flex flex-row items-center gap-2">
                  <Label className="w-40">Print In Name</Label>
                  <Input className="h-7 bg-muted/50" disabled={true} name="printInName" {...register("printInName")} />
                </div>
                <div className="flex flex-row items-center gap-2">
                  <Label className="w-36">Address</Label>
                  <Textarea className="h-16 bg-muted/50" disabled={true} name="address" value={customerRefOrValue?.address} {...register("customerRefOrValue.address")} />
                </div>
                <div className="flex flex-row items-center gap-2">
                  <Label className="w-40">PAN No</Label>
                  <Input className="h-7 bg-muted/50" disabled={true} name="panNo" value={customerRefOrValue?.panNo} {...register("customerRefOrValue.panNo")} />
                </div>
                <div className="flex flex-row items-center gap-2">
                  <Label className="w-40">GST Number</Label>
                  <Input className="h-7 bg-muted/50" disabled={true} name="gstNo" value={customerRefOrValue?.gstNo} {...register("customerRefOrValue.gstNo")} />
                </div>
              </div>
            )}
            <div className="grid grid-cols-1 gap-x-4 gap-y-2 md:grid-cols-2 lg:grid-cols-4">
              <div className="flex flex-row items-center gap-2">
                <Label className="w-40 lg:w-44">Due Date</Label>
                <Controller control={control} name="dueDate" {...register("dueDate")}
                  render={({ field }) => (
                    <DatePicker value={field.value} onChange={(date) => field.onChange(date)} disableDate={false} />
                  )}
                />
              </div>
              <div className="flex flex-row items-center gap-2">
                <Label className="flex w-40 lg:w-44">Supplier Name{isError.supplier && <p className="ml-1 text-destructive">*</p>}</Label>
                <SupplierSelect onSupplierSelect={handleSupplierSelect} value={supplierName.id} isVisible={isVisible} isError={isError.supplier} />
              </div>
              <div className="flex flex-row items-center gap-2">
                <Label className="flex w-40 lg:w-48">Supplier Amount</Label>
                <Input className="h-7 bg-muted/50" name="supplierNetAmount" {...register("hotelProductDetails[0].supplierNetAmount")} />
              </div>
            </div>
            {/* <Separator />
            <CardDescription>Product Details :</CardDescription> */}
            <div className="grid grid-cols-1 gap-x-4 gap-y-2 md:grid-cols-2 lg:grid-cols-4">
              <div className="flex flex-row items-center gap-2">
                <Label className="flex w-40 lg:w-44">Hotel Details{errors.hotelProductDetails?.[0]?.product?.hotelDetails && <p className="ml-1 text-destructive">*</p>}</Label>
                <Input className={`h-7 bg-muted/50 ${errors.hotelProductDetails?.[0]?.product?.hotelDetails ? 'border-destructive focus-visible:ring-0' : ''}`} name="hotelDetails" {...register("hotelProductDetails[0].product.hotelDetails", { required: formState === "Submitted" })} />
              </div>
              <div className="flex flex-row items-center gap-2">
                <Label className="flex w-40 lg:w-48">Reservation No.{errors.hotelProductDetails?.[0]?.product?.reservationNo && <p className="ml-1 text-destructive">*</p>}</Label>
                <Input className={`h-7 bg-muted/50 ${errors.hotelProductDetails?.[0]?.product?.reservationNo ? 'border-destructive focus-visible:ring-0' : ''}`} name="reservationNo" {...register("hotelProductDetails[0].product.reservationNo", { required: formState === "Submitted" })} />
              </div>
              <div className="flex flex-row items-center gap-2">
                <Label className="flex w-40 lg:w-44">Requisition By{errors.hotelProductDetails?.[0]?.product?.requisitionBy && <p className="ml-1 text-destructive">*</p>}</Label>
                <Input className={`h-7 bg-muted/50 ${errors.hotelProductDetails?.[0]?.product?.requisitionBy ? 'border-destructive focus-visible:ring-0' : ''}`} name="requisitionBy" {...register("hotelProductDetails[0].product.requisitionBy", { required: formState === "Submitted" })} />
              </div>
              <div className="flex flex-row items-center gap-2">
                <Label className="flex w-40">Handled By{errors.hotelProductDetails?.[0]?.product?.handledBy && <p className="ml-1 text-destructive">*</p>}</Label>
                <Input className={`h-7 bg-muted/50 ${errors.hotelProductDetails?.[0]?.product?.handledBy ? 'border-destructive focus-visible:ring-0' : ''}`} name="handledBy" {...register("hotelProductDetails[0].product.handledBy", { required: formState === "Submitted" })} />
              </div>
              <div className="flex flex-row items-center gap-2">
                <Label className="flex w-40 lg:w-44">Through Agent</Label>
                <Input className="h-7 bg-muted/50" name="agent" {...register("agent")} />
              </div>
              {(agentComm !== "" || null) &&
                <div className="flex flex-row items-center gap-2">
                  <Label className="flex w-40 lg:w-44">Commission Amt.</Label>
                  <Input className="h-7 bg-muted/50" name="agentCommissionAmount" {...register("hotelProductDetails[0].product.fare[0].agentCommissionAmount")} />
                </div>
              }
            </div>
            <Separator />
            <div className="flex flex-row items-center justify-between gap-4 mr-2">
              <CardDescription>Guest Details :</CardDescription>
              <div className='flex items-center gap-4'>{editingPassengerForm.length > 1 && <div className='flex items-center gap-2'>Split Invoice -<Checkbox onCheckedChange={(checked) => { handleSplitInv(checked) }} /></div>}
                <Button size='sm' variant="secondary" onClick={(e) => { setpassengerFormEdit(prevState => ({ ...prevState, visible: !prevState.visible, editingIndex: null })); e.preventDefault(); setCurrentRemark({ name: '', charge: '' }); setRemarks([]); }}>Add Guest <CirclePlus className="ml-2 size-4" /></Button>
              </div>
            </div>
            <Card>
              <CardContent className="p-0">
                <Table>
                  <TableHeader className="bg-secondary/60">
                    <TableRow>
                      <TableHead className="rounded-tl-md"></TableHead>
                      <TableHead></TableHead>
                      <TableHead>Title</TableHead>
                      <TableHead>Name</TableHead>
                      <TableHead>Type / Gender</TableHead>
                      <TableHead>Mobile</TableHead>
                      <TableHead>Email</TableHead>
                      <TableHead className="rounded-tr-md"><span className="sr-only">Actions</span></TableHead>
                    </TableRow>
                  </TableHeader>
                  <TableBody>
                    {editingPassengerForm.length > 0 ? (
                      editingPassengerForm.map((passenger, index) => (
                        <React.Fragment key={index}>
                          <TableRow>
                            <TableCell></TableCell>
                            <TableCell>{index + 1}</TableCell>
                            <TableCell>{passenger.title}</TableCell>
                            <TableCell>{passenger.fullName}</TableCell>
                            <TableCell>{passenger.paxType} / {passenger.gender}</TableCell>
                            <TableCell>{passenger.mobile}</TableCell>
                            <TableCell>{passenger.email}</TableCell>
                            <TableCell>
                              <div className="flex flex-row items-center gap-4">
                                <Edit onClick={() => setpassengerFormEdit(prevState => ({ ...prevState, visible: !prevState.visible, editingIndex: index }))} className="cursor-pointer text-primary size-4" />
                                <Trash2 onClick={() => handleDeleteP(index)} className="cursor-pointer text-destructive size-4" />
                              </div>
                            </TableCell>
                          </TableRow>
                          {passengerFormEdit.visible && passengerFormEdit.editingIndex === index && (
                            <TableRow>
                              <TableCell colSpan={11}>
                                <div className="grid gap-3 mx-6 my-4 lg:grid-cols-5 sm:grid-cols-1 md:grid-cols-2">
                                  <div className="flex flex-row items-center gap-2">
                                    <Label className="flex w-40 lg:w-28">Title{isError.passenger && <p className="ml-1 text-destructive">*</p>}</Label>
                                    <Select name="title" defaultValue={passengerFormEdit.editingIndex !== null ? passengerForm[passengerFormEdit.editingIndex]?.title || '' : ''}
                                      onValueChange={(e) => handlePChange(passengerFormEdit.editingIndex, 'title', e)}>
                                      <SelectTrigger className="w-full h-7 bg-muted/50 focus:ring-transparent">
                                        <SelectValue />
                                      </SelectTrigger>
                                      <SelectContent>
                                        <SelectGroup>
                                          {titleItems.map(item => (
                                            <SelectItem key={item} value={item}>
                                              {item}
                                            </SelectItem>
                                          ))}
                                        </SelectGroup>
                                      </SelectContent>
                                    </Select>
                                  </div>
                                  <div className="flex flex-row items-center gap-2">
                                    <Label className="flex w-40">First Name{isError.passenger && <p className="ml-1 text-destructive">*</p>}</Label>
                                    <Input className="h-7 bg-muted/50" name="firstName" defaultValue={passengerFormEdit.editingIndex !== null ? passengerForm[passengerFormEdit.editingIndex]?.firstName || '' : ''}
                                      onChange={(e) => handlePChange(passengerFormEdit.editingIndex, 'firstName', e.target.value)} />
                                  </div>
                                  <div className="flex flex-row items-center gap-2">
                                    <Label className="flex w-40">Middle Name{isError.passenger && <p className="ml-1 text-destructive">*</p>}</Label>
                                    <Input className="h-7 bg-muted/50" name="middleName" defaultValue={passengerFormEdit.editingIndex !== null ? passengerForm[passengerFormEdit.editingIndex]?.middleName || '' : ''}
                                      onChange={(e) => handlePChange(passengerFormEdit.editingIndex, 'middleName', e.target.value)} />
                                  </div>
                                  <div className="flex flex-row items-center gap-2">
                                    <Label className="flex w-40">Last Name{isError.passenger && <p className="ml-1 text-destructive">*</p>}</Label>
                                    <Input className="h-7 bg-muted/50" name="lastName" defaultValue={passengerFormEdit.editingIndex !== null ? passengerForm[passengerFormEdit.editingIndex]?.lastName || '' : ''}
                                      onChange={(e) => handlePChange(passengerFormEdit.editingIndex, 'lastName', e.target.value)} />
                                  </div>
                                  <div className="flex flex-col justify-center">
                                    <div className="flex flex-row items-center gap-2">
                                      <Label className="flex w-40">Mobile{isError.passenger && <p className="ml-1 text-destructive">*</p>}</Label>
                                      <Input className="h-7 bg-muted/50" name="mobile" defaultValue={passengerFormEdit.editingIndex !== null ? passengerForm[passengerFormEdit.editingIndex]?.mobile || '' : ''}
                                        onChange={(e) => handlePChange(passengerFormEdit.editingIndex, 'mobile', e.target.value)} />
                                    </div>
                                    {passengerForm[passengerFormEdit.editingIndex]?.errors?.mobile && (
                                      <p className="text-sm text-destructive">{passengerForm[passengerFormEdit.editingIndex].errors.mobile}</p>
                                    )}
                                  </div>
                                  <div className="flex flex-col justify-center">
                                    <div className="flex flex-row items-center gap-2">
                                      <Label className="flex w-40 lg:w-28">Email{isError.passenger && <p className="ml-1 text-destructive">*</p>}</Label>
                                      <Input className="h-7 bg-muted/50" name="email" defaultValue={passengerFormEdit.editingIndex !== null ? passengerForm[passengerFormEdit.editingIndex]?.email : ''}
                                        onChange={(e) => handlePChange(passengerFormEdit.editingIndex, 'email', e.target.value)} />
                                    </div>
                                    {passengerForm[passengerFormEdit.editingIndex]?.errors?.email && (
                                      <p className="text-sm text-destructive">{passengerForm[passengerFormEdit.editingIndex].errors.email}</p>
                                    )}
                                  </div>
                                  <div className="flex flex-row items-center gap-2">
                                    <Label className="flex w-40 lg:w-32">Pax Type{isError.passenger && <p className="ml-1 text-destructive">*</p>}</Label>
                                    <Select name="paxType" defaultValue={passengerFormEdit.editingIndex !== null ? passengerForm[passengerFormEdit.editingIndex]?.paxType || '' : ''}
                                      onValueChange={(e) => handlePChange(passengerFormEdit.editingIndex, 'paxType', e)}>
                                      <SelectTrigger className="w-full h-7 bg-muted/50 focus:ring-transparent">
                                        <SelectValue />
                                      </SelectTrigger>
                                      <SelectContent>
                                        <SelectGroup>
                                          {paxTypeItems.map(item => (
                                            <SelectItem key={item} value={item}>
                                              {item}
                                            </SelectItem>
                                          ))}
                                        </SelectGroup>
                                      </SelectContent>
                                    </Select>
                                  </div>
                                  <div className="flex flex-row items-center gap-2">
                                    <Label className="flex w-40">Gender{isError.passenger && <p className="ml-1 text-destructive">*</p>}</Label>
                                    <Select name="gender" defaultValue={passengerFormEdit.editingIndex !== null ? passengerForm[passengerFormEdit.editingIndex]?.gender || '' : ''}
                                      onValueChange={(e) => handlePChange(passengerFormEdit.editingIndex, 'gender', e)}>
                                      <SelectTrigger className="w-full h-7 bg-muted/50 focus:ring-transparent">
                                        <SelectValue />
                                      </SelectTrigger>
                                      <SelectContent>
                                        <SelectGroup>
                                          {genderItems.map(item => (
                                            <SelectItem key={item} value={item}>
                                              {item}
                                            </SelectItem>
                                          ))}
                                        </SelectGroup>
                                      </SelectContent>
                                    </Select>
                                  </div>
                                  <div className="flex flex-row items-center col-span-2 gap-2">
                                    <Label className="w-40 lg:w-28">Address</Label>
                                    <Input className="h-7 bg-muted/50" name="address" defaultValue={passengerFormEdit.editingIndex !== null ? passengerForm[passengerFormEdit.editingIndex]?.address : ''}
                                      onChange={(e) => handlePChange(passengerFormEdit.editingIndex, 'address', e.target.value)} />
                                  </div>
                                  <div>
                                    <Card className="shadow-none">
                                      <CardContent className="px-4 py-2 text-sm rounded-md bg-muted/50">
                                        {passengerForm[passengerFormEdit.editingIndex]?.remark?.length > 0 ? (
                                          passengerForm[passengerFormEdit.editingIndex]?.remark.map((remark, index) => (
                                            <div key={index} className="flex justify-between mb-1">
                                              <span>{remark.name}</span>-
                                              <span>₹{remark.charge}</span>
                                              <div className='cursor-pointer' onClick={(e) => { deleteRemark(index, passengerFormEdit.editingIndex); e.preventDefault(); e.stopPropagation(); }}><Trash2 className='text-destructive size-4' /></div>
                                            </div>
                                          ))
                                        ) : (
                                          <div>No additional Charge</div>
                                        )}
                                      </CardContent>
                                    </Card>
                                  </div>
                                  <div className="flex flex-row items-center gap-2">
                                    <Label className="flex w-40">Remarks{isError.passenger && <p className="ml-1 text-destructive">*</p>}</Label>
                                    <Input
                                      className="h-7 bg-muted/50"
                                      name="remarkName"
                                      value={currentRemark.name}
                                      onChange={(e) => updateCurrentRemark('name', e.target.value)}
                                    />
                                  </div>
                                  <div className='flex items-center gap-2'>
                                    <div className="flex flex-row items-center gap-2">
                                      <Label className="flex w-40">Additional Charge{isError.passenger && <p className="ml-1 text-destructive">*</p>}</Label>
                                      <Input
                                        className="h-7 bg-muted/50"
                                        name="remarkCharge"
                                        value={currentRemark.charge}
                                        onChange={(e) => updateCurrentRemark('charge', e.target.value)}
                                      />
                                    </div>
                                    <div className="flex items-center p-2 rounded-md cursor-pointer h-7 bg-secondary" onClick={(e) => { addRemark(passengerFormEdit.editingIndex); e.preventDefault(); }}><CirclePlus className='size-4' /></div>
                                  </div>
                                </div>
                                <div className="flex flex-row col-span-4 mb-4 mr-6">
                                  <Button variant="outline" className="ml-auto mr-3 bg-primary/30 hover:bg-primary/40" onClick={handlePassengerFormSave}>{passengerFormEdit.editingIndex !== null ? "Save" : "Add"}</Button>
                                  <Button variant="outline" className="bg-primary/30 hover:bg-primary/40" onClick={() => { setpassengerFormEdit({ visible: false, editingIndex: null }); setCurrentRemark({ name: '', charge: '' }); }}>Close</Button>
                                </div>
                              </TableCell>
                            </TableRow>
                          )}
                        </React.Fragment>
                      ))
                    ) : (
                      <TableRow>
                        <TableCell colSpan={10} className="text-center">No Guest</TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
                {passengerFormEdit.visible && passengerFormEdit.editingIndex === null && (
                  <>
                    <Separator />
                    <div className="grid gap-3 mx-6 my-4 lg:grid-cols-5 sm:grid-cols-1 md:grid-cols-2">
                      <div className="flex flex-row items-center gap-2">
                        <Label className="flex w-40 lg:w-28">Title{isError.passenger && <p className="ml-1 text-destructive">*</p>}</Label>
                        <Select name="title" defaultValue={passengerFormEdit.editingIndex !== null ? passengerForm[passengerFormEdit.editingIndex]?.title || '' : ''}
                          onValueChange={(e) => handlePChange(passengerFormEdit.editingIndex, 'title', e)}>
                          <SelectTrigger className="w-full h-7 bg-muted/50 focus:ring-transparent">
                            <SelectValue />
                          </SelectTrigger>
                          <SelectContent>
                            <SelectGroup>
                              {titleItems.map(item => (
                                <SelectItem key={item} value={item}>
                                  {item}
                                </SelectItem>
                              ))}
                            </SelectGroup>
                          </SelectContent>
                        </Select>
                      </div>
                      <div className="flex flex-row items-center gap-2">
                        <Label className="flex w-40">First Name{isError.passenger && <p className="ml-1 text-destructive">*</p>}</Label>
                        <Input className="h-7 bg-muted/50" name="firstName" defaultValue={passengerFormEdit.editingIndex !== null ? passengerForm[passengerFormEdit.editingIndex]?.firstName || '' : ''}
                          onChange={(e) => handlePChange(passengerFormEdit.editingIndex, 'firstName', e.target.value)} />
                      </div>
                      <div className="flex flex-row items-center gap-2">
                        <Label className="flex w-40">Middle Name{isError.passenger && <p className="ml-1 text-destructive">*</p>}</Label>
                        <Input className="h-7 bg-muted/50" name="middleName" defaultValue={passengerFormEdit.editingIndex !== null ? passengerForm[passengerFormEdit.editingIndex]?.middleName || '' : ''}
                          onChange={(e) => handlePChange(passengerFormEdit.editingIndex, 'middleName', e.target.value)} />
                      </div>
                      <div className="flex flex-row items-center gap-2">
                        <Label className="flex w-40">Last Name{isError.passenger && <p className="ml-1 text-destructive">*</p>}</Label>
                        <Input className="h-7 bg-muted/50" name="lastName" defaultValue={passengerFormEdit.editingIndex !== null ? passengerForm[passengerFormEdit.editingIndex]?.lastName || '' : ''}
                          onChange={(e) => handlePChange(passengerFormEdit.editingIndex, 'lastName', e.target.value)} />
                      </div>
                      <div className="flex flex-col justify-center">
                        <div className="flex flex-row items-center gap-2">
                          <Label className="flex w-40">Mobile{isError.passenger && <p className="ml-1 text-destructive">*</p>}</Label>
                          <Input className="h-7 bg-muted/50" name="mobile" defaultValue={passengerFormEdit.editingIndex !== null ? passengerForm[passengerFormEdit.editingIndex]?.mobile || '' : ''}
                            onChange={(e) => handlePChange(passengerFormEdit.editingIndex, 'mobile', e.target.value)} />
                        </div>
                        {passengerForm[editingPassengerForm.length]?.errors?.mobile && (
                          <p className="text-xs text-destructive">{passengerForm[editingPassengerForm.length].errors.mobile}</p>
                        )}
                      </div>
                      <div className="flex flex-col justify-center">
                        <div className="flex flex-row items-center gap-2">
                          <Label className="flex w-40 lg:w-28">Email{isError.passenger && <p className="ml-1 text-destructive">*</p>}</Label>
                          <Input className="h-7 bg-muted/50" name="email" defaultValue={passengerFormEdit.editingIndex !== null ? passengerForm[passengerFormEdit.editingIndex]?.email : ''}
                            onChange={(e) => handlePChange(passengerFormEdit.editingIndex, 'email', e.target.value)} />
                        </div>
                        {passengerForm[editingPassengerForm.length]?.errors?.email && (
                          <p className="text-xs text-destructive">{passengerForm[editingPassengerForm.length].errors.email}</p>
                        )}
                      </div>
                      <div className="flex flex-row items-center gap-2">
                        <Label className="flex w-40">Pax Type{isError.passenger && <p className="ml-1 text-destructive">*</p>}</Label>
                        <Select name="paxType" defaultValue={passengerFormEdit.editingIndex !== null ? passengerForm[passengerFormEdit.editingIndex]?.paxType || '' : ''}
                          onValueChange={(e) => handlePChange(passengerFormEdit.editingIndex, 'paxType', e)}>
                          <SelectTrigger className="w-full h-7 bg-muted/50 focus:ring-transparent">
                            <SelectValue />
                          </SelectTrigger>
                          <SelectContent>
                            <SelectGroup>
                              {paxTypeItems.map(item => (
                                <SelectItem key={item} value={item}>
                                  {item}
                                </SelectItem>
                              ))}
                            </SelectGroup>
                          </SelectContent>
                        </Select>
                      </div>
                      <div className="flex flex-row items-center gap-2">
                        <Label className="flex w-40">Gender{isError.passenger && <p className="ml-1 text-destructive">*</p>}</Label>
                        <Select name="gender" defaultValue={passengerFormEdit.editingIndex !== null ? passengerForm[passengerFormEdit.editingIndex]?.gender || '' : ''}
                          onValueChange={(e) => handlePChange(passengerFormEdit.editingIndex, 'gender', e)}>
                          <SelectTrigger className="w-full h-7 bg-muted/50 focus:ring-transparent">
                            <SelectValue />
                          </SelectTrigger>
                          <SelectContent>
                            <SelectGroup>
                              {genderItems.map(item => (
                                <SelectItem key={item} value={item}>
                                  {item}
                                </SelectItem>
                              ))}
                            </SelectGroup>
                          </SelectContent>
                        </Select>
                      </div>
                      <div className="flex flex-row items-center col-span-2 gap-2">
                        <Label className="w-40 lg:w-28">Address</Label>
                        <Input className="h-7 bg-muted/50" name="address" defaultValue={passengerFormEdit.editingIndex !== null ? passengerForm[passengerFormEdit.editingIndex]?.address : ''}
                          onChange={(e) => handlePChange(passengerFormEdit.editingIndex, 'address', e.target.value)} />
                      </div>
                      <div>
                        <Card className="shadow-none">
                          <CardContent className="px-4 py-2 text-sm rounded-md bg-muted/50">
                            {remarks.length > 0 ? (
                              remarks.map((remark, index) => (
                                <div key={index} className="flex justify-between mb-1">
                                  <span>{remark.name}</span>-
                                  <span>₹{remark.charge}</span>
                                  <div className='cursor-pointer' onClick={(e) => { deleteRemark(index, passengerFormEdit.editingIndex); e.preventDefault(); e.stopPropagation(); }}><Trash2 className='text-destructive size-4' /></div>
                                </div>
                              ))
                            ) : (
                              <div>No additional Charge</div>
                            )}
                          </CardContent>
                        </Card>
                      </div>
                      <div className="flex flex-row items-center gap-2">
                        <Label className="flex w-40">Remarks{isError.passenger && <p className="ml-1 text-destructive">*</p>}</Label>
                        <Input
                          className="h-7 bg-muted/50"
                          name="remarkName"
                          value={currentRemark.name}
                          onChange={(e) => updateCurrentRemark('name', e.target.value)}
                        />
                      </div>
                      <div className='flex items-center gap-2'>
                        <div className="flex flex-row items-center gap-2">
                          <Label className="flex w-40">Additional Charge{isError.passenger && <p className="ml-1 text-destructive">*</p>}</Label>
                          <Input
                            className="h-7 bg-muted/50"
                            name="remarkCharge"
                            value={currentRemark.charge}
                            onChange={(e) => updateCurrentRemark('charge', e.target.value)}
                          />
                        </div>
                        <div className="flex items-center p-2 rounded-md cursor-pointer h-7 bg-secondary" onClick={(e) => { addRemark(passengerFormEdit.editingIndex); e.preventDefault(); }}><CirclePlus className='size-4' /></div>
                      </div>
                    </div>
                    <div className="flex flex-row col-span-4 mb-4 mr-6">
                      <Button variant="outline" className="ml-auto mr-3 bg-primary/30 hover:bg-primary/40" onClick={handlePassengerFormSave}>{passengerFormEdit.editingIndex !== null ? "Save" : "Add"}</Button>
                      <Button variant="outline" className="bg-primary/30 hover:bg-primary/40" onClick={() => { clearPassengerErrors(); setpassengerFormEdit({ visible: false, editingIndex: null }); setCurrentRemark({ name: '', charge: '' }); }}>Close</Button>
                    </div>
                  </>
                )}
              </CardContent>
            </Card>
            <Separator />
            <div className="flex flex-row items-center justify-between gap-4 mr-2">
              <CardDescription>Hotel Details :</CardDescription>
              <Button size='sm' variant="secondary" onClick={(e) => { sethotelFormEdit(prevState => ({ ...prevState, visible: !prevState.visible, editingIndex: null })); e.preventDefault(); }}>Add Room <CirclePlus className="ml-2 size-4" /></Button>
            </div>
            <Card>
              <CardContent className="p-0">
                <Table>
                  <TableHeader className="bg-secondary/60">
                    <TableRow>
                      <TableHead className="rounded-tl-md"></TableHead>
                      <TableHead></TableHead>
                      <TableHead>Hotel Name</TableHead>
                      <TableHead>Room Type</TableHead>
                      <TableHead>Occupancy</TableHead>
                      <TableHead>Check In / Out Date</TableHead>
                      <TableHead>No. of Adults / Child</TableHead>
                      <TableHead>Total Fare</TableHead>
                      <TableHead className="rounded-tr-md"><span className="sr-only">Actions</span></TableHead>
                    </TableRow>
                  </TableHeader>
                  <TableBody>
                    {editingHotelForm.length > 0 ? (
                      editingHotelForm.map((hotelDetails, index) => (
                        <React.Fragment key={index}>
                          <TableRow>
                            <TableCell></TableCell>
                            <TableCell>{index + 1}</TableCell>
                            <TableCell>{hotelDetails.hotelDetail}</TableCell>
                            <TableCell>{hotelDetails.roomType}</TableCell>
                            <TableCell>{hotelDetails.occupancy}</TableCell>
                            <TableCell>{hotelDetails.checkinDate ? new Date(hotelDetails.checkinDate).toLocaleString() : ""} - {hotelDetails.checkoutDate ? new Date(hotelDetails.checkoutDate).toLocaleString() : ""}</TableCell>
                            <TableCell>{hotelDetails.noOfAdults || 0} / {hotelDetails.noOfChildren || 0}</TableCell>
                            <TableCell>{hotelDetails?.fare?.netAmount ? <GenericAmountSeparator value={hotelDetails?.fare?.netAmount} viewMode={true} /> : ""}</TableCell>
                            <TableCell>
                              <div className="flex flex-row items-center gap-4">
                                <Edit onClick={() => sethotelFormEdit(prevState => ({ ...prevState, visible: !prevState.visible, editingIndex: index }))} className="cursor-pointer text-primary size-4" />
                                <Trash2 onClick={() => handleDeleteH(index)} className="cursor-pointer text-destructive size-4" />
                              </div>
                            </TableCell>
                          </TableRow>
                          {hotelFormEdit.visible && hotelFormEdit.editingIndex === index && (
                            <TableRow>
                              <TableCell colSpan={11}>
                                <div className="grid gap-3 mx-6 my-4 lg:grid-cols-4 sm:grid-cols-1 md:grid-cols-2">
                                  <div className="flex flex-row items-center gap-2">
                                    <Label className="flex w-40">Hotel Name{isError.hotelDetails && <p className="ml-1 text-destructive">*</p>}</Label>
                                    <Input className="h-7 bg-muted/50" name="hotelDetail" defaultValue={hotelFormEdit.editingIndex !== null ? hotelForm[hotelFormEdit.editingIndex]?.hotelDetail || '' : ''}
                                      onChange={(e) => handleHChange(hotelFormEdit.editingIndex, 'hotelDetail', e.target.value)} />
                                  </div>
                                  <div className="flex flex-row items-center gap-2">
                                    <Label className="flex w-40">Room Type{isError.hotelDetails && <p className="ml-1 text-destructive">*</p>}</Label>
                                    <Select name="roomType" defaultValue={hotelFormEdit.editingIndex !== null ? hotelForm[hotelFormEdit.editingIndex]?.roomType || '' : ''}
                                      onValueChange={(e) => handleHChange(hotelFormEdit.editingIndex, 'roomType', e)}>
                                      <SelectTrigger className="w-full h-7 bg-muted/50 focus:ring-transparent">
                                        <SelectValue />
                                      </SelectTrigger>
                                      <SelectContent>
                                        <SelectGroup>
                                          {roomTypeItems.map(item => (
                                            <SelectItem key={item} value={item}>
                                              {item}
                                            </SelectItem>
                                          ))}
                                        </SelectGroup>
                                      </SelectContent>
                                    </Select>
                                  </div>
                                  <div className="flex flex-row items-center gap-2">
                                    <Label className="flex w-40">Occupancy{isError.hotelDetails && <p className="ml-1 text-destructive">*</p>}</Label>
                                    <Select name="occupancy" defaultValue={hotelFormEdit.editingIndex !== null ? hotelForm[hotelFormEdit.editingIndex]?.occupancy || '' : ''}
                                      onValueChange={(e) => handleHChange(hotelFormEdit.editingIndex, 'occupancy', e)}>
                                      <SelectTrigger className="w-full h-7 bg-muted/50 focus:ring-transparent">
                                        <SelectValue />
                                      </SelectTrigger>
                                      <SelectContent>
                                        <SelectGroup>
                                          {occupancyItems.map(item => (
                                            <SelectItem key={item} value={item}>
                                              {item}
                                            </SelectItem>
                                          ))}
                                        </SelectGroup>
                                      </SelectContent>
                                    </Select>
                                  </div>
                                  <div className="flex flex-row items-center gap-2">
                                    <Label className="flex w-40">Check InDate{isError.hotelDetails && <p className="ml-1 text-destructive">*</p>}</Label>
                                    <DateTimePicker className="h-7 bg-muted/50" value={hotelFormEdit.editingIndex !== null ? hotelForm[hotelFormEdit.editingIndex].checkinDate : ''}
                                      onChange={(date) => handleHChange(hotelFormEdit.editingIndex, 'checkinDate', date)} />
                                  </div>
                                  <div className="flex flex-row items-center gap-2">
                                    <Label className="flex w-40">Check OutDate{isError.hotelDetails && <p className="ml-1 text-destructive">*</p>}</Label>
                                    <DateTimePicker className="h-7 bg-muted/50" value={hotelFormEdit.editingIndex !== null ? hotelForm[hotelFormEdit.editingIndex].checkoutDate : ''}
                                      onChange={(date) => handleHChange(hotelFormEdit.editingIndex, 'checkoutDate', date)} />
                                  </div>
                                  <div className="flex flex-row items-center gap-2">
                                    <Label className="flex w-40">No. Of Adults{isError.hotelDetails && <p className="ml-1 text-destructive">*</p>}</Label>
                                    <Input className="h-7 bg-muted/50" name="noOfAdults" defaultValue={hotelFormEdit.editingIndex !== null ? hotelForm[hotelFormEdit.editingIndex]?.noOfAdults : ''}
                                      onChange={(e) => handleHChange(hotelFormEdit.editingIndex, 'noOfAdults', e.target.value)} />
                                  </div>
                                  <div className="flex flex-row items-center gap-2">
                                    <Label className="flex w-40">No. Of Children{isError.hotelDetails && <p className="ml-1 text-destructive">*</p>}</Label>
                                    <Input className="h-7 bg-muted/50" name="noOfChildren" defaultValue={hotelFormEdit.editingIndex !== null ? hotelForm[hotelFormEdit.editingIndex]?.noOfChildren : ''}
                                      onChange={(e) => handleHChange(hotelFormEdit.editingIndex, 'noOfChildren', e.target.value)} />
                                  </div>
                                  <div className="flex flex-row items-center gap-2">
                                    <Label className="flex w-40">Meal Plan{isError.hotelDetails && <p className="ml-1 text-destructive">*</p>}</Label>
                                    <Select name="mealPlan" defaultValue={hotelFormEdit.editingIndex !== null ? hotelForm[hotelFormEdit.editingIndex]?.mealPlan || '' : ''}
                                      onValueChange={(e) => handleHChange(hotelFormEdit.editingIndex, 'mealPlan', e)}>
                                      <SelectTrigger className="w-full h-7 bg-muted/50 focus:ring-transparent">
                                        <SelectValue />
                                      </SelectTrigger>
                                      <SelectContent>
                                        <SelectGroup>
                                          {mealPlanItems.map(item => (
                                            <SelectItem key={item} value={item}>
                                              {item}
                                            </SelectItem>
                                          ))}
                                        </SelectGroup>
                                      </SelectContent>
                                    </Select>
                                  </div>
                                  <div className="flex flex-row items-center gap-2">
                                    <Label className="flex w-40">Hotel Tariff{isError.hotelDetails && <p className="ml-1 text-destructive">*</p>}</Label>
                                    <Input className="h-7 bg-muted/50" name="hotelTarrif" defaultValue={hotelFormEdit.editingIndex !== null ? hotelForm[hotelFormEdit.editingIndex]?.fare?.hotelTarrif : ''}
                                      onChange={(e) => handleHChange(hotelFormEdit.editingIndex, 'fare.hotelTarrif', e.target.value)} />
                                  </div>
                                  <div className="flex flex-row items-center gap-2">
                                    <Label className="flex w-40">Hotel Taxes{isError.hotelDetails && <p className="ml-1 text-destructive">*</p>}</Label>
                                    <Input className="h-7 bg-muted/50" name="hotelTaxes" defaultValue={hotelFormEdit.editingIndex !== null ? hotelForm[hotelFormEdit.editingIndex]?.fare?.hotelTaxes : ''}
                                      onChange={(e) => handleHChange(hotelFormEdit.editingIndex, 'fare.hotelTaxes', e.target.value)} />
                                  </div>
                                  <div className="flex flex-row items-center gap-2">
                                    <Label className="flex w-40">Additional Charge{isError.hotelDetails && <p className="ml-1 text-destructive">*</p>}</Label>
                                    <Input className="h-7 bg-muted/50" name="additionalCharges" defaultValue={hotelFormEdit.editingIndex !== null ? hotelForm[hotelFormEdit.editingIndex]?.fare?.additionalCharges : ''}
                                      onChange={(e) => handleHChange(hotelFormEdit.editingIndex, 'fare.additionalCharges', e.target.value)} />
                                  </div>
                                  {/* <div className="flex flex-row items-center gap-2">
                                    <Label className="flex w-40">Total Fare{isError.hotelDetails && <p className="ml-1 text-destructive">*</p>}</Label>
                                    <Input className="h-7 bg-muted/50" name="netAmount" defaultValue={hotelFormEdit.editingIndex !== null ? hotelForm[hotelFormEdit.editingIndex]?.fare?.netAmount : ''}
                                      onChange={(e) => handleHChange(hotelFormEdit.editingIndex, 'fare.netAmount', e.target.value)} />
                                  </div> */}
                                </div>
                                <div className="flex flex-row col-span-4 mb-4 mr-6">
                                  <Button variant="outline" className="ml-auto mr-3 bg-primary/30 hover:bg-primary/40" onClick={handleHotelFormSave}>{hotelFormEdit.editingIndex !== null ? "Save" : "Add"}</Button>
                                  <Button variant="outline" className="bg-primary/30 hover:bg-primary/40" onClick={() => sethotelFormEdit({ visible: false, editingIndex: null })}>Close</Button>
                                </div>
                              </TableCell>
                            </TableRow>
                          )}
                        </React.Fragment>
                      ))
                    ) : (
                      <TableRow>
                        <TableCell colSpan={10} className="text-center">No Hotel</TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
                {hotelFormEdit.visible && hotelFormEdit.editingIndex === null && (
                  <>
                    <Separator />
                    <div className="grid gap-3 mx-6 my-4 lg:grid-cols-4 sm:grid-cols-1 md:grid-cols-2">
                      <div className="flex flex-row items-center gap-2">
                        <Label className="flex w-40">Hotel Name{isError.hotelDetails && <p className="ml-1 text-destructive">*</p>}</Label>
                        <Input className="h-7 bg-muted/50" name="hotelDetail" defaultValue={hotelFormEdit.editingIndex !== null ? hotelForm[hotelFormEdit.editingIndex]?.hotelDetail || '' : ''}
                          onChange={(e) => handleHChange(hotelFormEdit.editingIndex, 'hotelDetail', e.target.value)} />
                      </div>
                      <div className="flex flex-row items-center gap-2">
                        <Label className="flex w-40">Room Type{isError.hotelDetails && <p className="ml-1 text-destructive">*</p>}</Label>
                        <Select name="roomType" defaultValue={hotelFormEdit.editingIndex !== null ? hotelForm[hotelFormEdit.editingIndex]?.roomType || '' : ''}
                          onValueChange={(e) => handleHChange(hotelFormEdit.editingIndex, 'roomType', e)}>
                          <SelectTrigger className="w-full h-7 bg-muted/50 focus:ring-transparent">
                            <SelectValue />
                          </SelectTrigger>
                          <SelectContent>
                            <SelectGroup>
                              {roomTypeItems.map(item => (
                                <SelectItem key={item} value={item}>
                                  {item}
                                </SelectItem>
                              ))}
                            </SelectGroup>
                          </SelectContent>
                        </Select>
                      </div>
                      <div className="flex flex-row items-center gap-2">
                        <Label className="flex w-40">Occupancy{isError.hotelDetails && <p className="ml-1 text-destructive">*</p>}</Label>
                        <Select name="occupancy" defaultValue={hotelFormEdit.editingIndex !== null ? hotelForm[hotelFormEdit.editingIndex]?.occupancy || '' : ''}
                          onValueChange={(e) => handleHChange(hotelFormEdit.editingIndex, 'occupancy', e)}>
                          <SelectTrigger className="w-full h-7 bg-muted/50 focus:ring-transparent">
                            <SelectValue />
                          </SelectTrigger>
                          <SelectContent>
                            <SelectGroup>
                              {occupancyItems.map(item => (
                                <SelectItem key={item} value={item}>
                                  {item}
                                </SelectItem>
                              ))}
                            </SelectGroup>
                          </SelectContent>
                        </Select>
                      </div>
                      <div className="flex flex-row items-center gap-2">
                        <Label className="flex w-40">Check InDate{isError.hotelDetails && <p className="ml-1 text-destructive">*</p>}</Label>
                        <DateTimePicker className="h-7 bg-muted/50" value={hotelFormEdit.editingIndex !== null ? hotelForm[hotelFormEdit.editingIndex].checkinDate : ''}
                          onChange={(date) => handleHChange(hotelFormEdit.editingIndex, 'checkinDate', date)} />
                      </div>
                      <div className="flex flex-row items-center gap-2">
                        <Label className="flex w-40">Check OutDate{isError.hotelDetails && <p className="ml-1 text-destructive">*</p>}</Label>
                        <DateTimePicker className="h-7 bg-muted/50" value={hotelFormEdit.editingIndex !== null ? hotelForm[hotelFormEdit.editingIndex].checkoutDate : ''}
                          onChange={(date) => handleHChange(hotelFormEdit.editingIndex, 'checkoutDate', date)} />
                      </div>
                      <div className="flex flex-row items-center gap-2">
                        <Label className="flex w-40">No. Of Adults{isError.hotelDetails && <p className="ml-1 text-destructive">*</p>}</Label>
                        <Input className="h-7 bg-muted/50" name="noOfAdults" defaultValue={hotelFormEdit.editingIndex !== null ? hotelForm[hotelFormEdit.editingIndex]?.noOfAdults : ''}
                          onChange={(e) => handleHChange(hotelFormEdit.editingIndex, 'noOfAdults', e.target.value)} />
                      </div>
                      <div className="flex flex-row items-center gap-2">
                        <Label className="flex w-40">No. Of Children{isError.hotelDetails && <p className="ml-1 text-destructive">*</p>}</Label>
                        <Input className="h-7 bg-muted/50" name="noOfChildren" defaultValue={hotelFormEdit.editingIndex !== null ? hotelForm[hotelFormEdit.editingIndex]?.noOfChildren : ''}
                          onChange={(e) => handleHChange(hotelFormEdit.editingIndex, 'noOfChildren', e.target.value)} />
                      </div>
                      <div className="flex flex-row items-center gap-2">
                        <Label className="flex w-40">Meal Plan{isError.hotelDetails && <p className="ml-1 text-destructive">*</p>}</Label>
                        <Select name="mealPlan" defaultValue={hotelFormEdit.editingIndex !== null ? hotelForm[hotelFormEdit.editingIndex]?.mealPlan || '' : ''}
                          onValueChange={(e) => handleHChange(hotelFormEdit.editingIndex, 'mealPlan', e)}>
                          <SelectTrigger className="w-full h-7 bg-muted/50 focus:ring-transparent">
                            <SelectValue />
                          </SelectTrigger>
                          <SelectContent>
                            <SelectGroup>
                              {mealPlanItems.map(item => (
                                <SelectItem key={item} value={item}>
                                  {item}
                                </SelectItem>
                              ))}
                            </SelectGroup>
                          </SelectContent>
                        </Select>
                      </div>
                      <div className="flex flex-row items-center gap-2">
                        <Label className="flex w-40">Hotel Tariff{isError.hotelDetails && <p className="ml-1 text-destructive">*</p>}</Label>
                        <Input className="h-7 bg-muted/50" name="hotelTarrif" defaultValue={hotelFormEdit.editingIndex !== null ? hotelForm[hotelFormEdit.editingIndex]?.fare?.hotelTarrif : ''}
                          onChange={(e) => handleHChange(hotelFormEdit.editingIndex, 'fare.hotelTarrif', e.target.value)} />
                      </div>
                      <div className="flex flex-row items-center gap-2">
                        <Label className="flex w-40">Hotel Taxes{isError.hotelDetails && <p className="ml-1 text-destructive">*</p>}</Label>
                        <Input className="h-7 bg-muted/50" name="hotelTaxes" defaultValue={hotelFormEdit.editingIndex !== null ? hotelForm[hotelFormEdit.editingIndex]?.fare?.hotelTaxes : ''}
                          onChange={(e) => handleHChange(hotelFormEdit.editingIndex, 'fare.hotelTaxes', e.target.value)} />
                      </div>
                      <div className="flex flex-row items-center gap-2">
                        <Label className="flex w-40">Additional Charge{isError.hotelDetails && <p className="ml-1 text-destructive">*</p>}</Label>
                        <Input className="h-7 bg-muted/50" name="additionalCharges" defaultValue={hotelFormEdit.editingIndex !== null ? hotelForm[hotelFormEdit.editingIndex]?.fare?.additionalCharges : ''}
                          onChange={(e) => handleHChange(hotelFormEdit.editingIndex, 'fare.additionalCharges', e.target.value)} />
                      </div>
                      {/* <div className="flex flex-row items-center gap-2">
                        <Label className="flex w-40">Total Fare{isError.hotelDetails && <p className="ml-1 text-destructive">*</p>}</Label>
                        <Input className="h-7 bg-muted/50" name="netAmount" defaultValue={hotelFormEdit.editingIndex !== null ? hotelForm[hotelFormEdit.editingIndex]?.fare?.netAmount : ''}
                          onChange={(e) => handleHChange(hotelFormEdit.editingIndex, 'fare.netAmount', e.target.value)} />
                      </div> */}
                    </div>
                    <div className="flex flex-row col-span-4 mb-4 mr-6">
                      <Button variant="outline" className="ml-auto mr-3 bg-primary/30 hover:bg-primary/40" onClick={handleHotelFormSave}>{hotelFormEdit.editingIndex !== null ? "Save" : "Add"}</Button>
                      <Button variant="outline" className="bg-primary/30 hover:bg-primary/40" onClick={() => sethotelFormEdit({ visible: false, editingIndex: null })}>Close</Button>
                    </div>
                  </>
                )}
              </CardContent>
            </Card>
            <Separator />
            <CardDescription>Fare Details :</CardDescription>
            <div className="grid grid-cols-1 gap-x-4 gap-y-2 md:grid-cols-2 lg:grid-cols-5">
              <div className="flex flex-row items-center gap-2">
                <Label className="flex w-40">Hotel Tariff{errors.hotelProductDetails?.[0]?.product?.fare[0].hotelTarrif && <p className="ml-1 text-destructive">*</p>}</Label>
                <Input className="h-7 bg-muted/50" name="hotelTarrif" disabled {...register("hotelProductDetails[0].product.fare[0].hotelTarrif", { required: formState === "Submitted" })} />
              </div>
              <div className="flex flex-row items-center gap-2">
                <Label className="flex w-40">Hotel Taxes{errors.hotelProductDetails?.[0]?.product?.fare[0].hotelTaxes && <p className="ml-1 text-destructive">*</p>}</Label>
                <Input className="h-7 bg-muted/50" name="hotelTaxes" disabled {...register("hotelProductDetails[0].product.fare[0].hotelTaxes", { required: formState === "Submitted" })} />
              </div>
              <div className="flex flex-row items-center gap-2">
                <Label className="flex w-40">Additional Charges{errors.hotelProductDetails?.[0]?.product?.fare[0].additionalCharges && <p className="ml-1 text-destructive">*</p>}</Label>
                <Input className="h-7 bg-muted/50" name="additionalCharges" disabled {...register("hotelProductDetails[0].product.fare[0].additionalCharges", { required: formState === "Submitted" })} />
              </div>
              <div className="flex flex-row items-center gap-2">
                <Label className="flex w-40">Markup{errors.hotelProductDetails?.[0]?.product?.fare[0].markup && <p className="ml-1 text-destructive">*</p>}</Label>
                <Input className="h-7 bg-muted/50" name="markup" {...register("hotelProductDetails[0].product.fare[0].markup", { required: formState === "Submitted" })} />
              </div>
              <div className="flex flex-row items-center gap-2">
                <Label className="flex w-40">Total Fare</Label>
                <Input className="h-7 bg-muted/50" name="netAmount" disabled {...register("hotelProductDetails[0].product.fare[0].netAmount", { required: formState === "Submitted" })} />
              </div>
            </div>
            <Separator />
            <div className='grid grid-cols-1 gap-5 lg:grid-cols-2'>
              <div>
                <div className="flex flex-row items-center justify-between mb-3">
                  <CardDescription>Customer Charges Details :</CardDescription>
                </div>
                <Card>
                  <CardContent className="p-0">
                    <Table>
                      <TableHeader className="bg-secondary/60">
                        <TableRow>
                          <TableHead className="rounded-tl-md"></TableHead>
                          <TableHead></TableHead>
                          <TableHead>Charge Name</TableHead>
                          <TableHead>Charge ₹</TableHead>
                          <TableHead className="hidden md:table-cell">Total Charge ₹</TableHead>
                          <TableHead>Total Tax</TableHead>
                          <TableHead className="rounded-tr-md"><span className="sr-only">Actions</span></TableHead>
                        </TableRow>
                      </TableHeader>
                      <TableBody>
                        {CUST_CHARGE_TYPES.map((chargeType, index) => {
                          const charge = custChargeForm.find(c => c.chargeCodeName === chargeType) || {
                            chargeCodeName: chargeType,
                            calculationMethod: "",
                            value: "",
                            totalValue: "",
                            tax: [],
                            taxTotal: null
                          };
                          return (
                            <TableRow key={index}>
                              <TableCell></TableCell>
                              <TableCell></TableCell>
                              <TableCell>{charge.chargeCodeName}<span className='ml-2 text-xs'>{charge.calculationMethod ? `(${charge.calculationMethod})` : "(PerTicket)"}</span>
                              </TableCell>
                              <TableCell>
                                <Input
                                  type="number"
                                  className="w-40 h-6"
                                  defaultValue={charge.value || ""}
                                  onChange={(e) => handleChargeChange(index, 'value', e.target.value)}
                                  onBlur={handleChargeFormSave}
                                />
                              </TableCell>
                              <TableCell>{charge.totalValue || ""}</TableCell>
                              <TableCell className="cursor-pointer">
                                <span className='flex items-center'>{charge.taxTotal || ""}
                                  <TooltipProvider delayDuration={100}>
                                    <Tooltip>
                                      <TooltipTrigger asChild>
                                        {charge.taxTotal && <Info className='ml-1 size-4' />}
                                      </TooltipTrigger>
                                      <TooltipContent className="mb-2">
                                        {charge.tax && charge.tax.length > 0 && charge.tax.some(t => t.taxCode !== "") ? charge.tax.map((tax) => (<div key={tax.taxCode}>{`${tax.taxCode}: ${tax.value}`}</div>)) : 'No Taxes'}
                                      </TooltipContent>
                                    </Tooltip>
                                  </TooltipProvider>
                                </span>
                              </TableCell>
                            </TableRow>
                          );
                        })}
                      </TableBody>
                    </Table>
                  </CardContent>
                </Card>
              </div>
              <Separator className="lg:sr-only" />
              <div>
                <div className="flex flex-row items-center justify-between mb-3">
                  <CardDescription>Supplier Charges Details :</CardDescription>
                </div>
                <Card>
                  <CardContent className="p-0">
                    <Table>
                      <TableHeader className="bg-secondary/60">
                        <TableRow>
                          <TableHead className="rounded-tl-md"></TableHead>
                          <TableHead></TableHead>
                          <TableHead>Charge Name</TableHead>
                          <TableHead>Charge ₹</TableHead>
                          <TableHead className="hidden md:table-cell">Total Charge ₹</TableHead>
                          <TableHead>Total Tax</TableHead>
                          <TableHead className="rounded-tr-md"><span className="sr-only">Actions</span></TableHead>
                        </TableRow>
                      </TableHeader>
                      <TableBody>
                        {suppChargeForm.map((charge, index) => (
                          <TableRow key={index}>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell>{charge.chargeCodeName}<span className='ml-2 text-xs'>{charge.calculationMethod ? `(${charge.calculationMethod})` : "(PerTicket)"}</span></TableCell>
                            <TableCell><Input className="w-40 h-6" value={charge.value} readOnly /></TableCell>
                            <TableCell>{charge.totalValue || ""}</TableCell>
                            <TableCell className="cursor-pointer">
                              <span className='flex items-center'>{charge.taxTotal || ""}
                                <TooltipProvider delayDuration={100}>
                                  <Tooltip>
                                    <TooltipTrigger asChild>{charge.taxTotal && <Info className='ml-1 size-4' />}</TooltipTrigger>
                                    <TooltipContent className="mb-2">
                                      {charge.tax && charge.tax.length > 0 && charge.tax.some(t => t.taxCode !== "") ? charge.tax.map((tax) => (<div key={tax.taxCode}>{`${tax.taxCode}: ${tax.value}`}</div>)) : 'No Taxes'}
                                    </TooltipContent>
                                  </Tooltip>
                                </TooltipProvider>
                              </span>
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </CardContent>
                </Card>
              </div>
            </div>
          </div>
        </ScrollArea>
        <CardFooter className="py-0 mt-2 mr-2">
          {
            !id ? (
              <div className="ml-auto">
                <Button onClick={onSave} className="mr-2" disabled={loading}>
                  {loading === "Save" ? 'Saving...' : 'Save draft'}
                </Button>
                <Button onClick={() => onCreate('Submitted')} disabled={loading}>
                  {loading === "Create" ? 'Creating...' : 'Create invoice'}
                </Button>
              </div>
            ) : (
              <div className='ml-auto'>
                {(data && data.state === "Draft") &&
                  <Button onClick={onSave} disabled={loading}>
                    {loading === "Edit" ? 'Editing...' : 'Save Changes'}
                  </Button>}
                {(data && data.state === "Draft") &&
                  <Button onClick={() => onCreate('Submitted')} className="ml-2" disabled={loading}>
                    {loading === "Create" ? 'Creating...' : 'Create invoice'}
                  </Button>
                }
              </div>
            )
          }
        </CardFooter>
      </form>
    </div>
  )
}
