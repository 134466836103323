import { useState } from 'react';
import { Button } from 'src/components/ui/button'
import { Dialog, DialogContent, DialogDescription, DialogFooter, DialogHeader, DialogTitle, DialogTrigger } from 'src/components/ui/dialog'

export default function SplitDialog({ onCreate, loading, type, getFormValues }) {
    const [open, setOpen] = useState(false);

    const handleConfirm = () => {
        const formValues = getFormValues();
        onCreate({
            ...formValues,
            split: true,
            state: 'Submitted'
        });
        setOpen(false);
    }
    return (
        <div>
            <Dialog open={open} onOpenChange={setOpen}>
                <DialogTrigger className='flex ' asChild>
                    <Button disabled={loading}>
                        {loading === "Create" ? 'Creating...' : 'Create invoice'}
                    </Button>
                </DialogTrigger>
                <DialogContent className="max-w-lg">
                    <DialogHeader className='gap-3'>
                        <DialogTitle>Are you absolutely sure?</DialogTitle>
                        <DialogDescription>
                            Proceeding will divide your {type} into multiple individual invoices. Please confirm if you wish to continue.
                        </DialogDescription>
                    </DialogHeader>
                    <DialogFooter className="sm:justify-start">
                        <Button onClick={handleConfirm} disabled={loading}>
                            Confirm
                        </Button>
                    </DialogFooter>
                </DialogContent>
            </Dialog>
        </div>
    )
}
