export function splitInvoiceAirline(originalInvoice) {
  const passengers = originalInvoice.productDetails[0].product.passenger;

  return passengers.map((passenger, index) => {
    const newInvoice = JSON.parse(JSON.stringify(originalInvoice));

    if (newInvoice.invoiceId) {
      newInvoice.invoiceId = `${newInvoice.invoiceId}-${index + 1}`;
    }

    newInvoice.productDetails[0].product.passenger = [passenger];
    const chrgTax = newInvoice.productDetails[0].product.charges;
    let totalValue = 0;
    let taxTotal = 0;
    if (Array.isArray(chrgTax)) {
      chrgTax.forEach(chargeItem => {
        totalValue += parseFloat(chargeItem.totalValue) || 0;
        taxTotal += parseFloat(chargeItem.taxTotal) || 0;
      });
    }

    const passengerFare = passenger.fare;
    newInvoice.netAmount = passengerFare.netAmount + taxTotal + totalValue;
    newInvoice.productDetails[0].netAmount = passengerFare.netAmount + taxTotal + totalValue;

    //fare
    newInvoice.productDetails[0].product.fare = newInvoice.productDetails[0].product.fare.map(fare => ({
      ...fare,
      fare: fare.fare / passengers.length,
      baseFare: fare.baseFare / passengers.length,
      totalFare: fare.totalFare / passengers.length,
      markup: fare.markup / passengers.length,
      comTax: fare.comTax / passengers.length,
      yq: fare.yq ? fare.yq / passengers.length : null,
      k3: fare.k3 / passengers.length,
      nonComTax: fare.nonComTax / passengers.length,
      netAmount: fare.netAmount / passengers.length,
    }));

    return newInvoice;
  });
}