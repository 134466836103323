"use client"
import * as React from "react"
import { format } from "date-fns"
import { Calendar as CalendarIcon } from "lucide-react"
import { cn } from "src/lib/utils"
import { Button } from "src/components/ui/button"
import { Calendar } from "src/components/ui/calendar"
import { Popover, PopoverContent, PopoverTrigger, } from "src/components/ui/popover"

export function DatePicker({value, onChange, disableDate, error, current}) {
  const [date, setDate] = React.useState();
  
  React.useEffect(() => {
    setDate(value ? value : current ? new Date().toISOString() : null);
  }, [value, current]);;

  const handleDateChange = (newDate) => {
    setDate(newDate);
    if (onChange) {
      onChange(newDate);
    }
  };

  return (
    <Popover modal={true}>
      <PopoverTrigger asChild disabled={disableDate}>
        <Button
          variant={"outline"}
          className={cn(
            "w-full h-7 bg-muted/50 justify-start text-left font-normal",
            !date && "text-muted-foreground",
            error && "border-destructive focus-visible:ring-0"
          )}
        >
          <CalendarIcon className="w-4 h-4 mr-2" />
            {date ? format(date, "PPP") : <span>Pick a date</span>}
        </Button>
      </PopoverTrigger>
      <PopoverContent className="w-auto p-0">
        <Calendar
          mode="single"
          selected={date}
          onSelect={handleDateChange}
          initialFocus
        />
      </PopoverContent>
    </Popover>
  )
}
export function DatePickerWithRange({ className, onDateChange }) {
  const [date, setDate] = React.useState();

  React.useEffect(() => {
    if (date && date.from && date.to) {
      onDateChange(date);
    } else {
      onDateChange(null);
    }
  }, [date, onDateChange]);

  return (
    <div className={cn("grid gap-2", className)}>
      <Popover>
        <PopoverTrigger asChild>
          <Button
            id="date"
            variant={"outline"}
            className={cn(
              "justify-start",
              !date && "text-muted-foreground"
            )}
          >
            {/* <CalendarIcon className="w-4 h-4 mr-2" /> */}
            {date?.from ? (
              date.to ? (
                <>
                  {format(date.from, "LLL dd, y")} -{" "}
                  {format(date.to, "LLL dd, y")}
                </>
              ) : (
                format(date.from, "LLL dd, y")
              )
            ) : (
              <span>Search by Date...</span>
            )}
          </Button>
        </PopoverTrigger>
        <PopoverContent className="w-auto p-0" align="start">
          <Calendar
            initialFocus
            mode="range"
            defaultMonth={date?.from}
            selected={date}
            onSelect={setDate}
            numberOfMonths={2}
          />
        </PopoverContent>
      </Popover>
    </div>
  )
}