import { Button } from 'src/components/ui/button';
import { Dialog, DialogContent, DialogDescription, DialogFooter, DialogHeader, DialogTitle, DialogTrigger } from 'src/components/ui/dialog';
import { Separator } from 'src/components/ui/separator';
import { BadgeInfo } from 'lucide-react';
import React, { useState } from 'react'

export default function SupplierView({supplier}) {
  const [open, setOpen] = useState(false);

  return (
    <div>
      <Dialog open={open} onOpenChange={setOpen}>
        <DialogTrigger asChild>
          <BadgeInfo className="text-stay/90 py-0 size-[2vh] hover:text-stay" />
        </DialogTrigger>
        <DialogContent className="lg:w-[60vw] w-[100vw] ">
          <DialogHeader>
            <DialogTitle className="flex items-center gap-2 mb-2">Supplier Details</DialogTitle>
            <Separator />
            <div className="grid gap-3">
              <div className='grid gap-2 text-sm lg:grid-cols-4 md:grid-cols-3 sm:grid-cols-2'>
              <div>First Name : {supplier.firstName}</div>
                <div>Middle Name : {supplier.middleName}</div>
                <div>Last Name : {supplier.lastName}</div>
                <div>Contact Number: {supplier.contactNumber}</div>
                <div>Email: {supplier.emailId}</div>
                {/* Add more fields as needed */}
              </div>
              <Separator />
              <DialogDescription>Address Details</DialogDescription>
              {supplier.addressId?.map((address, index) => (
                <div key={index} className='grid gap-2 text-sm lg:grid-cols-4 md:grid-cols-3 sm:grid-cols-2'>
                  <div>Address Line: {address.addressLine}</div>
                  <div>Country: {address.country}</div>
                  <div>State: {address.state}</div>
                  <div>City: {address.city}</div>
                  <div>Type: {address.type}</div>
                </div>
              ))}
              <Separator />
              <DialogDescription>Bank Details</DialogDescription>
              {supplier.bankDetails?.map((bank, index) => (
                <div key={index} className='grid gap-2 text-sm lg:grid-cols-4 md:grid-cols-3 sm:grid-cols-2'>
                  <div>Account Number: {bank.accountNumber}</div>
                  <div>Branch: {bank.branch}</div>
                  <div>Account Type: {bank.accountType}</div>
                  <div>IFSC Code: {bank.ifscCode}</div>
                  <div>Role: {bank.role}</div>
                </div>
              ))}
              <Separator />
              <DialogDescription>KYC Details</DialogDescription>
              {supplier.kyc?.map((kyc, index) => (
                <div key={index} className='grid gap-2 text-sm lg:grid-cols-4 md:grid-cols-3 sm:grid-cols-2'>
                  <div>	KYC Number : {kyc.kycNumber}</div>
                  <div> Establishment Type : {kyc.establishmentType}</div>
                  <div> Business Place : {kyc.businessPlace}</div>
                  <div> Authorized Person : {kyc.authorizedPerson}</div>
                  <div> Establishment Business : {kyc.establishmentBusiness}</div>
                  <div> Risk Category : {kyc.riskCategory}</div>
                </div>
              ))}
              <Separator />
              <DialogDescription>Tax details</DialogDescription>
              <div className='grid gap-2 text-sm lg:grid-cols-4 md:grid-cols-3 sm:grid-cols-2'>
                <div>	GST Number : {supplier.gstNumber}</div>
                <div> GST State : {supplier.gstState}</div>
                <div> PAN Number : {supplier.panNumber}</div>
                <div> TAN Number : {supplier.tanNumber}</div>
                <div> CIN Number : {supplier.cinNumber}</div>
                <div> Service Tax Number : {supplier.serviceTaxNumber}</div>
                <div> VAT Number : {supplier.vatNumber}</div>
                <div> CSTTIN Number : {supplier.cstTinNumber}</div>
                <div> IATA Number : {supplier.iataNumber}</div>
              </div>
            </div>
            <DialogFooter>
              <Button type="button" className="ml-2" onClick={() => setOpen(false)}>Close</Button>
            </DialogFooter>
          </DialogHeader>
        </DialogContent>
      </Dialog>
    </div>
  );
}  
