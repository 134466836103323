import { Badge } from "src/components/ui/badge";
import {
  Command,
  CommandItem,
  CommandEmpty,
  CommandList,
} from "src/components/ui/command";
import { cn } from "src/lib/utils";
import { Command as CommandPrimitive } from "cmdk";
import { X as RemoveIcon, Check } from "lucide-react";
import React, {
  createContext,
  forwardRef,
  useCallback,
  useContext,
  useState,
} from "react";

const MultiSelectContext = createContext(null);

const useMultiSelect = () => {
  const context = useContext(MultiSelectContext);
  if (!context) {
    throw new Error("useMultiSelect must be used within MultiSelectProvider");
  }
  return context;
};

const MultiSelector = ({
  value,
  onValuesChange: onValueChange,
  loop = false,
  className,
  children,
  dir,
  ...props
}) => {
  const [inputValue, setInputValue] = useState("");
  const [open, setOpen] = useState(false);
  const [activeIndex, setActiveIndex] = useState(-1);

  // const onValueChangeHandler = useCallback(
  //   (val) => {  
  //     if (value?.includes(val)) {
  //       onValueChange(value.filter((item) => item !== val));
  //     } else {
  //       onValueChange([...value, val]);
  //     }
  //   },
  //   [onValueChange, value]
  // );
  const onValueChangeHandler = useCallback(
    (val) => {  
      const currentValue = Array.isArray(value) ? value : [];
      if (currentValue.includes(val)) {
        onValueChange(currentValue.filter((item) => item !== val));
      } else {
        onValueChange([...currentValue, val]);
      }
    },
    [onValueChange, value]
  );

  const handleKeyDown = useCallback(
    (e) => {
      const moveNext = () => {
        const nextIndex = activeIndex + 1;
        setActiveIndex(
          nextIndex > value.length - 1 ? (loop ? 0 : -1) : nextIndex
        );
      };

      const movePrev = () => {
        const prevIndex = activeIndex - 1;
        setActiveIndex(prevIndex < 0 ? value.length - 1 : prevIndex);
      };

      switch (e.key) {
        case "Backspace":
        case "Delete":
          if (value.length > 0 && inputValue.length === 0) {
            if (activeIndex !== -1 && activeIndex < value.length) {
              onValueChange(value.filter((item) => item !== value[activeIndex]));
              const newIndex = activeIndex - 1 < 0 ? 0 : activeIndex - 1;
              setActiveIndex(newIndex);
            } else {
              onValueChange(
                value.filter((item) => item !== value[value.length - 1])
              );
            }
          }
          break;
        case "Enter":
          setOpen(true);
          break;
        case "Escape":
          if (activeIndex !== -1) {
            setActiveIndex(-1);
          } else {
            setOpen(false);
          }
          break;
        case "ArrowLeft":
          if (dir === "rtl") {
            movePrev();
          } else {
            moveNext();
          }
          break;
        case "ArrowRight":
          if (dir === "rtl") {
            moveNext();
          } else {
            movePrev();
          }
          break;
          default:
            break;
      }
    },
    [activeIndex, value, loop, inputValue.length, dir, onValueChange]
  );

  return (
    <MultiSelectContext.Provider
      value={{
        value,
        onValueChange: onValueChangeHandler,
        open,
        setOpen,
        inputValue,
        setInputValue,
        activeIndex,
        setActiveIndex,
      }}
    >
      <Command
        onKeyDown={handleKeyDown}
        className={cn(
          "overflow-visible bg-transparent flex flex-col space-y-2",
          className
        )}
        dir={dir}
        {...props}
      >
        {children}
      </Command>
    </MultiSelectContext.Provider>
  );
};

const MultiSelectorTrigger = forwardRef(({ className, children, ...props }, ref) => {
  const { value, onValueChange, activeIndex } = useMultiSelect();

  const mousePreventDefault = useCallback((e) => {
    e.preventDefault();
    e.stopPropagation();
  }, []);

  return (
    <div
      ref={ref}
      className={cn(
        "flex flex-wrap gap-1  border border-muted rounded-lg bg-background",
        className
      )}
      {...props}
    >
      {value?.map((item, index) => (
        <Badge key={item}
          className={cn(
            "px-1 m-1 rounded-xl flex items-center gap-1",
            activeIndex === index && "ring-2 ring-muted-foreground "
          )}
          variant="secondary"
        >
          <span className="text-xs">{item}</span>
          <button
            aria-label={`Remove ${item} option`}
            aria-roledescription="button to remove option"
            type="button"
            onMouseDown={mousePreventDefault}
            onClick={() => onValueChange(item)}
          >
            <RemoveIcon className="w-4 h-4 hover:stroke-destructive" />
          </button>
        </Badge>
      ))}
      {children}
    </div>
  );
});

MultiSelectorTrigger.displayName = "MultiSelectorTrigger";

const MultiSelectorInput = forwardRef(({ className, ...props }, ref) => {
  const { setOpen, inputValue, setInputValue, activeIndex, setActiveIndex } =
    useMultiSelect();
  return (
    <CommandPrimitive.Input 
      {...props}
      ref={ref}
      value={inputValue}
      onValueChange={activeIndex === -1 ? setInputValue : undefined}
      onBlur={() => setOpen(false)}
      onFocus={() => setOpen(true)}
      onClick={() => setActiveIndex(-1)}
      placeholder="Select Taxes..." 
      
      className={cn(
        "rounded-md pl-2 ring-offset-background focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring border border-input bg-muted/50 placeholder:text-muted-foreground flex-1",
        className,
        activeIndex !== -1 && "caret-transparent",
        "[&::placeholder]:text-[14px]", 
        "[&::placeholder]:text-black",
        "[&::placeholder]:font-semibold" 
      )}
    />
  );
});

MultiSelectorInput.displayName = "MultiSelectorInput";

const MultiSelectorContent = forwardRef(({ children }, ref) => {
  const { open } = useMultiSelect();
  return (
    <div ref={ref} className="relative">
      {open && children}
    </div>
  );
});

MultiSelectorContent.displayName = "MultiSelectorContent";

const MultiSelectorList = forwardRef(({ className, children }, ref) => {
  return (
    <CommandList
      ref={ref}
      className={cn(
        "p-2 flex flex-col gap-2 rounded-md scrollbar-thin scrollbar-track-transparent transition-colors scrollbar-thumb-muted-foreground dark:scrollbar-thumb-muted scrollbar-thumb-rounded-lg w-full absolute bg-background shadow-md z-10 border border-muted top-0",
        className
      )}
    >
      {children}
      <CommandEmpty>
        <span className="text-muted-foreground">No results found</span>
      </CommandEmpty>
    </CommandList>
  );
});

MultiSelectorList.displayName = "MultiSelectorList";

const MultiSelectorItem = forwardRef(({ className, value, children, ...props }, ref) => {
  const { value: Options, onValueChange, setInputValue } = useMultiSelect();

  const mousePreventDefault = useCallback((e) => {
    e.preventDefault();
    e.stopPropagation();
  }, []);

  const isIncluded = Options?.includes(value);
  return (
    <CommandItem
      ref={ref}
      {...props}
      onSelect={() => {
        onValueChange(value);
        setInputValue("");
      }}
      className={cn(
        "rounded-md cursor-pointer px-2 py-1 transition-colors flex justify-between ",
        className,
        isIncluded && "opacity-50 cursor-default",
        props.disabled && "opacity-50 cursor-not-allowed"
      )}
      onMouseDown={mousePreventDefault}
    >
      {children}
      {isIncluded && <Check className="w-4 h-4" />}
    </CommandItem>
  );
});

MultiSelectorItem.displayName = "MultiSelectorItem";

export {
  MultiSelector,
  MultiSelectorTrigger,
  MultiSelectorInput,
  MultiSelectorContent,
  MultiSelectorList,
  MultiSelectorItem,
};