import Keycloak from "keycloak-js";

const keycloakConfig = {
  url: "http://192.168.1.50:5050",
  realm: "AmigoRealm",
  clientId: "master-client",
};

const keycloak = new Keycloak(keycloakConfig);

export default keycloak;