import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Input } from 'src/components/ui/input.jsx';
import { Calendar, ChevronLeft, ChevronRight, CirclePlus, Edit, Search } from 'lucide-react';
import { Card, CardContent } from 'src/components/ui/card';
import {
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableHeader,
    TableRow,
} from 'src/components/ui/table';
import {
    Tooltip,
    TooltipContent,
    TooltipProvider,
    TooltipTrigger,
} from "src/components/ui/tooltip";
import { Button } from 'src/components/ui/button';
import { DatePickerWithRange } from '../../../components/datepicker';
import { Link } from 'react-router-dom';
import DeleteBox from '../components/delete-dialog';
import ProductChargeConfigView from './cpcc-view';

export default function ProductChargeConfigList() {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [searchQuery, setSearchQuery] = useState('');
    const [dateRange, setDateRange] = useState(null);
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 10;
    let totalPages;

    useEffect(() => {
        fetchData();
    }, []);

    const handleSuccess = () => {
        fetchData();
    }

    const fetchData = async () => {
        try {
            const response = await fetch(`${(process.env.REACT_APP_API_HOST_MSTR === undefined) ? "" : process.env.REACT_APP_API_HOST_MSTR}/amigo-lekha-api/customerProductChargeConfigManagement/v1/customerProductChargeConfig`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                },
            });
            if (!response.ok) {
                throw new Error('Failed to fetch data');
            }
            const fetchedData = await response.json();
            setData(fetchedData);
        } catch (error) {
            console.error('Error fetching data:', error);
        } finally {
            setLoading(false);
        }
    };

    const sortData = useCallback((data, searchQuery, dateRange) => {
        if (!data) {
            return [];
        }
        const query = searchQuery.toLowerCase();
        const endDate = dateRange?.to ? new Date(dateRange.to) : null;
        if (endDate) {
            endDate.setDate(endDate.getDate() + 1);
        }

        const filteredData = data.filter((item) => {
            const itemDate = new Date(item.createdAt);
            return (
                Object.values(item)
                    .join(' ')
                    .toLowerCase()
                    .includes(query) &&
                (!dateRange ||
                    (dateRange.from <= itemDate && itemDate < endDate))
            );
        });
        const indexOfLastItem = currentPage * itemsPerPage;
        const indexOfFirstItem = indexOfLastItem - itemsPerPage;
        const currentPageData = filteredData.slice(indexOfFirstItem, indexOfLastItem);

        return currentPageData;
    }, [currentPage, itemsPerPage]);

    const handlePreviousPage = () => {
        if (currentPage > 1) {
            setCurrentPage(currentPage - 1);
        }
    };

    const totalItems = data ? data.length : 0;
    totalPages = data ? Math.ceil(totalItems / itemsPerPage) : 0;

    const handleNextPage = () => {
        if (currentPage < totalPages) {
            setCurrentPage(currentPage + 1);
        }
    };

    const sortedData = useMemo(() => sortData(data, searchQuery, dateRange), [sortData, data, searchQuery, dateRange]);

    return (
        <div className="flex flex-col items-center w-full h-full">
            <main className="grid items-start w-full gap-4 px-5 py-2 lg:py-5 md:gap-4">
                <div className="flex items-center">
                    <div className="relative">
                        <Input
                            type="text"
                            placeholder="Search by Field..."
                            className="lg:w-[28vh] md:w-[25vh] w-36 hover:bg-muted transition-all" onChange={(e) => setSearchQuery(e.target.value)}
                        />
                        <div className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                            <Search className="size-5 text-muted-foreground" />
                        </div>
                    </div>
                    <div className='relative'>
                        <DatePickerWithRange className="ml-6 lg:w-[28vh] md:w-[25vh] w-36" onDateChange={setDateRange} />
                        <div className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                            <Calendar className='size-5 text-muted-foreground' />
                        </div>
                    </div>
                    <div className="flex items-center ml-auto">
                        <Link to="add"><Button > <CirclePlus className='mr-1 size-4' />Add Charge Config</Button></Link>
                    </div>
                </div>
                <Card>
                    <CardContent className="mt-3">
                        <Table>
                            <TableHeader className="bg-secondary/60">
                                <TableRow>
                                    <TableHead></TableHead>
                                    <TableHead>Code</TableHead>
                                    <TableHead>Customer ID</TableHead>
                                    <TableHead>Name</TableHead>
                                    <TableHead>Created By</TableHead>
                                    <TableHead>
                                        <span className="sr-only">Actions</span>
                                    </TableHead>
                                </TableRow>
                            </TableHeader>
                            <TableBody>
                                {loading ? (
                                    <TableRow>
                                        <TableCell colSpan={7} className="text-center">
                                            Loading...
                                        </TableCell>
                                    </TableRow>
                                ) : sortedData.length > 0 ? (
                                    sortedData.map((chargeconfig) => (
                                        <TableRow key={chargeconfig.id}>
                                            <TableCell></TableCell>
                                            <TableCell>{chargeconfig.code}</TableCell>
                                            <TableCell>{chargeconfig.customer.id}</TableCell>
                                            <TableCell>{chargeconfig.name}</TableCell>
                                            <TableCell>{chargeconfig.createdBy}</TableCell>
                                            <TableCell className="flex items-center gap-3 py-2">
                                                <TooltipProvider delayDuration={100}>
                                                    <Tooltip>
                                                        <TooltipTrigger>
                                                            <ProductChargeConfigView chargeConfig={chargeconfig} />
                                                        </TooltipTrigger>
                                                        <TooltipContent className="mb-2">
                                                            <p>View Config</p>
                                                        </TooltipContent>
                                                    </Tooltip>
                                                </TooltipProvider>
                                                <TooltipProvider delayDuration={100}>
                                                    <Tooltip>
                                                        <TooltipTrigger><Link to="edit" state={{ item: chargeconfig }}><Edit className="text-primary/90 size-[1.9vh]" /></Link></TooltipTrigger>
                                                        <TooltipContent className="mb-2"><p>Edit Config</p></TooltipContent>
                                                    </Tooltip>
                                                </TooltipProvider>
                                                <TooltipProvider delayDuration={100}>
                                                    <Tooltip>
                                                        <TooltipTrigger>
                                                            <DeleteBox code={chargeconfig.customer.id} onDeleteSuccess={handleSuccess} />
                                                        </TooltipTrigger>
                                                        <TooltipContent className="mb-2">
                                                            <p>Delete Config</p>
                                                        </TooltipContent>
                                                    </Tooltip>
                                                </TooltipProvider>
                                            </TableCell>
                                        </TableRow>
                                    ))
                                ) : (
                                    <TableRow>
                                        <TableCell colSpan={7} className="text-center">
                                            No data available
                                        </TableCell>
                                    </TableRow>
                                )}
                            </TableBody>
                        </Table>
                        <div className="flex items-center justify-end mt-2 space-x-2">
                            <div className="mr-4 text-[1.6vh] text-muted-foreground">Pages {currentPage} of {totalPages}</div>
                            <Button variant="outline" size="sm" onClick={handlePreviousPage} disabled={currentPage === 1}>
                                <ChevronLeft className="mr-1 size-4" />Previous
                            </Button>
                            <Button variant="outline" size="sm" onClick={handleNextPage} disabled={!data || currentPage === totalPages || (totalItems <= itemsPerPage)}>
                                Next<ChevronRight className="ml-1 size-4" />
                            </Button>
                        </div>
                    </CardContent>
                </Card>
            </main>
        </div>
    );
}