
import * as React from "react"
import { cn } from "src/lib/utils"
import { Button } from "src/components/ui/button"
import {
    Command,
    CommandEmpty,
    CommandGroup,
    CommandInput,
    CommandItem,
    CommandList,
} from "src/components/ui/command"
import {
    Popover,
    PopoverContent,
    PopoverTrigger,
} from "src/components/ui/popover"
import { Check, ChevronsDownUp } from "lucide-react"

export function SearchCustomer({ onCustomerSelect, value, isVisible, isError, disable }) {
    const [open, setOpen] = React.useState(false)
    const [customers, setCustomer] = React.useState([]);
    const [selectedCustomer, setSelectedCustomer] = React.useState(null);
    const [loading, setLoading] = React.useState(false);
    const commandListRef = React.useRef(null);

    React.useEffect(() => {
        if (commandListRef.current) {
            commandListRef.current.scrollTop = 0;
        }
    }, [customers]);
    const getCustomers = async () => {
        setLoading(true);
        try {
            const response = await fetch(`${(process.env.REACT_APP_API_HOST_MSTR === undefined) ? "" : process.env.REACT_APP_API_HOST_MSTR}/amigo-lekha-api/customerManagement/v1/customer`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json'
                },
            });
            if (!response.ok) {
                throw new Error('Failed to fetch data');
            }
            const fetchedData = await response.json();
            setCustomer(fetchedData);
        } catch (error) {
            console.error("Error fetching data:", error);
        } finally {
            setLoading(false);
        }
    }

    const getCustomerCharges = async (customerId) => {
        try {
            const response = await fetch(`${(process.env.REACT_APP_API_HOST_MSTR === undefined) ? "" : process.env.REACT_APP_API_HOST_MSTR}/amigo-lekha-api/customerProductChargeConfigManagement/v1/customerProductChargeConfig/${customerId}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json'
                },
            });
            if (response.status === 404) {
                return {};
            } else if (!response.ok) {
                throw new Error('Failed to fetch data');
            }
            const fetchedData = await response.json();
            return fetchedData;
        } catch (error) {
            console.error("Error fetching data:", error);
            return {};
        }
    }
    const handleSelectCustomer = async (customerId) => {
        try {
            const customer = customers.find((cust) => cust.customerCode === customerId);
            setSelectedCustomer(customer);
            const charges = await getCustomerCharges(customerId);
            onCustomerSelect(customer, charges);
        } catch (error) {
            console.error("Error in handleSelectCustomer:", error);
        } finally {
            setOpen(false);
        }
    };
    React.useEffect(() => {
        if (value) {
            const customer = customers.find((cust) => cust.customerCode === value);
            if (customer) {
                setSelectedCustomer(customer);
            }
        }
    }, [value, customers]);
    React.useEffect(() => {
        if (isVisible) {
            getCustomers();
        }
    }, [isVisible]);

    return (
        <Popover open={open} onOpenChange={setOpen} modal={true}>
            <PopoverTrigger asChild onClick={getCustomers}>
                <Button
                    variant="outline"
                    role="combobox"
                    aria-expanded={open}
                    className={`w-[28vh] bg-muted h-7 justify-between ${isError && "border-destructive"}`}
                    disabled={disable}
                >
                    <span className="w-32 truncate">{selectedCustomer?.fullName || "Select customer..."}</span>
                    <ChevronsDownUp className="w-4 h-4 ml-2 opacity-50 shrink-0" />
                </Button>
            </PopoverTrigger>
            <PopoverContent className="w-[28vh] h-[30vh] p-0">
                <Command>
                    <CommandInput placeholder="Search customer..." className="h-8" />
                    <CommandList ref={commandListRef}>
                        <CommandEmpty>{loading ? "loading..." : "No customer found."}</CommandEmpty>
                        <CommandGroup>
                            {customers.map((customer) => (
                                <CommandItem
                                    key={customer.customerCode}
                                    value={customer.name}
                                    onSelect={() => handleSelectCustomer(customer.customerCode)}
                                >
                                    {customer.fullName}
                                    <Check
                                        className={cn(
                                            "ml-auto h-4 w-4",
                                            selectedCustomer?.customerCode === customer.customerCode ? "opacity-100" : "opacity-0"
                                        )}
                                    />
                                </CommandItem>
                            ))}
                        </CommandGroup>
                    </CommandList>
                </Command>
            </PopoverContent>
        </Popover>
    )
}
