import React, { useEffect, useState } from 'react'
import InvoiceSelect from '../../creditNotePage/invoice-select'
import { Select, SelectContent, SelectGroup, SelectItem, SelectTrigger, SelectValue } from 'src/components/ui/select';
import PNRSelect from '../../creditNotePage/pnr-select';
import { Card, CardContent } from 'src/components/ui/card';
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from 'src/components/ui/table';
import {
    Tooltip,
    TooltipContent,
    TooltipProvider,
    TooltipTrigger,
} from "src/components/ui/tooltip";
import { CirclePlus, RotateCcw, Trash2 } from 'lucide-react';
import { Button } from 'src/components/ui/button';
import GenericAmountSeparator from 'src/pages/components/genric-amountseperator';

export default function SmInv() {
    const [product, setProduct] = useState("");
    const [addedData, setAddedDate] = useState([]);
    const [selectedInvId, setSelectedInvId] = useState(null);
    useEffect(() => {
        setProduct("airline");
    }, []);
    return (
        <div className='m-5'>
            <div className="flex items-center justify-center w-full mb-5 text-[3vh] font-bold">Split / Merge</div>
            {/* <div className='px-10 text-sm font-bold'>Direct Add :</div> */}
            <div className='grid grid-cols-6 gap-4 px-6 py-2 text-sm lg:px-10 lg:py-5 md:gap-4'>
                <div className='flex items-center gap-4 pr-4 border-r-2 border-secondary-foreground'>Product
                    <Select defaultValue="airline" onValueChange={(value) => setProduct(value)}>
                        <SelectTrigger className="w-44 h-7 bg-muted/50 focus:ring-transparent">
                            <SelectValue />
                        </SelectTrigger>
                        <SelectContent>
                            <SelectGroup>
                                <SelectItem value="airline">Airline</SelectItem>
                                <SelectItem value="other">Other</SelectItem>
                            </SelectGroup>
                        </SelectContent>
                    </Select>
                </div>
                <div className={`flex items-center ${product === "airline" && "col-span-2"} gap-4`}><InvoiceSelect onInvoiceSelect={(invoice) => console.log(invoice)} isVisible={true} isError={false} subType={false} /> {product === "airline" && <>or<PNRSelect onPNRSelect={(invoice) => console.log(invoice)} isVisible={true} isError={false} /></>}</div>
                <div className='flex gap-2'>
                <Button size="icon" className="h-7"><CirclePlus className='size-4' /></Button>
                <Button size="icon" variant="ghost" className="h-7"><RotateCcw className='size-4' /></Button>
                </div>
            </div>
            {/* <div className='px-10 text-sm font-bold'>Search By :</div> */}
            <Card className="mx-10">
                <CardContent className="p-0">
                    <Table>
                        <TableHeader className="bg-secondary/60">
                            <TableRow>
                                <TableHead className="rounded-tl-md"></TableHead>
                                <TableHead>Invoice Id</TableHead>
                                <TableHead>Customer Name</TableHead>
                                <TableHead>Created At</TableHead>
                                <TableHead>Net Amount</TableHead>
                                <TableHead className="rounded-tr-md"><span className="sr-only">Actions</span></TableHead>
                            </TableRow>
                        </TableHeader>
                        <TableBody>
                            {addedData.length > 0 ? (
                                addedData.map((item, index) => (
                                    <TableRow key={index}>
                                        <TableCell></TableCell>
                                        <TableCell>{item.invoiceId}</TableCell>
                                        <TableCell>{item.customerRefOrValue.name}</TableCell>
                                        <TableCell>{item.invoiceDate ? new Date(item.invoiceDate).toLocaleDateString() : ""}</TableCell>
                                        <TableCell>{item.netAmount ? <GenericAmountSeparator value={item.netAmount} viewMode={true} /> : '-'}</TableCell>
                                        <TableCell className="w-[20vh]">
                                            <div className="flex items-center gap-3">
                                                <TooltipProvider delayDuration={100}>
                                                    <Tooltip>
                                                        <TooltipTrigger><Trash2 className='size-[1.95vh] text-destructive/50' /></TooltipTrigger>
                                                        <TooltipContent className="mb-2">
                                                            <p>Remove Invoice</p>
                                                        </TooltipContent>
                                                    </Tooltip>
                                                </TooltipProvider>
                                            </div>
                                        </TableCell>
                                    </TableRow>
                                ))
                            ) : (
                                <TableRow>
                                    <TableCell colSpan={7} className="text-center">No Invoice Added</TableCell>
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </CardContent>
            </Card>
            <div className='flex w-full px-10'>
                <div className='mt-4 ml-auto'>
                    <Button>Merge</Button><Button className="mx-2">Split</Button>
                </div>
            </div>
        </div>
    )
}
