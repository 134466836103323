import React, { useState, useEffect } from 'react';
import { Input } from 'src/components/ui/input';

const formatAmount = (amount) => {
    if (!amount && amount !== 0) return '';
    return amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

const parseAmount = (formattedAmount) => {
    if (!formattedAmount) return '';
    return formattedAmount.replace(/,/g, '');
};

export default function GenericAmountSeparator({ value, onChange, viewMode, icon, required }) {
    const [formattedValue, setFormattedValue] = useState(formatAmount(value));

    useEffect(() => {
        setFormattedValue(formatAmount(value));
    }, [value]);

    const handleChange = (e) => {
        const rawValue = parseAmount(e.target.value);
        setFormattedValue(formatAmount(rawValue));
        onChange(rawValue);
    };

    if (viewMode) {
        return <span>{icon === true ? "" : "₹"}{formatAmount(value)}</span>;
    }

    return (
        <Input
            type="text"
            value={formattedValue}
            onChange={handleChange}
            className={`h-7 bg-muted/50 ${required && "border-destructive focus-visible:ring-0"}`}
        />
    );
};


