import { Button } from 'src/components/ui/button';
import { Dialog, DialogContent, DialogHeader, DialogTitle, DialogTrigger } from 'src/components/ui/dialog';
import { format } from 'date-fns';
import { BadgeInfo, ReceiptText } from 'lucide-react';
import React, { useRef, useState } from 'react'
import { Link } from 'react-router-dom';
import { useReactToPrint } from 'react-to-print';
import { ScrollArea } from 'src/components/ui/scroll-area';
import { Separator } from 'src/components/ui/separator';
import Deletedialog from '../../components/delete-dialog';
import GenericAmountSeparator from '../../components/genric-amountseperator';

export default function ViewReceipt({ data, icon, onViewSuccess }) {
    const [open, setOpen] = useState(false);
    const item = data;
    const componentRef = useRef();
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    });
    const handleSuccess = () => {
        setOpen(false);
        onViewSuccess();
    }
    const formatDate = (dateString) => {
        if (!dateString) {
            return '';
        }
        const date = new Date(dateString);
        return format(date, 'dd-MM-yyyy');
    };
    return (
        <div>
            <style>
                {`
                    @media print {
                        .print-exclude {
                            display: none;
                        }
                        .print-content {
                            width: 95vw;
                            height: 95vh;
                            margin: 2vh;
                            overflow: hidden;
                            page-break-inside: avoid;
                            box-shadow: none;
                        }
                        .print-content * {
                            page-break-inside: avoid;
                        }
                    }
                    @media screen {
                        .print-only {
                            display: none;
                            margin-top: 10px;
                        }
                    }
                `}
            </style>
            <Dialog open={open} onOpenChange={setOpen}>
                <DialogTrigger asChild>
                    {icon ? <BadgeInfo className="text-stay/90 py-0 size-[2.1vh] hover:text-stay" /> : <span className='transition-all cursor-pointer hover:text-primary/70'>{item.receiptNo}</span>}
                </DialogTrigger>
                <DialogContent className="lg:w-[70vw] w-[100vw]">
                    <DialogHeader className='flex flex-row items-center'>
                        <DialogTitle className="flex items-center gap-2 ml-5">Receipt Details <ReceiptText /></DialogTitle>
                        {item.state === "Draft" && <Link className='ml-auto mr-4' to={`/dashboard/receipt/airline/edit/${item.receiptNo}`} state={{ item: item }}><Button size="sm" className="text-slate-100">Edit</Button></Link>}
                        <Button size="sm" className={`${item.state === "Submitted" && "ml-auto"} mr-4 text-slate-100 bg-stay/75 hover:bg-stay/90`} onClick={handlePrint}>Print</Button>
                        <div><Deletedialog deleteCall="Inside" onDeleteSuccess={handleSuccess} type="receipt" Id={item.receiptNo} disable={item.state === "Deleted" && true} /></div>
                    </DialogHeader>
                    <ScrollArea className="h-[calc(100vh-200px)] lg:h-[65vh]">
                        <div ref={componentRef} className='flex flex-col justify-between mx-5 print-content' >
                            <div>
                                <div className='flex justify-between print-only'>
                                    <div className='flex gap-1'>
                                        <img src='' alt="alt" className='w-20 h-20 bg-secondary/50' />
                                        <div className='flex flex-col text-[1.5vh]]'>
                                            <span className='font-bold'>RAFFINATO TOURS AND TRAVELS</span>
                                            <span className='w-[50vh] text-xs'> Office No A16, 131 Ground Floor, Goodwill Square, Survey No 17/1A/1,<br />
                                                Lohegaon Dhanori Road, Dhanori Lohegaon Road Near C.T Hospital, Dhanori
                                                Pune 411015.</span>
                                        </div>
                                    </div>
                                    <div className='text-xs'> Tel: +91 8308864745<br />
                                        email: info@raffinato.in,<br />
                                        robert.lazarus@raffinato.in<br />
                                        PAN No: AAZFR2544R<br />
                                        IATA No : 14344595<br />
                                        GSTIN: 27AAZFR2544R1Z2<br />
                                        State: Maharashtra (27)<br />
                                    </div>
                                </div>
                                <Separator />
                                <div className='flex w-full font-bold justify-evenly'>RECEIPT</div>
                                <Separator />
                                <div className='flex justify-between my-1 text-xs'>
                                    <div>
                                        Receipt No. : <span className="font-bold">{item.receiptNo}</span>
                                    </div>
                                    <div>
                                        Date: <span className="font-bold">{formatDate(item.receiptDate ? item.receiptDate : 0)}</span>
                                    </div>
                                </div>
                                <div className='my-2 text-xs'>
                                    Received From :  <span className="font-bold">{item.ReceivedFrom?.name}</span>
                                </div>
                                <div className='flex mt-4 text-xs'>
                                    By Cheque No. : {item.receiptDetails.chequeNo} ,
                                    Dated : {formatDate(item.receiptDetails.chequeDate ? item.receiptDetails.chequeDate : 0)}
                                </div>
                                <div className='flex justify-between py-[1px] text-xs font-bold border-y border-y-secondary-foreground/70'>
                                    <div>Account</div>
                                    <div>INR Amount</div>
                                </div>
                                <div className='flex justify-between mt-1 text-xs font-bold'>
                                    <div>{item.receiptDetails.addToBankRef.name}</div>
                                    <div><GenericAmountSeparator value={item.receiptDetails.amount} viewMode={true} icon={true} /></div>
                                </div>
                                <div className='mt-2 mb-5 text-xs'>
                                    <span className='border-b border-b-secondary-foreground/70'>Appropriation Details :</span>
                                    <div className='grid grid-cols-5 mt-1 lg:grid-cols-7'>
                                        <div className='grid col-span-4 gap-1'>
                                            {item.relatedTransactions.map((item, index) => (
                                                <div key={index} className='grid grid-cols-4 gap-2'>
                                                    <div>{item.id}</div>
                                                    <div>{item.transactionDate ? format(new Date(item.transactionDate), 'dd-MM-yyyy') : 'N/A'}</div>
                                                    <div>name</div>
                                                    <div><GenericAmountSeparator value={item.transactionAmount} viewMode={true} /> {item.transactionType}</div>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                </div>
                                <div className='flex justify-between mt-1 text-xs font-bold'>
                                    <div>TDS Deducted By Customer FY 2024-25</div>
                                    <div className='font-normal'>(0.00)</div>
                                </div>
                                <div className='flex justify-between my-1 text-xs font-bold'>
                                    <div>Rounding Off</div>
                                    <div className='font-normal'>0.00</div>
                                </div>
                                <div className='flex justify-between mt-1 mb-5 text-xs font-bold'>
                                    <div></div>
                                    <div>
                                        <span>Total</span>
                                        <span className='pl-5 border-y border-y-secondary-foreground/70'><GenericAmountSeparator value={item.receiptDetails.amount} viewMode={true} icon={true} /></span>
                                    </div>
                                </div>
                                <div className='flex justify-between mt-6 text-xs'>
                                    Cheques subject to realisation
                                </div>
                                <div className='mt-20 print-only'>
                                    <div className='mt-20 text-xs text-end'>
                                        For RAFFINATO TOURS AND TRAVELS
                                    </div>
                                    <div className='flex justify-between mt-6 text-xs'>
                                        <div>Authorised By</div>
                                        <span>Authorised Signatory</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </ScrollArea>
                </DialogContent>
            </Dialog>
        </div>
    )
}
