import { Button } from 'src/components/ui/button'
import { Card, CardContent, CardDescription, CardFooter } from 'src/components/ui/card'
import { ScrollArea } from 'src/components/ui/scroll-area'
import { Separator } from 'src/components/ui/separator'
import React, { useEffect, useState } from 'react'
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom'
import { Controller, useForm } from 'react-hook-form'
import { toast } from 'sonner'
import { CirclePlus, Edit, Trash2, Undo2 } from 'lucide-react'
import { Label } from 'src/components/ui/label'
import { DatePicker } from '../../../components/datepicker'
import { Input } from 'src/components/ui/input'
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from 'src/components/ui/table';
import SelectDrop from '../../masterPage/components/search-select'
import GenericAmountSeparator from 'src/pages/components/genric-amountseperator'
import SupplierSelect from 'src/pages/components/supplier-select'
import { Select, SelectContent, SelectGroup, SelectItem, SelectTrigger, SelectValue } from 'src/components/ui/select'

export default function PymtBankForm() {
  const [loading, setLoading] = useState("");
  const [paymentNo, setPaymentNo] = useState('');
  const [isVisible, setIsVisible] = useState(false);
  const [formState, setFormState] = useState('Draft');
  const [ledgerFormEdit, setLedgerFormEdit] = useState({ visible: false, editingIndex: null });
  const [transactionFormEdit, setTransactionFormEdit] = useState({ visible: false, editingIndex: null });
  const { register, handleSubmit, control, reset, watch, setValue, getValues, trigger, formState: { errors }, } = useForm({
    defaultValues: {
      category: "bank",
      createdBy: "amigo2",
    }
  });

  const { id } = useParams();
  const location = useLocation();
  const data = location.state?.item;
  const navigate = useNavigate();
  const [isError, setIsError] = useState({
    supplier: false,
    ledger: false,
    transaction: false,
  });

  useEffect(() => {
    getLists();
    setValue('paymentDate', new Date().toISOString());
    if (data && id) {
      setPaymentNo(data.paymentNo);
      reset(data);
      if (data.paymentDetails && data.paymentDetails.ledgerDetail && data.paymentDetails.ledgerDetail.ledger && data.paymentDetails.ledgerDetail.ledger.every(item => item.id === null)) {
        const ledgerData = data.paymentDetails.ledgerDetail;
        setLedgerDetails(ledgerData);
        setEditingLedgerForm(ledgerData);
      }
      if (data.relatedTransactions && data.relatedTransactions.length > 0) {
        const transactionData = data.relatedTransactions;
        setTransactionDetails(transactionData);
        setEditingTransactionForm(transactionData);
      }
      setIsVisible(true);
    }
  }, [setValue, data, id, reset]);

  const [tranTypeItems, setTranTypeItems] = useState([]);

  const getLists = async () => {
    try {
      const response = await fetch(`${(process.env.REACT_APP_API_HOST_MSTR === undefined) ? "" : process.env.REACT_APP_API_HOST_MSTR}/amigo-lekha-api/listManagement/v1/list`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json'
        },
      });
      if (!response.ok) {
        throw new Error('Failed to fetch data');
      }
      const fetchedData = await response.json();
      fetchedData.forEach(item => {
        switch (item.name) {
          case 'TransactionType':
            setTranTypeItems(item.items);
            break;
          default:
            break;
        }
      });
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  }

  const handleSupplierSelect = async (supplier) => {
    setIsError(prevState => ({ ...prevState, supplier: false }));
    setValue("PaidTo.id", supplier.supplierCode || "");
    setValue("PaidTo.name", supplier.fullName || "");
  }
  const paidTo = watch("PaidTo");

  const [ledgerDetail, setLedgerDetails] = useState([
    {
      ledger: { id: "" },
      transactionType: "",
      amount: "",
      fcAmount: "",
      currency: "",
      rate: "",
      description: "",
      approval: "",
    }
  ]);

  const [editingLedgerForm, setEditingLedgerForm] = useState([]);

  const handleLedgerFormSave = (e) => {
    e.preventDefault();
    const filteredLedgerForm = ledgerDetail.filter(ledger => Object.values(ledger).some(field => field !== "")
    );
    if (filteredLedgerForm.length === 0) { return; }
    setValue('paymentDetails.ledgerDetail', ledgerDetail);
    setEditingLedgerForm(ledgerDetail);
    setLedgerFormEdit({ visible: false, editingIndex: null });
  };
  const handleLChange = (index, field, value) => {
    if (index === null) {
      index = editingLedgerForm.length;
    }
    setLedgerDetails((prevLedger) => {
      const updatedLedger = [...prevLedger];
      if (field === 'id') {
        updatedLedger[index] = {
          ...updatedLedger[index],
          ledger: {
            ...updatedLedger[index]?.ledger,
            id: value.name
          }
        };
      } else {
        updatedLedger[index] = {
          ...updatedLedger[index],
          [field]: value
        };
      }
      return updatedLedger;
    });
  }
  const handleDeleteL = (index) => {
    const updatedLedger = editingLedgerForm.filter((_, i) => i !== index);
    setValue("paymentDetails.ledgerDetail", updatedLedger);
    setEditingLedgerForm(updatedLedger);
    setLedgerDetails(updatedLedger);
  };

  const [transactionDetail, setTransactionDetails] = useState([
    {
      id: "",
      transactionDate: "",
      transactionType: "",
      transactionAmount: "",
    }
  ]);

  const [editingTransactionForm, setEditingTransactionForm] = useState([]);

  const handleTChange = (index, field, value) => {
    if (index === null) {
      index = editingTransactionForm.length;
    }
    setTransactionDetails((prevTransaction) => {
      const updatedTransaction = [...prevTransaction];
      updatedTransaction[index] = {
        ...updatedTransaction[index],
        [field]: value
      };
      return updatedTransaction;
    });
  }

  const handleTransactionFormSave = (e) => {
    e.preventDefault();
    const filteredTransactionForm = transactionDetail.filter(transaction => Object.values(transaction).some(field => field !== ""));
    if (filteredTransactionForm.length === 0) { return; }
    setValue('relatedTransactions', transactionDetail)
    setEditingTransactionForm(transactionDetail);
    setTransactionFormEdit({ visible: false, editingIndex: null });
  };

  const handleDeleteT = (index) => {
    const updatedTransaction = editingTransactionForm.filter((_, i) => i !== index);
    setValue("relatedTransactions", updatedTransaction)
    setEditingTransactionForm(updatedTransaction);
    setTransactionDetails(updatedTransaction);
  };

  const updateValidationRules = (state) => {
    if (state === 'Submitted') {
      setValue('state', 'Submitted');
    } else {
      setValue('state', 'Draft');
    }
    trigger();
  }

  const onSave = () => {
    setFormState('Draft');
    updateValidationRules('Draft');
    const data = getValues();
    setIsError(prevState => ({ ...prevState, ledger: false, transaction: false }))
    if (!data.PaidTo?.id) {
      setIsError(prevState => ({ ...prevState, supplier: true }));
      return;
    }
  }

  const onCreate = (state) => {
    setFormState('Submitted');
    updateValidationRules('Submitted');
    const data = getValues();
    if (state) {
      setValue('state', state);
    }
    if (!data.PaidTo?.id) {
      setIsError(prevState => ({ ...prevState, supplier: true }));
      return;
    }
    const ledgers = data.paymentDetails?.ledgerDetail || [];
    if (ledgers.length === 0 || !ledgers[0].ledger?.id) {
      setIsError(prevState => ({ ...prevState, ledger: true }));
      setLedgerFormEdit({ visible: true, editingIndex: null })
      toast.error("Ledger is required");
      return;
    }
    const transactions = data.relatedTransactions || [];
    if (transactions.length === 0 || !transactions[0].id) {
      setIsError(prevState => ({ ...prevState, transaction: true }));
      setTransactionFormEdit({ visible: true, editingIndex: null })
      toast.error("Transaction is required");
      return;
    }
  }

  const onSubmit = (data) => {
    if (data.paymentNo) {
      handleEditPymt(data);
    } else {
      handleCreatePymt(data);
    }
  };

  const handleCreatePymt = async (data) => {
    const { id, ...requestData } = data;
    if (Object.values(isError).some(error => error)) {
      return;
    }
    setLoading("Create");
    try {
      const response = await fetch(`${(process.env.REACT_APP_API_HOST_INV === undefined) ? "" : process.env.REACT_APP_API_HOST_INV}/amigo-lekha-api/paymentManagement/v1/payment`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(requestData),
      });
      if (!response.ok) {
        throw new Error('Failed to create payment');
      }
      const res = await response.json();
      const id = encodeURIComponent(res.paymentNo);
      reset();
      toast.success("Payment created successfuly!", { duration: "5000", position: "top-right", description: `Payment Id - ${id}` });
      if (res.state === "Draft") {
        navigate(`/dashboard/payment/bank/edit/${id}`, { state: { item: res } });
      } else {
        navigate(`/dashboard/payment/bank`);
      }
    } catch (error) {
      console.error("Error creating Payment:", error);
      toast.error("Failed to create Payment");
    } finally {
      setLoading("");
    }
  };

  const handleEditPymt = async (data) => {
    if (Object.values(isError).some(error => error)) {
      return;
    }
    if (data.state === "Draft") {
      setLoading("Edit");
    } else {
      setLoading("Create");
    }
    try {
      const response = await fetch(`${(process.env.REACT_APP_API_HOST_INV === undefined) ? "" : process.env.REACT_APP_API_HOST_INV}/amigo-lekha-api/paymentManagement/v1/payment/${data.paymentNo}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(data),
      });
      if (!response.ok) {
        throw new Error('Failed to edit payment');
      }
      const res = await response.json();
      const id = encodeURIComponent(res.paymentNo);
      toast.success(`Payment ${res.state === "Draft" ? "Edited" : "Submitted"} Successfuly!`, { duration: "5000", position: "top-right", description: `Payment Id - ${id}` });
      reset(res);
      if (res.state === "Draft") {
        navigate(`/dashboard/payment/bank/edit/${id}`, { state: { item: res } });
      } else {
        navigate(`/dashboard/payment/bank`);
      }
    } catch (error) {
      console.error("Error editing Payment:", error);
      toast.error("Failed to edit Payment");
    } finally {
      setLoading("");
    }
  };
  return (
    <div className='mx-8 my-5'>
      <Link to="/dashboard/payment/bank" className='absolute mt-1 ml-5'><Button variant="outline" size="icon"><Undo2 className='size-4' /></Button></Link>
      <div className="flex items-center justify-center w-full mb-5 text-[3vh] font-bold">{id ? "Edit" : "New"} Payment</div>
      {paymentNo && <div className='flex mx-5 my-0'><div>Payment No - <span className='text-primary/70'>{paymentNo}</span></div><div className='ml-auto mr-2 text-sm'>Created By : {data?.createdBy}&ensp;|</div><div className='text-sm'>Updated By : {data?.updatedBy}</div></div>}
      <Separator />
      <form onSubmit={handleSubmit(onSubmit)} className='mt-2'>
        <ScrollArea className="h-[calc(100vh-200px)] lg:h-[70vh]">
          <div className='gap-4 px-4 py-2'>
            <div className="grid grid-cols-1 gap-4 mb-4 md:grid-cols-2 lg:grid-cols-4">
              <div className="flex flex-row items-center gap-2">
                <Label className="flex w-40 lg:w-44">Payment Date{errors.paymentDate && <p className="ml-1 text-destructive">*</p>}</Label>
                <Controller control={control} name="paymentDate" rules={{ required: true }} {...register("paymentDate")}
                  render={({ field }) => (
                    <DatePicker value={field.value} onChange={(date) => field.onChange(date)} disableDate={false} current={true} error={errors.paymentDate} />
                  )}
                />
              </div>
              <div className="flex flex-row items-center gap-2">
                <Label className="flex w-28">Paid To{isError.supplier && <p className="ml-1 text-destructive">*</p>}</Label>
                <SupplierSelect onSupplierSelect={handleSupplierSelect} value={paidTo?.id} isVisible={isVisible} isError={isError.supplier} />
              </div>
              <div className="flex flex-row items-center col-span-2 gap-2">
                <Label className="flex w-40 lg:w-32">Naration{errors.description && <p className="ml-1 text-destructive">*</p>}</Label>
                <Input className={`h-7 bg-muted/50 ${errors.description ? 'border-destructive focus-visible:ring-0' : ''}`} name="description" {...register("description", { required: formState === "Submitted" })} />
              </div>
            </div>
            <Separator />
            <div className='my-4'>
              <CardDescription className="my-2">Bank Details:</CardDescription>
              <div className="grid grid-cols-1 gap-x-4 gap-y-2 md:grid-cols-2 lg:grid-cols-4">
                <div className="flex flex-row items-center gap-2">
                  <Label className="flex w-40">Bank Name{errors.paymentDetails?.addToBankRef?.name && <p className="ml-1 text-destructive">*</p>}</Label>
                  <Input className={`h-7 bg-muted/50 ${errors.paymentDetails?.addToBankRef?.name ? 'border-destructive focus-visible:ring-0' : ''}`} name="name" {...register("paymentDetails.addToBankRef.name", { required: formState === "Submitted" })} />
                </div>
                <div className="flex flex-row items-center gap-2">
                  <Label className="flex w-40">Type{errors.paymentDetails?.transactionType && <p className="ml-1 text-destructive">*</p>}</Label>
                  <Input className={`h-7 bg-muted/50 ${errors.paymentDetails?.transactionType ? 'border-destructive focus-visible:ring-0' : ''}`} name="transactionType" {...register("paymentDetails.transactionType", { required: formState === "Submitted" })} />
                </div>
                <div className="flex flex-row items-center gap-2">
                  <Label className="flex w-40"> Amount{errors.paymentDetails?.amount && (<p className="ml-1 text-destructive">*</p>)}</Label>
                  {/* <GenericAmountSeparator value={watch("paymentDetails.amount", '')} onChange={(newValue) => setValue("paymentDetails.amount", newValue)} required={formState === "Submitted"}/> */}
                  <Controller name="paymentDetails.amount" control={control} rules={{ required: formState === "Submitted" }} render={({ field }) => (
                    <GenericAmountSeparator
                      {...field}
                      required={!!errors.paymentDetails?.amount}
                    />
                  )}
                  />
                </div>
                <div className="flex flex-row items-center gap-2">
                  <Label className="flex w-40 lg:w-56">FC Amount{errors.paymentDetails?.fcAmount && <p className="ml-1 text-destructive">*</p>}</Label>
                  {/* <GenericAmountSeparator value={watch("paymentDetails.fcAmount", '')} onChange={(newValue) => setValue("paymentDetails.fcAmount", newValue)} required={formState === "Submitted"} /> */}
                  <Controller name="paymentDetails.fcAmount" control={control} rules={{ required: formState === "Submitted" }} render={({ field }) => (
                    <GenericAmountSeparator
                      {...field}
                      required={!!errors.paymentDetails?.fcAmount}
                    />
                  )}
                  />
                </div>
                <div className="flex flex-row items-center gap-2">
                  <Label className="flex w-40">Currency{errors.paymentDetails?.currency && <p className="ml-1 text-destructive">*</p>}</Label>
                  <Input className={`h-7 bg-muted/50 ${errors.paymentDetails?.currency ? 'border-destructive focus-visible:ring-0' : ''}`} name="currency" {...register("paymentDetails.currency", { required: formState === "Submitted" })} />
                </div>
                <div className="flex flex-row items-center gap-2">
                  <Label className="flex w-40">Rate{errors.paymentDetails?.rate && <p className="ml-1 text-destructive">*</p>}</Label>
                  <Input className={`h-7 bg-muted/50 ${errors.paymentDetails?.rate ? 'border-destructive focus-visible:ring-0' : ''}`} name="rate" {...register("paymentDetails.rate", { required: formState === "Submitted" })} />
                </div>
                <div className="flex flex-row items-center gap-2">
                  <Label className="flex w-40">Cheque No{errors.paymentDetails?.chequeNo && <p className="ml-1 text-destructive">*</p>}</Label>
                  <Input className={`h-7 bg-muted/50 ${errors.paymentDetails?.chequeNo ? 'border-destructive focus-visible:ring-0' : ''}`} name="chequeNo" {...register("paymentDetails.chequeNo", { required: formState === "Submitted" })} />
                </div>
                <div className="flex flex-row items-center gap-2">
                  <Label className="flex w-40 lg:w-56">Cheque Date{errors.paymentDetails?.chequeDate && <p className="ml-1 text-destructive">*</p>}</Label>
                  <Controller control={control} name="chequeDate" rules={{ required: formState === "Submitted" }} {...register("paymentDetails.chequeDate", { required: formState === "Submitted" })}
                    render={({ field }) => (
                      <DatePicker value={field.value} onChange={(date) => field.onChange(date)} error={errors.paymentDetails?.chequeDate} />
                    )}
                  />
                </div>
                <div className="flex flex-row items-center gap-2">
                  <Label className="flex w-40">Drawn On{errors.paymentDetails?.drawnOn && <p className="ml-1 text-destructive">*</p>}</Label>
                  <Input className={`h-7 bg-muted/50 ${errors.paymentDetails?.drawnOn ? 'border-destructive focus-visible:ring-0' : ''}`} name="drawnOn" {...register("paymentDetails.drawnOn", { required: formState === "Submitted" })} />
                </div>
                <div className="flex flex-row items-center gap-2">
                  <Label className="flex w-40">Branch{errors.paymentDetails?.branch && <p className="ml-1 text-destructive">*</p>}</Label>
                  <Input className={`h-7 bg-muted/50 ${errors.paymentDetails?.branch ? 'border-destructive focus-visible:ring-0' : ''}`} name="branch" {...register("paymentDetails.branch", { required: formState === "Submitted" })} />
                </div>
                <div className="flex flex-row items-center gap-2">
                  <Label className="flex w-40">Rec. Date{errors.paymentDetails?.recDate && <p className="ml-1 text-destructive">*</p>}</Label>
                  <Controller control={control} name="recDate" rules={{ required: formState === "Submitted" }} {...register("paymentDetails.recDate", { required: formState === "Submitted" })}
                    render={({ field }) => (
                      <DatePicker value={field.value} onChange={(date) => field.onChange(date)} error={errors.paymentDetails?.recDate} />
                    )}
                  />
                </div>
              </div>

            </div>
            <Separator />
            <div>
              <div className="flex flex-row items-center justify-between gap-4 my-2">
                <CardDescription >Ledger Details:</CardDescription>
                <Button size='sm' variant="secondary" onClick={(e) => { setLedgerFormEdit(prevState => ({ ...prevState, visible: !prevState.visible, editingIndex: null })); e.preventDefault(); }}>
                  Add Ledger<CirclePlus className="ml-2 size-4" />
                </Button>
              </div>
              <Card className="my-3">
                <CardContent className="p-0">
                  <Table>
                    <TableHeader className="bg-secondary/60">
                      <TableRow>
                        <TableHead className="rounded-tl-md"></TableHead>
                        <TableHead>Ledger</TableHead>
                        <TableHead>Type</TableHead>
                        <TableHead>Amount</TableHead>
                        <TableHead>FC Amount</TableHead>
                        <TableHead>Currency</TableHead>
                        <TableHead>Rate</TableHead>
                        <TableHead>Approval</TableHead>
                        <TableHead>Naration</TableHead>
                        <TableHead className="rounded-tr-md"><span className="sr-only">Actions</span></TableHead>
                      </TableRow>
                    </TableHeader>
                    <TableBody>
                      {editingLedgerForm.length > 0 ? (
                        editingLedgerForm.map((item, index) => (
                          <React.Fragment key={index}>
                            <TableRow>
                              <TableCell></TableCell>
                              <TableCell>{item.ledger?.id}</TableCell>
                              <TableCell>{item.transactionType}</TableCell>
                              <TableCell>{item.amount ? <GenericAmountSeparator value={item.amount} viewMode={true} /> : '-'}</TableCell>
                              <TableCell>{item.fcAmount ? <GenericAmountSeparator value={item.fcAmount} viewMode={true} /> : '-'}</TableCell>
                              <TableCell>{item.currency}</TableCell>
                              <TableCell>{item.rate}</TableCell>
                              <TableCell>{item.approval}</TableCell>
                              <TableCell>{item.description}</TableCell>
                              <TableCell>
                                <div className="flex flex-row items-center gap-4">
                                  <Edit onClick={() => setLedgerFormEdit(prevState => ({ ...prevState, visible: !prevState.visible, editingIndex: index }))} className="cursor-pointer text-primary size-4" />
                                  <Trash2 onClick={() => handleDeleteL(index)} className="cursor-pointer text-destructive size-4" />
                                </div>
                              </TableCell>
                            </TableRow>
                            {ledgerFormEdit.visible && ledgerFormEdit.editingIndex === index && (
                              <TableRow>
                                <TableCell colSpan={11}>
                                  <div className="grid grid-cols-1 gap-4 px-4 my-4 md:grid-cols-2 lg:grid-cols-4">
                                    <div className="flex flex-row items-center gap-3">
                                      <Label className="w-40">Ledger Name{isError.ledger && <p className="ml-1 text-destructive">*</p>}</Label>
                                      <SelectDrop onTypeSelect={(type) => handleLChange(ledgerFormEdit.editingIndex, "id", type)} typeName="ledger" isVisible={true} value={ledgerFormEdit.editingIndex !== null ? ledgerDetail[ledgerFormEdit.editingIndex]?.ledger?.id : ''} />
                                    </div>
                                    <div className="flex flex-row items-center gap-2">
                                      <Label className="w-40">Type{isError.ledger && <p className="ml-1 text-destructive">*</p>}</Label>
                                      <Input className="h-7 bg-muted/50" name="transactionType" defaultValue={ledgerFormEdit.editingIndex !== null ? ledgerDetail[ledgerFormEdit.editingIndex]?.transactionType || '' : ''}
                                        onChange={(e) => handleLChange(ledgerFormEdit.editingIndex, 'transactionType', e.target.value)} />
                                    </div>
                                    <div className="flex flex-row items-center gap-2">
                                      <Label className="w-40">Amount{isError.ledger && <p className="ml-1 text-destructive">*</p>}</Label>
                                      <GenericAmountSeparator value={ledgerFormEdit.editingIndex !== null ? ledgerDetail[ledgerFormEdit.editingIndex]?.amount || '' : ''} onChange={(value) => handleLChange(ledgerFormEdit.editingIndex, 'amount', value)} />
                                    </div>
                                    <div className="flex flex-row items-center gap-2">
                                      <Label className="w-40">FC Amount{isError.ledger && <p className="ml-1 text-destructive">*</p>}</Label>
                                      <GenericAmountSeparator value={ledgerFormEdit.editingIndex !== null ? ledgerDetail[ledgerFormEdit.editingIndex]?.fcAmount || '' : ''} onChange={(value) => handleLChange(ledgerFormEdit.editingIndex, 'fcAmount', value)} />
                                    </div>
                                    <div className="flex flex-row items-center gap-2">
                                      <Label className="w-40 lg:w-48">Currency{isError.ledger && <p className="ml-1 text-destructive">*</p>}</Label>
                                      <Input className="h-7 bg-muted/50" name="currency" defaultValue={ledgerFormEdit.editingIndex !== null ? ledgerDetail[ledgerFormEdit.editingIndex]?.currency || '' : ''}
                                        onChange={(e) => handleLChange(ledgerFormEdit.editingIndex, 'currency', e.target.value)} />
                                    </div>
                                    <div className="flex flex-row items-center gap-2">
                                      <Label className="w-40">Rate{isError.ledger && <p className="ml-1 text-destructive">*</p>}</Label>
                                      <Input className="h-7 bg-muted/50" name="rate" defaultValue={ledgerFormEdit.editingIndex !== null ? ledgerDetail[ledgerFormEdit.editingIndex]?.rate || '' : ''}
                                        onChange={(e) => handleLChange(ledgerFormEdit.editingIndex, 'rate', e.target.value)} />
                                    </div>
                                    <div className="flex flex-row items-center gap-2">
                                      <Label className="w-40">Approval{isError.ledger && <p className="ml-1 text-destructive">*</p>}</Label>
                                      <Input className="h-7 bg-muted/50" name="approval" defaultValue={ledgerFormEdit.editingIndex !== null ? ledgerDetail[ledgerFormEdit.editingIndex]?.approval || '' : ''}
                                        onChange={(e) => handleLChange(ledgerFormEdit.editingIndex, 'approval', e.target.value)} />
                                    </div>
                                    <div className="flex flex-row items-center gap-2">
                                      <Label className="w-40">Naration{isError.ledger && <p className="ml-1 text-destructive">*</p>}</Label>
                                      <Input className="h-7 bg-muted/50" name="description" defaultValue={ledgerFormEdit.editingIndex !== null ? ledgerDetail[ledgerFormEdit.editingIndex]?.description || '' : ''}
                                        onChange={(e) => handleLChange(ledgerFormEdit.editingIndex, 'description', e.target.value)} />
                                    </div>
                                  </div>
                                  <div className="flex flex-row col-span-4 px-4 my-4">
                                    <Button variant="outline" className="ml-auto mr-3 bg-primary/30 hover:bg-primary/40" onClick={handleLedgerFormSave}>Save</Button>
                                    <Button variant="outline" className="bg-primary/30 hover:bg-primary/40" onClick={(e) => { setLedgerFormEdit(prevState => ({ ...prevState, visible: !prevState.visible, editingIndex: null })); e.preventDefault(); }}>Close</Button>
                                  </div>
                                </TableCell>
                              </TableRow>
                            )}
                          </React.Fragment>
                        ))
                      ) : (
                        <TableRow>
                          <TableCell colSpan={10} className="text-center">No Ledger Details</TableCell>
                        </TableRow>
                      )}
                    </TableBody>
                  </Table>
                  {ledgerFormEdit.visible && ledgerFormEdit.editingIndex === null && (
                    <div>
                      <Separator />
                      <div className="grid grid-cols-1 gap-4 px-4 my-4 md:grid-cols-2 lg:grid-cols-4">
                        <div className="flex flex-row items-center gap-3">
                          <Label className="w-40">Ledger Name{isError.ledger && <p className="ml-1 text-destructive">*</p>}</Label>
                          <SelectDrop onTypeSelect={(type) => handleLChange(ledgerFormEdit.editingIndex, "id", type)} typeName="ledger" isVisible={true} value={ledgerFormEdit.editingIndex !== null ? ledgerDetail[ledgerFormEdit.editingIndex]?.ledger?.id : ''} />
                        </div>
                        <div className="flex flex-row items-center gap-2">
                          <Label className="w-40">Type{isError.ledger && <p className="ml-1 text-destructive">*</p>}</Label>
                          <Input className="h-7 bg-muted/50" name="transactionType" defaultValue={ledgerFormEdit.editingIndex !== null ? ledgerDetail[ledgerFormEdit.editingIndex]?.transactionType || '' : ''}
                            onChange={(e) => handleLChange(ledgerFormEdit.editingIndex, 'transactionType', e.target.value)} />
                        </div>
                        <div className="flex flex-row items-center gap-2">
                          <Label className="w-40">Amount{isError.ledger && <p className="ml-1 text-destructive">*</p>}</Label>
                          <GenericAmountSeparator value={ledgerFormEdit.editingIndex !== null ? ledgerDetail[ledgerFormEdit.editingIndex]?.amount || '' : ''} onChange={(value) => handleLChange(ledgerFormEdit.editingIndex, 'amount', value)} />
                        </div>
                        <div className="flex flex-row items-center gap-2">
                          <Label className="w-40">FC Amount{isError.ledger && <p className="ml-1 text-destructive">*</p>}</Label>
                          <GenericAmountSeparator value={ledgerFormEdit.editingIndex !== null ? ledgerDetail[ledgerFormEdit.editingIndex]?.fcAmount || '' : ''} onChange={(value) => handleLChange(ledgerFormEdit.editingIndex, 'fcAmount', value)} />
                        </div>
                        <div className="flex flex-row items-center gap-2">
                          <Label className="w-40 lg:w-48">Currency{isError.ledger && <p className="ml-1 text-destructive">*</p>}</Label>
                          <Input className="h-7 bg-muted/50" name="currency" defaultValue={ledgerFormEdit.editingIndex !== null ? ledgerDetail[ledgerFormEdit.editingIndex]?.currency || '' : ''}
                            onChange={(e) => handleLChange(ledgerFormEdit.editingIndex, 'currency', e.target.value)} />
                        </div>
                        <div className="flex flex-row items-center gap-2">
                          <Label className="w-40">Rate{isError.ledger && <p className="ml-1 text-destructive">*</p>}</Label>
                          <Input className="h-7 bg-muted/50" name="rate" defaultValue={ledgerFormEdit.editingIndex !== null ? ledgerDetail[ledgerFormEdit.editingIndex]?.rate || '' : ''}
                            onChange={(e) => handleLChange(ledgerFormEdit.editingIndex, 'rate', e.target.value)} />
                        </div>
                        <div className="flex flex-row items-center gap-2">
                          <Label className="w-40">Approval{isError.ledger && <p className="ml-1 text-destructive">*</p>}</Label>
                          <Input className="h-7 bg-muted/50" name="approval" defaultValue={ledgerFormEdit.editingIndex !== null ? ledgerDetail[ledgerFormEdit.editingIndex]?.approval || '' : ''}
                            onChange={(e) => handleLChange(ledgerFormEdit.editingIndex, 'approval', e.target.value)} />
                        </div>
                        <div className="flex flex-row items-center gap-2">
                          <Label className="w-40">Naration{isError.ledger && <p className="ml-1 text-destructive">*</p>}</Label>
                          <Input className="h-7 bg-muted/50" name="description" defaultValue={ledgerFormEdit.editingIndex !== null ? ledgerDetail[ledgerFormEdit.editingIndex]?.description || '' : ''}
                            onChange={(e) => handleLChange(ledgerFormEdit.editingIndex, 'description', e.target.value)} />
                        </div>
                      </div>
                      <div className="flex flex-row col-span-4 px-4 my-4">
                        <Button variant="outline" className="ml-auto mr-3 bg-primary/30 hover:bg-primary/40" onClick={handleLedgerFormSave}>Save</Button>
                        <Button variant="outline" className="bg-primary/30 hover:bg-primary/40" onClick={(e) => { setLedgerFormEdit(prevState => ({ ...prevState, visible: !prevState.visible, editingIndex: null })); e.preventDefault(); }}>Close</Button>
                      </div>
                    </div>
                  )}
                </CardContent>
              </Card>
            </div>
            <Separator />
            <div>
              <div className="flex flex-row items-center justify-between gap-4 my-2">
                <CardDescription >Transaction Details:</CardDescription>
                <Button size='sm' variant="secondary" onClick={(e) => { setTransactionFormEdit(prevState => ({ ...prevState, visible: !prevState.visible, editingIndex: null })); e.preventDefault(); }}>
                  Add Transaction<CirclePlus className="ml-2 size-4" />
                </Button>
              </div>
              <Card className="my-3">
                <CardContent className="p-0">
                  <Table>
                    <TableHeader className="bg-secondary/60">
                      <TableRow>
                        <TableHead className="rounded-tl-md"></TableHead>
                        <TableHead>Transaction No</TableHead>
                        <TableHead>Date</TableHead>
                        <TableHead>Type</TableHead>
                        <TableHead>Amount</TableHead>
                        <TableHead className="rounded-tr-md"><span className="sr-only">Actions</span></TableHead>
                      </TableRow>
                    </TableHeader>
                    <TableBody>
                      {editingTransactionForm.length > 0 ? (
                        editingTransactionForm.map((item, index) => (
                          <React.Fragment key={index}>
                            <TableRow>
                              <TableCell></TableCell>
                              <TableCell>{item.id}</TableCell>
                              <TableCell>{item.transactionDate ? new Date(item.transactionDate).toLocaleDateString() : ""}</TableCell>
                              <TableCell>{item.transactionType}</TableCell>
                              <TableCell>{item.transactionAmount ? <GenericAmountSeparator value={item.transactionAmount} viewMode={true} /> : '-'}</TableCell>
                              <TableCell>
                                <div className="flex flex-row items-center gap-4">
                                  <Edit onClick={() => setTransactionFormEdit(prevState => ({ ...prevState, visible: !prevState.visible, editingIndex: index }))} className="cursor-pointer text-primary size-4" />
                                  <Trash2 onClick={() => handleDeleteT(index)} className="cursor-pointer text-destructive size-4" />
                                </div>
                              </TableCell>
                            </TableRow>
                            {transactionFormEdit.visible && transactionFormEdit.editingIndex === index && (
                              <TableRow>
                                <TableCell colSpan={11}>
                                  <div className="grid grid-cols-1 gap-4 px-4 my-4 md:grid-cols-2 lg:grid-cols-4">
                                    <div className="flex flex-row items-center gap-3">
                                      <Label className="w-40 lg:w-44">Transaction No{isError.transaction && <p className="ml-1 text-destructive">*</p>}</Label>
                                      <Input className="h-7 bg-muted/50" name="id" defaultValue={transactionFormEdit.editingIndex !== null ? transactionDetail[transactionFormEdit.editingIndex]?.id || '' : ''}
                                        onChange={(e) => handleTChange(transactionFormEdit.editingIndex, 'id', e.target.value)} />
                                    </div>
                                    <div className="flex flex-row items-center gap-2">
                                      <Label className="w-40">Date{isError.transaction && <p className="ml-1 text-destructive">*</p>}</Label>
                                      <DatePicker className="h-7 bg-muted/50" value={transactionFormEdit.editingIndex !== null ? transactionDetail[transactionFormEdit.editingIndex].transactionDate : ''}
                                        onChange={(date) => handleTChange(transactionFormEdit.editingIndex, 'transactionDate', date)} />
                                    </div>
                                    <div className="flex flex-row items-center gap-2">
                                      <Label className="w-40">Type{isError.transaction && <p className="ml-1 text-destructive">*</p>}</Label>
                                      <Select name="title" defaultValue={transactionFormEdit.editingIndex !== null ? transactionDetail[transactionFormEdit.editingIndex]?.transactionType || '' : ''}
                                        onValueChange={(e) => handleTChange(transactionFormEdit.editingIndex, 'transactionType', e)}>
                                        <SelectTrigger className="w-full h-7 bg-muted/50 focus:ring-transparent">
                                          <SelectValue />
                                        </SelectTrigger>
                                        <SelectContent>
                                          <SelectGroup>
                                            {tranTypeItems.map(item => (
                                              <SelectItem key={item} value={item}>
                                                {item}
                                              </SelectItem>
                                            ))}
                                          </SelectGroup>
                                        </SelectContent>
                                      </Select>
                                    </div>
                                    <div className="flex flex-row items-center gap-2">
                                      <Label className="w-40">Amount{isError.transaction && <p className="ml-1 text-destructive">*</p>}</Label>
                                      <GenericAmountSeparator value={transactionFormEdit.editingIndex !== null ? transactionDetail[transactionFormEdit.editingIndex]?.transactionAmount || '' : ''}
                                        onChange={(value) => handleTChange(transactionFormEdit.editingIndex, 'transactionAmount', value)} />
                                    </div>
                                  </div>
                                  <div className="flex flex-row col-span-4 px-4 my-4">
                                    <Button variant="outline" className="ml-auto mr-3 bg-primary/30 hover:bg-primary/40" onClick={handleTransactionFormSave}>Save</Button>
                                    <Button variant="outline" className="bg-primary/30 hover:bg-primary/40" onClick={(e) => { setTransactionFormEdit(prevState => ({ ...prevState, visible: !prevState.visible, editingIndex: null })); e.preventDefault(); }}>Close</Button>
                                  </div>
                                </TableCell>
                              </TableRow>
                            )}
                          </React.Fragment>
                        ))
                      ) : (
                        <TableRow>
                          <TableCell colSpan={10} className="text-center">No Transactions</TableCell>
                        </TableRow>
                      )}
                    </TableBody>
                  </Table>
                  {transactionFormEdit.visible && transactionFormEdit.editingIndex === null && (
                    <div>
                      <Separator />
                      <div className="grid grid-cols-1 gap-4 px-4 my-4 md:grid-cols-2 lg:grid-cols-4">
                        <div className="flex flex-row items-center gap-3">
                          <Label className="w-40 lg:w-44">Transaction No{isError.transaction && <p className="ml-1 text-destructive">*</p>}</Label>
                          <Input className="h-7 bg-muted/50" name="id" defaultValue={transactionFormEdit.editingIndex !== null ? transactionDetail[transactionFormEdit.editingIndex]?.id || '' : ''}
                            onChange={(e) => handleTChange(transactionFormEdit.editingIndex, 'id', e.target.value)} />
                        </div>
                        <div className="flex flex-row items-center gap-2">
                          <Label className="w-40">Date{isError.transaction && <p className="ml-1 text-destructive">*</p>}</Label>
                          <DatePicker className="h-7 bg-muted/50" value={transactionFormEdit.editingIndex !== null ? transactionDetail[transactionFormEdit.editingIndex].transactionDate : ''}
                            onChange={(date) => handleTChange(transactionFormEdit.editingIndex, 'transactionDate', date)} />
                        </div>
                        <div className="flex flex-row items-center gap-2">
                          <Label className="w-40">Type{isError.transaction && <p className="ml-1 text-destructive">*</p>}</Label>
                          <Select name="title" defaultValue={transactionFormEdit.editingIndex !== null ? transactionDetail[transactionFormEdit.editingIndex]?.transactionType || '' : ''}
                            onValueChange={(e) => handleTChange(transactionFormEdit.editingIndex, 'transactionType', e)}>
                            <SelectTrigger className="w-full h-7 bg-muted/50 focus:ring-transparent">
                              <SelectValue />
                            </SelectTrigger>
                            <SelectContent>
                              <SelectGroup>
                                {tranTypeItems.map(item => (
                                  <SelectItem key={item} value={item}>
                                    {item}
                                  </SelectItem>
                                ))}
                              </SelectGroup>
                            </SelectContent>
                          </Select>
                        </div>
                        <div className="flex flex-row items-center gap-2">
                          <Label className="w-40">Amount{isError.transaction && <p className="ml-1 text-destructive">*</p>}</Label>
                          <GenericAmountSeparator value={transactionFormEdit.editingIndex !== null ? transactionDetail[transactionFormEdit.editingIndex]?.transactionAmount || '' : ''}
                            onChange={(value) => handleTChange(transactionFormEdit.editingIndex, 'transactionAmount', value)} />
                        </div>
                      </div>
                      <div className="flex flex-row col-span-4 px-4 my-4">
                        <Button variant="outline" className="ml-auto mr-3 bg-primary/30 hover:bg-primary/40" onClick={handleTransactionFormSave}>Save</Button>
                        <Button variant="outline" className="bg-primary/30 hover:bg-primary/40" onClick={(e) => { setTransactionFormEdit(prevState => ({ ...prevState, visible: !prevState.visible, editingIndex: null })); e.preventDefault(); }}>Close</Button>
                      </div>
                    </div>
                  )}
                </CardContent>
              </Card>
            </div>
          </div>
        </ScrollArea>
        <CardFooter className="py-0 mt-2 mr-2">
          {
            !id ? (
              <div className="ml-auto">
                <Button onClick={onSave} className="mr-2" disabled={loading}>
                  {loading === "Save" ? 'Saving...' : 'Save Draft'}
                </Button>
                <Button onClick={() => onCreate('Submitted')} disabled={loading}>
                  {loading === "Create" ? 'Creating...' : 'Create Payment'}
                </Button>
              </div>
            ) : (
              <div className='ml-auto'>
                {(data && data.state === "Draft") &&
                  <Button onClick={onSave} disabled={loading}>
                    {loading === "Edit" ? 'Saving...' : 'Save Changes'}
                  </Button>}
                {(data && data.state === "Draft") &&
                  <Button onClick={() => onCreate('Submitted')} className="ml-2" disabled={loading}>
                    {loading === "Create" ? 'Creating...' : 'Create Payment'}
                  </Button>
                }
              </div>
            )
          }
        </CardFooter>
      </form>
    </div>
  )
}
