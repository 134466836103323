import { Button } from 'src/components/ui/button'
import { CirclePlus, Edit, Trash2 } from 'lucide-react'
import React, { useCallback, useEffect, useState } from 'react'
import {
    AlertDialog,
    AlertDialogCancel,
    AlertDialogContent,
    AlertDialogDescription,
    AlertDialogFooter,
    AlertDialogTitle,
    AlertDialogTrigger,
} from "src/components/ui/alert-dialog"
import { Controller, useForm } from 'react-hook-form';
import { Card, CardContent, CardDescription, CardFooter } from 'src/components/ui/card';
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from "src/components/ui/table";
import { Select, SelectContent, SelectGroup, SelectItem, SelectTrigger, SelectValue, } from "src/components/ui/select"
import { Label } from 'src/components/ui/label';
import { toast } from 'sonner';
import { Input } from 'src/components/ui/input';
import { Separator } from 'src/components/ui/separator';
import { ScrollArea } from 'src/components/ui/scroll-area';
import { DateTimePicker } from 'src/components/ui/dateTimePicker/datetime-picker';
import { DatePicker } from 'src/pages/components/datepicker';


export default function CabProductDialog({ dropData, onRideSave, data, dirtyCheck, calcCharge, suppAmount, rideClose }) {
    const { register, reset, control, setValue, getValues, formState: { errors, isDirty }, trigger, watch } = useForm({
        mode: "onChange",
        defaultValues: {
            carDetails: "",
            agent: "",
            handledBy: "",
            requisitionBy: "",
            passenger: [],
            carriers: [],
            charges: [],
            supplierCharges: [],
        }
    });
    const { titleItems, paxTypeItems, genderItems, categoryItems, dutyTypeItems } = dropData;
    const [custChargeForm, setCustChargeForm] = useState([
        {
            chargeCodeName: "GST",
            calculationMethod: "",
            value: 0,
            totalValue: 0,
            tax: [],
            taxTotal: null,
        },
        {
            chargeCodeName: "Tolls",
            calculationMethod: "",
            value: 0,
            totalValue: 0,
            tax: [],
            taxTotal: null,
        },
        {
            chargeCodeName: "Night Charges",
            calculationMethod: "",
            value: 0,
            totalValue: 0,
            tax: [],
            taxTotal: null,
        },
        {
            chargeCodeName: "Driver Allowance",
            calculationMethod: "",
            value: 0,
            totalValue: 0,
            tax: [],
            taxTotal: null,
        },
        {
            chargeCodeName: "Parking",
            calculationMethod: "",
            value: 0,
            totalValue: 0,
            tax: [],
            taxTotal: null,
        },
        {
            chargeCodeName: "Other Charges",
            calculationMethod: "",
            value: 0,
            totalValue: 0,
            tax: [],
            taxTotal: null,
        }
    ]);
    const [suppChargeForm, setSuppChargeForm] = useState([
        {
            chargeCodeName: "GST",
            calculationMethod: "",
            value: 0,
            totalValue: 0,
            tax: [],
            taxTotal: null,
        },
        {
            chargeCodeName: "Tolls",
            calculationMethod: "",
            value: 0,
            totalValue: 0,
            tax: [],
            taxTotal: null,
        },
        {
            chargeCodeName: "Night Charges",
            calculationMethod: "",
            value: 0,
            totalValue: 0,
            tax: [],
            taxTotal: null,
        },
        {
            chargeCodeName: "Driver Allowance",
            calculationMethod: "",
            value: 0,
            totalValue: 0,
            tax: [],
            taxTotal: null,
        },
        {
            chargeCodeName: "Parking",
            calculationMethod: "",
            value: 0,
            totalValue: 0,
            tax: [],
            taxTotal: null,
        },
        {
            chargeCodeName: "Other Charges",
            calculationMethod: "",
            value: 0,
            totalValue: 0,
            tax: [],
            taxTotal: null,
        }
    ]);
    const initialCharges = [
        { name: "GST", customerCharge: 0, supplierCharge: 0 },
        { name: "Tolls", customerCharge: 0, supplierCharge: 0 },
        { name: "Night Charges", customerCharge: 0, supplierCharge: 0 },
        { name: "Driver Allowance", customerCharge: 0, supplierCharge: 0 },
        { name: "Parking", customerCharge: 0, supplierCharge: 0 },
        { name: "Other Charges", customerCharge: 0, supplierCharge: 0 },
    ];

    const [charges, setCharges] = useState(initialCharges);
    const [supplierAmount, setSupplierAmount] = useState(0);

    useEffect(() => {
        const gstAmount = (suppAmount * 5) / 100;
        setSupplierAmount(parseFloat(suppAmount) || 0);
        setSuppChargeForm(prevState => {
            const updatedCharges = Array.isArray(prevState) ? [...prevState] : [];
            const gstIndex = updatedCharges.findIndex(charge => charge.chargeCodeName === "GST");
            if (gstIndex !== -1) {
                updatedCharges[gstIndex].totalValue = gstAmount || 0;
            }
            return updatedCharges;
        });
        setValue("supplierCharges", suppChargeForm);
        setCharges(prevCharges =>
            prevCharges.map(charge =>
                charge.name === "GST" ? { ...charge, supplierCharge: gstAmount || 0 } : charge
            )
        );
    }, [suppAmount]);

    useEffect(() => {
        if (data) {
            reset(data);
            setRemarks(data.passenger[0].remark);
            const updatedCharges = charges.map(charge => {
                const customerCharge = data.charges?.find(c => c.chargeCodeName === charge.name)?.totalValue || 0;
                const supplierCharge = data.supplierCharges?.find(s => s.chargeCodeName === charge.name)?.totalValue || 0;
                return {
                    ...charge,
                    customerCharge,
                    supplierCharge
                };
            });
            setCharges(updatedCharges);
            setCustChargeForm(data?.charges);
            setSuppChargeForm(data?.supplierCharges)
        }
    }, [data, reset, genderItems]);

    useEffect(() => {
        dirtyCheck(isDirty);
    }, [dirtyCheck, isDirty]);

    const [currentRemark, setCurrentRemark] = useState({ name: '', charge: '' });
    const [remarks, setRemarks] = useState([]);

    const updateCurrentRemark = (field, value) => {
        setCurrentRemark(prev => ({ ...prev, [field]: value }));
    };
    const addRemark = (index) => {
        if (currentRemark.name || currentRemark.charge) {
            const newRemarks = Array.isArray(remarks) ? [...remarks] : [];
            if (!newRemarks.some(r => r.name === currentRemark.name && r.charge === currentRemark.charge)) {
                newRemarks.push(currentRemark);
                setRemarks(newRemarks);
                setValue(`passenger[${index}].remark`, newRemarks);
            }
            setCurrentRemark({ name: '', charge: '' });
        }
    };
    const deleteRemark = (remarkIndex, passengerIndex) => {
        const newRemarks = remarks.filter((_, index) => index !== remarkIndex);
        setRemarks(newRemarks);
        setValue(`passenger[${passengerIndex}].remark`, newRemarks);
    };
    const cabTarrif = watch("carriers[0].fare.cabTarrif", 0);
    const extraKmCharges = watch("carriers[0].fare.extraKmCharges", 0);
    const extraTimeCharges = watch("carriers[0].fare.extraTimeCharges", 0);
    const additionalCharges = watch("carriers[0].fare.additionalCharges", 0);

    useEffect(() => {
        const parsedCabTarrif = parseFloat(cabTarrif) || 0;
        const parsedKmCharges = parseFloat(extraKmCharges) || 0;
        const parsedTimeCharges = parseFloat(extraTimeCharges) || 0;
        const parsedAdditionalCharges = parseFloat(additionalCharges) || 0;
        const netAmount = parsedCabTarrif + parsedKmCharges + parsedTimeCharges + parsedAdditionalCharges;
        const gstAmount = (parseFloat(netAmount) * 5) / 100;
        setValue("carriers[0].fare.netAmount", netAmount);
        setCustChargeForm(prevState => {
            const updatedCharges = Array.isArray(prevState) ? [...prevState] : [];
            const gstIndex = updatedCharges.findIndex(charge => charge.chargeCodeName === "GST");
            if (gstIndex !== -1) {
                updatedCharges[gstIndex].totalValue = gstAmount || 0;
            }
            return updatedCharges;
        });
        setValue("charges", custChargeForm);
        setCharges(prevCharges =>
            prevCharges.map(charge =>
                charge.name === "GST" ? { ...charge, customerCharge: gstAmount || 0 } : charge
            )
        );
    }, [setValue, additionalCharges, cabTarrif, extraKmCharges, extraTimeCharges]);

    const extraKmRate = watch("carriers[0].fare.extraKmRate", 0);
    const extraTimeRate = watch("carriers[0].fare.extraTimeRate", 0);
    const extraTime = watch("carriers[0].fare.extraTime", 0);
    const extraKm = watch("carriers[0].fare.extraKM", 0);

    useEffect(() => {
        const extraTimeCharges = extraTimeRate * extraTime;
        const extraKmCharges = extraKmRate * extraKm;
        setValue("carriers[0].fare.extraTimeCharges", extraTimeCharges);
        setValue("carriers[0].fare.extraKmCharges", extraKmCharges);
    }, [extraKm, extraKmRate, extraTime, extraTimeRate, setValue]);

    const handleChargeChange = (formType, index, event) => {
        const { value } = event.target;
        const updatedValue = parseFloat(value) || 0;
        if (formType === "customer") {
            setCustChargeForm(prevState => {
                const updatedForm = [...prevState];
                updatedForm[index].totalValue = updatedValue;
                setCharges(prevCharges =>
                    prevCharges.map(charge =>
                        charge.name === updatedForm[index].chargeCodeName
                            ? { ...charge, customerCharge: updatedValue }
                            : charge
                    )
                );
                setValue("charges", updatedForm);
                return updatedForm;
            });
        } else if (formType === "supplier") {
            setSuppChargeForm(prevState => {
                const updatedForm = [...prevState];
                updatedForm[index].totalValue = updatedValue;
                setCharges(prevCharges =>
                    prevCharges.map(charge =>
                        charge.name === updatedForm[index].chargeCodeName
                            ? { ...charge, supplierCharge: updatedValue }
                            : charge
                    )
                );
                setValue("supplierCharges", updatedForm);
                return updatedForm;
            });
        }
    };

    const customerTotal = charges.reduce((total, charge) => {
        return total + (Number(charge.customerCharge) || 0);
    }, 0);
    const supplierTotal = charges.reduce((total, charge) => {
        return total + (Number(charge.supplierCharge) || 0);
    }, 0);

    const handleSave = async (e) => {
        e.preventDefault();
        const isValid = await trigger();
        if (!isValid) {
            toast.error("Fill all the required details!")
            return;
        }
        setValue("charges", custChargeForm);
        setValue("supplierCharges", suppChargeForm);
        const data = getValues();
        onRideSave(data);
        clear();
    }
    const clear = (e) => {
        if (e) e.preventDefault();
        reset();
    }
    const handleClose = () => {
        rideClose(true);
    }
    return (
        <>
            <form className='m-4'>
                <div className="grid grid-cols-1 mb-5 gap-x-4 gap-y-2 md:grid-cols-2 lg:grid-cols-5">
                    <div className="flex flex-row items-center gap-2">
                        <Label className="flex w-40">Car Details</Label>
                        <Input className="h-7 bg-muted/50" name="carDetails" {...register("carDetails")} />
                    </div>
                    <div className="flex flex-row items-center gap-2">
                        <Label className="flex w-40 lg:w-44">Through Agent</Label>
                        <Input className="h-7 bg-muted/50" name="agent" {...register("agent")} />
                    </div>
                    <div className="flex flex-row items-center gap-2">
                        <Label className="flex w-40 lg:w-44">Requisition By</Label>
                        <Input className="h-7 bg-muted/50" name="requisitionBy" {...register("requisitionBy")} />
                    </div>
                    <div className="flex flex-row items-center gap-2">
                        <Label className="flex w-40 lg:w-44">Handled By</Label>
                        <Input className="h-7 bg-muted/50" name="handledBy" {...register("handledBy")} />
                    </div>
                </div>
                <Separator />
                <div className="flex flex-row items-center justify-between gap-4 my-3 mr-2">
                    <CardDescription>Passenger Details :</CardDescription>
                </div>
                <div className="grid gap-3 my-4 lg:grid-cols-4 sm:grid-cols-1 md:grid-cols-2">
                    <div className="flex flex-row items-center gap-2">
                        <Label className="flex w-40 lg:w-32">Title</Label>
                        <Controller name="passenger[0].title" defaultValue="Mr" control={control} rules={{ required: true }} render={({ field }) => (
                            <Select onValueChange={field.onChange} value={field.value}>
                                <SelectTrigger className="w-full h-7 bg-muted/50 focus:ring-transparent">
                                    <SelectValue />
                                </SelectTrigger>
                                <SelectContent>
                                    <SelectGroup>
                                        {titleItems.map(item => (
                                            <SelectItem key={item} value={item}>
                                                {item}
                                            </SelectItem>
                                        ))}
                                    </SelectGroup>
                                </SelectContent>
                            </Select>
                        )}
                        />
                    </div>
                    <div className="flex flex-row items-center gap-2">
                        <Label className="flex w-40 lg:w-32">Name<p className="ml-1 text-destructive">*</p></Label>
                        <Input className={`h-7 bg-muted/50 ${errors.passenger?.[0]?.firstName && 'border-destructive focus-visible:ring-0'}`} name="firstName" {...register("passenger[0].firstName", { required: true })} />
                    </div>
                    {/* <div className="flex flex-row items-center gap-2">
                        <Label className="flex w-40 lg:w-32">First Name<p className="ml-1 text-destructive">*</p></Label>
                        <Input className={`h-7 bg-muted/50 ${errors.passenger?.[0]?.firstName && 'border-destructive focus-visible:ring-0'}`} name="firstName" {...register("passenger[0].firstName", { required: true })} />
                    </div>
                    <div className="flex flex-row items-center gap-2">
                        <Label className="flex w-40">Middle Name</Label>
                        <Input className="h-7 bg-muted/50" name="middleName" {...register("passenger[0].middleName")} />
                    </div>
                    <div className="flex flex-row items-center gap-2">
                        <Label className="flex w-40 lg:w-32">Last Name<p className="ml-1 text-destructive">*</p></Label>
                        <Input className={`h-7 bg-muted/50 ${errors.passenger?.[0]?.lastName && 'border-destructive focus-visible:ring-0'}`} name="lastName" {...register("passenger[0].lastName", { required: true })} />
                    </div> */}
                    <div className="flex flex-col justify-center">
                        <div className="flex flex-row items-center gap-2">
                            <Label className="flex w-40">Mobile</Label>
                            <Input className={`h-7 bg-muted/50 ${errors.passenger?.[0]?.mobile && 'border-destructive focus-visible:ring-0'}`} name="mobile" {...register("passenger[0].mobile", {
                                pattern: {
                                    value: /^[0-9]{10}$/,
                                    message: "Please enter a valid 10-digit mobile number"
                                }
                            })} onBlur={() => trigger("passenger[0].mobile")} />
                        </div>
                        {errors.passenger?.[0]?.mobile && (
                            <p className="text-xs text-destructive">{errors.passenger[0].mobile.message}</p>
                        )}
                    </div>
                    <div className="flex flex-col justify-center">
                        <div className="flex flex-row items-center gap-2">
                            <Label className="flex w-40 lg:w-32">Email</Label>
                            <Input className={`h-7 bg-muted/50 ${errors.passenger?.[0]?.email && 'border-destructive focus-visible:ring-0'}`} name="email" {...register("passenger[0].email", {
                                pattern: {
                                    value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
                                    message: "Please enter a valid email address"
                                }
                            })} onBlur={() => trigger("passenger[0].email")} />
                        </div>
                        {errors.passenger?.[0]?.email && (
                            <p className="text-xs text-destructive">{errors.passenger[0].email.message}</p>
                        )}
                    </div>
                    {/* <div className="flex flex-row items-center gap-2">
                        <Label className="flex w-40 lg:w-32">Pax Type</Label>
                        <Controller name="passenger[0].paxType" defaultValue="Adult" control={control} rules={{ required: true }} render={({ field }) => (
                            <Select onValueChange={field.onChange} value={field.value}>
                                <SelectTrigger className="w-full h-7 bg-muted/50 focus:ring-transparent">
                                    <SelectValue />
                                </SelectTrigger>
                                <SelectContent>
                                    <SelectGroup>
                                        {paxTypeItems.map(item => (
                                            <SelectItem key={item} value={item}>
                                                {item}
                                            </SelectItem>
                                        ))}
                                    </SelectGroup>
                                </SelectContent>
                            </Select>
                        )}
                        />
                    </div>
                    <div className="flex flex-row items-center gap-2">
                        <Label className="flex w-40">Gender</Label>
                        <Controller name="passenger[0].gender" defaultValue="Male" control={control} rules={{ required: true }} render={({ field }) => (
                            <Select onValueChange={field.onChange} value={field.value}>
                                <SelectTrigger className="w-full h-7 bg-muted/50 focus:ring-transparent">
                                    <SelectValue />
                                </SelectTrigger>
                                <SelectContent>
                                    <SelectGroup>
                                        {genderItems.map(item => (
                                            <SelectItem key={item} value={item}>
                                                {item}
                                            </SelectItem>
                                        ))}
                                    </SelectGroup>
                                </SelectContent>
                            </Select>
                        )}
                        />
                    </div> */}
                    <div className="flex flex-row items-center gap-2">
                        <Label className="w-40 lg:w-32">Address</Label>
                        <Input className="h-7 bg-muted/50" name="address" {...register("passenger[0].address")} />
                    </div>
                    <div className="flex flex-row items-center gap-2">
                        <Label className="flex w-40 lg:w-32">No. of Passengers</Label>
                        <Input className={`h-7 bg-muted/50 ${errors.passenger?.[0]?.numberOfPassenger && 'border-destructive focus-visible:ring-0'}`} type="number" name="numberOfPassenger" {...register("passenger[0].numberOfPassenger")} />
                    </div>
                    {/* <div>
                        <Card className="shadow-none">
                            <CardContent className="px-4 py-2 text-sm rounded-md bg-muted/50">
                                {remarks?.length > 0 ? (
                                    remarks.map((remark, index) => (
                                        <div key={index} className="flex justify-between mb-1">
                                            <span>{remark.name}</span>-
                                            <span>₹{remark.charge}</span>
                                            <div className='cursor-pointer' onClick={(e) => { deleteRemark(index, 0); e.preventDefault(); e.stopPropagation(); }}><Trash2 className='text-destructive size-4' /></div>
                                        </div>
                                    ))
                                ) : (
                                    <div>No additional Charge</div>
                                )}
                            </CardContent>
                        </Card>
                    </div>
                    <div className="flex flex-row items-center gap-2">
                        <Label className="flex w-40 lg:w-32">Remarks</Label>
                        <Input
                            className="h-7 bg-muted/50"
                            name="remarkName"
                            value={currentRemark.name}
                            onChange={(e) => updateCurrentRemark('name', e.target.value)}
                        />
                    </div>
                    <div className='flex items-center gap-2'>
                        <div className="flex flex-row items-center gap-2">
                            <Label className="flex w-40">Additional Charge</Label>
                            <Input
                                className="h-7 bg-muted/50"
                                name="remarkCharge"
                                value={currentRemark.charge}
                                onChange={(e) => updateCurrentRemark('charge', e.target.value)}
                            />
                        </div>
                        <div className="flex items-center p-2 rounded-md cursor-pointer h-7 bg-secondary" onClick={(e) => { addRemark(0); e.preventDefault(); }}><CirclePlus className='size-4' /></div>
                    </div> */}
                </div>
                <Separator />
                <div className="flex flex-row items-center justify-between gap-4 my-4 mr-2">
                    <CardDescription>Cab details :</CardDescription>
                </div>
                <div className="grid gap-3 my-4 lg:grid-cols-4 sm:grid-cols-1 md:grid-cols-2">
                    <div className="flex flex-row items-center gap-2">
                        <Label className="flex w-40">Car Tariff <p className="ml-1 text-destructive">*</p></Label>
                        <Input type="number" className={`h-7 bg-muted/50 ${errors.carriers?.[0]?.fare?.cabTarrif && 'border-destructive focus-visible:ring-0'}`} name="cabTarrif" {...register("carriers[0].fare.cabTarrif", { required: true })} />
                    </div>
                    <div className="flex flex-row items-center gap-2">
                        <Label className="w-40">Extra KM Rate</Label>
                        <Input className={`h-7 bg-muted/50 ${errors.carriers?.[0]?.fare?.extraKmRate && 'border-destructive focus-visible:ring-0'}`} name="extraKmRate" {...register("carriers[0].fare.extraKmRate")} />
                    </div>
                    <div className="flex flex-row items-center gap-2">
                        <Label className="w-40">Extra Time Rate</Label>
                        <Input className={`h-7 bg-muted/50 ${errors.carriers?.[0]?.fare?.extraTimeRate && 'border-destructive focus-visible:ring-0'}`} name="extraTimeRate" {...register("carriers[0].fare.extraTimeRate")} />
                    </div>
                    <div className="flex flex-row items-center gap-2">
                        <Label className="w-40">Duty Type <p className="ml-1 text-destructive">*</p></Label>
                        <Controller name="carriers[0].dutyType" control={control} rules={{ required: true }} render={({ field }) => (
                            <Select onValueChange={field.onChange} value={field.value}>
                                <SelectTrigger className={`w-full h-7 bg-muted/50 focus:ring-transparent ${errors.carriers?.[0]?.dutyType && 'border-destructive focus-visible:ring-0'}`}>
                                    <SelectValue />
                                </SelectTrigger>
                                <SelectContent>
                                    <SelectGroup>
                                        {dutyTypeItems.map(item => (
                                            <SelectItem key={item} value={item}>
                                                {item}
                                            </SelectItem>
                                        ))}
                                    </SelectGroup>
                                </SelectContent>
                            </Select>
                        )}
                        />
                    </div>
                    <div className="flex flex-row items-center gap-2">
                        <Label className="w-40">Cab Category <p className="ml-1 text-destructive">*</p></Label>
                        <Controller name="carriers[0].cabCategory" control={control} rules={{ required: true }} render={({ field }) => (
                            <Select onValueChange={field.onChange} value={field.value}>
                                <SelectTrigger className={`w-full h-7 bg-muted/50 focus:ring-transparent ${errors.carriers?.[0]?.cabCategory && 'border-destructive focus-visible:ring-0'}`}>
                                    <SelectValue />
                                </SelectTrigger>
                                <SelectContent>
                                    <SelectGroup>
                                        {categoryItems.map(item => (
                                            <SelectItem key={item} value={item}>
                                                {item}
                                            </SelectItem>
                                        ))}
                                    </SelectGroup>
                                </SelectContent>
                            </Select>
                        )}
                        />
                    </div>
                    <div className="flex flex-row items-center gap-2">
                        <Label className="w-40">Driver Name</Label>
                        <Input className="h-7 bg-muted/50" name="driverName" {...register("carriers[0].driverName")} />
                    </div>
                    <div className="flex flex-row items-center gap-2">
                        <Label className="w-40">From Location <p className="ml-1 text-destructive">*</p></Label>
                        <Input className={`h-7 bg-muted/50 ${errors.carriers?.[0]?.fromLocation && 'border-destructive focus-visible:ring-0'}`} name="fromLocation" {...register("carriers[0].fromLocation", { required: true })} />
                    </div>
                    <div className="flex flex-row items-center gap-2">
                        <Label className="w-40">To Location <p className="ml-1 text-destructive">*</p></Label>
                        <Input className={`h-7 bg-muted/50 ${errors.carriers?.[0]?.toLocation && 'border-destructive focus-visible:ring-0'}`} name="toLocation" {...register("carriers[0].toLocation", { required: true })} />
                    </div>
                    <div className="flex flex-row items-center gap-2">
                        <Label className="w-40">Start Date/Time</Label>
                        <Controller control={control} name="carriers[0].startDate" {...register("carriers[0].startDate")}
                            render={({ field }) => (
                                <DateTimePicker value={field.value} onChange={(date) => field.onChange(date)} />
                            )}
                        />
                    </div>
                    <div className="flex flex-row items-center gap-2">
                        <Label className="w-40">End Date/Time</Label>
                        <Controller control={control} name="carriers[0].endDate" {...register("carriers[0].endDate")}
                            render={({ field }) => (
                                <DateTimePicker value={field.value} onChange={(date) => field.onChange(date)} />
                            )}
                        />
                    </div>
                    <div className="flex flex-row items-center gap-2">
                        <Label className="w-40">Starting Km</Label>
                        <Input className="h-7 bg-muted/50" name="startKM" {...register("carriers[0].startKM")} />
                    </div>
                    <div className="flex flex-row items-center gap-2">
                        <Label className="w-40">Ending Km</Label>
                        <Input className="h-7 bg-muted/50" name="endKM" {...register("carriers[0].endKM")} />
                    </div>
                    <div className="flex flex-row items-center gap-2">
                        <Label className="w-40">Extra Km</Label>
                        <Input className="h-7 bg-muted/50" name="extraKM" {...register("carriers[0].fare.extraKM")} />
                    </div>
                    <div className="flex flex-row items-center gap-2">
                        <Label className="w-40">Extra Time</Label>
                        <Input className="h-7 bg-muted/50" name="extraTime" {...register("carriers[0].fare.extraTime")} />
                    </div>

                    <div className="flex flex-row items-center gap-2">
                        <Label className="flex w-40">Extra KM Charges</Label>
                        <Input type="number" className="h-7 bg-muted/50" name="extraKmCharges" {...register("carriers[0].fare.extraKmCharges")} />
                    </div>
                    <div className="flex flex-row items-center gap-2">
                        <Label className="flex w-40">Extra Time Charges</Label>
                        <Input type="number" className="h-7 bg-muted/50" name="extraTimeCharges" {...register("carriers[0].fare.extraTimeCharges")} />
                    </div>
                    <div className="flex flex-row items-center gap-2">
                        <Label className="flex w-40">Additional Charges</Label>
                        <Input type="number" className="h-7 bg-muted/50" name="additionalCharges" {...register("carriers[0].fare.additionalCharges")} />
                    </div>
                </div>
                <Separator />
                <div>
                    <div className="flex flex-row items-center justify-between my-4 mb-3">
                        <CardDescription>Charges Details :</CardDescription>
                    </div>
                    <Card>
                        <CardContent className="p-0">
                            <Table>
                                <TableHeader className="bg-secondary/60">
                                    <TableRow>
                                        <TableHead className="rounded-tl-md"></TableHead>
                                        <TableHead>Charge Name</TableHead>
                                        <TableHead>Customer</TableHead>
                                        <TableHead>Supplier</TableHead>
                                        <TableHead className="rounded-tr-md"><span className="sr-only">Actions</span></TableHead>
                                    </TableRow>
                                </TableHeader>
                                <TableBody>
                                    <TableRow>
                                        <TableCell></TableCell>
                                        <TableCell>Gross Amount</TableCell>
                                        <TableCell><Input className="w-40 h-6" value={watch("carriers[0].fare.netAmount")} readOnly /></TableCell>
                                        <TableCell><Input className="w-40 h-6" value={supplierAmount} readOnly /></TableCell>
                                    </TableRow>
                                    {charges.map((charge, index) => (
                                        <TableRow key={index}>
                                            <TableCell></TableCell>
                                            <TableCell>{charge.name}</TableCell>
                                            <TableCell><Input className="w-40 h-6" value={charge.customerCharge} readOnly={charge.name === "GST"} onChange={(e) => handleChargeChange("customer", index, e)} /></TableCell>
                                            <TableCell><Input className="w-40 h-6" value={charge.supplierCharge} readOnly={charge.name === "GST"} onChange={(e) => handleChargeChange("supplier", index, e)} /></TableCell>
                                        </TableRow>
                                    ))}
                                    <TableRow>
                                        <TableCell></TableCell>
                                        <TableCell>Total</TableCell>
                                        <TableCell><Input className="w-40 h-6" value={customerTotal + watch("carriers[0].fare.netAmount")} readOnly /></TableCell>
                                        <TableCell><Input className="w-40 h-6" value={supplierTotal + supplierAmount} readOnly /></TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </CardContent>
                    </Card>
                </div>
            </form>
            <CardFooter>
                {/* <Button variant="outline" className='ml-auto mr-2 bg-primary/30 hover:bg-primary/40' onClick={(e) => handleClose(e)}>Close</Button> */}
                <Button variant="outline" className={`ml-auto bg-primary/30 hover:bg-primary/40 ${isDirty && "border-destructive"}`} onClick={handleSave}>Save Ride</Button>
            </CardFooter>
        </>
    )
}
