"use client";

import React, { useEffect, useState } from "react";
import { add, format } from "date-fns";
import { Calendar as CalendarIcon } from "lucide-react";

import { cn } from "src/lib/utils";
import { Button } from "src/components/ui/button";
import { Calendar } from "src/components/ui/calendar";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "src/components/ui/popover";
import { TimePickerDemo } from "./time-picker-demo";

export function DateTimePicker({ value, onChange, current }) {
  const [date, setDate] = useState(value ? new Date(value) : current ? new Date() : null);

  useEffect(() => {
    setDate(value ? new Date(value) : current ? new Date() : null);
  }, [value, current]);
  /**
   * carry over the current time when a user clicks a new day
   * instead of resetting to 00:00
   */
  const handleSelect = (newDay) => {
    if (!newDay) return;
    // if (!date) {
    //   setDate(newDay);
    //   return;
    // }
    if (!date) {
      const newDateFull = add(newDay, { hours: 0, minutes: 0, seconds: 0 });
      handleDateChange(newDateFull);
      return;
    }    
    const diff = newDay.getTime() - date.getTime();
    const diffInDays = diff / (1000 * 60 * 60 * 24);
    const newDateFull = add(date, { days: Math.ceil(diffInDays) });
    handleDateChange(newDateFull);
  };
  const handleDateChange = (newDate) => {
    setDate(newDate);
    if (onChange) {
      onChange(newDate);
    }
  };

  return (
    <Popover modal={true}>
      <PopoverTrigger asChild>
        <Button
          variant={"outline"}
          className={cn(
            "w-full truncate h-7 bg-muted/50 justify-start text-left font-normal",
            !date && "text-muted-foreground"
          )}
        >
          <CalendarIcon className="w-4 h-4 mr-2" />
          {date ? format(date, "PPP HH:mm:ss") : <span>Pick a date</span>}
        </Button>
      </PopoverTrigger>
      <PopoverContent className="w-auto p-0">
          <Calendar
            mode="single"
            selected={date}
            onSelect={(d) => handleSelect(d)}
            initialFocus
          />
          <div className="p-3 border-t border-border">
          <TimePickerDemo setDate={handleDateChange} date={date} />
        </div>
      </PopoverContent>
    </Popover>
  );
}
