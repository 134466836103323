import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { DropdownMenu, DropdownMenuSeparator, DropdownMenuContent, DropdownMenuTrigger, DropdownMenuItem, DropdownMenuShortcut } from 'src/components/ui/dropdown-menu'
import { Button } from 'src/components/ui/button';
import { User, LogOut, CircleHelp, Menu } from 'lucide-react';
import { toast } from 'sonner';
import keycloak from 'src/keycloak';
import { getUserInfo } from 'src/helper/user-data';


export default function Navbar({ toggleSidebar }) {
    const navigate = useNavigate();
    const[userData, setUserData] = useState(null);
    const handleSupport = () => {
        toast.info("Mail sent", { description: "You can contact us through the link provided in mail!", position: "top-center" });
    };
    
    useEffect(() => {
        const userData = getUserInfo();
        setUserData(userData);
    }, []);
    return (
        <>
            <div className="flex justify-between lg:px-16 px-4 h-[8vh] text-xl border-b-[1px] border-primary/50 bg-secondary">
                <div className='flex items-center gap-5'>
                    <Link to={"/dashboard"} className="flex items-center text-[2.5vh] font-bold">
                        <div className='text-[2.5vh] font-bold'>Amigo Nexus</div>
                    </Link>
                    <div className="flex justify-end py-4 lg:sr-only md:sr-only">
                        <Menu size={26} className="cursor-pointer" onClick={toggleSidebar}/>
                    </div>
                </div>
                <div className="flex items-center gap-3 lg:gap-4">
                    <div className='text-[2.3vh]'>{userData?.fullName || "User Name"}</div>
                    <DropdownMenu>
                        <DropdownMenuTrigger asChild className='size-7 lg:size-10'>
                            <Button variant="outline" size="icon" className="relative">
                                <User className='size-4 lg:size-6' />
                            </Button>
                        </DropdownMenuTrigger>
                        <DropdownMenuContent align="end">
                            {/* <Link to={"/dashboard/profile"}> */}
                                <DropdownMenuItem className="cursor-pointer">
                                    Profile
                                    <DropdownMenuShortcut><User className="size-5" /></DropdownMenuShortcut>
                                </DropdownMenuItem>
                            {/* </Link> */}
                            <DropdownMenuSeparator />
                            <DropdownMenuItem className="cursor-pointer" onClick={handleSupport}>
                                Support
                                <DropdownMenuShortcut><CircleHelp className="size-5" /></DropdownMenuShortcut>
                            </DropdownMenuItem>
                            <DropdownMenuSeparator />
                                <DropdownMenuItem className="text-red-600 cursor-pointer" onClick={(e) => {e.preventDefault(); 
                                    // keycloak.logout()
                                    navigate("/login")}}>
                                    Log out
                                    <DropdownMenuShortcut><LogOut className="size-5" /></DropdownMenuShortcut>
                                </DropdownMenuItem>
                        </DropdownMenuContent>
                    </DropdownMenu>
                </div>
            </div>
        </>

    )
}
