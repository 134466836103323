import { Button } from 'src/components/ui/button'
import { CirclePlus, Edit, Trash2, Undo2 } from 'lucide-react'
import React, { useCallback, useEffect, useState } from 'react'
import { Link, useLocation } from 'react-router-dom'
import { Separator } from 'src/components/ui/separator'
import { Input } from 'src/components/ui/input'
import { Card, CardContent, CardDescription, CardFooter } from 'src/components/ui/card'
import { Label } from 'src/components/ui/label'
import { SearchCustomer } from '../../invoicePage/components/search-select'
import { useForm } from 'react-hook-form'
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from 'src/components/ui/table'
import { Select, SelectContent, SelectGroup, SelectItem, SelectTrigger, SelectValue } from 'src/components/ui/select'
import SelectDrop from '../components/search-select'
import { toast } from 'sonner'
import { MultiSelector, MultiSelectorContent, MultiSelectorInput, MultiSelectorItem, MultiSelectorList, MultiSelectorTrigger } from '../components/multi-select'
import { DatePicker } from '../../../components/datepicker'
import GenericAmountSeparator from 'src/pages/components/genric-amountseperator'


export default function ProductChargeConfigForm({ isVisible, typeName }) {
    const [configFormEdit, setConfigFormEdit] = useState({ visible: false, editingIndex: null });
    const { register, handleSubmit, watch, reset, setValue, formState: { errors }, } = useForm({});
    const [calculationMethodItems, setCalculationMethodItems] = useState([]);
    const [roundOffItems, setRoundOffItems] = useState([]);
    const [values, setValues] = useState([]);
    const [taxes, setTaxes] = useState([]);

    const getTaxes = useCallback(async () => {
        try {
            const response = await fetch(`${(process.env.REACT_APP_API_HOST_MSTR === undefined) ? "" : process.env.REACT_APP_API_HOST_MSTR}/amigo-lekha-api/taxTypeManagement/v1/taxType`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json'
                },
            });
            if (!response.ok) {
                throw new Error('Failed to fetch tax data');
            }
            const fetchedTaxData = await response.json();
            setTaxes(fetchedTaxData);
        } catch (error) {
            console.error("Error fetching tax data:", error);
        }
    }, [])

    React.useEffect(() => {
        getTaxes();
    }, [getTaxes]);

    const handleCustomerSelect = (customer) => {
        setValue("name", customer.fullName);
        setValue("customer.id", customer.customerCode);
    };

    const customerName = watch("customer.id") || '';

    const [configDetail, setConfigDetails] = useState([
        {
            ProductRef: {id : ""},
            ledger: {id : ""},
            taxes: [],
            amount: "",
            calculationMethod: "",
            roundoffType: "",
            effectStartDate: "",
            effectEndDate: "",
        }
    ]);

    const [editingConfigForm, setEditingConfigForm] = useState([]);

    const handleConfigFormSave = (e) => {
        e.preventDefault();
        const filteredChargeConfigForm = configDetail.filter(chargeConfig => Object.values(chargeConfig).some(field => field !== "" && field != null && field !== undefined)
        );
        if (filteredChargeConfigForm.length === 0) { return; }
        setConfigDetails(configDetail);
        setValue('configDetails', configDetail);
        setEditingConfigForm(configDetail);
        setConfigFormEdit({ visible: false, editingIndex: null });
        setValues([]);
    };

    const handleChargeConfigChange = (index, field, value) => {
        if (index === null) {
            index = editingConfigForm.length;
        }
        setConfigDetails((prevConfig) => {
            const updatedConfig = [...prevConfig];
            if (!updatedConfig[index]) {
                updatedConfig[index] = {};
            }
            if (field === "ProductRef" || field === "ledger") {
                updatedConfig[index] = {
                    ...updatedConfig[index],
                    [field]: { id: value.code }
                };
            } else {
                updatedConfig[index] = {
                    ...updatedConfig[index],
                    [field]: field === "taxes" ? value : value
                };
            }
            setValues(updatedConfig[index].taxes);
            return updatedConfig;
        });
    };


    const handleDelete = (index) => {
        const updatedConfig = editingConfigForm.filter((_, i) => i !== index);
        setValue('configDetails', updatedConfig)
        setEditingConfigForm(updatedConfig);
        setConfigDetails(updatedConfig);
        setConfigFormEdit({ visible: false, editingIndex: null });
    };

    const getLists = async () => {
        try {
            const response = await fetch(`${(process.env.REACT_APP_API_HOST_MSTR === undefined) ? "" : process.env.REACT_APP_API_HOST_MSTR}/amigo-lekha-api/listManagement/v1/list`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json'
                },
            });
            if (!response.ok) {
                throw new Error('Failed to fetch data');
            }
            const fetchedData = await response.json();
            fetchedData.forEach(item => {
                switch (item.name) {
                    case 'CalculationMethod':
                        setCalculationMethodItems(item.items);
                        break;
                    case 'RoundOffType':
                        setRoundOffItems(item.items);
                        break;
                    default:
                        break;
                }
            });
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    }

    //Create customerProductChargeConfig
    const handleCreateChargeConfig = async (data) => {
        try {
            const response = await fetch(`${(process.env.REACT_APP_API_HOST_MSTR === undefined) ? "" : process.env.REACT_APP_API_HOST_MSTR}/amigo-lekha-api/customerProductChargeConfigManagement/v1/customerProductChargeConfig`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(data),
            });
            if (!response.ok) {
                throw new Error('Failed to create customerProductChargeConfig');
            }
            const res = await response.json();
            const code = res.code
            toast.success("customerProductChargeConfig created successfuly!", { duration: "5000", position: "top-right", description: `customerProductChargeConfig Id- ${code}` });
        } catch (error) {
            toast.error("Failed to create customerProductChargeConfig");
        }
    };

    //edit customerProductChargeConfig
    const handleEditChargeConfig = async (data) => {
        try {
            const response = await fetch(`${(process.env.REACT_APP_API_HOST_MSTR === undefined) ? "" : process.env.REACT_APP_API_HOST_MSTR}/amigo-lekha-api/customerProductChargeConfigManagement/v1/customerProductChargeConfig/${data.customer.id}`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(data),
            });
            if (!response.ok) {
                throw new Error('Failed to create customerProductChargeConfig');
            }
            const res = await response.json();
            const code = res.code;
            toast.success("customerProductChargeConfig edit successfuly!", { duration: "5000", position: "top-right", description: `customerProductChargeConfig Id- ${code}` });
        } catch (error) {
            toast.error("Failed to edit customerProductChargeConfig");
        }
    };

    const location = useLocation();
    const data = location.state?.item;
    const [editchargeConfig, setEditChargeConfig] = useState(false);

    useEffect(() => {
        getLists()
        if (data) {
            setEditChargeConfig(true);
            reset(data);
            if (data.configDetails && data.configDetails.length > 0) {
                const configData = data.configDetails;
                setConfigDetails(configData);
                setEditingConfigForm(configData);
            } else {
                setConfigDetails([{
                    ProductRef: "",
                    ledger: "",
                    taxes: [],
                    amount: "",
                    calculationMethod: "",
                    roundoffType: "",
                    effectStartDate: "",
                    effectEndDate: "",
                }])
            }
        }
    }, [data, reset, setValue])

    const onSubmit = (data) => {
        const code = data.customer.id;
        data.code = code;
        if (editchargeConfig) {
            handleEditChargeConfig(data);
        } else {
            handleCreateChargeConfig(data);
        }
    }

    return (
        <div className='m-5'>
            <Link to="/dashboard/masters/productchargeconfig" className='absolute mt-1 ml-5'>
                <Button variant="outline" size="icon"><Undo2 className='size-4' /></Button>
            </Link>
            <div className="flex items-center justify-center w-full mb-5 text-[3vh] font-bold">New Product ChargeConfig</div>
            <form onSubmit={handleSubmit(onSubmit)} className='mx-5 mt-2'>
                <Separator />
                <CardDescription className='my-2 font-bold text-gray-700'>General Details:</CardDescription>
                <div className="grid grid-cols-1 gap-2 mt-5 mb-4 md:grid-cols-2 lg:grid-cols-4">
                    <div className="flex flex-row items-center gap-2">
                        <Label className="w-40 text-gray-500">Customer Name</Label>
                        <SearchCustomer onCustomerSelect={handleCustomerSelect} isVisible={true} value={customerName} />
                    </div>
                </div>
                <Separator />
                <div className='flex flex-row gap-4 mt-4'>
                    <div className='w-full'>
                        <div className="flex flex-row items-center justify-between gap-2 my-2">
                            <CardDescription className='font-bold text-gray-700'>Config Details:</CardDescription>
                            <Button size='sm' variant="secondary" onClick={(e) => { setConfigFormEdit(prevState => ({ ...prevState, visible: !prevState.visible, editingIndex: null })); e.preventDefault(); }}>Add Charge Config<CirclePlus className="ml-2 size-4" />
                            </Button>
                        </div>
                        <Card className="my-3">
                            <CardContent className="p-0">
                                <Table>
                                    <TableHeader className="bg-secondary/60">
                                        <TableRow>
                                            <TableHead className="rounded-tl-md"></TableHead>
                                            <TableHead>Product</TableHead>
                                            <TableHead>Ledger</TableHead>
                                            <TableHead>Amount</TableHead>
                                            <TableHead>Calculation Method</TableHead>
                                            <TableHead>Roundoff Type</TableHead>
                                            <TableHead>Taxes</TableHead>
                                            <TableHead className="rounded-tr-md"><span className="sr-only">Actions</span></TableHead>
                                        </TableRow>
                                    </TableHeader>
                                    <TableBody>
                                        {editingConfigForm.length > 0 ? (
                                            editingConfigForm.map((item, index) => (
                                                <React.Fragment key={index}>
                                                    <TableRow>
                                                        <TableCell></TableCell>
                                                        <TableCell>{item.ProductRef?.code || item.ProductRef?.id}</TableCell>
                                                        <TableCell>{item.ledger?.code || item.ledger?.id}</TableCell>
                                                        {/* <TableCell>{item.amount}</TableCell> */}
                                                        <TableCell>{item.amount ? <GenericAmountSeparator value={item.amount} viewMode={true} /> : '-'}</TableCell>
                                                        <TableCell>{item.calculationMethod}</TableCell>
                                                        <TableCell>{item.roundoffType}</TableCell>
                                                        {/* <TableCell>{Array.isArray(item.taxes) ? item.taxes.map((tax) => tax.id).join(', ') : ''}</TableCell> */}
                                                        <TableCell>{Array.isArray(item.taxes) ? item.taxes.join(', ') : ''}</TableCell>
                                                        <TableCell>
                                                            <div className="flex flex-row items-center gap-4">
                                                                <Edit onClick={() => setConfigFormEdit(prevState => ({ ...prevState, visible: !prevState.visible, editingIndex: index }))} className="cursor-pointer text-primary size-4" />
                                                                <Trash2 onClick={() => handleDelete(index)} className="cursor-pointer text-destructive size-4" />
                                                            </div>
                                                        </TableCell>
                                                    </TableRow>
                                                    {configFormEdit.visible && configFormEdit.editingIndex === index && (
                                                        <TableRow>
                                                            <TableCell colSpan={11}>
                                                                <div className="grid grid-cols-1 gap-4 px-4 my-4 md:grid-cols-2 lg:grid-cols-4">
                                                                    <div className="flex flex-row items-center gap-3">
                                                                        <Label className="w-40">Product</Label>
                                                                        <SelectDrop onTypeSelect={(type) => handleChargeConfigChange(configFormEdit.editingIndex, "ProductRef", type)} typeName="product" isVisible={true} value={configFormEdit.editingIndex !== null && configDetail[configFormEdit.editingIndex] ? configDetail[configFormEdit.editingIndex]?.ProductRef?.id : ''} />
                                                                    </div>
                                                                    <div className="flex flex-row items-center gap-3">
                                                                        <Label className="w-40">Ledger</Label>
                                                                        <SelectDrop onTypeSelect={(type) => handleChargeConfigChange(configFormEdit.editingIndex, "ledger", type)} typeName="ledger" isVisible={true} value={configFormEdit.editingIndex !== null && configDetail[configFormEdit.editingIndex] ? configDetail[configFormEdit.editingIndex]?.ledger?.id : ''} />
                                                                    </div>
                                                                    <div className="flex flex-row items-center gap-2">
                                                                        <Label className="w-40">Taxes</Label>
                                                                        <MultiSelector value={configFormEdit.editingIndex !== null && configDetail[configFormEdit.editingIndex] ? configDetail[configFormEdit.editingIndex].taxes : []} onValuesChange={(newValues) => { handleChargeConfigChange(configFormEdit.editingIndex, "taxes", newValues); }} className="p-0">
                                                                            <MultiSelectorTrigger>
                                                                                <MultiSelectorInput className='h-7' />
                                                                            </MultiSelectorTrigger>
                                                                            <MultiSelectorContent>
                                                                                <MultiSelectorList>
                                                                                    {taxes.map(tax => (
                                                                                        <MultiSelectorItem key={tax.code} value={tax.code}>
                                                                                            {tax.name}
                                                                                        </MultiSelectorItem>
                                                                                    ))}
                                                                                </MultiSelectorList>
                                                                            </MultiSelectorContent>
                                                                        </MultiSelector>
                                                                    </div>
                                                                    <div className="flex flex-row items-center gap-2">
                                                                        <Label className="w-40 lg:w-52">Amount</Label>
                                                                        {/* <Input className="h-7 bg-muted/50 " name="amount" onChange={(e) => handleChargeConfigChange(configFormEdit.editingIndex, 'amount', e.target.value)} defaultValue={configFormEdit.editingIndex !== null ? configDetail[configFormEdit.editingIndex]?.amount || '' : ''} /> */}
                                                                        <GenericAmountSeparator value={configFormEdit.editingIndex !== null ? configDetail[configFormEdit.editingIndex]?.amount || '' : ''}
                                                                            onChange={(value) => handleChargeConfigChange(configFormEdit.editingIndex, 'amount', value)} />
                                                                    </div>
                                                                    <div className="flex flex-row items-center gap-2">
                                                                        <Label className="w-40 lg:w-52">Effect StartDate</Label>
                                                                        <DatePicker className="ml-6 lg:w-[28vh] md:w-[25vh] w-36" value={configFormEdit.editingIndex !== null ? configDetail[configFormEdit.editingIndex]?.effectStartDate || '' : ''} onChange={(date) => handleChargeConfigChange(configFormEdit.editingIndex, 'effectStartDate', date)} />
                                                                    </div>
                                                                    <div className="flex flex-row items-center gap-2">
                                                                        <Label className="w-40 lg:w-52">Effect EndDate</Label>
                                                                        <DatePicker className="ml-6 lg:w-[28vh] md:w-[25vh] w-36" value={configFormEdit.editingIndex !== null ? configDetail[configFormEdit.editingIndex]?.effectEndDate || '' : ''} onChange={(date) => handleChargeConfigChange(configFormEdit.editingIndex, 'effectEndDate', date)} />
                                                                    </div>
                                                                    <div className="flex flex-row items-center gap-2">
                                                                        <Label className="w-40 lg:w-52">Calculation Method</Label>
                                                                        <Select name="calculationMethod" defaultValue={configFormEdit.editingIndex !== null ? configDetail[configFormEdit.editingIndex]?.calculationMethod || '' : ''}
                                                                            onValueChange={(e) => handleChargeConfigChange(configFormEdit.editingIndex, 'calculationMethod', e)} >
                                                                            <SelectTrigger className="h-7 bg-muted/50 focus:ring-transparent">
                                                                                <SelectValue />
                                                                            </SelectTrigger>
                                                                            <SelectContent>
                                                                                <SelectGroup>
                                                                                    {calculationMethodItems.map(item => (
                                                                                        <SelectItem key={item} value={item}>
                                                                                            {item}
                                                                                        </SelectItem>
                                                                                    ))}
                                                                                </SelectGroup>
                                                                            </SelectContent>
                                                                        </Select>
                                                                    </div>
                                                                    <div className="flex flex-row items-center gap-2">
                                                                        <Label className="w-40 lg:w-52">RoundOff Type</Label>
                                                                        <Select name="roundoffType" defaultValue={configFormEdit.editingIndex !== null ? configDetail[configFormEdit.editingIndex]?.roundoffType || '' : ''}
                                                                            onValueChange={(e) => handleChargeConfigChange(configFormEdit.editingIndex, 'roundoffType', e)}>
                                                                            <SelectTrigger className="w-full h-7 bg-muted/50 focus:ring-transparent" >
                                                                                <SelectValue />
                                                                            </SelectTrigger>
                                                                            <SelectContent>
                                                                                <SelectGroup>
                                                                                    {roundOffItems.map(item => (
                                                                                        <SelectItem key={item} value={item}>
                                                                                            {item}
                                                                                        </SelectItem>
                                                                                    ))}
                                                                                </SelectGroup>
                                                                            </SelectContent>
                                                                        </Select>
                                                                    </div>
                                                                </div>
                                                                <div className="flex flex-row col-span-4 px-4 my-4">
                                                                    <Button variant="outline" className="ml-auto mr-3 bg-primary/30 hover:bg-primary/40" onClick={handleConfigFormSave}>save</Button>
                                                                    <Button variant="outline" className="bg-primary/30 hover:bg-primary/40" onClick={(e) => { setConfigFormEdit(prevState => ({ ...prevState, visible: !prevState.visible, editingIndex: null })); e.preventDefault(); }}>Close</Button>
                                                                </div>
                                                            </TableCell>
                                                        </TableRow>
                                                    )}
                                                </React.Fragment>
                                            ))
                                        ) : (
                                            <TableRow>
                                                <TableCell colSpan={10} className="text-center">No Details</TableCell>
                                            </TableRow>
                                        )}
                                    </TableBody>
                                </Table>
                                {configFormEdit.visible && configFormEdit.editingIndex === null && (
                                    <div>
                                        <Separator />
                                        <div className="grid grid-cols-1 gap-4 px-4 my-4 md:grid-cols-2 lg:grid-cols-4">
                                            <div className="flex flex-row items-center gap-3">
                                                <Label className="w-40">Product</Label>
                                                <SelectDrop onTypeSelect={(type) => handleChargeConfigChange(configFormEdit.editingIndex, "ProductRef", type)} typeName="product" isVisible={true} value={configFormEdit.editingIndex !== null && configDetail[configFormEdit.editingIndex] ? configDetail[configFormEdit.editingIndex]?.ProductRef?.id : ''} />
                                            </div>
                                            <div className="flex flex-row items-center gap-3">
                                                <Label className="w-40">Ledger</Label>
                                                <SelectDrop onTypeSelect={(type) => handleChargeConfigChange(configFormEdit.editingIndex, "ledger", type)} typeName="ledger" isVisible={true} value={configFormEdit.editingIndex !== null && configDetail[configFormEdit.editingIndex] ? configDetail[configFormEdit.editingIndex]?.ledger?.id : ''} />
                                            </div>
                                            <div className="flex flex-row items-center gap-2">
                                                <Label className="w-40">Taxes</Label>
                                                <MultiSelector value={values} onValuesChange={(newValues) => { handleChargeConfigChange(configFormEdit.editingIndex, "taxes", newValues); }} className="p-0">
                                                    <MultiSelectorTrigger>
                                                        <MultiSelectorInput className='h-7' />
                                                    </MultiSelectorTrigger>
                                                    <MultiSelectorContent>
                                                        <MultiSelectorList>
                                                            {taxes.map(tax => (
                                                                <MultiSelectorItem key={tax.code} value={tax.code}>
                                                                    {tax.name}
                                                                </MultiSelectorItem>
                                                            ))}
                                                        </MultiSelectorList>
                                                    </MultiSelectorContent>
                                                </MultiSelector>
                                            </div>
                                            <div className="flex flex-row items-center gap-2">
                                                <Label className="w-40 lg:w-52">Amount</Label>
                                                {/* <Input className="h-7 bg-muted/50 " name="amount" onChange={(e) => handleChargeConfigChange(configFormEdit.editingIndex, 'amount', e.target.value)} defaultValue={configFormEdit.editingIndex !== null ? configDetail[configFormEdit.editingIndex]?.amount || '' : ''} /> */}
                                                <GenericAmountSeparator value={configFormEdit.editingIndex !== null ? configDetail[configFormEdit.editingIndex]?.amount || '' : ''}
                                                    onChange={(value) => handleChargeConfigChange(configFormEdit.editingIndex, 'amount', value)} />
                                            </div>
                                            <div className="flex flex-row items-center gap-2">
                                                <Label className="w-40 lg:w-52">Effect StartDate</Label>
                                                <DatePicker className="ml-6 lg:w-[28vh] md:w-[25vh] w-36" value={configFormEdit.editingIndex !== null ? configDetail[configFormEdit.editingIndex]?.effectStartDate || '' : ''} onChange={(date) => handleChargeConfigChange(configFormEdit.editingIndex, 'effectStartDate', date)} />
                                            </div>
                                            <div className="flex flex-row items-center gap-2">
                                                <Label className="w-40 lg:w-52">Effect EndDate</Label>
                                                <DatePicker className="ml-6 lg:w-[28vh] md:w-[25vh] w-36" value={configFormEdit.editingIndex !== null ? configDetail[configFormEdit.editingIndex]?.effectEndDate || '' : ''} onChange={(date) => handleChargeConfigChange(configFormEdit.editingIndex, 'effectEndDate', date)} />
                                            </div>
                                            <div className="flex flex-row items-center gap-2">
                                                <Label className="w-40 lg:w-52">Calculation Method</Label>
                                                <Select name="calculationMethod" defaultValue={configFormEdit.editingIndex !== null ? configDetail[configFormEdit.editingIndex]?.calculationMethod || '' : ''}
                                                    onValueChange={(e) => handleChargeConfigChange(configFormEdit.editingIndex, 'calculationMethod', e)} >
                                                    <SelectTrigger className="h-7 bg-muted/50 focus:ring-transparent">
                                                        <SelectValue />
                                                    </SelectTrigger>
                                                    <SelectContent>
                                                        <SelectGroup>
                                                            {calculationMethodItems.map(item => (
                                                                <SelectItem key={item} value={item}>
                                                                    {item}
                                                                </SelectItem>
                                                            ))}
                                                        </SelectGroup>
                                                    </SelectContent>
                                                </Select>
                                            </div>
                                            <div className="flex flex-row items-center gap-2">
                                                <Label className="w-40 lg:w-52">RoundOff Type</Label>
                                                <Select name="roundoffType" defaultValue={configFormEdit.editingIndex !== null ? configDetail[configFormEdit.editingIndex]?.roundoffType || '' : ''}
                                                    onValueChange={(e) => handleChargeConfigChange(configFormEdit.editingIndex, 'roundoffType', e)}>
                                                    <SelectTrigger className="w-full h-7 bg-muted/50 focus:ring-transparent" >
                                                        <SelectValue />
                                                    </SelectTrigger>
                                                    <SelectContent>
                                                        <SelectGroup>
                                                            {roundOffItems.map(item => (
                                                                <SelectItem key={item} value={item}>
                                                                    {item}
                                                                </SelectItem>
                                                            ))}
                                                        </SelectGroup>
                                                    </SelectContent>
                                                </Select>
                                            </div>
                                        </div>
                                        <div className="flex flex-row col-span-4 px-4 my-4">
                                            <Button variant="outline" className="ml-auto mr-3 bg-primary/30 hover:bg-primary/40" onClick={handleConfigFormSave}>save</Button>
                                            <Button variant="outline" className="bg-primary/30 hover:bg-primary/40" onClick={(e) => { setConfigFormEdit(prevState => ({ ...prevState, visible: !prevState.visible, editingIndex: null })); e.preventDefault(); }}>Close</Button>
                                        </div>
                                    </div>
                                )}
                            </CardContent>
                        </Card>
                    </div>
                </div>
                <CardFooter className="py-0 mt-2 mr-2">
                    <div className="ml-auto">
                        <Button type='submit'>{editchargeConfig ? 'Save' : 'Create'}</Button>
                    </div>
                </CardFooter>
            </form>
        </div>
    )
}
