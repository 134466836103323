import { Button } from 'src/components/ui/button'
import { CirclePlus, Edit, Info, Trash2, Undo2 } from 'lucide-react'
import React, { useEffect, useState } from 'react'
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom'
import { Separator } from 'src/components/ui/separator';
import { ScrollArea } from 'src/components/ui/scroll-area';
import { Controller, useForm } from 'react-hook-form';
import { toast } from 'sonner';
import { Label } from 'src/components/ui/label';
import { DatePicker } from 'src/pages/components/datepicker';
import { SearchCustomer } from '../components/search-select';
import { Input } from 'src/components/ui/input';
import { Textarea } from 'src/components/ui/textarea';
import SupplierSelect from 'src/pages/components/supplier-select';
import { Card, CardContent, CardDescription, CardFooter } from 'src/components/ui/card';
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from "src/components/ui/table";
import { Checkbox } from 'src/components/ui/checkbox';
import CabProductDialog from './product-dialog';

export default function InvCabForm() {
  const { id } = useParams();
  const location = useLocation();
  const data = location.state?.item;
  const navigate = useNavigate();
  const [invoiceId, setInvoiceId] = useState('');
  const [customerForm, setCustomerForm] = useState(false);
  const [supplierAmount, setSupplierAmount] = useState(0);
  const [loading, setLoading] = useState("");
  const [rideFormEdit, setRideFormEdit] = useState({ visible: true, editingIndex: null });
  const [isVisible, setIsVisible] = useState(false);
  const [isDirty, setIsDirty] = useState(false);
  const [isError, setIsError] = useState({
    customer: false,
    supplier: false,
    ride: false
  });
  const { register, handleSubmit, control, reset, watch, setValue, getValues, trigger, formState: { errors }, } = useForm({
    defaultValues: {
      category: "cab",
      createdBy: "amigo2",
      cabProductDetails: [
        {
          id: "",
          code: "",
          name: "cab",
          netAmount: 0,
          state: "",
          product: { rideDetails: [] }
        },
      ],
      customerRefOrValue: {
        id: "",
        name: "",
        address: "",
        email: "",
        phone: "",
        panNo: "",
        gstNo: "",
      },
    }
  });
  const [titleItems, setTitleItems] = useState([]);
  const [paxTypeItems, setPaxTypeItems] = useState([]);
  const [genderItems, setGenderItems] = useState([]);
  const [categoryItems, setCategoryItems] = useState([]);
  const [dutyTypeItems, setDutyTypeItems] = useState([]);
  const dialogData = {
    titleItems,
    paxTypeItems,
    genderItems,
    categoryItems,
    dutyTypeItems
  };
  const [rideForm, setRideForm] = useState([]);
  const [custChargeForm, setCustChargeForm] = useState([
    {
      chargeCodeName: "Service Charge",
      calculationMethod: "",
      value: 0,
      totalValue: 0,
      tax: [],
      taxTotal: null,
    },
    {
      chargeCodeName: "Miscellaneous Charges",
      calculationMethod: "",
      value: 0,
      totalValue: 0,
      tax: [],
      taxTotal: null,
    },
    {
      chargeCodeName: "Documentation Charge",
      calculationMethod: "",
      value: 0,
      totalValue: 0,
      tax: [],
      taxTotal: null,
    },
    {
      chargeCodeName: "Management Fees",
      calculationMethod: "",
      value: 0,
      totalValue: 0,
      tax: [],
      taxTotal: null,
    }
  ]);
  const [suppChargeForm, setSuppChargeForm] = useState([
    {
      chargeCodeName: "Commission Charge",
      calculationMethod: "",
      value: 0,
      totalValue: 0,
      tax: [],
      taxTotal: null,
    },
    {
      chargeCodeName: "Miscellaneous Charges",
      calculationMethod: "",
      value: 0,
      totalValue: 0,
      tax: [],
      taxTotal: null,
    },
    {
      chargeCodeName: "Documentation Charge",
      calculationMethod: "",
      value: 0,
      totalValue: 0,
      tax: [],
      taxTotal: null,
    },
    {
      chargeCodeName: "Management Fees",
      calculationMethod: "",
      value: 0,
      totalValue: 0,
      tax: [],
      taxTotal: null,
    }
  ])
  const CUST_CHARGE_TYPES = [
    "Service Charge",
    "Miscellaneous Charges",
    "Documentation Charge",
    "Management Fees"
  ];
  const agentComm = watch("agent");

  useEffect(() => {
    getLists();
    setValue('invoiceDate', new Date().toISOString());
    if (data && id) {
      setInvoiceId(data.invoiceId);
      reset(data);
      if (data.cabProductDetails && data.cabProductDetails.length > 0 && data.cabProductDetails[0].product && data.cabProductDetails[0].product.rideDetails) {
        const rideDetailsData = data.cabProductDetails[0].product.rideDetails;
        setRideForm(rideDetailsData);
        if (rideDetailsData.length === 0) { setRideFormEdit({ visible: true, editingIndex: null }); } else {
          setRideFormEdit({ visible: true, editingIndex: 0 });
        }
      }
      if (data.cabProductDetails && data.cabProductDetails.length > 0 && data.cabProductDetails[0].product && data.cabProductDetails[0].product.charges) {
        const chargeData = data.cabProductDetails[0].product.charges;
        if (chargeData.length === 0) {
          setCustChargeForm(custChargeForm);
        } else {
          setCustChargeForm(chargeData);
        }
      } else {
        setCustChargeForm([
          {
            chargeCodeName: "",
            calculationMethod: "",
            value: 0,
            totalValue: 0,
            tax: [],
            taxTotal: 0,
          }
        ]);
      }
      if (data.cabProductDetails && data.cabProductDetails.length > 0 && data.cabProductDetails[0].product && data.cabProductDetails[0].product.supplierCharges) {
        const suppChargeData = data.cabProductDetails[0].product.supplierCharges;
        setSuppChargeForm(suppChargeData);
      }
      setIsVisible(true);
    }
  }, [data, id, reset, setValue]);

  const getLists = async () => {
    try {
      const response = await fetch(`${(process.env.REACT_APP_API_HOST_MSTR === undefined) ? "" : process.env.REACT_APP_API_HOST_MSTR}/amigo-lekha-api/listManagement/v1/list`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json'
        },
      });
      if (!response.ok) {
        throw new Error('Failed to fetch data');
      }
      const fetchedData = await response.json();
      fetchedData.forEach(item => {
        switch (item.name) {
          case 'Title':
            setTitleItems(item.items);
            break;
          case 'PaxType':
            setPaxTypeItems(item.items);
            break;
          case 'DutyType':
            setDutyTypeItems(item.items);
            break;
          case 'CabCategory':
            setCategoryItems(item.items);
            break;
          case 'Gender':
            setGenderItems(item.items);
            break;
          default:
            break;
        }
      });
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  }
  const calculateTaxForCharge = (totalValue, chargeTaxes, taxValues) => {
    let taxTotal = 0;
    const updatedTaxes = chargeTaxes.map(chargeTax => {
      const matchingTaxValue = taxValues.find(taxValue => taxValue.id === chargeTax.taxCode);
      if (matchingTaxValue) {
        const taxAmount = totalValue * (matchingTaxValue.value / 100);
        taxTotal += taxAmount;
        return { ...chargeTax, value: taxAmount };
      }
      return chargeTax;
    });
    return { tax: updatedTaxes, taxTotal };
  };
  const [taxValues, setTaxValues] = useState([]);

  const handleCustomerSelect = async (customer, customerCharges) => {
    const chrgForm = [{
      chargeCodeName: "Service Charge",
      calculationMethod: "",
      value: 0,
      totalValue: 0,
      tax: [],
      taxTotal: null,
    },
    {
      chargeCodeName: "Miscellaneous Charges",
      calculationMethod: "",
      value: 0,
      totalValue: 0,
      tax: [],
      taxTotal: null,
    },
    {
      chargeCodeName: "Documentation Charge",
      calculationMethod: "",
      value: 0,
      totalValue: 0,
      tax: [],
      taxTotal: null,
    },
    {
      chargeCodeName: "Management Fees",
      calculationMethod: "",
      value: 0,
      totalValue: 0,
      tax: [],
      taxTotal: null,
    }]
    if (!customerCharges || !customerCharges.configDetails || customerCharges.configDetails.length === 0) {
      toast.error(`No charges for ${customer.customerCode}`);
      setValue("cabProductDetails[0].product.charges", chrgForm);
    }
    const cabCharges = customerCharges?.configDetails?.filter(charge => charge.ProductRef.id === "Cab");
    if (cabCharges?.length === 0) {
      toast.error(`No cab charges for ${customer.customerCode}`);
      setValue("cabProductDetails[0].product.charges", chrgForm);
    }
    setIsError(prevState => ({ ...prevState, customer: false }));
    setValue('customerRefOrValue.id', customer.customerCode || '');
    setValue('customerRefOrValue.name', customer.fullName || '');
    setValue('customerRefOrValue.phone', customer.contactNumber || '');
    setValue('customerRefOrValue.email', customer.emailId || '');
    setValue('customerRefOrValue.address', customer.addressId[0]?.addressLine || '');
    setValue('customerRefOrValue.panNo', customer.panNumber || '');
    setValue('customerRefOrValue.gstNo', customer.gstNumber || '');
    setCustChargeForm([]);
    const updatedChargeForm = custChargeForm.map(charge => ({
      ...charge,
      value: 0,
      calculationMethod: '',
      totalValue: 0,
      tax: [{
        taxCode: "",
        value: 0,
      }],
      taxTotal: 0,
    }));
    let fetchedTaxValues;
    const hasCabCharge = customerCharges.configDetails?.some(charge => charge.ProductRef.id === "Cab");
    if (hasCabCharge) {
      fetchedTaxValues = await getTaxValues(chrgForm, customerCharges?.configDetails);
    }
    const currentDate = new Date();
    customerCharges?.configDetails
      ?.filter((charge) => charge.ProductRef.id === "Cab")
      .forEach((charge) => {
        const matchingCharge = updatedChargeForm.find(
          (c) => c.chargeCodeName === charge.ledger.id
        );
        if (matchingCharge) {
          matchingCharge.value = charge.amount || 0;
          matchingCharge.calculationMethod = charge.calculationMethod;
          const isWithinDateRange = (
            (charge.effectStartDate === null && charge.effectEndDate === null) ||
            (charge.effectStartDate === null && new Date(charge.effectEndDate) >= currentDate) ||
            (charge.effectEndDate === null && new Date(charge.effectStartDate) <= currentDate) ||
            (new Date(charge.effectStartDate) <= currentDate && new Date(charge.effectEndDate) >= currentDate)
          );
          if (isWithinDateRange) {
            matchingCharge.tax = charge.taxes?.map(taxCode => ({
              taxCode,
              value: 0
            })) || [];
          } else {
            matchingCharge.tax = [];
          }
        }
      });
    setCustChargeForm(updatedChargeForm);
    if (fetchedTaxValues) {
      calcCharge(updatedChargeForm, pLength, fetchedTaxValues);
    }
  };
  const customerRefOrValue = watch("customerRefOrValue");
  let pLength = 0;

  const getTaxValues = async (chargeForm, configDetails) => {
    let taxIds;
    if (configDetails !== null) {
      taxIds = configDetails
        ?.filter((charge) => charge.ProductRef.id === "Cab")
        ?.flatMap((charge) => charge.taxes?.map((tax) => tax) || [])
        .filter((id, index, self) => self.indexOf(id) === index);
    } else {
      taxIds = chargeForm
        .flatMap((charge) => charge.tax?.map((tax) => tax.taxCode) || [])
        .filter((id, index, self) => self.indexOf(id) === index);
    }
    const taxValues = await Promise.all(
      taxIds.map(async (taxId) => {
        try {
          const response = await fetch(`${(process.env.REACT_APP_API_HOST_MSTR === undefined) ? "" : process.env.REACT_APP_API_HOST_MSTR}/amigo-lekha-api/taxTypeManagement/v1/taxType/${taxId}`);
          if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
          }
          const data = await response.json();
          return { id: taxId, value: data.valueDetails[0].value };
        } catch (error) {
          console.error(`Error fetching tax data for ID ${taxId}:`, error);
          return { id: taxId, value: 0 };
        }
      })
    );
    if (taxValues.length > 0) {
      calcCharge(chargeForm, pLength, taxValues);
    }
    setTaxValues(taxValues);
    return taxValues;
  };

  const calcCharge = (updatedChargeForm, length, taxValues) => {
    if (taxValues.length === 0 && customerRefOrValue.name) {
      getTaxValues(custChargeForm, null);
    }
    if (!length) { length = 0 };
    const charges = updatedChargeForm.map((charge) => {
      let totalValue;
      switch (charge.calculationMethod) {
        case "PerTicket":
          totalValue = charge.value;
          break;
        case "PerPassenger":
          const multi = length === 0 ? 1 : length;
          totalValue = multi * charge.value;
          break;
        default:
          totalValue = charge.value;
      }
      return {
        ...charge,
        totalValue,
      };
    });
    const chargesWithTax = charges.map((charge) => {
      const { tax, taxTotal } = calculateTaxForCharge(charge.totalValue, charge.tax, taxValues);
      const roundedTax = tax.map(t => ({
        ...t,
        value: Math.round(t.value * 100) / 100
      }));
      const roundedTaxTotal = Math.round(taxTotal * 100) / 100;
      return { ...charge, tax: roundedTax, taxTotal: roundedTaxTotal };
    });
    setCustChargeForm(chargesWithTax);
    setValue("cabProductDetails[0].product.charges", chargesWithTax);
  };

  const handleChargeChange = (index, field, value) => {
    if (index === null) {
      index = 0;
    }
    setCustChargeForm((prevCharges) => {
      const updatedCharges = [...prevCharges];
      updatedCharges[index] = {
        ...updatedCharges[index],
        [field]: value
      };
      return updatedCharges;
    });
  };
  const handleChargeFormSave = (e) => {
    e.preventDefault();
    setValue('cabProductDetails[0].product.charges', custChargeForm);
    calcCharge(custChargeForm, pLength, taxValues);
  };

  const handleSupplierSelect = (supplier) => {
    setIsError(prevState => ({ ...prevState, supplier: false }));
    setValue("cabProductDetails[0].product.agentRefOrValue.name", supplier.fullName);
    setValue("cabProductDetails[0].product.agentRefOrValue.id", supplier.supplierCode);
  }
  const supplierName = watch("cabProductDetails[0].product.agentRefOrValue") || '';

  const watchSupplierAmount = watch("cabProductDetails[0].supplierNetAmount");
  const [suppChargesTotal, setSuppChargesTotal] = useState(0);
  useEffect(() => {
    setSupplierAmount(watchSupplierAmount);
    const charges = getValues("cabProductDetails[0].product.rideDetails[0].supplierCharges")
    const charge = charges?.reduce((total, charge) => {
      return total + (Number(charge.totalValue) || 0);
    }, 0);
    setSuppChargesTotal(charge)
  }, [watchSupplierAmount]);

  const carTarrif = watch("cabProductDetails[0].product.fare[0].cabTarrif", 0);
  const extraKMCharges = watch("cabProductDetails[0].product.fare[0].extraKmCharges", 0);
  const extraTimeCharges = watch("cabProductDetails[0].product.fare[0].extraTimeCharges", 0);
  const markup = watch("cabProductDetails[0].product.fare[0].markup", 0);
  const additionalcharges = watch("cabProductDetails[0].product.fare[0].additionalCharges", 0);

  useEffect(() => {
    const parsedCarTarrif = parseFloat(carTarrif) || 0;
    const parsedExtraKMCharges = parseFloat(extraKMCharges) || 0;
    const parsedExtraTimeCharges = parseFloat(extraTimeCharges) || 0;
    const parsedMarkup = parseFloat(markup) || 0;
    const parsedAC = parseFloat(additionalcharges) || 0;
    const netAmount = parsedCarTarrif + parsedExtraKMCharges + parsedExtraTimeCharges + parsedMarkup + parsedAC;
    setValue("cabProductDetails[0].product.fare[0].netAmount", netAmount);
  }, [additionalcharges, carTarrif, extraKMCharges, extraTimeCharges, markup, setValue]);

  const handleSplitInv = (checked) => {
    setValue('split', checked);
  };

  const handleRideChange = (data) => {
    setRideForm((prevRideForm) => [...prevRideForm, data]);
    const currentValues = getValues();
    const currentRideDetails = currentValues?.cabProductDetails?.[0]?.product?.rideDetails || [];
    const updatedRideDetails = [...currentRideDetails, data];
    setValue("cabProductDetails.0.product.rideDetails", updatedRideDetails);
    calcFare(updatedRideDetails);
    toast.success("Ride Saved Successfully!", { duration: 1000 })
    setRideFormEdit({ visible: true, editingIndex: 0 });
  };
  const handleRideEdit = (data, index) => {
    setRideForm((prevRideForm) => {
      const updatedRideForm = [...prevRideForm];
      if (index < updatedRideForm.length) {
        updatedRideForm[index] = data;
      } else {
        updatedRideForm.push(data);
      }
      return updatedRideForm;
    });

    const currentValues = getValues();
    const currentRideDetails = currentValues.cabProductDetails[0]?.product?.rideDetails || [];
    const updatedRideDetails = [...currentRideDetails];
    if (index < updatedRideDetails.length) {
      updatedRideDetails[index] = data;
    } else {
      updatedRideDetails.push(data);
    }
    setValue("cabProductDetails.0.product.rideDetails", updatedRideDetails);
    // setRideFormEdit({ visible: false, editingIndex: null });
    toast.success("Ride Saved Successfully!", { duration: 1000 })
    calcFare(updatedRideDetails);
  };

  const handleDeleteRide = (index) => {
    const updatedRides = rideForm.filter((_, i) => i !== index);
    if (updatedRides.length === 0) {
      setRideFormEdit({ visible: true, editingIndex: null });
    }
    setValue('cabProductDetails[0].product.rideDetails', updatedRides);
    setRideForm(updatedRides);
    calcFare(updatedRides);
  }

  const calcFare = (rideForm) => {
    let totalCabTarrif = 0;
    let totalExtraKmCharges = 0;
    let totalExtraTimeCharges = 0;
    let totalAdditionalCharges = 0;
    rideForm.forEach(ride => {
      ride?.carriers?.forEach(carrier => {
        totalCabTarrif += parseFloat(carrier?.fare?.cabTarrif) || 0;
        totalExtraKmCharges += parseFloat(carrier?.fare?.extraKmCharges) || 0;
        totalExtraTimeCharges += parseFloat(carrier?.fare?.extraTimeCharges) || 0;
        totalAdditionalCharges += parseFloat(carrier?.fare?.additionalCharges) || 0;
      });
    });
    const totalNetAmount = totalCabTarrif + totalExtraKmCharges + totalExtraTimeCharges + totalAdditionalCharges + parseFloat(watch("cabProductDetails[0].product.fare[0].markup"));
    const updatedFare = [
      {
        cabTarrif: totalCabTarrif,
        extraKmCharges: totalExtraKmCharges,
        markup: parseFloat(watch("cabProductDetails[0].product.fare[0].markup")) || 0,
        extraTimeCharges: totalExtraTimeCharges,
        additionalCharges: totalAdditionalCharges,
        netAmount: totalNetAmount,
      }
    ];
    setValue('cabProductDetails[0].product.fare', updatedFare);
  }

  const [formState, setFormState] = useState('Draft');
  const updateValidationRules = (state) => {
    if (state === 'Submitted') {
      setValue('state', 'Submitted');
      setValue('cabProductDetails[0].state', 'Submitted');
    } else {
      setValue('state', 'Draft');
      setValue('cabProductDetails[0].state', 'Draft');
    }
    trigger();
  }

  const onSave = () => {
    setFormState('Draft');
    updateValidationRules('Draft');
    const data = getValues();
    setIsError(prevState => ({ ...prevState, ride: false, supplier: false }))
    if (!data.customerRefOrValue?.id) {
      setIsError(prevState => ({ ...prevState, customer: true }));
      return;
    }
  }

  const onCreate = (state) => {
    setFormState('Submitted');
    updateValidationRules('Submitted');
    const data = getValues();
    if (state) {
      setValue('state', state);
      setValue('cabProductDetails[0].state', state);
    }
    if (!data.customerRefOrValue?.id) {
      setIsError(prevState => ({ ...prevState, customer: true }));
      if (!data.cabProductDetails[0]?.product.agentRefOrValue?.name) {
        setIsError(prevState => ({ ...prevState, supplier: true }));
      }
      return;
    }
    if (!data.cabProductDetails[0]?.product.agentRefOrValue?.name) {
      setIsError(prevState => ({ ...prevState, supplier: true }));
      return;
    }
    const rides = data.cabProductDetails[0]?.product?.rideDetails || [];
    if (rides.length === 0 && !rides[0]?.carDetails) {
      setIsError(prevState => ({ ...prevState, ride: true }));
      toast.error("Ride details is required, Save!");
      return;
    }
    // if (rides.length > 0 && rideFormEdit.visible) {
    //   toast.error("Save or Close ride form!");
    //   return;
    // }
  }
  const onError = (errors) => {
    if (Object.keys(errors).length > 0) {
      toast.error("Please fill in all required fields.");
    }
  };

  const onSubmit = (data) => {
    const netAmt = calcNet(data);
    if (data.cabProductDetails && data.cabProductDetails[0]) {
      data.cabProductDetails[0].netAmount = netAmt;
      data.netAmount = netAmt;
    }
    if (data.invoiceId) {
      handleEditInv(data);
    } else {
      handleCreateInv(data);
    }
  };

  const handleCreateInv = async (data) => {
    const { id, ...requestData } = data;
    if (Object.values(isError).some(error => error)) {
      return;
    }
    setLoading("Create");
    try {
      const response = await fetch(`${(process.env.REACT_APP_API_HOST_INV === undefined) ? "" : process.env.REACT_APP_API_HOST_INV}/amigo-lekha-api/invoiceManagement/v1/invoice`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(requestData),
      });
      if (!response.ok) {
        throw new Error('Failed to create invoice');
      }
      const res = await response.json();
      const id = encodeURIComponent(res.invoiceId);
      reset();
      toast.success("Invoice created successfuly!", { duration: "5000", position: "top-right", description: `Invoice Id - ${id}` });
      if (res.state === "Draft") {
        navigate(`/dashboard/invoices/cab/edit/${id}`, { state: { item: res } });
      } else {
        navigate(`/dashboard/invoices/cab`);
      }
    } catch (error) {
      console.error("Error creating invoice:", error);
      toast.error("Failed to create invoice");
    } finally {
      setLoading("");
    }
  };

  const handleEditInv = async (data) => {
    if (Object.values(isError).some(error => error)) {
      return;
    }
    if (data.state === "Draft") {
      setLoading("Edit");
    } else {
      setLoading("Create");
    }
    try {
      const response = await fetch(`${(process.env.REACT_APP_API_HOST_INV === undefined) ? "" : process.env.REACT_APP_API_HOST_INV}/amigo-lekha-api/invoiceManagement/v1/invoice/${data.invoiceId}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(data),
      });
      // ?invoiceId=${data.invoiceId} for query param
      if (!response.ok) {
        throw new Error('Failed to edit invoice');
      }
      const res = await response.json();
      const id = encodeURIComponent(res.invoiceId);
      toast.success(`Invoice ${res.state === "Draft" ? "Edited" : "Submitted"} Successfuly!`, { duration: "5000", position: "top-right", description: `Invoice Id - ${id}` });
      reset(res);
      if (res.state === "Draft") {
        navigate(`/dashboard/invoices/cab/edit/${id}`, { state: { item: res } });
      } else {
        navigate(`/dashboard/invoices/cab`);
      }
    } catch (error) {
      console.error("Error editing invoice:", error);
      toast.error("Failed to edit invoice");
    } finally {
      setLoading("");
    }
  };

  const calcNet = (data) => {
    let fareTotal = data.cabProductDetails[0]?.product?.fare[0]?.netAmount;
    if (isNaN(fareTotal) || fareTotal === undefined) { fareTotal = 0; }
    const chrgTax = data.cabProductDetails?.[0]?.product?.rideDetails?.flatMap(ride => {
      return ride.charges.filter(charge => charge.totalValue !== 0);
    });
    let totalValue = 0;
    let taxTotal = 0;
    if (Array.isArray(chrgTax)) {
      chrgTax.forEach(chargeItem => {
        totalValue += parseFloat(chargeItem.totalValue) || 0;
        taxTotal += parseFloat(chargeItem.taxTotal) || 0;
      });
    }
    const remarks = data.cabProductDetails?.[0]?.product?.rideDetails?.flatMap(ride => {
      return ride.passenger?.filter(passenger =>
        passenger.remark?.length > 0 &&
        passenger.remark.some(remark => remark.name && remark.charge)
      ) || [];
    });
    let remarksTotal = remarks?.reduce((total, passenger) => {
      return total + (passenger.remark?.reduce((passengerTotal, remark) => {
        return passengerTotal + (parseFloat(remark.charge) || 0);
      }, 0) || 0);
    }, 0);
    const netAmount = fareTotal + totalValue + taxTotal + remarksTotal;
    return netAmount;
  }
  return (
    <div className='mx-8 my-5'>
      <Link to={`/dashboard/invoices/cab`} className='absolute mt-1 ml-5'><Button variant="outline" size="icon"><Undo2 className='size-4' /></Button></Link>
      <div className="flex items-center justify-center w-full mb-5 text-[3vh] font-bold">{id ? "Edit" : "New"} Invoice (cab)</div>
      {invoiceId && <div className='flex mx-5 my-0 text-sm'><div className='font-bold'>Invoice Id - <span className='text-primary/70'>{invoiceId}</span></div><div className='ml-auto'>NetAmount : ₹{data?.cabProductDetails?.[0]?.netAmount}&ensp;|</div><div>&ensp;Supplier Amount : ₹{data?.cabProductDetails?.[0]?.supplierNetAmount + (suppChargesTotal || 0)}&ensp;|</div><div>&ensp;Created By : {data?.createdBy}</div></div>}
      <Separator />
      <form onSubmit={handleSubmit(onSubmit, onError)} className='mt-2'>
        <ScrollArea className="h-[calc(100vh-200px)] lg:h-[70vh]">
          <div className="grid gap-4 px-5 py-2">
            <div className="grid grid-cols-1 gap-4 md:grid-cols-2 lg:grid-cols-5">
              <div className="flex flex-row items-center gap-2">
                <Label className="flex w-40">Invoice Date{errors.invoiceDate && <p className="ml-1 text-destructive">*</p>}</Label>
                <Controller control={control} name="invoiceDate" rules={{ required: true }} {...register("invoiceDate")}
                  render={({ field }) => (
                    <DatePicker value={field.value} onChange={(date) => field.onChange(date)} disableDate={false} current={true} error={errors.invoiceDate} />
                  )}
                />
              </div>
              <div className="flex flex-row items-center gap-2">
                <Label className="flex w-40">Customer Name{isError.customer && <p className="ml-1 text-destructive">*</p>}</Label>
                <SearchCustomer onCustomerSelect={handleCustomerSelect} value={customerRefOrValue?.id} isVisible={isVisible} isError={isError.customer} />
                {/* {isError.customer && <p className="text-xs text-red-500">Select Customer!</p>} */}
              </div>
              <div className="flex flex-row items-center gap-2">
                <Label className="w-40 lg:w-48">Print In Name</Label>
                <Input className="h-7 bg-muted/50" name="printInName" {...register("printInName")} />
              </div>
              <Button variant='secondary' className="w-20 h-7" onClick={(e) => { setCustomerForm(!customerForm); e.preventDefault() }}>{customerForm ? "Hide" : "More"} Info</Button>
            </div>
            {customerForm && (
              <div className="grid grid-cols-1 gap-2 md:grid-cols-2 lg:grid-cols-4">
                <div className="flex flex-row items-center gap-2">
                  <Label className="w-40">Phone No</Label>
                  <Input className="h-7 bg-muted/50" disabled={true} name="phone" value={customerRefOrValue?.phone} {...register("customerRefOrValue.phone")} />
                </div>
                <div className="flex flex-row items-center gap-2">
                  <Label className="w-40">Email</Label>
                  <Input className="h-7 bg-muted/50" disabled={true} name="email" value={customerRefOrValue?.email} {...register("customerRefOrValue.email")} />
                </div>
                {/* <div className="flex flex-row items-center gap-2">
                  <Label className="w-40">Print In Name</Label>
                  <Input className="h-7 bg-muted/50" disabled={true} name="printInName" {...register("printInName")} />
                </div> */}
                <div className="flex flex-row items-center gap-2">
                  <Label className="w-36">Address</Label>
                  <Textarea className="h-16 bg-muted/50" disabled={true} name="address" value={customerRefOrValue?.address} {...register("customerRefOrValue.address")} />
                </div>
                <div className="flex flex-row items-center gap-2">
                  <Label className="w-40">PAN No</Label>
                  <Input className="h-7 bg-muted/50" disabled={true} name="panNo" value={customerRefOrValue?.panNo} {...register("customerRefOrValue.panNo")} />
                </div>
                <div className="flex flex-row items-center gap-2">
                  <Label className="w-40">GST Number</Label>
                  <Input className="h-7 bg-muted/50" disabled={true} name="gstNo" value={customerRefOrValue?.gstNo} {...register("customerRefOrValue.gstNo")} />
                </div>
              </div>
            )}
            <div className="grid grid-cols-1 gap-x-4 gap-y-2 md:grid-cols-2 lg:grid-cols-5">
              <div className="flex flex-row items-center gap-2">
                <Label className="w-40">Due Date</Label>
                <Controller control={control} name="dueDate" {...register("dueDate")}
                  render={({ field }) => (
                    <DatePicker value={field.value} onChange={(date) => field.onChange(date)} disableDate={false} />
                  )}
                />
              </div>
              <div className="flex flex-row items-center gap-2">
                <Label className="flex w-40 lg:w-56">Supplier Name{isError.supplier && <p className="ml-1 text-destructive">*</p>}</Label>
                <SupplierSelect onSupplierSelect={handleSupplierSelect} value={supplierName.id} isVisible={isVisible} isError={isError.supplier} />
              </div>
              <div className="flex flex-row items-center gap-2">
                <Label className="flex w-40 lg:w-48">Supplier Amount</Label>
                <Input className="h-7 bg-muted/50" name="supplierNetAmount" {...register("cabProductDetails[0].supplierNetAmount")} />
              </div>
              <div className="flex flex-row items-center gap-2">
                <Label className="flex w-40 lg:w-48">Supplier Invoice No.</Label>
                <Input className="h-7 bg-muted/50" name="supplierInvNo" {...register("cabProductDetails[0].supplierInvNo")} />
              </div>
            </div>
            {/* <div className="grid grid-cols-1 gap-x-4 gap-y-2 md:grid-cols-2 lg:grid-cols-5">
              <div className="flex flex-row items-center gap-2">
                <Label className="flex w-40">Cab Details{errors.cabProductDetails?.[0]?.product?.carDetails && <p className="ml-1 text-destructive">*</p>}</Label>
                <Input className={`h-7 bg-muted/50 ${errors.cabProductDetails?.[0]?.product?.carDetails ? 'border-destructive focus-visible:ring-0' : ''}`} name="carDetails" {...register("cabProductDetails[0].product.carDetails", { required: formState === "Submitted" })} />
              </div>
              <div className="flex flex-row items-center gap-2">
                <Label className="flex w-40 lg:w-44">Through Agent</Label>
                <Input className="h-7 bg-muted/50" name="agent" {...register("agent")} />
              </div>
              {(agentComm !== "" || null) &&
                <div className="flex flex-row items-center gap-2">
                  <Label className="flex w-40 lg:w-44">Commission Amt.</Label>
                  <Input className="h-7 bg-muted/50" name="agentCommissionAmount" {...register("cabProductDetails[0].product.fare[0].agentCommissionAmount")} />
                </div>}
              <div className="flex flex-row items-center gap-2">
                <Label className="flex w-40 lg:w-44">Requisition By{errors.cabProductDetails?.[0]?.product?.requisitionBy && <p className="ml-1 text-destructive">*</p>}</Label>
                <Input className={`h-7 bg-muted/50 ${errors.cabProductDetails?.[0]?.product?.requisitionBy ? 'border-destructive focus-visible:ring-0' : ''}`} name="requisitionBy" {...register("cabProductDetails[0].product.requisitionBy", { required: formState === "Submitted" })} />
              </div>
              <div className="flex flex-row items-center gap-2">
                <Label className="flex w-40 lg:w-44">Handled By{errors.cabProductDetails?.[0]?.product?.handledBy && <p className="ml-1 text-destructive">*</p>}</Label>
                <Input className={`h-7 bg-muted/50 ${errors.cabProductDetails?.[0]?.product?.handledBy ? 'border-destructive focus-visible:ring-0' : ''}`} name="handledBy" {...register("cabProductDetails[0].product.handledBy", { required: formState === "Submitted" })} />
              </div>
            </div> */}
            <Separator />
            <div className="flex flex-row items-center justify-between gap-4 mr-2">
              <CardDescription>Ride details :</CardDescription>
              {/* <CabProductDialog dropData={dialogData} onRideSave={(data) => { handleRideChange(data) }} error={isError.ride} calcCharge={(length) => { calcCharge(custChargeForm, length, taxValues) }} /> */}
              {/* <Button size='sm' variant="secondary" onClick={(e) => { setRideFormEdit(prevState => ({ ...prevState, visible: true, editingIndex: null })); e.preventDefault(); }}>Add Ride <CirclePlus className="ml-2 size-4" /></Button> */}
            </div>
            <Card>
              <CardContent className="p-0">
                <Table>
                  <TableHeader className="bg-secondary/60">
                    <TableRow>
                      <TableHead className="rounded-tl-md"></TableHead>
                      <TableHead></TableHead>
                      <TableHead>Car Detail</TableHead>
                      <TableHead>Agent</TableHead>
                      <TableHead>Handled By</TableHead>
                      <TableHead>No. of Passengers</TableHead>
                      <TableHead>Total Fare</TableHead>
                      <TableHead className="rounded-tr-md"><span className="sr-only">Actions</span></TableHead>
                    </TableRow>
                  </TableHeader>
                  <TableBody>
                    {rideForm.length > 0 ? (
                      rideForm.map((ride, index) => (
                        <React.Fragment key={index}>
                          <TableRow >
                            <TableCell></TableCell>
                            <TableCell>{index + 1}</TableCell>
                            <TableCell>{ride.carDetails || "NA"}</TableCell>
                            <TableCell>{ride.agent || "NA"}</TableCell>
                            <TableCell>{ride.handledBy || "NA"}</TableCell>
                            <TableCell>{ride.passenger ? ride.passenger[0].numberOfPassenger : 0}</TableCell>
                            <TableCell>{ride.carriers ? parseFloat(ride.carriers.reduce((sum, carrier) => sum + (parseFloat(carrier.fare?.netAmount) || 0), 0)).toFixed(0) : '0'}</TableCell>
                            <TableCell>
                              <div className="flex flex-row items-center gap-4">
                                {/* <CabProductDialog dropData={dialogData} onRideSave={(data) => { handleRideEdit(data, index) }} data={ride} calcCharge={(length) => { calcCharge(custChargeForm, length, taxValues); pLength = length; }} /> */}
                                <Edit onClick={() => setRideFormEdit(prevState => ({ ...prevState, visible: true, editingIndex: index }))} className="cursor-pointer text-primary size-4" />
                                <Trash2 onClick={() => handleDeleteRide(index)} className="cursor-pointer text-destructive size-4" />
                              </div>
                            </TableCell>
                          </TableRow>
                          {rideFormEdit.visible && rideFormEdit.editingIndex === index && (
                            <TableRow>
                              <TableCell colSpan={11}>
                                <CabProductDialog dropData={dialogData} onRideSave={(data) => { handleRideEdit(data, index) }} dirtyCheck={(dirty) => {setIsDirty(dirty)}} data={ride} suppAmount={supplierAmount} rideClose={(value) => { if (value) { setRideFormEdit({ visible: false, editingIndex: null }); } }} />
                              </TableCell>
                            </TableRow>
                          )}
                        </React.Fragment>
                      ))
                    ) : (
                      <TableRow>
                        <TableCell colSpan={8} className="text-center">No Rides</TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
                {rideFormEdit.visible && rideFormEdit.editingIndex === null && (
                  <>
                    <Separator />
                    <CabProductDialog dropData={dialogData} onRideSave={(data) => handleRideChange(data)} dirtyCheck={(dirty) => {setIsDirty(dirty)}} error={isError.ride} suppAmount={supplierAmount} rideClose={(value) => { if (value) { setRideFormEdit({ visible: false, editingIndex: null }); } }} />
                  </>
                )}
              </CardContent>
            </Card>
          </div>
        </ScrollArea>
        <CardFooter className="py-0 mt-2 mr-2">
          {
            <div className="flex items-center gap-2 ml-auto">
              {isDirty && <p className='text-sm text-destructive'>Save Ride - Unsaved Changes!</p>}
              {(!data || data.state === 'Draft') && (
                <Button onClick={onSave} disabled={loading}>
                  {loading === "Edit" ? 'Editing...' : 'Save Invoice'}
                </Button>
              )}
              <Button onClick={() => onCreate('Submitted')} disabled={loading || isDirty}>
                {loading === "Create" ? 'Creating...' : 'Create Invoice'}
              </Button>
            </div>
          }
        </CardFooter>
      </form>
    </div>
  )
}
