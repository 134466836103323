import { Button } from 'src/components/ui/button';
import { Command, CommandEmpty, CommandGroup, CommandInput, CommandItem, CommandList } from 'src/components/ui/command';
import { Popover, PopoverContent, PopoverTrigger } from 'src/components/ui/popover';
import { cn } from 'src/lib/utils';
import { Check, ChevronsDownUp } from 'lucide-react';
import React, { useCallback } from 'react'

export default function SelectDrop({ onTypeSelect, value, isVisible, typeName }) {
    const [open, setOpen] = React.useState(false)
    const [loading, setLoading] = React.useState(false);
    const [types, setType] = React.useState([]);
    const [selectedType, setSelectedType] = React.useState(null);
    const getTypes = useCallback(async () => {
        setLoading(true);
        try {
            const response = await fetch(`${(process.env.REACT_APP_API_HOST_MSTR === undefined) ? "" : process.env.REACT_APP_API_HOST_MSTR}/amigo-lekha-api/${typeName}Management/v1/${typeName}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json'
                },
            });
            if (!response.ok) {
                throw new Error('Failed to fetch data');
            }
            const fetchedData = await response.json();
            setType(fetchedData);
        } catch (error) {
            console.error("Error fetching data:", error);
        } finally {
            setLoading(false);
        }
    }, [typeName])
    const handleSelectType = async (typeId) => {
        const type = types.find((cpnt) => cpnt.code === typeId);
        setSelectedType(type);
        onTypeSelect(type);
        setOpen(false);
    };
    React.useEffect(() => {
        if (value) {
            const type = types.find((cpnt) => cpnt.name === value);
            if (type) {
                setSelectedType(type);
            }
        }
    }, [value, types, onTypeSelect]);

    React.useEffect(() => {
        if (isVisible) {
            getTypes();
        }
    }, [getTypes, isVisible]);
    return (
        <Popover open={open} onOpenChange={setOpen} modal={true}>
            <PopoverTrigger asChild onClick={getTypes}>
                <Button
                    variant="outline"
                    role="combobox"
                    aria-expanded={open}
                    className={`w-[32vh] bg-muted/50 h-7 justify-between`}
                >
                    {selectedType?.name || `Select ${typeName}...`}
                    <ChevronsDownUp className="w-4 h-4 ml-2 opacity-50 shrink-0" />
                </Button>
            </PopoverTrigger>
            <PopoverContent className="w-[28vh] h-[30vh] p-0">
                <Command>
                    <CommandInput placeholder={`Search ${typeName}...`} className="h-8" />
                    <CommandList>
                        <CommandEmpty>{loading ? "loading..." : `No ${typeName} found.`}</CommandEmpty>
                        <CommandGroup>
                            {types.map((type) => (
                                <CommandItem
                                    key={type.code}
                                    value={type.name}
                                    onSelect={() => handleSelectType(type.code)}
                                >
                                    {type.name}
                                    <Check
                                        className={cn(
                                            "ml-auto h-4 w-4",
                                            selectedType?.code === type.code ? "opacity-100" : "opacity-0"
                                        )}
                                    />
                                </CommandItem>
                            ))}
                        </CommandGroup>
                    </CommandList>
                </Command>
            </PopoverContent>
        </Popover>
    )
}
