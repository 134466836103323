import { Button } from 'src/components/ui/button';
import { Card, CardContent, CardDescription, CardFooter } from 'src/components/ui/card';
import { Input } from 'src/components/ui/input';
import { Label } from 'src/components/ui/label';
import { Link, useNavigate } from 'react-router-dom'
import { toast } from 'sonner';
import { useParams, useLocation } from 'react-router-dom';
import { Controller, useForm } from 'react-hook-form';
import { Select, SelectContent, SelectGroup, SelectItem, SelectTrigger, SelectValue } from 'src/components/ui/select';
import { Separator } from 'src/components/ui/separator';
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from "src/components/ui/table";
import { DatePicker } from 'src/pages/components/datepicker';
import { Textarea } from 'src/components/ui/textarea';
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from 'src/components/ui/tooltip';
import { CirclePlus, Edit, Info, Trash2, Undo2 } from 'lucide-react';
import { ScrollArea } from 'src/components/ui/scroll-area';
import React, { useEffect, useState } from 'react'
import { SearchCustomer } from '../../invoicePage/components/search-select';
import AirlineSelect from '../../invoicePage/components/airline-select';
import CarrierSelect from '../../invoicePage/components/carrier-select';
import SupplierSelect from 'src/pages/components/supplier-select';
import InvoiceSelect from '../invoice-select';
import { Checkbox } from 'src/components/ui/checkbox';
import PNRSelect from '../pnr-select';

export default function CnoteAirlineForm() {
  const [loading, setLoading] = useState("");
  const [passengerFormEdit, setpassengerFormEdit] = useState({ visible: false, editingIndex: null });
  const [carrierFormEdit, setcarrierFormEdit] = useState({ visible: false, editingIndex: null });
  const [customerForm, setCustomerForm] = useState(false);
  const [creditNoteId, setCreditNoteId] = useState('');
  const [isVisible, setIsVisible] = useState(false);
  const [isError, setIsError] = useState({
    invoice: false,
    customer: false,
    airline: false,
    passenger: false,
    carrier: false,
    fare: false,
    supplier: false,
  });
  const [selectedFromLocation, setSelectedFromLocation] = useState('');
  const [selectedToLocation, setSelectedToLocation] = useState('');
  const { register, handleSubmit, control, reset, watch, setValue, getValues, trigger, formState: { errors }, } = useForm({
    defaultValues: {
      id: 0,
      creditNoteId: "",
      creditNoteDate: "",
      dueDate: "",
      agent: "",
      category: "airline",
      createdBy: "amigo2",
      printInName: "",
      manualBillNo: "",
      referredBy: "",
      state: "",
      productDetails: [
        {
          id: "",
          code: "",
          name: "airline",
          netAmount: 0,
          product: {
            gds: "",
            gdsNo: "",
            airline: "",
            airlinePNR: "",
            requisitionNo: "",
            requisitionBy: "",
            requisitionDate: "",
            validationDate: "",
            passenger: [],
            carriers: [],
            fare: [],
            charges: [],
          },
          state: "",
        },
      ],
      customerRefOrValue: {
        id: "",
        name: "",
        address: "",
        email: "",
        phone: "",
        panNo: "",
        gstNo: "",
      },
    }
  });
  // passenger states
  const [passengerForm, setPassengerForm] = useState([
    {
      title: "",
      firstName: "",
      lastName: "",
      paxType: "",
      ticketNo: "",
      fare: {
        baseFare: 0,
        baseFareMarkup: 0,
        totalBaseFare: 0,
        comTax: 0,
        nonComTax: 0,
        nonComTaxMarkup: 0,
        totalNonComTax: 0,
      }
    }
  ]);
  const [editingPassengerForm, setEditingPassengerForm] = useState([]);
  // carrier states
  const [carrierForm, setCarrierForm] = useState([
    {
      fromLocation: "",
      toLocation: "",
      carrier: "",
      carrierType: "",
      airlineClass: "",
      departureDate: "",
    }
  ]);
  const [editingCarrierForm, setEditingCarrierForm] = useState([]);
  // fare states
  const [fareForm, setFareForm] = useState([
    {
      baseFare: 0,
      baseFareMarkup: 0,
      totalBaseFare: 0,
      comTax: 0,
      nonComTax: 0,
      nonComTaxMarkup: 0,
      totalNonComTax: 0,
      netAmount: 0,
    }
  ]);
  const [chargeForm, setChargeForm] = useState([
    {
      chargeCodeName: "Service Charge",
      calculationMethod: "",
      value: 0,
      totalValue: 0,
      tax: [],
      taxTotal: null,
    },
    {
      chargeCodeName: "Miscellaneous Charges",
      calculationMethod: "",
      value: 0,
      totalValue: 0,
      tax: [],
      taxTotal: null,
    },
    {
      chargeCodeName: "Documentation Charge",
      calculationMethod: "",
      value: 0,
      totalValue: 0,
      tax: [],
      taxTotal: null,
    },
    {
      chargeCodeName: "Management Fees",
      calculationMethod: "",
      value: 0,
      totalValue: 0,
      tax: [],
      taxTotal: null,
    },
    {
      chargeCodeName: "Cancellation Charges",
      calculationMethod: "",
      value: 0,
      totalValue: 0,
      tax: [],
      taxTotal: null,
    }
  ]);
  const CHARGE_TYPES = [
    "Service Charge",
    "Miscellaneous Charges",
    "Documentation Charge",
    "Management Fees",
    "Cancellation Charges"
  ];

  const [titleItems, setTitleItems] = useState([]);
  const [paxTypeItems, setPaxTypeItems] = useState([]);
  const [classItems, setClassItems] = useState([]);
  const [carrierTypeItems, setCarrierTypeItems] = useState([]);

  const { id } = useParams();
  const location = useLocation();
  const data = location.state?.item;
  const navigate = useNavigate();

  useEffect(() => {
    getLists();
    setValue('creditNoteDate', new Date().toISOString());
    if (data && id) {
      setCreditNoteId(data.creditNoteId);
      setInvAmount(data.invoiceNetAmount);
      setCreditAmount(data.creditNoteNetAmount);
      reset(data);
      if (data.productDetails && data.productDetails.length > 0 && data.productDetails[0].product && data.productDetails[0].product.passenger) {
        const passengerData = data.productDetails[0].product.passenger;
        setPassengerForm(passengerData);
        setEditingPassengerForm(passengerData);
      } else {
        setPassengerForm([
          {
            title: "",
            firstName: "",
            lastName: "",
            paxType: "",
            ticketNo: "",
          }
        ]);
      }
      if (data.productDetails && data.productDetails.length > 0 && data.productDetails[0].product && data.productDetails[0].product.carriers) {
        const carriersData = data.productDetails[0].product.carriers;
        setCarrierForm(carriersData);
        setEditingCarrierForm(carriersData);
      } else {
        setCarrierForm([
          {
            fromLocation: "",
            toLocation: "",
            carrier: "",
            carrierType: "",
            airlineClass: "",
            departureDate: "",
          }
        ]);
      }
      if (data.productDetails && data.productDetails.length > 0 && data.productDetails[0].product && data.productDetails[0].product.fare) {
        const fareData = data.productDetails[0].product.fare;
        setFareForm(fareData);
      } else {
        setFareForm([
          {
            baseFare: 0,
            baseFareMarkup: 0,
            totalBaseFare: 0,
            comTax: 0,
            nonComTax: 0,
            nonComTaxMarkup: 0,
            totalNonComTax: 0,
            netAmount: 0,
          }
        ]);
      }
      if (data.productDetails && data.productDetails.length > 0 && data.productDetails[0].product && data.productDetails[0].product.charges) {
        const chargeData = data.productDetails[0].product.charges;
        setChargeForm(chargeData);
      } else {
        setChargeForm([
          {
            chargeCodeName: "",
            calculationMethod: "",
            value: 0,
            totalValue: 0,
            tax: [],
            taxTotal: 0,
          }
        ]);
      }
      setIsVisible(true);
    }
  }, [data, id, reset, setValue]);

  const getLists = async () => {
    try {
      const response = await fetch(`${(process.env.REACT_APP_API_HOST_MSTR === undefined) ? "" : process.env.REACT_APP_API_HOST_MSTR}/amigo-lekha-api/listManagement/v1/list`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json'
        },
      });
      if (!response.ok) {
        throw new Error('Failed to fetch data');
      }
      const fetchedData = await response.json();
      fetchedData.forEach(item => {
        switch (item.name) {
          case 'Title':
            setTitleItems(item.items);
            break;
          case 'PaxType':
            setPaxTypeItems(item.items);
            break;
          case 'Class':
            setClassItems(item.items);
            break;
          case 'CarrierType':
            setCarrierTypeItems(item.items);
            break;
          default:
            break;
        }
      });
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  }
  const calculateTaxForCharge = (totalValue, chargeTaxes, taxValues) => {
    let taxTotal = 0;
    const updatedTaxes = chargeTaxes?.map(chargeTax => {
      const matchingTaxValue = taxValues.find(taxValue => taxValue.id === chargeTax.taxCode);
      if (matchingTaxValue) {
        const taxAmount = totalValue * (matchingTaxValue.value / 100);
        taxTotal += taxAmount;
        return { ...chargeTax, value: taxAmount };
      }
      return chargeTax;
    });
    return { tax: updatedTaxes, taxTotal };
  };

  const [taxValues, setTaxValues] = useState([]);
  const [invAmount, setInvAmount] = useState(0);
  const [creditAmount, setCreditAmount] = useState(0);
  const [invChargeTotal, setInvChargeTotal] = useState(0);

  const handleInvoiceSelect = (invoice) => {
    setIsError(prevState => ({ ...prevState, invoice: false }));
    if (invoice.customerRefOrValue.id) {
      setIsError(prevState => ({ ...prevState, customer: false }));
    }
    const creditNDate = getValues("creditNoteDate");
    reset(invoice);
    setValue('creditNoteDate', creditNDate);
    setValue("productDetails[0].product.cancellationDate", new Date().toISOString())
    setInvAmount(invoice.netAmount);
    setValue("productDetails[0].invoiceNetAmount", invoice.netAmount);
    setValue("invoiceNetAmount", invoice.netAmount);
    setValue("category","airline");
    setValue("creditNoteId", creditNoteId);
    calcCredit(invoice.productDetails[0].product.charges, invoice.netAmount, true);
    if (invoice.productDetails && invoice.productDetails.length > 0 && invoice.productDetails[0].product && invoice.productDetails[0].product.passenger) {
      const passengerData = invoice.productDetails[0].product.passenger;
      console.log(passengerData);
      setPassengerForm(passengerData);
      setEditingPassengerForm(passengerData);
    } else {
      setPassengerForm([
        {
          title: "",
          firstName: "",
          lastName: "",
          paxType: "",
          ticketNo: "",
        }
      ]);
    }
    if (invoice.productDetails && invoice.productDetails.length > 0 && invoice.productDetails[0].product && invoice.productDetails[0].product.carriers) {
      const carriersData = invoice.productDetails[0].product.carriers;
      setCarrierForm(carriersData);
      setEditingCarrierForm(carriersData);
    } else {
      setCarrierForm([
        {
          fromLocation: "",
          toLocation: "",
          carrier: "",
          carrierType: "",
          airlineClass: "",
          departureDate: "",
        }
      ]);
    }
    if (invoice.productDetails && invoice.productDetails.length > 0 && invoice.productDetails[0].product && invoice.productDetails[0].product.fare) {
      const fareData = invoice.productDetails[0].product.fare;
      setFareForm(fareData);
    } else {
      setFareForm([
        {
          baseFare: 0,
          baseFareMarkup: 0,
          totalBaseFare: 0,
          comTax: 0,
          nonComTax: 0,
          nonComTaxMarkup: 0,
          totalNonComTax: 0,
          netAmount: 0,
        }
      ]);
    }
    setIsVisible(true);
    setValue('invoiceRefOrValue.invoiceId', invoice.invoiceId || '');
  }
  const invoiceRefOrValue = watch("invoiceRefOrValue");
  const airlinePNR = watch("productDetails[0].product.airlinePNR");

  const handleCustomerSelect = async (customer, customerCharges) => {
    if (Object.keys(customerCharges).length === 0) {
      toast.error(`No charges for ${customer.customerCode}`);
    }
    setIsError(prevState => ({ ...prevState, customer: false }));
    setValue('customerRefOrValue.id', customer.customerCode || '');
    setValue('customerRefOrValue.name', customer.fullName || '');
    setValue('customerRefOrValue.phone', customer.contactNumber || '');
    setValue('customerRefOrValue.email', customer.emailId || '');
    setValue('customerRefOrValue.address', customer.addressId[0]?.addressLine || '');
    setValue('customerRefOrValue.panNo', customer.panNumber || '');
    setValue('customerRefOrValue.gstNo', customer.gstNumber || '');
    const updatedChargeForm = chargeForm.map(charge => ({
      ...charge,
      value: 0,
      calculationMethod: '',
      totalValue: 0,
      tax: [{
        taxCode: "",
        value: 0,
      }],
      taxTotal: 0,
    }));
    let fetchedTaxValues;
    if (customerCharges.configDetails) {
      fetchedTaxValues = await getTaxValues(customerCharges?.configDetails);
    }
    const currentDate = new Date();
    customerCharges?.configDetails
      ?.filter((charge) => charge.ProductRef.id === "Airline")
      .forEach((charge) => {
        const matchingCharge = updatedChargeForm.find(
          (c) => c.chargeCodeName === charge.ledger.id
        );
        if (matchingCharge) {
          matchingCharge.value = charge.amount || 0;
          matchingCharge.calculationMethod = charge.calculationMethod;
          const isWithinDateRange = (
            (charge.effectStartDate === null && charge.effectEndDate === null) ||
            (charge.effectStartDate === null && new Date(charge.effectEndDate) >= currentDate) ||
            (charge.effectEndDate === null && new Date(charge.effectStartDate) <= currentDate) ||
            (new Date(charge.effectStartDate) <= currentDate && new Date(charge.effectEndDate) >= currentDate)
          );
          if (isWithinDateRange) {
            matchingCharge.tax = charge.taxes?.map(taxCode => ({
              taxCode,
              value: 0
            })) || [];
          } else {
            matchingCharge.tax = [];
          }
        }
      });
    setChargeForm(updatedChargeForm);
    if (fetchedTaxValues) {
      calcCharge(updatedChargeForm, passengerForm.length, fetchedTaxValues);
    }
  };
  const customerRefOrValue = watch("customerRefOrValue");

  const getTaxValues = async (configDetails) => {
    let taxIds;
    if (configDetails !== null) {
      taxIds = configDetails
        ?.filter((charge) => charge.ProductRef.id === "Airline")
        ?.flatMap((charge) => charge.taxes?.map((tax) => tax) || [])
        .filter((id, index, self) => self.indexOf(id) === index);
    } else {
      taxIds = chargeForm
        .flatMap((charge) => charge.tax?.map((tax) => tax.taxCode) || [])
        .filter((id, index, self) => self.indexOf(id) === index);
    }
    const taxValues = await Promise.all(
      taxIds.map(async (taxId) => {
        try {
          const response = await fetch(`${(process.env.REACT_APP_API_HOST_MSTR === undefined) ? "" : process.env.REACT_APP_API_HOST_MSTR}/amigo-lekha-api/taxTypeManagement/v1/taxType/${taxId}`);
          if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
          }
          const data = await response.json();
          return { id: taxId, value: data.valueDetails[0].value };
        } catch (error) {
          console.error(`Error fetching tax data for ID ${taxId}:`, error);
          return { id: taxId, value: 0 };
        }
      })
    );
    if (taxValues.length > 0) {
      calcCharge(chargeForm, passengerForm.length, taxValues);
    }
    setTaxValues(taxValues);
    return taxValues;
  };

  const handleAirlineSelect = (airline) => {
    setIsError(prevState => ({ ...prevState, airline: false }));
    setValue("productDetails[0].product.airline", airline.name);
  }
  const airlineName = watch("productDetails[0].product.airline") || '';

  const handleSupplierSelect = (supplier) => {
    setIsError(prevState => ({ ...prevState, supplier: false }));
    setValue("productDetails[0].product.agentRefOrValue.name", supplier.fullName);
    setValue("productDetails[0].product.agentRefOrValue.id", supplier.supplierCode);
  }
  const supplierName = watch("productDetails[0].product.agentRefOrValue") || '';

  const handleFlightSelect = (flight, index) => {
    handleCChange(index, 'carrier', flight.name);
    setSelectedFromLocation(flight.fromLocation);
    setSelectedToLocation(flight.toLocation);
    handleCChange(index, 'fromLocation', flight.fromLocation);
    handleCChange(index, 'toLocation', flight.toLocation);
  }

  // passenger handle
  const handlePChange = (index, field, value) => {
    if (index === null) {
      index = editingPassengerForm.length;
    }
    setPassengerForm((prevPassengers) => {
      const updatedPassengers = [...prevPassengers];
      updatedPassengers[index] = {
        ...updatedPassengers[index],
        [field]: value
      };
      if (field === "status") {
        setEditingPassengerForm(updatedPassengers);
        setValue('productDetails[0].product.passenger', updatedPassengers);
        const amount = updatedPassengers[index].fare.baseFare + updatedPassengers[index].fare.comTax + updatedPassengers[index].fare.nonComTax + updatedPassengers[index].fare.markup + updatedPassengers[index].fare.k3;
        if (value === "Cancelled") {
          setCreditAmount(creditAmount - amount);
        } else {
          setCreditAmount(creditAmount + amount);
        }
      }
      return updatedPassengers;
    });
  };
  const handlePassengerFormSave = (e) => {
    e.preventDefault();
    const isPassengerEmpty = (passenger) =>
      Object.entries(passenger).every(([key, value]) =>
        key === 'fare'
          ? Object.values(value).every(v => v === 0 || v === "")
          : value === ""
      );
    if (passengerForm.every(isPassengerEmpty)) { return; }
    setValue('productDetails[0].product.passenger', passengerForm);
    setEditingPassengerForm(passengerForm);
    setIsError(prevState => ({ ...prevState, passenger: false }));
    calcfare(fareForm, passengerForm);
    calcCharge(chargeForm, passengerForm.length, taxValues);
    setpassengerFormEdit({ visible: false, editingIndex: null });
  };

  const handleDeleteP = (index) => {
    const updatedPassengers = passengerForm.filter((_, i) => i !== index);
    setValue('productDetails[0].product.passenger', updatedPassengers);
    setEditingPassengerForm(updatedPassengers);
    setPassengerForm(updatedPassengers);
    calcfare(fareForm, updatedPassengers);
    calcCharge(chargeForm, updatedPassengers.length, taxValues);
    setpassengerFormEdit({ visible: false, editingIndex: null });
  }

  const calcCharge = (updatedChargeForm, length, taxValues) => {
    if (taxValues.length === 0 && customerRefOrValue.name) {
      getTaxValues(null);
    }
    if (!length) { length = 0 };
    const charges = updatedChargeForm.map((charge) => {
      let totalValue;
      switch (charge.calculationMethod) {
        case "PerTicket":
          totalValue = charge.value;
          break;
        case "PerPassenger":
          const multi = length === 0 ? 1 : length;
          totalValue = multi * charge.value;
          break;
        default:
          totalValue = charge.value;
      }
      return {
        ...charge,
        totalValue,
      };
    });
    const chargesWithTax = charges.map((charge) => {
      const { tax, taxTotal } = calculateTaxForCharge(charge.totalValue, charge.tax, taxValues);
      return { ...charge, tax, taxTotal };
    });
    const cancelledPassengers = passengerForm.filter(passenger => passenger.status === 'Cancelled');
    const cancelledPAmount = cancelledPassengers.reduce((total, passenger) => {
      return total + (passenger.fare.baseFare + passenger.fare.comTax + passenger.fare.nonComTax + passenger.fare.markup + passenger.fare.k3);
    }, 0);
    const total = Math.abs(invAmount - cancelledPAmount - invChargeTotal);
    calcCredit(chargesWithTax, total, false);
    setChargeForm(chargesWithTax);
    setValue("productDetails[0].product.charges", chargesWithTax);
  };

  //carrier handle
  const handleCChange = (index, field, value) => {
    if (index === null) {
      index = editingCarrierForm.length;
    }
    setCarrierForm((prevCarrier) => {
      const updatedCarrier = [...prevCarrier];
      updatedCarrier[index] = {
        ...updatedCarrier[index],
        [field]: value
      };
      return updatedCarrier;
    });
  };
  const handleCarrierFormSave = (e) => {
    e.preventDefault();
    const filteredCarrierForm = carrierForm.filter(carrier => Object.values(carrier).some(field => field !== "")
    );
    if (filteredCarrierForm.length === 0) { return; }
    setValue('productDetails[0].product.carriers', carrierForm);
    setEditingCarrierForm(carrierForm);
    setIsError(prevState => ({ ...prevState, carrier: false }));
    setcarrierFormEdit({ visible: false, editingIndex: null });
    setSelectedFromLocation("");
    setSelectedToLocation("");
  };
  const handleDeleteC = (index) => {
    const updatedCarriers = carrierForm.filter((_, i) => i !== index);
    setValue('productDetails[0].product.carriers', updatedCarriers);
    setEditingCarrierForm(updatedCarriers);
    setCarrierForm(updatedCarriers);
    setcarrierFormEdit({ visible: false, editingIndex: null });
  }

  // fare handle
  const handleFChange = (index, field, value) => {
    setFareForm((prevFare) => {
      const updatedFare = [...prevFare];
      updatedFare[index] = {
        ...updatedFare[index],
        [field]: value
      };
      const baseFare = parseFloat(updatedFare[index].baseFare) || 0;
      const baseFareMarkup = parseFloat(updatedFare[index].baseFareMarkup) || 0;
      updatedFare[index].totalBaseFare = baseFare + baseFareMarkup;
      const nonComTax = parseFloat(updatedFare[index].nonComTax) || 0;
      const nonComTaxMarkup = parseFloat(updatedFare[index].nonComTaxMarkup) || 0;
      updatedFare[index].totalNonComTax = nonComTax + nonComTaxMarkup;
      const net = baseFare + baseFareMarkup + nonComTax + nonComTaxMarkup + (parseFloat(updatedFare[index].comTax) || 0);
      updatedFare[index].netAmount = net;
      return updatedFare;
    });
  };
  const handleFareFormSave = (e) => {
    e?.preventDefault();
    const isFormEmpty = fareForm.every(item => Object.values(item).every(value => value === 0 || value === "0" || value === "" || value == null));
    if (!isFormEmpty) {
      setValue('productDetails[0].product.fare', fareForm);
    }
    calcfare(fareForm, editingPassengerForm);
    setIsError(prevState => ({ ...prevState, fare: isFormEmpty }));
  };

  const calcfare = (fareForm, form) => {
    const length = form.length;
    if (length > 0) {
      const fare = fareForm[0];
      const toNumber = (value) => Number(value) || 0;
      const roundToTwoDecimals = (num) => {
        return Math.round(num * 100) / 100;
      };
      const farePerPassenger = {
        baseFare: roundToTwoDecimals(toNumber(fare?.baseFare) / length),
        baseFareMarkup: roundToTwoDecimals(toNumber(fare?.baseFareMarkup) / length),
        totalBaseFare: roundToTwoDecimals((toNumber(fare?.baseFare) + toNumber(fare?.baseFareMarkup)) / length),
        comTax: roundToTwoDecimals(toNumber(fare?.comTax) / length),
        nonComTax: roundToTwoDecimals(toNumber(fare?.nonComTax) / length),
        nonComTaxMarkup: roundToTwoDecimals(toNumber(fare?.nonComTaxMarkup) / length),
        totalNonComTax: roundToTwoDecimals((toNumber(fare?.nonComTax) + toNumber(fare?.nonComTaxMarkup)) / length),
        netAmount: roundToTwoDecimals((toNumber(fare?.totalBaseFare) + toNumber(fare?.totalNonComTax) + toNumber(fare?.comTax)) / length),
      };
      const updatedPassengerForm = form.map(passenger => ({
        ...passenger,
        fare: farePerPassenger
      }));
      setPassengerForm(updatedPassengerForm);
      setEditingPassengerForm(updatedPassengerForm);
      setValue('productDetails[0].product.passenger', updatedPassengerForm);
    }
  }
  const handleChargeChange = (index, field, value) => {
    if (index === null) {
      index = 0;
    }
    setChargeForm((prevCharges) => {
      const updatedCharges = [...prevCharges];
      updatedCharges[index] = {
        ...updatedCharges[index],
        [field]: value
      };
      return updatedCharges;
    });
  };
  const handleChargeFormSave = (e) => {
    e.preventDefault();
    setValue('productDetails[0].product.charges', chargeForm);
    calcCharge(chargeForm, editingPassengerForm.length, taxValues);
  };

  const updateValidationRules = (state) => {
    if (state === 'Submitted') {
      setValue('state', 'Submitted');
      setValue('productDetails[0].state', 'Submitted');
    } else {
      setValue('state', 'Draft');
      setValue('productDetails[0].state', 'Draft');
    }
    trigger();
  }

  const [formState, setFormState] = useState('Draft');

  const onSave = () => {
    setFormState('Draft');
    updateValidationRules('Draft');
    const data = getValues();
    setIsError(prevState => ({ ...prevState, airline: false, passenger: false, carrier: false, fare: false, supplier: false }))
    const newErrors = {};
    if (!data.invoiceRefOrValue?.invoiceId) {
      newErrors.invoice = true;
    }
    if (!data.customerRefOrValue.id) {
      newErrors.customer = true;
    }
    if (Object.keys(newErrors).length > 0) {
      setIsError(prevState => ({ ...prevState, ...newErrors }));
      return;
    }
  }

  const onCreate = (state) => {
    setFormState('Submitted');
    updateValidationRules('Submitted');
    const data = getValues();
    if (state) {
      setValue('state', state);
      setValue('productDetails[0].state', state);
    }
    const newErrors = {};
    if (!data.invoiceRefOrValue?.invoiceId) {
      newErrors.invoice = true;
    }
    if (!data.customerRefOrValue.id) {
      newErrors.customer = true;
    }
    if (!data.productDetails[0]?.product.airline) {
      newErrors.airline = true;
    }
    if (!data.productDetails[0]?.product.agentRefOrValue?.name) {
      newErrors.supplier = true;
    }
    if (Object.keys(newErrors).length > 0) {
      setIsError(prevState => ({ ...prevState, ...newErrors }));
      return;
    }
    const passengers = data.productDetails[0]?.product?.passenger || [];
    if (passengers.length === 0 || !passengers[0].firstName) {
      setIsError(prevState => ({ ...prevState, passenger: true }));
      setpassengerFormEdit({ visible: true, editingIndex: null })
      toast.error("Passenger is required");
      return;
    }
    const carriers = data.productDetails[0]?.product?.carriers || [];
    if (carriers.length === 0 || !carriers[0].carrier) {
      setIsError(prevState => ({ ...prevState, carrier: true }));
      setcarrierFormEdit({ visible: true, editingIndex: null })
      toast.error("Carrier is required");
      return;
    }
    const fares = data.productDetails[0]?.product?.fare || [];
    if (fares.length === 0 || !fares[0].baseFare) {
      setIsError(prevState => ({ ...prevState, fare: true }));
      toast.error("Fare is required");
      return;
    }
  }

  const onSubmit = (data) => {
    data.productDetails[0].creditNoteNetAmount = creditAmount;
    data.creditNoteNetAmount = creditAmount;
    if (data.creditNoteId) {
      handleEditCN(data);
    } else {
      handleCreateCN(data);
    }
  };

  const handleCreateCN = async (data) => {
    const { id, ...requestData } = data;
    if (Object.values(isError).some(error => error)) {
      return;
    }
    setLoading("Create");
    try {
      const response = await fetch(`${(process.env.REACT_APP_API_HOST_INV === undefined) ? "" : process.env.REACT_APP_API_HOST_INV}/amigo-lekha-api/creditNoteManagement/v1/creditNote`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(requestData),
      });
      if (!response.ok) {
        throw new Error('Failed to create Credit Note');
      }
      const res = await response.json();
      const id = encodeURIComponent(res.creditNoteId);
      reset();
      setEditingPassengerForm([]);
      setEditingCarrierForm([]);
      toast.success("Credit Note created successfuly!", { duration: "5000", position: "top-right", description: `Credit Note Id - ${id}` });
      if (res.state === "Draft") {
        navigate(`/dashboard/credit-note/airline/edit/${id}`, { state: { item: res } });
      } else {
        navigate(`/dashboard/credit-note/airline`);
      }
    } catch (error) {
      console.error("Error creating Credit Note:", error);
      toast.error("Failed to create Credit Note");
    } finally {
      setLoading("");
    }
  };

  const handleEditCN = async (data) => {
    if (Object.values(isError).some(error => error)) {
      return;
    }
    if (data.state === "Draft") {
      setLoading("Edit");
    } else {
      setLoading("Create");
    }
    try {
      const response = await fetch(`${(process.env.REACT_APP_API_HOST_INV === undefined) ? "" : process.env.REACT_APP_API_HOST_INV}/amigo-lekha-api/creditNoteManagement/v1/creditNote/${data.creditNoteId}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(data),
      });
      if (!response.ok) {
        throw new Error('Failed to edit Credit Note');
      }
      const res = await response.json();
      const id = encodeURIComponent(res.creditNoteId);
      toast.success(`Credit Note ${res.state === "Draft" ? "Edited" : "Submitted"} Successfuly!`, { duration: "5000", position: "top-right", description: `Credit Note Id - ${id}` });
      reset(res);
      if (res.state === "Draft") {
        navigate(`/dashboard/credit-note/airline/edit/${id}`, { state: { item: res } });
      } else {
        navigate(`/dashboard/credit-note/airline`);
      }
    } catch (error) {
      console.error("Error editing Credit Note:", error);
      toast.error("Failed to edit Credit Note");
    } finally {
      setLoading("");
    }
  };

  const calcCredit = (charges, netAmount, invoice) => {
    let totalValue = 0;
    let taxTotal = 0;
    if (Array.isArray(charges)) {
      charges.forEach(chargeItem => {
        totalValue += parseFloat(chargeItem.totalValue) || 0;
        taxTotal += parseFloat(chargeItem.taxTotal) || 0;
      });
    }
    if(invoice){setInvChargeTotal(totalValue + taxTotal);}
    const credit = netAmount - (totalValue + taxTotal);
    setCreditAmount(credit);
    setValue("productDetails[0].creditNoteNetAmount", credit);
    setValue("creditNoteNetAmount", credit);
    return credit;
  }
  return (
    <div className='mx-8 my-5'>
      <Link to={`/dashboard/credit-note/airline`} className='absolute mt-1 ml-5'><Button variant="outline" size="icon"><Undo2 className='size-4' /></Button></Link>
      <div className="flex items-center justify-center w-full mb-5 text-[3vh] font-bold">{id ? "Edit" : "New"} Credit Note</div>
      {/* <Card className="pt-4 mx-5">
      <CardContent> */}
      {creditNoteId && <div className='flex mx-5 my-0'><div>Credit Note Id - <span className='text-primary/70'>{creditNoteId}</span></div><div className='ml-auto mr-2'>Credit Note NetAmount : <span className='font-bold'>₹{data?.creditNoteNetAmount}</span>&ensp;|</div><div>Created By : <span className='font-bold'>{data?.createdBy}</span></div></div>}
      <Separator />
      <form onSubmit={handleSubmit(onSubmit)} className='mt-2'>
        <ScrollArea className="h-[calc(100vh-200px)] lg:h-[70vh]">
          <div className="grid gap-4 px-5 py-2">
            <div className="grid grid-cols-1 gap-4 md:grid-cols-2 lg:grid-cols-5">
              <div className="flex flex-row items-center gap-2">
                <Label className="flex w-40">Credit Date{errors.creditNoteDate && <p className="ml-1 text-destructive">*</p>}</Label>
                <Controller control={control} name="creditNoteDate" rules={{ required: true }} {...register("creditNoteDate")}
                  render={({ field }) => (
                    <DatePicker value={field.value} onChange={(date) => field.onChange(date)} disableDate={false} current={true} error={errors.creditNoteDate} />
                  )}
                />
              </div>
              <div className="flex flex-row items-center gap-2">
                <Label className="flex w-32 lg:w-44">Invoice Id{isError.invoice && <p className="ml-1 text-destructive">*</p>}</Label>
                <InvoiceSelect onInvoiceSelect={handleInvoiceSelect} value={invoiceRefOrValue?.invoiceId} isVisible={isVisible} isError={isError.invoice} subType={true}/>
              </div>
              <div className="flex flex-row items-center gap-2">
                <Label className="flex w-32 lg:w-44">Airline PNR{isError.invoice && <p className="ml-1 text-destructive">*</p>}</Label>
                <PNRSelect onPNRSelect={handleInvoiceSelect} value={airlinePNR} isVisible={isVisible} isError={isError.invoice}/>
              </div>
              <div className="flex gap-2">
                <div className="flex flex-row items-center gap-2">
                  <Label className="flex w-32">Customer Name{isError.customer && <p className="ml-1 text-destructive">*</p>}</Label>
                  <SearchCustomer onCustomerSelect={handleCustomerSelect} value={customerRefOrValue.id} isVisible={isVisible} isError={isError.customer} disable={true} />
                </div>
                <Button variant='secondary' className="w-20 h-7" onClick={(e) => { setCustomerForm(!customerForm); e.preventDefault() }}>{customerForm ? "Hide" : "More"} Info</Button>
              </div>
            </div>
            {customerForm && (
              <div className="grid grid-cols-1 gap-2 md:grid-cols-2 lg:grid-cols-4">
                <div className="flex flex-row items-center gap-2">
                  <Label className="w-40">Phone No</Label>
                  <Input className="h-7 bg-muted/50" disabled={true} name="phone" value={customerRefOrValue.phone} {...register("customerRefOrValue.phone")} />
                </div>
                <div className="flex flex-row items-center gap-2">
                  <Label className="w-40">Email</Label>
                  <Input className="h-7 bg-muted/50" disabled={true} name="email" value={customerRefOrValue.email} {...register("customerRefOrValue.email")} />
                </div>
                <div className="flex flex-row items-center gap-2">
                  <Label className="w-40">Print In Name</Label>
                  <Input className="h-7 bg-muted/50" disabled={true} name="printInName" {...register("printInName")} />
                </div>
                <div className="flex flex-row items-center gap-2">
                  <Label className="w-36">Address</Label>
                  <Textarea className="h-16 bg-muted/50" disabled={true} name="address" value={customerRefOrValue.address} {...register("customerRefOrValue.address")} />
                </div>
                <div className="flex flex-row items-center gap-2">
                  <Label className="w-40">PAN No</Label>
                  <Input className="h-7 bg-muted/50" disabled={true} name="panNo" value={customerRefOrValue.panNo} {...register("customerRefOrValue.panNo")} />
                </div>
                <div className="flex flex-row items-center gap-2">
                  <Label className="w-40">GST Number</Label>
                  <Input className="h-7 bg-muted/50" disabled={true} name="gstNo" value={customerRefOrValue.gstNo} {...register("customerRefOrValue.gstNo")} />
                </div>
              </div>
            )}
            <div className="grid grid-cols-1 gap-x-4 gap-y-2 md:grid-cols-2 lg:grid-cols-5">
              <div className="flex flex-row items-center gap-2">
                <Label className="w-40">Due Date</Label>
                <Controller control={control} name="dueDate" {...register("dueDate")}
                  render={({ field }) => (
                    <DatePicker value={field.value} onChange={(date) => field.onChange(date)} disableDate={false} />
                  )}
                />
              </div>
              <div className="flex flex-row items-center gap-2">
                <Label className="flex w-40 lg:w-44">Supplier Name{isError.supplier && <p className="ml-1 text-destructive">*</p>}</Label>
                <SupplierSelect onSupplierSelect={handleSupplierSelect} value={supplierName.id} isVisible={isVisible} isError={isError.supplier} />
              </div>
              <div className="flex flex-row items-center gap-2">
                <Label className="flex w-40">Amount{errors.productDetails?.[0]?.supplierNetAmount && <p className="ml-1 text-destructive">*</p>}</Label>
                <Input className={`h-7 bg-muted/50 ${errors.productDetails?.[0]?.supplierNetAmount ? 'border-destructive focus-visible:ring-0' : ''}`} name="totalValue" {...register("productDetails[0].supplierNetAmount", { required: formState === "Submitted" })} />
              </div>
            </div>
            <Separator />
            <CardDescription>Product Details :</CardDescription>
            <div className="grid grid-cols-1 gap-x-4 gap-y-2 md:grid-cols-2 lg:grid-cols-4">
              <div className="flex flex-row items-center gap-2">
                <Label className="flex w-40 lg:w-48">GDS{errors.productDetails?.[0]?.product?.gds && <p className="ml-1 text-destructive">*</p>}</Label>
                <Input className={`h-7 bg-muted/50 ${errors.productDetails?.[0]?.product?.gds ? 'border-destructive focus-visible:ring-0' : ''}`} name="gds" {...register("productDetails[0].product.gds", { required: formState === "Submitted" })} />
              </div>
              <div className="flex flex-row items-center gap-2">
                <Label className="flex w-40 lg:w-44">GDS PNR{errors.productDetails?.[0]?.product?.gdsNo && <p className="ml-1 text-destructive">*</p>}</Label>
                <Input className={`h-7 bg-muted/50 ${errors.productDetails?.[0]?.product?.gdsNo ? 'border-destructive focus-visible:ring-0' : ''}`} name="phone" {...register("productDetails[0].product.gdsNo", { required: formState === "Submitted" })} />
              </div>
              <div className="flex flex-row items-center gap-2">
                <Label className="flex w-40 lg:w-44">Airline Name{isError.airline && <p className="ml-1 text-destructive">*</p>}</Label>
                <AirlineSelect onAirlineSelect={handleAirlineSelect} type="airline" value={airlineName} isVisible={isVisible} isError={isError.airline} />
              </div>
              <div className="flex flex-row items-center gap-2">
                <Label className="flex w-40 lg:w-48">Cancellation Date{errors.productDetails?.[0]?.product?.cancellationDate && <p className="ml-1 text-destructive">*</p>}</Label>
                <Controller control={control} name="productDetails[0].product.cancellationDate" rules={{ required: formState === "Submitted" }} {...register("productDetails[0].product.cancellationDate", { required: formState === "Submitted" })}
                  render={({ field }) => (
                    <DatePicker value={field.value} onChange={(date) => field.onChange(date)} error={errors.productDetails?.[0]?.product?.cancellationDate} current={true} />
                  )}
                />
              </div>
              <div className="flex flex-row items-center gap-2">
                <Label className="flex w-40 lg:w-44">Cancellation By{errors.productDetails?.[0]?.product?.customerCancellationBy && <p className="ml-1 text-destructive">*</p>}</Label>
                <Input className={`h-7 bg-muted/50 ${errors.productDetails?.[0]?.product?.customerCancellationBy ? 'border-destructive focus-visible:ring-0' : ''}`} name="customerCancellationBy" {...register("productDetails[0].product.customerCancellationBy", { required: formState === "Submitted" })} />
              </div>
              <div className="flex flex-row items-center gap-2">
                <Label className="flex w-40 lg:w-44">Cancellation No{errors.productDetails?.[0]?.product?.customerCancellationNo && <p className="ml-1 text-destructive">*</p>}</Label>
                <Input className={`h-7 bg-muted/50 ${errors.productDetails?.[0]?.product?.customerCancellationNo ? 'border-destructive focus-visible:ring-0' : ''}`} name="customerCancellationNo" {...register("productDetails[0].product.customerCancellationNo", { required: formState === "Submitted" })} />

              </div>
              <div className="flex flex-row items-center gap-2">
                <Label className="flex w-40 lg:w-56">Customer Cancellation Date{errors.productDetails?.[0]?.product?.customerCancellationDate && <p className="ml-1 text-destructive">*</p>}</Label>
                <Controller control={control} name="productDetails[0].product.customerCancellationDate" rules={{ required: formState === "Submitted" }} {...register("productDetails[0].product.customerCancellationDate", { required: formState === "Submitted" })}
                  render={({ field }) => (
                    <DatePicker value={field.value} onChange={(date) => field.onChange(date)} error={errors.productDetails?.[0]?.product?.customerCancellationDate} />
                  )}
                />
              </div>
            </div>
            <Separator />
            <div className="flex flex-row items-center justify-between gap-4 mr-2">
              <CardDescription>Passenger details : (checked passengers : cancelled)</CardDescription>
              {/* <Button size='sm' variant="secondary" onClick={(e) => { setpassengerFormEdit(prevState => ({ ...prevState, visible: !prevState.visible, editingIndex: null })); e.preventDefault(); }}>Add Passenger <CirclePlus className="ml-2 size-4" /></Button> */}
            </div>
            <Card>
              <CardContent className="p-0">
                <Table>
                  <TableHeader className="bg-secondary/60">
                    <TableRow>
                      <TableHead className="rounded-tl-md"></TableHead>
                      <TableHead></TableHead>
                      <TableHead>Name</TableHead>
                      <TableHead className="hidden md:table-cell">Pax Type</TableHead>
                      <TableHead>Ticket No</TableHead>
                      <TableHead>Base Fare</TableHead>
                      <TableHead>Com Tax</TableHead>
                      <TableHead>NonCom Tax</TableHead>
                      <TableHead className="rounded-tr-md"><span className="sr-only">Actions</span></TableHead>
                    </TableRow>
                  </TableHeader>
                  <TableBody>
                    {editingPassengerForm.length > 0 ? (
                      editingPassengerForm.map((passenger, index) => (
                        <React.Fragment key={index}>
                          <TableRow>
                            <TableCell></TableCell>
                            <TableCell><div className="flex">
                              <Checkbox checked={passenger.status === "Cancelled"} onCheckedChange={(checked) => {
                                const newStatus = checked ? "Cancelled" : "Active";
                                handlePChange(index, "status", newStatus);
                              }}
                              // onBlur={handlePassengerFormSave} 
                              />
                            </div></TableCell>
                            <TableCell>{passenger.title} {passenger.firstName} {passenger.lastName}</TableCell>
                            <TableCell className="hidden md:table-cell">{passenger.paxType}</TableCell>
                            <TableCell>{passenger.ticketNo}</TableCell>
                            <TableCell>{passenger.fare?.baseFare}</TableCell>
                            <TableCell>{passenger.fare?.comTax}</TableCell>
                            <TableCell>{passenger.fare?.nonComTax}</TableCell>
                            <TableCell>
                              {/* <div className="flex">
                                <Checkbox />
                              </div> */}
                              {/* <div className="flex">
                                <Edit onClick={() => setpassengerFormEdit(prevState => ({ ...prevState, visible: !prevState.visible, editingIndex: index }))} className="cursor-pointer text-primary size-4" />
                                <Trash2 onClick={() => handleDeleteP(index)} className="cursor-pointer text-destructive size-4" />
                              </div> */}
                            </TableCell>
                          </TableRow>
                          {passengerFormEdit.visible && passengerFormEdit.editingIndex === index && (
                            <TableRow>
                              <TableCell colSpan={11}>
                                <div className="grid gap-3 mx-6 my-4 lg:grid-cols-4 sm:grid-cols-1 md:grid-cols-2">
                                  <div className="flex flex-row items-center gap-2">
                                    <Label className="flex w-40">Title{isError.passenger && <p className="ml-1 text-destructive">*</p>}</Label>
                                    <Select name="title" defaultValue={passengerFormEdit.editingIndex !== null ? passengerForm[passengerFormEdit.editingIndex]?.title || '' : ''}
                                      onValueChange={(e) => handlePChange(passengerFormEdit.editingIndex, 'title', e)}>
                                      <SelectTrigger className="w-full h-7 bg-muted/50 focus:ring-transparent">
                                        <SelectValue />
                                      </SelectTrigger>
                                      <SelectContent>
                                        <SelectGroup>
                                          {titleItems.map(item => (
                                            <SelectItem key={item} value={item}>
                                              {item}
                                            </SelectItem>
                                          ))}
                                        </SelectGroup>
                                      </SelectContent>
                                    </Select>
                                  </div>
                                  <div className="flex flex-row items-center gap-2">
                                    <Label className="flex w-40">First Name{isError.passenger && <p className="ml-1 text-destructive">*</p>}</Label>
                                    <Input className="h-7 bg-muted/50" name="firstName" defaultValue={passengerFormEdit.editingIndex !== null ? passengerForm[passengerFormEdit.editingIndex]?.firstName || '' : ''}
                                      onChange={(e) => handlePChange(passengerFormEdit.editingIndex, 'firstName', e.target.value)} />
                                  </div>
                                  <div className="flex flex-row items-center gap-2">
                                    <Label className="flex w-40">Last Name{isError.passenger && <p className="ml-1 text-destructive">*</p>}</Label>
                                    <Input className="h-7 bg-muted/50" name="lastName" defaultValue={passengerFormEdit.editingIndex !== null ? passengerForm[passengerFormEdit.editingIndex]?.lastName || '' : ''}
                                      onChange={(e) => handlePChange(passengerFormEdit.editingIndex, 'lastName', e.target.value)} />
                                  </div>
                                  <div className="flex flex-row items-center gap-2">
                                    <Label className="flex w-40">Pax Type{isError.passenger && <p className="ml-1 text-destructive">*</p>}</Label>
                                    <Select name="paxType" defaultValue={passengerFormEdit.editingIndex !== null ? passengerForm[passengerFormEdit.editingIndex]?.paxType || '' : ''}
                                      onValueChange={(e) => handlePChange(passengerFormEdit.editingIndex, 'paxType', e)}>
                                      <SelectTrigger className="w-full h-7 bg-muted/50 focus:ring-transparent">
                                        <SelectValue />
                                      </SelectTrigger>
                                      <SelectContent>
                                        <SelectGroup>
                                          {paxTypeItems.map(item => (
                                            <SelectItem key={item} value={item}>
                                              {item}
                                            </SelectItem>
                                          ))}
                                        </SelectGroup>
                                      </SelectContent>
                                    </Select>
                                  </div>
                                  <div className="flex flex-row items-center gap-2">
                                    <Label className="flex w-40">Ticket No{isError.passenger && <p className="ml-1 text-destructive">*</p>}</Label>
                                    <Input className="h-7 bg-muted/50" name="ticketNo" defaultValue={passengerFormEdit.editingIndex !== null ? passengerForm[passengerFormEdit.editingIndex]?.ticketNo : ''}
                                      onChange={(e) => handlePChange(passengerFormEdit.editingIndex, 'ticketNo', e.target.value)} />
                                  </div>
                                  <div className="flex flex-row items-center gap-2">
                                    <Label className="flex w-40">BaseFare</Label>
                                    <Input type="number" disabled className="h-7 bg-muted/50" name="baseFare" defaultValue={passengerFormEdit.editingIndex !== null ? passengerForm[passengerFormEdit.editingIndex]?.fare?.baseFare : 0} />
                                  </div>
                                  <div className="flex flex-row items-center gap-2">
                                    <Label className="flex w-40">BaseFare Markup</Label>
                                    <Input type="number" disabled className="h-7 bg-muted/50" name="baseFareMarkup" defaultValue={passengerFormEdit.editingIndex !== null ? passengerForm[passengerFormEdit.editingIndex]?.fare?.baseFareMarkup : 0} />
                                  </div>
                                  <div className="flex flex-row items-center gap-2">
                                    <Label className="flex w-40">Com Tax</Label>
                                    <Input type="number" disabled className="h-7 bg-muted/50" name="comTax" defaultValue={passengerFormEdit.editingIndex !== null ? passengerForm[passengerFormEdit.editingIndex]?.fare?.comTax : 0} />
                                  </div>
                                  <div className="flex flex-row items-center gap-2">
                                    <Label className="flex w-40">NonCom Tax</Label>
                                    <Input type="number" disabled className="h-7 bg-muted/50" name="nonComTax" defaultValue={passengerFormEdit.editingIndex !== null ? passengerForm[passengerFormEdit.editingIndex]?.fare?.nonComTax : 0} />
                                  </div>
                                  <div className="flex flex-row items-center gap-2">
                                    <Label className="flex w-40">NonCom Tax Markup</Label>
                                    <Input type="number" disabled className="h-7 bg-muted/50" name="nonComTaxMarkup" defaultValue={passengerFormEdit.editingIndex !== null ? passengerForm[passengerFormEdit.editingIndex]?.fare?.nonComTaxMarkup : 0} />
                                  </div>
                                </div>
                                <div className="flex flex-row col-span-4 mb-4 mr-6">
                                  <Button variant="outline" className="ml-auto mr-3 bg-primary/30 hover:bg-primary/40" onClick={handlePassengerFormSave}>{passengerFormEdit.editingIndex !== null ? "Save" : "Add"}</Button>
                                  <Button variant="outline" className="bg-primary/30 hover:bg-primary/40" onClick={() => setpassengerFormEdit({ visible: false, editingIndex: null })}>Close</Button>
                                </div>
                              </TableCell>
                            </TableRow>
                          )}
                        </React.Fragment>
                      ))
                    ) : (
                      <TableRow>
                        <TableCell colSpan={10} className="text-center">No Passenger</TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
                {passengerFormEdit.visible && passengerFormEdit.editingIndex === null && (
                  <>
                    <Separator />
                    <div className="grid gap-3 mx-6 my-4 lg:grid-cols-4 sm:grid-cols-1 md:grid-cols-2">
                      <div className="flex flex-row items-center gap-2">
                        <Label className="flex w-40">Title{isError.passenger && <p className="ml-1 text-destructive">*</p>}</Label>
                        <Select name="title" defaultValue={passengerFormEdit.editingIndex !== null ? passengerForm[passengerFormEdit.editingIndex]?.title || '' : ''}
                          onValueChange={(e) => handlePChange(passengerFormEdit.editingIndex, 'title', e)}>
                          <SelectTrigger className="w-full h-7 bg-muted/50 focus:ring-transparent">
                            <SelectValue />
                          </SelectTrigger>
                          <SelectContent>
                            <SelectGroup>
                              {titleItems.map(item => (
                                <SelectItem key={item} value={item}>
                                  {item}
                                </SelectItem>
                              ))}
                            </SelectGroup>
                          </SelectContent>
                        </Select>
                      </div>
                      <div className="flex flex-row items-center gap-2">
                        <Label className="flex w-40">First Name{isError.passenger && <p className="ml-1 text-destructive">*</p>}</Label>
                        <Input className="h-7 bg-muted/50" name="firstName" defaultValue={passengerFormEdit.editingIndex !== null ? passengerForm[passengerFormEdit.editingIndex]?.firstName || '' : ''}
                          onChange={(e) => handlePChange(passengerFormEdit.editingIndex, 'firstName', e.target.value)} />
                      </div>
                      <div className="flex flex-row items-center gap-2">
                        <Label className="flex w-40">Last Name{isError.passenger && <p className="ml-1 text-destructive">*</p>}</Label>
                        <Input className="h-7 bg-muted/50" name="lastName" defaultValue={passengerFormEdit.editingIndex !== null ? passengerForm[passengerFormEdit.editingIndex]?.lastName || '' : ''}
                          onChange={(e) => handlePChange(passengerFormEdit.editingIndex, 'lastName', e.target.value)} />
                      </div>
                      <div className="flex flex-row items-center gap-2">
                        <Label className="flex w-40">Pax Type{isError.passenger && <p className="ml-1 text-destructive">*</p>}</Label>
                        <Select name="paxType" defaultValue={passengerFormEdit.editingIndex !== null ? passengerForm[passengerFormEdit.editingIndex]?.paxType || '' : ''}
                          onValueChange={(e) => handlePChange(passengerFormEdit.editingIndex, 'paxType', e)}>
                          <SelectTrigger className="w-full h-7 bg-muted/50 focus:ring-transparent">
                            <SelectValue />
                          </SelectTrigger>
                          <SelectContent>
                            <SelectGroup>
                              {paxTypeItems.map(item => (
                                <SelectItem key={item} value={item}>
                                  {item}
                                </SelectItem>
                              ))}
                            </SelectGroup>
                          </SelectContent>
                        </Select>
                      </div>
                      <div className="flex flex-row items-center gap-2">
                        <Label className="flex w-40">Ticket No{isError.passenger && <p className="ml-1 text-destructive">*</p>}</Label>
                        <Input className="h-7 bg-muted/50" name="ticketNo" defaultValue={passengerFormEdit.editingIndex !== null ? passengerForm[passengerFormEdit.editingIndex]?.ticketNo : ''}
                          onChange={(e) => handlePChange(passengerFormEdit.editingIndex, 'ticketNo', e.target.value)} />
                      </div>
                      <div className="flex flex-row items-center gap-2">
                        <Label className="flex w-40">BaseFare</Label>
                        <Input type="number" disabled className="h-7 bg-muted/50" name="baseFare" defaultValue={passengerFormEdit.editingIndex !== null ? passengerForm[passengerFormEdit.editingIndex]?.fare?.baseFare : 0} />
                      </div>
                      <div className="flex flex-row items-center gap-2">
                        <Label className="flex w-40">BaseFare Markup</Label>
                        <Input type="number" disabled className="h-7 bg-muted/50" name="baseFareMarkup" defaultValue={passengerFormEdit.editingIndex !== null ? passengerForm[passengerFormEdit.editingIndex]?.fare?.baseFareMarkup : 0} />
                      </div>
                      <div className="flex flex-row items-center gap-2">
                        <Label className="flex w-40">Com Tax</Label>
                        <Input type="number" disabled className="h-7 bg-muted/50" name="comTax" defaultValue={passengerFormEdit.editingIndex !== null ? passengerForm[passengerFormEdit.editingIndex]?.fare?.comTax : 0} />
                      </div>
                      <div className="flex flex-row items-center gap-2">
                        <Label className="flex w-40">NonCom Tax</Label>
                        <Input type="number" disabled className="h-7 bg-muted/50" name="nonComTax" defaultValue={passengerFormEdit.editingIndex !== null ? passengerForm[passengerFormEdit.editingIndex]?.fare?.nonComTax : 0} />
                      </div>
                      <div className="flex flex-row items-center gap-2">
                        <Label className="flex w-40">NonCom Tax Markup</Label>
                        <Input type="number" disabled className="h-7 bg-muted/50" name="nonComTaxMarkup" defaultValue={passengerFormEdit.editingIndex !== null ? passengerForm[passengerFormEdit.editingIndex]?.fare?.nonComTaxMarkup : 0} />
                      </div>
                    </div>
                    <div className="flex flex-row col-span-4 mb-4 mr-6">
                      <Button variant="outline" className="ml-auto mr-3 bg-primary/30 hover:bg-primary/40" onClick={handlePassengerFormSave}>{passengerFormEdit.editingIndex !== null ? "Save" : "Add"}</Button>
                      <Button variant="outline" className="bg-primary/30 hover:bg-primary/40" onClick={() => setpassengerFormEdit({ visible: false, editingIndex: null })}>Close</Button>
                    </div>
                  </>
                )}
              </CardContent>
            </Card>
            <Separator />
            <div className="flex flex-row items-center justify-between gap-4 mr-2">
              <CardDescription>Carrier details :</CardDescription>
              {/* <Button size='sm' variant="secondary" onClick={(e) => { setcarrierFormEdit(prevState => ({ ...prevState, visible: !prevState.visible, editingIndex: null })); e.preventDefault(); }}>Add carrier <CirclePlus className="ml-2 size-4" /></Button> */}
            </div>
            <Card>
              <CardContent className="p-0">
                <Table>
                  <TableHeader className="bg-secondary/60">
                    <TableRow>
                      <TableHead className="rounded-tl-md"></TableHead>
                      <TableHead></TableHead>
                      <TableHead className="hidden md:table-cell">Carrier</TableHead>
                      <TableHead>From Location</TableHead>
                      <TableHead>To Location</TableHead>
                      <TableHead>Class</TableHead>
                      <TableHead>Departure / Arrival</TableHead>
                      <TableHead className="rounded-tr-md"><span className="sr-only">Actions</span></TableHead>
                    </TableRow>
                  </TableHeader>
                  <TableBody>
                    {editingCarrierForm.length > 0 ? (
                      editingCarrierForm.map((carriers, index) => (
                        <React.Fragment key={index}>
                          <TableRow>
                            <TableCell></TableCell>
                            <TableCell>{index + 1}</TableCell>
                            <TableCell className="hidden md:table-cell">{carriers.carrier}</TableCell>
                            <TableCell>{carriers.fromLocation}</TableCell>
                            <TableCell>{carriers.toLocation}</TableCell>
                            <TableCell>{carriers.airlineClass}</TableCell>
                            <TableCell>{carriers.departureDate ? new Date(carriers.departureDate).toLocaleDateString() : ""} - {carriers.arrivalDate ? new Date(carriers.arrivalDate).toLocaleDateString() : ""}</TableCell>
                            <TableCell>
                              {/* <div className="flex flex-row items-center gap-4">
                                <Edit onClick={() => setcarrierFormEdit(prevState => ({ ...prevState, visible: !prevState.visible, editingIndex: index }))} className="cursor-pointer text-primary size-4" />
                                <Trash2 onClick={() => handleDeleteC(index)} className="cursor-pointer text-destructive size-4" />
                              </div> */}
                            </TableCell>
                          </TableRow>
                          {carrierFormEdit.visible && carrierFormEdit.editingIndex === index && (
                            <TableRow>
                              <TableCell colSpan={11}>
                                <div className="grid grid-cols-1 gap-3 mx-6 my-4 md:grid-cols-2 lg:grid-cols-4">
                                  <div className="flex flex-row items-center gap-2">
                                    <Label className="flex w-40">Flight No.{isError.carrier && <p className="ml-1 text-destructive">*</p>}</Label>
                                    <CarrierSelect onFlightSelect={(flight) => handleFlightSelect(flight, carrierFormEdit.editingIndex)} value={carrierFormEdit.editingIndex !== null ? carrierForm[carrierFormEdit.editingIndex].carrier : ''}
                                      isVisible={true} isError={isError.airline} />
                                  </div>
                                  <div className="flex flex-row items-center gap-2">
                                    <Label className="flex w-40">From Location{isError.carrier && <p className="ml-1 text-destructive">*</p>}</Label>
                                    <Input className="h-7 bg-muted/50" name="fromLocation" value={selectedFromLocation || carrierForm[carrierFormEdit.editingIndex]?.fromLocation} placeholder="select flight no."
                                      onChange={(e) => handleCChange(carrierFormEdit.editingIndex, 'fromLocation', e.target.value)} readOnly />
                                  </div>
                                  <div className="flex flex-row items-center gap-2">
                                    <Label className="flex w-40">To Location{isError.carrier && <p className="ml-1 text-destructive">*</p>}</Label>
                                    <Input className="h-7 bg-muted/50" name="toLocation" value={selectedToLocation || carrierForm[carrierFormEdit.editingIndex]?.toLocation} placeholder="select flight no."
                                      onChange={(e) => handleCChange(carrierFormEdit.editingIndex, 'toLocation', e.target.value)} readOnly />
                                  </div>
                                  <div className="flex flex-row items-center gap-2">
                                    <Label className="flex w-40 lg:w-32">Class{isError.carrier && <p className="ml-1 text-destructive">*</p>}</Label>
                                    <Select name="airlineClass" defaultValue={carrierFormEdit.editingIndex !== null ? carrierForm[carrierFormEdit.editingIndex].airlineClass : ''}
                                      onValueChange={(e) => handleCChange(carrierFormEdit.editingIndex, 'airlineClass', e)}>
                                      <SelectTrigger className="w-full h-7 bg-muted/50 focus:ring-transparent">
                                        <SelectValue />
                                      </SelectTrigger>
                                      <SelectContent>
                                        <SelectGroup>
                                          {classItems.map(item => (
                                            <SelectItem key={item} value={item}>
                                              {item}
                                            </SelectItem>
                                          ))}
                                        </SelectGroup>
                                      </SelectContent>
                                    </Select>
                                  </div>
                                  <div className="flex flex-row items-center gap-2">
                                    <Label className="flex w-40">Departure Date{isError.carrier && <p className="ml-1 text-destructive">*</p>}</Label>
                                    <DatePicker className="h-7 bg-muted/50" value={carrierFormEdit.editingIndex !== null ? carrierForm[carrierFormEdit.editingIndex].departureDate : ''}
                                      onChange={(date) => handleCChange(carrierFormEdit.editingIndex, 'departureDate', date)} />
                                  </div>
                                </div>
                                <div className="flex flex-row col-span-4 mb-4 mr-6">
                                  <Button variant="outline" onClick={handleCarrierFormSave} className="ml-auto mr-3 bg-primary/30 hover:bg-primary/40">{carrierFormEdit.editingIndex !== null ? "Save" : "Add"}</Button>
                                  <Button variant="outline" className="bg-primary/30 hover:bg-primary/40" onClick={() => setcarrierFormEdit({ visible: false, editingIndex: null })}>Close</Button>
                                </div>
                              </TableCell>
                            </TableRow>
                          )}
                        </React.Fragment>
                      ))
                    ) : (
                      <TableRow>
                        <TableCell colSpan={7} className="text-center">No Carrier</TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
                {carrierFormEdit.visible && carrierFormEdit.editingIndex === null && (
                  <>
                    <Separator />
                    <div className="grid grid-cols-1 gap-3 mx-6 my-4 md:grid-cols-2 lg:grid-cols-4">
                      <div className="flex flex-row items-center gap-2">
                        <Label className="flex w-40">Flight No.{isError.carrier && <p className="ml-1 text-destructive">*</p>}</Label>
                        <CarrierSelect onFlightSelect={(flight) => handleFlightSelect(flight, carrierFormEdit.editingIndex)} value={carrierFormEdit.editingIndex !== null ? carrierForm[carrierFormEdit.editingIndex].carrier : ''}
                          isVisible={true} isError={isError.airline} />
                      </div>
                      <div className="flex flex-row items-center gap-2">
                        <Label className="flex w-40">From Location{isError.carrier && <p className="ml-1 text-destructive">*</p>}</Label>
                        <Input className="h-7 bg-muted/50" name="fromLocation" value={selectedFromLocation || carrierForm[carrierFormEdit.editingIndex]?.fromLocation} placeholder="select flight no."
                          onChange={(e) => handleCChange(carrierFormEdit.editingIndex, 'fromLocation', e.target.value)} readOnly />
                      </div>
                      <div className="flex flex-row items-center gap-2">
                        <Label className="flex w-40">To Location{isError.carrier && <p className="ml-1 text-destructive">*</p>}</Label>
                        <Input className="h-7 bg-muted/50" name="toLocation" value={selectedToLocation || carrierForm[carrierFormEdit.editingIndex]?.toLocation} placeholder="select flight no."
                          onChange={(e) => handleCChange(carrierFormEdit.editingIndex, 'toLocation', e.target.value)} readOnly />
                      </div>
                      <div className="flex flex-row items-center gap-2">
                        <Label className="flex w-40 lg:w-32">Class{isError.carrier && <p className="ml-1 text-destructive">*</p>}</Label>
                        <Select name="airlineClass" defaultValue={carrierFormEdit.editingIndex !== null ? carrierForm[carrierFormEdit.editingIndex].airlineClass : ''}
                          onValueChange={(e) => handleCChange(carrierFormEdit.editingIndex, 'airlineClass', e)}>
                          <SelectTrigger className="w-full h-7 bg-muted/50 focus:ring-transparent">
                            <SelectValue />
                          </SelectTrigger>
                          <SelectContent>
                            <SelectGroup>
                              {classItems.map(item => (
                                <SelectItem key={item} value={item}>
                                  {item}
                                </SelectItem>
                              ))}
                            </SelectGroup>
                          </SelectContent>
                        </Select>
                      </div>
                      <div className="flex flex-row items-center gap-2">
                        <Label className="flex w-40">Departure Date{isError.carrier && <p className="ml-1 text-destructive">*</p>}</Label>
                        <DatePicker className="h-7 bg-muted/50" value={carrierFormEdit.editingIndex !== null ? carrierForm[carrierFormEdit.editingIndex].departureDate : ''}
                          onChange={(date) => handleCChange(carrierFormEdit.editingIndex, 'departureDate', date)} />
                      </div>
                    </div>
                    <div className="flex flex-row col-span-4 mb-4 mr-6">
                      <Button variant="outline" onClick={handleCarrierFormSave} className="ml-auto mr-3 bg-primary/30 hover:bg-primary/40">{carrierFormEdit.editingIndex !== null ? "Save" : "Add"}</Button>
                      <Button variant="outline" className="bg-primary/30 hover:bg-primary/40" onClick={() => setcarrierFormEdit({ visible: false, editingIndex: null })}>Close</Button>
                    </div>
                  </>
                )}
              </CardContent>
            </Card>
            <Separator />
            <div className="flex flex-row items-center justify-between mr-2">
              <CardDescription>Fare details : </CardDescription>
              <div className='mr-2'>Total Fare : <span className='font-bold'>₹{fareForm[0]?.netAmount}</span></div>
            </div>
            <div className="grid grid-cols-1 gap-x-4 gap-y-2 md:grid-cols-2 lg:grid-cols-5">
              <div className="flex flex-row items-center gap-2">
                <Label className="flex w-40">Base Fare{isError.fare && <p className="ml-1 text-destructive">*</p>}</Label>
                <Input className="h-7 bg-muted/50" type="number" name="baseFare" readOnly value={fareForm[0]?.baseFare}
                  onChange={(e) => handleFChange(0, 'baseFare', e.target.value)} onBlur={handleFareFormSave} />
              </div>
              <div className="flex flex-row items-center gap-2">
                <Label className="flex w-40">Com Tax{isError.fare && <p className="ml-1 text-destructive">*</p>}</Label>
                <Input className="h-7 bg-muted/50" type="number" name="comTax" readOnly value={fareForm[0]?.comTax}
                  onChange={(e) => handleFChange(0, 'comTax', e.target.value)} onBlur={handleFareFormSave} />
              </div>
              <div className="flex flex-row items-center gap-2">
                <Label className="flex w-40">Non Com Tax{isError.fare && <p className="ml-1 text-destructive">*</p>}</Label>
                <Input className="h-7 bg-muted/50" type="number" name="nonComTax" readOnly value={fareForm[0]?.nonComTax}
                  onChange={(e) => handleFChange(0, 'nonComTax', e.target.value)} onBlur={handleFareFormSave} />
              </div>
              <div className="flex flex-row items-center gap-2">
                <Label className="flex w-40">Markup{isError.fare && <p className="ml-1 text-destructive">*</p>}</Label>
                <Input className="h-7 bg-muted/50" type="number" name="markup" readOnly value={fareForm[0]?.markup}
                  onChange={(e) => handleFChange(0, 'markup', e.target.value)} onBlur={handleFareFormSave} />
              </div>
              <div className="flex flex-row items-center gap-2">
                <Label className="flex w-40">K3 Tax{isError.fare && <p className="ml-1 text-destructive">*</p>}</Label>
                <Input className="h-7 bg-muted/50" type="number" name="k3" readOnly value={fareForm[0]?.k3}
                  onChange={(e) => handleFChange(0, 'k3', e.target.value)} onBlur={handleFareFormSave} />
              </div>
            </div>
            <Separator />
            <div className="flex flex-row items-center justify-between gap-4 mr-2">
              <CardDescription>Charges details :</CardDescription>
            </div>
            <Card>
              <CardContent className="p-0">
                <Table>
                  <TableHeader className="bg-secondary/60">
                    <TableRow>
                      <TableHead className="rounded-tl-md"></TableHead>
                      <TableHead></TableHead>
                      <TableHead>Charge Name</TableHead>
                      <TableHead>Charge ₹</TableHead>
                      <TableHead className="hidden md:table-cell">Total Charge ₹</TableHead>
                      <TableHead>Total Tax</TableHead>
                      <TableHead className="rounded-tr-md"><span className="sr-only">Actions</span></TableHead>
                    </TableRow>
                  </TableHeader>
                  {/* <TableBody>
                    {chargeForm.map((charge, index) => (
                      <TableRow key={index}>
                        <TableCell></TableCell>
                        <TableCell></TableCell>
                        <TableCell>{charge.chargeCodeName}<span className='ml-2 text-xs'>{charge.calculationMethod ? `(${charge.calculationMethod})` : "(PerTicket)"}</span></TableCell>
                        <TableCell><Input className="w-40 h-6" value={charge.value || ""} onChange={(e) => handleChargeChange(index, 'value', e.target.value)} onBlur={handleChargeFormSave} /></TableCell>
                        <TableCell>{charge.totalValue || ""}</TableCell>
                        <TableCell className="cursor-pointer">
                          <span className='flex items-center'>{charge.taxTotal || ""}
                            <TooltipProvider delayDuration={100}>
                              <Tooltip>
                                <TooltipTrigger asChild>{charge.taxTotal && <Info className='ml-1 size-4' />}</TooltipTrigger>
                                <TooltipContent className="mb-2">
                                  {charge.tax && charge.tax.length > 0 && charge.tax.some(t => t.taxCode !== "") ? charge.tax.map((tax) => (<div key={tax.taxCode}>{`${tax.taxCode}: ${tax.value}`}</div>)) : 'No Taxes'}
                                </TooltipContent>
                              </Tooltip>
                            </TooltipProvider>
                          </span>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody> */}
                  <TableBody>
                    {CHARGE_TYPES.map((chargeType, index) => {
                      const charge = chargeForm?.find(c => c.chargeCodeName === chargeType) || {
                        chargeCodeName: chargeType,
                        calculationMethod: "",
                        value: "",
                        totalValue: "",
                        tax: [],
                        taxTotal: null
                      };
                      return (
                        <TableRow key={index}>
                          <TableCell></TableCell>
                          <TableCell></TableCell>
                          <TableCell>{charge.chargeCodeName}<span className='ml-2 text-xs'>{charge.calculationMethod ? `(${charge.calculationMethod})` : "(PerTicket)"}</span>
                          </TableCell>
                          <TableCell>
                            <Input
                              type="number"
                              className="w-40 h-6"
                              value={charge.value || ""}
                              onChange={(e) => handleChargeChange(index, 'value', e.target.value)}
                              onBlur={handleChargeFormSave}
                            />
                          </TableCell>
                          <TableCell>{charge.totalValue || ""}</TableCell>
                          <TableCell className="cursor-pointer">
                            <span className='flex items-center'>{charge.taxTotal || ""}
                              <TooltipProvider delayDuration={100}>
                                <Tooltip>
                                  <TooltipTrigger asChild>
                                    {charge.taxTotal && <Info className='ml-1 size-4' />}
                                  </TooltipTrigger>
                                  <TooltipContent className="mb-2">
                                    {charge.tax && charge.tax.length > 0 && charge.tax.some(t => t.taxCode !== "") ? charge.tax.map((tax) => (<div key={tax.taxCode}>{`${tax.taxCode}: ${tax.value}`}</div>)) : 'No Taxes'}
                                  </TooltipContent>
                                </Tooltip>
                              </TooltipProvider>
                            </span>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </CardContent>
            </Card>
          </div>
        </ScrollArea>
        <CardFooter className="py-0 mt-2 mr-2">
          <div className='text-sm'>Invoice NetAmount : <span className='font-bold'>₹{invAmount}</span>, CreditNote Amount : <span className='font-bold'>₹{creditAmount}</span></div>
          {
            !id ? (
              <div className="ml-auto">
                <Button onClick={onSave} className="mr-2" disabled={loading}>
                  {loading === "Save" ? 'Saving...' : 'Save draft'}
                </Button>
                <Button onClick={() => onCreate('Submitted')} disabled={loading}>
                  {loading === "Create" ? 'Creating...' : 'Create credit note'}
                </Button>
              </div>
            ) : (
              <div className='ml-auto'>
                {(data && data.state === "Draft") &&
                  <Button onClick={onSave} disabled={loading}>
                    {loading === "Edit" ? 'Editing...' : 'Save Changes'}
                  </Button>}
                {(data && data.state === "Draft") &&
                  <Button onClick={() => onCreate('Submitted')} className="ml-2" disabled={loading}>
                    {loading === "Create" ? 'Creating...' : 'Create credit note'}
                  </Button>
                }
              </div>
            )
          }
        </CardFooter>
      </form>
      {/* </CardContent>
    </Card> */}
    </div>
  )
}
