import { Link, useNavigate } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import { Input } from '../../components/ui/input';
import { Label } from '../../components/ui/label';
import { toast } from 'sonner';
import ModeToggle from '../../components/ui/mode-toggle';
import { Button } from '../../components/ui/button';
import { Eye, EyeOff } from 'lucide-react';

export default function LoginPage() {
    const navigate = useNavigate();
    const [showPassword, setShowPassword] = useState(false);
    const [user, setUser] = useState({
        email: "",
        password: "",
    });
    const [isLoading, setIsLoading] = useState(false);

    const handleInputChange = (event) => {
        setUser({ ...user, [event.target.name]: event.target.value });
    };

    const handleTogglePassword = (e) => {
        e.preventDefault();
        setShowPassword(!showPassword);
    };

    const handleForgotPassword = () => {
        toast.info("Mail sent", { description: "Go through the link provided in mail!", position: "top-center" });
    };

    function handleSubmit(event) {
        event.preventDefault();
        const validCredentials = [
            { email: "amigo", password: "amigo@123" },
            { email: "yara", password: "yara@123" },
        ];
        const isValidUser = validCredentials.some(
            (cred) => cred.email === user.email && cred.password === user.password
        );
        if (isValidUser) {
            navigate("/dashboard/admin");
            toast.success("Login Successful!");
        } else {
            toast.error("Enter valid email and password!");
        }
    }

    return (
        <div className="min-h-screen">
            <div>
                <nav className="max-w-7xl mx-auto lg:p-10 p-5 flex justify-between text-[3.1vh]">
                    <Link to={"/"}>
                        <h1 className="flex flex-row mt-1 font-bold">Amigo Nexus</h1>
                    </Link>
                    <ModeToggle variant="outline" />
                </nav>
            </div>
            <div className="flex items-center justify-center h-[60vh] w-full px-4 py-12">
                <div className="mx-auto grid w-[350px] gap-6">
                    <div className="grid gap-2 text-center">
                        <h1 className="text-[2.8vh] font-bold">User login</h1>
                    </div>
                    <form onSubmit={handleSubmit}>
                        <div className="grid gap-6">
                            <div className="grid gap-2">
                                <Label>Username or Email</Label>
                                <Input
                                    name="email"
                                    type="text"
                                    value={user.email}
                                    onChange={handleInputChange}
                                    required />
                            </div>
                            <div className="grid gap-2">
                                <div className="flex items-center">
                                    <Label>Password</Label>
                                </div>
                                <div className='flex flex-row-reverse'>
                                    <Input name="password" type={showPassword ? "text" : "password"} required value={user.password} onChange={handleInputChange} />
                                    <span className="absolute px-2 m-2 text-gray-500" onClick={handleTogglePassword}>
                                        {showPassword ? <EyeOff /> : <Eye />}
                                    </span>
                                </div>
                                <p className="flex flex-row-reverse w-full pr-2 text-sm transition-all cursor-pointer hover:text-primary" onClick={handleForgotPassword}>
                                    forgot password?
                                </p>
                            </div>
                            <Button type="submit">
                                {isLoading ? "Loading" : "Login"}
                            </Button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}
