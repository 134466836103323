import { Separator } from 'src/components/ui/separator';
import React, { useRef, useState } from 'react'
import { Button } from 'src/components/ui/button'
import { Dialog, DialogContent, DialogHeader, DialogTitle, DialogTrigger } from 'src/components/ui/dialog'
import { format } from 'date-fns';
import { BadgeInfo, Scroll } from 'lucide-react';
import { useReactToPrint } from 'react-to-print';
import { ScrollArea } from 'src/components/ui/scroll-area';
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from 'src/components/ui/table';
import { Link } from 'react-router-dom';
import Deletedialog from 'src/pages/components/delete-dialog';
import GenericAmountSeparator from 'src/pages/components/genric-amountseperator';

export default function ViewInvoiceCab({ data, icon, onViewSuccess }) {
    const [open, setOpen] = useState(false);
    const item = data;
    const componentRef = useRef();
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    });
    const handleSuccess = () => {
        setOpen(false);
        onViewSuccess();
    }
    const formatDate = (dateString) => {
        if (!dateString) {
            return '';
        }
        const date = new Date(dateString);
        return format(date, 'dd-MM-yyyy');
    };
    return (
        <div>
            <style>
                {`
                    @media print {
                        .print-exclude {
                            display: none;
                        }
                        .print-content {
                            width: 95vw;
                            height: 95vh;
                            margin: 2vh;
                            overflow: hidden;
                            page-break-inside: avoid;
                            box-shadow: none;
                        }
                        .print-content * {
                            page-break-inside: avoid;
                        }
                    }
                    @media screen {
                        .print-only {
                            display: none;
                            margin-top: 10px;
                        }
                    }
                `}
            </style>
            <Dialog open={open} onOpenChange={setOpen}>
                <DialogTrigger asChild>
                    {icon ? <BadgeInfo className="text-stay/90 py-0 size-[2.1vh] hover:text-stay" /> : <span className='transition-all cursor-pointer hover:text-primary/70'>{item.invoiceId}</span>}
                </DialogTrigger>
                <DialogContent className="lg:w-[75vw] w-[100vw]">
                    <DialogHeader className='flex flex-row items-center'>
                        <DialogTitle className="flex items-center gap-2 ml-5">Invoice Details <Scroll /></DialogTitle>
                        <Link className='ml-auto mr-4' to={`/dashboard/invoices/cab/edit/${item.invoiceId}`} state={{ item: item }}><Button size="sm" className="text-slate-100">Edit</Button></Link>
                        <Button size="sm" className={`mr-4 text-slate-100 bg-stay/75 hover:bg-stay/90`} onClick={handlePrint}>Print</Button>
                        <div><Deletedialog deleteCall="Inside" onDeleteSuccess={handleSuccess} Id={item.invoiceId} disable={item.state === "Deleted" && true} type="invoice" /></div>
                    </DialogHeader>
                    <ScrollArea className="h-[calc(100vh-200px)] lg:h-[65vh]">
                        <div ref={componentRef} className='flex flex-col justify-between mx-5 print-content' >
                            <div>
                                <div className='flex justify-between print-only'>
                                    <div className='flex gap-1'>
                                        {/* <img src='' alt="alt" className='w-20 h-20 bg-secondary/50' /> */}
                                        <div className='flex flex-col text-[1.5vh]]'>
                                            <span className='font-bold'>YARA TOURS AND TRAVELS</span>
                                            <span className='w-[50vh] text-xs'> Office No 01 Ground Floor, Khandve Vihar, Kamal Park, Survey No 18/3/1/10,<br />
                                                Lohegaon Dhanori Road, Near C.T Hospital, Dhanori
                                                Pune 411015.</span>
                                        </div>
                                    </div>
                                    <div className='text-xs'> Tel: +91 8308864745<br />
                                        email: yaaratourstravels@gmail.com,<br />
                                        PAN No: AADFY4379F<br />
                                        GSTIN: 27AADFY4379F1ZT<br />
                                        State: Maharashtra (27)<br />
                                    </div>
                                </div>
                                <Separator />
                                <div className='flex w-full font-bold justify-evenly'>TAX  INVOICE</div>
                                <Separator />
                                <div className='flex justify-between my-1 text-xs'>
                                    <div>
                                        To,<br />{data.printInName ? data.printInName : data.customerRefOrValue.name}<br />
                                        {data.customerRefOrValue.address}<br />
                                        <div className='flex'><div className='w-12'>PAN No.</div>: {data.customerRefOrValue.panNo}</div>
                                        <div className='flex'><div className='w-12'>GSTIN</div>: {data.customerRefOrValue.gstNo}</div>
                                        <div className='flex'><div className='w-12'>Phone</div>: {data.customerRefOrValue.phone}</div>
                                    </div>
                                    <div>
                                        <div className='flex'><div className='w-20'>Invoice No.</div>:<span className="font-bold">&nbsp;{data.invoiceId}</span></div>
                                        <div className='flex'><div className='w-20'>Invoice Date</div>: {formatDate(data.invoiceDate)}</div>
                                        <div className='flex'><div className='w-20'>Due Date</div>: {formatDate(data.dueDate)}</div>
                                    </div>
                                </div>
                                {data.cabProductDetails?.[0]?.product?.rideDetails?.map((rides, index) => (
                                    <div key={index} className='mt-2'>
                                        <div className='flex pl-1 text-xs font-bold bg-secondary border-[1px] border-secondary-foreground/40 border-b-0'>
                                            Cab : {rides.carDetails} ,
                                            Handled By: {rides.handledBy}
                                        </div>
                                        <div>
                                            <Table>
                                                <TableHeader className="bg-secondary">
                                                    <TableRow className="text-xs">
                                                        <TableHead className="border-[1px] border-secondary-foreground/40 text-secondary-foreground">Report To</TableHead>
                                                        <TableHead className="border-[1px] border-secondary-foreground/40 text-center text-secondary-foreground">Cab Category</TableHead>
                                                        <TableHead className="border-[1px] border-secondary-foreground/40 text-center text-secondary-foreground">Duty Type</TableHead>
                                                        <TableHead className="border-[1px] border-secondary-foreground/40 text-center text-secondary-foreground">Start DateTime - Km / End DateTime - Km</TableHead>
                                                        <TableHead className="border-[1px] border-secondary-foreground/40 text-center text-secondary-foreground">Extra KM - Rate / Time - Rate</TableHead>
                                                        <TableHead className="border-[1px] border-secondary-foreground/40 text-center text-secondary-foreground">Car Tariff</TableHead>
                                                        <TableHead className="border-[1px] border-secondary-foreground/40 text-center text-secondary-foreground">Extra KM / Time Charges</TableHead>
                                                        <TableHead className="border-[1px] border-secondary-foreground/40 text-center text-secondary-foreground">Other Charges</TableHead>
                                                        <TableHead className="border-[1px] border-secondary-foreground/40 text-center text-secondary-foreground">Total</TableHead>
                                                    </TableRow>
                                                </TableHeader>
                                                <TableBody>
                                                    <TableRow key={index} className="lg:text-xs text-[1vh]">
                                                        <TableCell className="border-[1px] p-0 border-secondary-foreground/40 min-w-40">
                                                            {rides.passenger?.map((passenger, passengerIndex) => (
                                                                <div key={passengerIndex} className={`${passengerIndex !== rides.passenger?.length - 1 && ""} lg:py-1 py-0 pl-2 border-secondary-foreground/40`}>
                                                                    {passengerIndex + 1 + "."} {passenger.title + "."} {passenger.firstName} {passenger.lastName}
                                                                </div>
                                                            ))}
                                                        </TableCell>
                                                        <TableCell className="border-[1px] p-0 text-center border-secondary-foreground/40">
                                                            {rides.carriers?.map((carrier, pIndex) => (
                                                                <div key={pIndex} className={`lg:py-2 py-0 ${pIndex !== rides.carriers.length - 1 ? "border-b-[1px] border-secondary-foreground/40" : ""}`}>
                                                                    {carrier.cabCategory}
                                                                </div>
                                                            ))}
                                                        </TableCell>
                                                        <TableCell className="border-[1px] p-0 text-center border-secondary-foreground/40">
                                                            {rides.carriers?.map((carrier, pIndex) => (
                                                                <div key={pIndex} className={`${pIndex !== rides.carriers?.length - 1 && "border-b-[1px]"} lg:py-2 py-0 border-secondary-foreground/40`}>
                                                                    {carrier.dutyType}
                                                                </div>
                                                            ))}
                                                        </TableCell>
                                                        <TableCell className="border-[1px] p-0 text-center border-secondary-foreground/40">
                                                            {rides.carriers?.map((carrier, cIndex) => (
                                                                <div key={cIndex} className={`${cIndex !== rides.carriers?.length - 1 && "border-b-[1px]"} lg:py-2 py-0 border-secondary-foreground/40`}>
                                                                    ({new Date(carrier.startDate).toLocaleString()} - {carrier.startKM && `${carrier.startKM || 0}km`}) /{" "}
                                                                    ({new Date(carrier.endDate).toLocaleString()} - {carrier.endKM && `${carrier.endKM || 0}km`})
                                                                </div>
                                                            ))}
                                                        </TableCell>
                                                        <TableCell className="border-[1px] p-0 text-center border-secondary-foreground/40">
                                                            {rides.carriers?.map((carrier, cIndex) => (
                                                                <div key={cIndex} className={`${cIndex !== rides.carriers?.length - 1 && "border-b-[1px]"} lg:py-2 py-0 border-secondary-foreground/40`}>
                                                                    {carrier.fare?.extraKM || 0} - {carrier.fare?.extraKmRate || 0} / {carrier.fare?.extraTime || 0} - {carrier.fare?.extraTimeRate || 0}
                                                                </div>
                                                            ))}
                                                        </TableCell>
                                                        <TableCell className="border-[1px] p-0 text-center border-secondary-foreground/40">
                                                            {rides.carriers?.map((carrier, cIndex) => (
                                                                <div key={cIndex} className={`${cIndex !== rides.carriers?.length - 1 && "border-b-[1px]"} lg:py-2 py-0 border-secondary-foreground/40`}><GenericAmountSeparator value={carrier.fare?.cabTarrif} viewMode={true} icon={true} /></div>
                                                            ))}
                                                        </TableCell>
                                                        <TableCell className="border-[1px] p-0 text-center border-secondary-foreground/40">
                                                            {rides.carriers?.map((carrier, cIndex) => (
                                                                <div key={cIndex} className={`${cIndex !== rides.carriers?.length - 1 && "border-b-[1px]"} lg:py-2 py-0 border-secondary-foreground/40`}><GenericAmountSeparator value={carrier.fare?.extraKmCharges || 0} viewMode={true} icon={true} /> / <GenericAmountSeparator value={carrier.fare?.extraTimeCharges || 0} viewMode={true} icon={true} /></div>
                                                            ))}
                                                        </TableCell>
                                                        <TableCell className="border-[1px] p-0 text-center border-secondary-foreground/40">
                                                            {rides.carriers?.map((carrier, cIndex) => (
                                                                <div key={cIndex} className={`${cIndex !== rides.carriers?.length - 1 && "border-b-[1px]"} lg:py-2 py-0 border-secondary-foreground/40`}><GenericAmountSeparator value={carrier.fare?.additionalCharges} viewMode={true} icon={true} /></div>
                                                            ))}
                                                        </TableCell>
                                                        <TableCell className="border-[1px] p-0 text-center border-secondary-foreground/40">
                                                            {rides.carriers?.map((carrier, cIndex) => (
                                                                <div key={cIndex} className={`${cIndex !== rides.carriers?.length - 1 && "border-b-[1px]"} lg:py-2 py-0 border-secondary-foreground/40`}><GenericAmountSeparator value={carrier.fare?.netAmount} viewMode={true} icon={true} /></div>
                                                            ))}
                                                        </TableCell>
                                                    </TableRow>
                                                    {rides.passenger?.some(passenger => passenger.remark?.length > 0) && (
                                                        <>
                                                            <TableRow>
                                                                <TableCell colSpan={9} className="border-[1px] p-0 pl-3 lg:text-xs text-[1vh] border-secondary-foreground/40">
                                                                    Additional charges
                                                                </TableCell>
                                                            </TableRow>
                                                            {data.cabProductDetails?.[0]?.product?.rideDetails.map((ride, rideIndex) => (
                                                                ride.passenger?.filter(passenger => passenger.remark?.length > 0).map((passenger, passengerIndex) => (
                                                                    <TableRow key={`${rideIndex}-${passengerIndex}`}>
                                                                        <TableCell colSpan={2} className="border-[1px] lg:py-2 p-0 pl-3 lg:text-xs text-[1vh] border-secondary-foreground/40">
                                                                            {passenger.title + "."} {passenger.firstName} {passenger.lastName} / {passenger.paxType}
                                                                        </TableCell>
                                                                        <TableCell colSpan={2} className="border-[1px] px-2 lg:text-xs text-[1vh] border-secondary-foreground/40">
                                                                            {passenger.remark.filter(remark => remark.name && remark.charge).map((remark, rIndex, filteredRemarks) => (
                                                                                <div key={rIndex} className={`${rIndex !== filteredRemarks?.length - 1 && "border-b-[1px]"} py-0 border-secondary-foreground/40`}>
                                                                                    {remark.name}
                                                                                </div>
                                                                            ))}
                                                                        </TableCell>
                                                                        <TableCell colSpan={4} className="border-[1px] lg:py-2 p-0 lg:text-xs text-[1vh] border-secondary-foreground/40">
                                                                            <div></div>
                                                                        </TableCell>
                                                                        <TableCell className="border-[1px] lg:py-2 p-0 text-center lg:text-xs text-[1vh] border-secondary-foreground/40">
                                                                            {passenger.remark.filter(remark => remark.name && remark.charge).map((remark, rIndex, filteredRemarks) => (
                                                                                <div key={rIndex} className={`${rIndex !== filteredRemarks?.length - 1 && "border-b-[1px]"} py-0 border-secondary-foreground/40`}>
                                                                                    {remark.charge}
                                                                                </div>
                                                                            ))}
                                                                        </TableCell>
                                                                    </TableRow>
                                                                ))
                                                            ))}
                                                        </>
                                                    )}
                                                    <TableRow>
                                                        <TableCell colSpan={2} className="border-[1px] p-0 lg:text-xs text-[1vh] border-secondary-foreground/40"></TableCell>
                                                        <TableCell colSpan={3} className="border-[1px] p-0 lg:text-xs text-[1vh] text-end pr-2 border-secondary-foreground/40"></TableCell>
                                                        <TableCell colSpan={3} className="border-[1px] p-0 lg:text-xs text-[1vh] font-bold pl-2 border-secondary-foreground/40">Gross Total</TableCell>
                                                        {(() => {
                                                            const remarks = rides.passenger?.filter(passenger =>
                                                                passenger.remark?.length > 0 &&
                                                                passenger.remark.some(remark => remark.name && remark.charge)
                                                            ) || [];
                                                            let remarksTotal = remarks?.reduce((total, passenger) => {
                                                                return total + (passenger.remark?.reduce((passengerTotal, remark) => {
                                                                    return passengerTotal + (parseFloat(remark.charge) || 0);
                                                                }, 0) || 0);
                                                            }, 0);
                                                            const netAmount = rides.carriers.reduce((total, carrier) => {
                                                                return total + (parseFloat(carrier.fare?.netAmount) || 0);
                                                            }, 0);
                                                            const totalAmount = netAmount + remarksTotal;
                                                            return (<TableCell className="border-[1px] p-0 lg:text-xs text-[1vh] font-bold text-center border-secondary-foreground/40"><GenericAmountSeparator value={totalAmount} viewMode={true} icon={true} /></TableCell>);
                                                        })()}
                                                    </TableRow>
                                                    {data.cabProductDetails?.[0]?.product?.rideDetails.length - 1 === index &&
                                                        <>
                                                            <TableRow className="h-2"></TableRow>
                                                            <TableRow>
                                                                <TableCell colSpan={5} className="border-l-[1px] p-0 lg:text-xs text-[1vh] border-secondary-foreground/40"></TableCell>
                                                                <TableCell colSpan={3} className="border-[1px] p-0 lg:text-[1.3vh] text-[1vh] border-secondary-foreground/40">
                                                                    {data.cabProductDetails?.[0]?.product.rideDetails[0]?.charges?.filter(charge => Number(charge.totalValue) !== 0).map((charge, chargeIndex, filteredCharges) => (
                                                                        <div key={chargeIndex} className={`${chargeIndex !== filteredCharges.length - 1 && "border-b-[1px]"} block border-secondary-foreground/40`
                                                                        }>&nbsp;(+) {charge.chargeCodeName}
                                                                            <div>{charge.tax.filter(charge => Number(charge.value) !== 0).map((tax, index, filteredTaxes) => (<div className={`lg:text-[1.1vh] text-[.9vh] ${index !== filteredTaxes.length && "border-t-[1px] border-secondary-foreground/40"}`} key={index}>&nbsp;(+) {tax.taxCode}</div>))}</div>
                                                                        </div>
                                                                    ))}
                                                                </TableCell>
                                                                <TableCell className="border-[1px] p-0 text-end lg:text-[1.3vh] text-[1vh] border-secondary-foreground/40">
                                                                    {data.cabProductDetails?.[0]?.product.rideDetails[0]?.charges?.filter(charge => Number(charge.totalValue) !== 0).map((charge, cIndex, filteredCharges) => (
                                                                        <div key={cIndex} className={`${cIndex !== filteredCharges.length - 1 && "border-b-[1px]"} border-secondary-foreground/40`
                                                                        }><GenericAmountSeparator value={Number(charge.totalValue)} viewMode={true} icon={true} />&ensp;<div>{charge.tax.filter(charge => Number(charge.value) !== 0).map((tax, index, filteredTaxes) => (<div className={`lg:text-[1.1vh] text-[.9vh] pr-2 ${index !== filteredTaxes.length && "border-t-[1px] border-secondary-foreground/40"}`} key={index}> {tax.value}</div>))}</div>
                                                                        </div>
                                                                    ))}
                                                                </TableCell>
                                                            </TableRow>
                                                            <TableRow>
                                                                <TableCell colSpan={5} className="border-b-[1px] border-l-[1px] p-0 text-xs border-secondary-foreground/40"></TableCell>
                                                                <TableCell colSpan={4} className="border-[1px] p-0 text-xs font-bold border-secondary-foreground/40 bg-secondary" >
                                                                    <div className='flex justify-between'>
                                                                        <div className='pl-2'>Total INR</div>
                                                                        <div><GenericAmountSeparator value={data.cabProductDetails?.[0]?.netAmount} viewMode={true} icon={true} />&ensp;</div>
                                                                    </div>
                                                                </TableCell>
                                                            </TableRow>
                                                        </>}
                                                </TableBody>
                                            </Table>
                                        </div>
                                    </div>
                                ))}
                                <div className='mt-2 text-sm'>FARES ARE NOT PAYABLE UNDER REVERSE CHARGE</div>
                            </div>
                            <div>
                                <div className='flex mt-10 border-t-2 print-only'>
                                    <div className='text-[.9vh]  lg:w-[30vw] w-[60vw]'>
                                        E. & O.E.<br />
                                        Terms & Conditions :<br />
                                        CASH : Payment To Be Made To The Cashier & Printed Official Receipt Must Be Obtained<br />
                                        CHEQUE : All Cheque/Demand Draft In Payment Of Bills Must Be Crossed 'A/C Payee' & Should Be Drawn In<br />
                                        Favour Of 'YARA TOURS AND TRAVELS'<br />
                                        LATE PAYMENT : Interest @ 24% Per Annum Will Be Charged On Outstanding Bill After Due Date<br />
                                        VERY IMPORTANT : Kindly Check All Details Carefully To Avoid Unnecessary Complications<br /><br />
                                        Bank Details :<br />
                                        Bank Name: ICICI Bank, Account No: 397705003783, Account Type: Current account, IFSC Code: ICICI0003977,<br />
                                        Branch: Dhanori, Pune 411015<br />
                                        We are not liable for any third party(Airlines/Hotels) GST input tax credit or GST Invoice<br /><br />
                                        <span className='text-[.9vh]'>This Is Computer Generated Invoice, Requires No Signature</span>
                                    </div>
                                    <div className='w-[30vw] flex items-center justify-center'>
                                        Authorised signature here
                                    </div>
                                </div>
                            </div>
                        </div>
                    </ScrollArea>
                </DialogContent>
            </Dialog>

        </div>
    )
}
