import { Button } from 'src/components/ui/button';
import { Card, CardContent, CardDescription, CardFooter } from 'src/components/ui/card';
import { Input } from 'src/components/ui/input';
import { Label } from 'src/components/ui/label';
import { ScrollArea } from 'src/components/ui/scroll-area';
import { Separator } from 'src/components/ui/separator';
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from 'src/components/ui/table';
import { CirclePlus, Edit, Trash2, Undo2 } from 'lucide-react';
import React, { useEffect, useState } from 'react'
import { Link, useLocation } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { toast } from 'sonner';
import { Select, SelectContent, SelectGroup, SelectItem, SelectTrigger, SelectValue } from 'src/components/ui/select';

export default function ServiceProviderForm() {

  const [bankFormEdit, setBankFormEdit] = useState({ visible: false, editingIndex: null });
  const [addressFormEdit, setAddressFormEdit] = useState({ visible: false, editingIndex: null });
  const { register, handleSubmit, reset, setValue, formState: { errors }, } = useForm({});

  //Address details
  const [addressDetails, setAddressDetails] = useState([
    {
      addressLine: "",
      country: "",
      state: "",
      city: "",
      type: "",
    }
  ]);
  const [editingAddressForm, setEditingAddressForm] = useState([]);
  const handleAddressFormSave = (e) => {
    e.preventDefault();
    const filteredAddressForm = addressDetails.filter(address => Object.values(address).some(field => field !== "")
    );
    if (filteredAddressForm.length === 0) { return; }
    setValue('address', addressDetails)
    setEditingAddressForm(addressDetails);
    setAddressFormEdit({ visible: false, editingIndex: null });
  }

  const handleAChange = (index, field, value) => {
    if (index === null) {
      index = editingAddressForm.length;
    }
    setAddressDetails((prevAddress) => {
      const updatedAddress = [...prevAddress];
      updatedAddress[index] = {
        ...updatedAddress[index],
        [field]: value
      };
      return updatedAddress;
    });
  }

  //Bank details
  const [bankDetail, setBankDetails] = useState([
    {
      accountName: "",
      branch: "",
      accountType: "",
      ifscCode: "",
      role: "",
    }
  ]);

  const [editingBankForm, setEditingBankForm] = useState([]);

  const handleBankFormSave = (e) => {
    e.preventDefault();
    const filteredBankForm = bankDetail.filter(bank => Object.values(bank).some(field => field !== "")
    );
    if (filteredBankForm.length === 0) { return; }
    setValue('bankDetails', bankDetail)
    setEditingBankForm(bankDetail);
    setBankFormEdit({ visible: false, editingIndex: null });
  };
  const handleBChange = (index, field, value) => {
    if (index === null) {
      index = editingBankForm.length;
    }
    setBankDetails((prevBank) => {
      const updatedBank = [...prevBank];
      updatedBank[index] = {
        ...updatedBank[index],
        [field]: value
      };

      return updatedBank;
    });
  }


  //Create serviceProvider
  const handleCreateServicePro = async (data) => {
    try {
      const response = await fetch(`${(process.env.REACT_APP_API_HOST_MSTR === undefined) ? "" : process.env.REACT_APP_API_HOST_MSTR}/amigo-lekha-api/serviceProviderManagement/v1/serviceProvider`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(data),
      });
      if (!response.ok) {
        throw new Error('Failed to create customer');
      }
      const res = await response.json();
      const code = res.code
      toast.success("ServiceProvider created successfuly!", { duration: "5000", position: "top-right", description: `serviceProvider Id- ${code}` });
    } catch (error) {
      toast.error("Failed to create serviceProvider");
    }
  };
  //edit serviceProvider
  const handleEditServicepro = async (data) => {
    try {
      const response = await fetch(`${(process.env.REACT_APP_API_HOST_MSTR === undefined) ? "" : process.env.REACT_APP_API_HOST_MSTR}/amigo-lekha-api/serviceProviderManagement/v1/serviceProvider/${data.code}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(data),
      });
      if (!response.ok) {
        throw new Error('Failed to create Service provider');
      }
      const res = await response.json();
      const code = res.code;
      toast.success("Service provider edit successfuly!", { duration: "5000", position: "top-right", description: `Service Pro Id- ${code}` });
    } catch (error) {
      toast.error("Failed to edit Service provider");
    }
  };

  const location = useLocation();
  const data = location.state?.item;
  const [editservicePro, setEditServicePro] = useState(false);

  const [countryItems, setCountryItems] = useState([]);
  const [stateItems, setStateItems] = useState([]);
  const [accountTypeItems, setAccountTypeItems] = useState([]);
  const getLists = async () => {
    try {
      const response = await fetch(`${(process.env.REACT_APP_API_HOST_MSTR === undefined) ? "" : process.env.REACT_APP_API_HOST_MSTR}/amigo-lekha-api/listManagement/v1/list`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json'
        },
      });
      if (!response.ok) {
        throw new Error('Failed to fetch data');
      }
      const fetchedData = await response.json();
      fetchedData.forEach(item => {
        switch (item.name) {
          case 'Country':
            setCountryItems(item.items);
            break;
          case 'State':
            setStateItems(item.items);
            break;
          // case 'City':
          //   setCityItems(item.items);
          //   break;
          case 'AccountType':
            setAccountTypeItems(item.items);
            break;
          default:
            break;
        }
      });
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  }

  useEffect(() => {
    getLists();
    if (data) {
      setEditServicePro(true);
      reset(data);
      if (data.address && data.address.length > 0) {
        const addressData = data.address;
        setAddressDetails(addressData);
        setEditingAddressForm(addressData);
      } else {
        setAddressDetails([{
          addressLine: "",
          country: "",
          state: "",
          city: "",
          type: "",
        }])
      }
      if (data.bankDetails && data.bankDetails.length > 0) {
        const bankData = data.bankDetails;
        setBankDetails(bankData);
        setEditingBankForm(bankData);
      } else {
        setBankDetails([{
          accountName: "",
          branch: "",
          accountType: "",
          ifscCode: "",
          role: "",
        }])
      }
    }
  }, [data, reset, setValue])

  //Delete Functionality 

  const handleDeleteA = (index) => {
    const updatedAddresses = editingAddressForm.filter((_, i) => i !== index);
    setValue("address", updatedAddresses)
    setEditingAddressForm(updatedAddresses);
    setAddressDetails(updatedAddresses);
  };

  const handleDeleteB = (index) => {
    const updatedBank = editingBankForm.filter((_, i) => i !== index);
    setValue("bankDetails", updatedBank)
    setEditingBankForm(updatedBank);
    setBankDetails(updatedBank);
  };


  const onSubmit = (data) => {
    if (editservicePro) {
      handleEditServicepro(data);
    } else {
      handleCreateServicePro(data);
    }

  }

  return (
    <div className='m-5'>
      <Link to="/dashboard/masters/serviceprovider" className='absolute mt-1 ml-5'>
        <Button variant="outline" size="icon"><Undo2 className='size-4' /></Button>
      </Link>
      <div className="flex items-center justify-center w-full mb-5 text-[3vh] font-bold">New Service Provider</div>
      <form onSubmit={handleSubmit(onSubmit)} className='mx-5 mt-2'>
        <Separator />
        <ScrollArea className="h-[calc(100vh-200px)] lg:h-[70vh]">
          <CardDescription className='my-2 font-bold text-gray-700'>General Details:</CardDescription>
          <div className="grid grid-cols-1 gap-2 mt-5 mb-4 md:grid-cols-2 lg:grid-cols-4">
            <div className="flex flex-row items-center gap-2">
              <Label className="flex justify-end w-40 text-gray-500 text-end">Code{errors.customerCode && <p className="ml-1 text-destructive">*</p>}</Label>
              <Input className={`h-7 bg-muted/50 ${errors.code ? 'border-destructive focus-visible:ring-0' : ''}`} name="code" {...register("code", { required: true })} disabled={editservicePro} />
            </div>
            <div className="flex flex-row items-center gap-2">
              <Label className="flex justify-end w-40 text-gray-500 text-end">Name{errors.name && <p className="ml-1 text-destructive">*</p>}</Label>
              <Input className={`h-7 bg-muted/50 ${errors.name ? 'border-destructive focus-visible:ring-0' : ''}`} name="name" {...register("name", { required: true })} />
            </div>
            <div className="flex flex-row items-center gap-2">
              <Label className="flex justify-end w-40 text-gray-500 text-end">Branch{errors.branch && <p className="ml-1 text-destructive">*</p>}</Label>
              <Input className={`h-7 bg-muted/50 ${errors.branch ? 'border-destructive focus-visible:ring-0' : ''}`} name="branch" {...register("branch", { required: true })} />
            </div>
            <div className="flex flex-row items-center gap-2">
              <Label className="w-40 text-gray-500 text-end">Email ID</Label>
              <Input className="h-7 bg-muted/50" name="emailId" {...register("emailId")} />
            </div>
            <div className="flex flex-row items-center gap-2">
              <Label className="w-40 text-gray-500 text-end">Phone Number</Label>
              <Input className="h-7 bg-muted/50" name="phoneNumber" {...register("phoneNumber")} />
            </div>
            <div className="flex flex-row items-center gap-2">
              <Label className="w-40 text-gray-500 text-end">PAN Number</Label>
              <Input className="h-7 bg-muted/50" name="panNumber" {...register("panNumber")} />
            </div>
            <div className="flex flex-row items-center gap-2">
              <Label className="w-40 text-gray-500 text-end">IATA Number</Label>
              <Input className="h-7 bg-muted/50" name="iataNumber" {...register("iataNumber")} />
            </div>
            <div className="flex flex-row items-center gap-2">
              <Label className="w-40 text-gray-500 text-end">GST Number</Label>
              <Input className="h-7 bg-muted/50" name="gstNumber" {...register("gstNumber")} />
            </div>
            <div className="flex flex-row items-center gap-2">
              <Label className="w-40 text-gray-500 text-end">State Of Supply</Label>
              <Input className="h-7 bg-muted/50" name="stateOfSupply" {...register("stateOfSupply")} />
            </div>
            <div className="flex flex-row items-center gap-2">
              <Label className="w-40 text-gray-500 text-end">Com Tax</Label>
              <Input className="h-7 bg-muted/50" name="comTax" {...register("comTax")} />
            </div>
            <div className="flex flex-row items-center gap-2">
              <Label className="w-40 text-gray-500 text-end">Non-Com Tax</Label>
              <Input className="h-7 bg-muted/50" name="nonComTax" {...register("nonComTax")} />
            </div>
            <div className="flex flex-row items-center gap-2">
              <Label className="w-40 text-gray-500 text-end">Markup</Label>
              <Input className="h-7 bg-muted/50" name="markup" {...register("markup")} />
            </div>
          </div>
          {/* </CardContent>
                </Card> */}

          <Separator />
          <div className='w-full px-4 py-3'>
            <div className="flex flex-row items-center justify-between gap-2 my-2">
              <CardDescription className='font-bold text-gray-700'>Address Details:</CardDescription>
              <Button size='sm' variant="secondary" onClick={(e) => { setAddressFormEdit(prevState => ({ ...prevState, visible: !prevState.visible, editingIndex: null })); e.preventDefault(); }}>
                Add Address<CirclePlus className="ml-2 size-4" />
              </Button>
            </div>
            <Card className="my-3">
              <CardContent className="p-0">
                <Table>
                  <TableHeader className="bg-secondary/60">
                    <TableRow>
                      <TableHead className="rounded-tl-md"></TableHead>
                      <TableHead>Address Line</TableHead>
                      <TableHead>Country</TableHead>
                      <TableHead>State</TableHead>
                      <TableHead>City</TableHead>
                      <TableHead>Type</TableHead>
                      <TableHead className="rounded-tr-md"><span className="sr-only">Actions</span></TableHead>
                    </TableRow>
                  </TableHeader>
                  <TableBody>
                    {editingAddressForm.length > 0 ?
                      editingAddressForm.map((item, index) => (
                        <React.Fragment key={index}>
                          <TableRow key={index}>
                            <TableCell></TableCell>
                            <TableCell>{item.addressLine}</TableCell>
                            <TableCell>{item.country}</TableCell>
                            <TableCell>{item.state}</TableCell>
                            <TableCell>{item.city}</TableCell>
                            <TableCell>{item.type}</TableCell>
                            <TableCell>
                              <div className="flex flex-row items-center gap-4">
                                <Edit onClick={() => { setAddressFormEdit(prevState => ({ ...prevState, visible: true, editingIndex: index })) }} className="cursor-pointer text-primary size-4" />
                                <Trash2 onClick={() => handleDeleteA(index)} className="cursor-pointer text-destructive size-4" />
                              </div>
                            </TableCell>
                          </TableRow>
                          {addressFormEdit.visible && addressFormEdit.editingIndex === index && (
                            <TableRow>
                              <TableCell colSpan={11}>
                                <div className="grid grid-cols-1 gap-4 px-4 my-4 md:grid-cols-2 lg:grid-cols-3 ">
                                  <div className="flex flex-row items-center gap-3">
                                    <Label className="w-40">Address Line</Label>
                                    <Input className="h-7 bg-muted/50" name="addressLine" defaultValue={addressFormEdit.editingIndex !== null ? addressDetails[addressFormEdit.editingIndex]?.addressLine || '' : ''}
                                      onChange={(e) => handleAChange(addressFormEdit.editingIndex, 'addressLine', e.target.value)} />
                                  </div>
                                  <div className="flex flex-row items-center gap-2">
                                    <Label className="w-40">Country</Label>
                                    {/* <Input className="h-7 bg-muted/50" name="country" defaultValue={addressFormEdit.editingIndex !== null ? addressDetails[addressFormEdit.editingIndex]?.country || '' : ''}
                                        onChange={(e) => handleAChange(addressFormEdit.editingIndex, 'country', e.target.value)} /> */}
                                    <Select name="country" defaultValue={addressFormEdit.editingIndex !== null ? addressDetails[addressFormEdit.editingIndex]?.country || '' : ''}
                                      onValueChange={(e) => handleAChange(addressFormEdit.editingIndex, 'country', e)}>
                                      <SelectTrigger className="w-full h-7 bg-muted/50 focus:ring-transparent">
                                        <SelectValue />
                                      </SelectTrigger>
                                      <SelectContent>
                                        <SelectGroup>
                                          {countryItems.map(item => (
                                            <SelectItem key={item} value={item}>
                                              {item}
                                            </SelectItem>
                                          ))}
                                        </SelectGroup>
                                      </SelectContent>
                                    </Select>
                                  </div>
                                  <div className="flex flex-row items-center gap-2">
                                    <Label className="w-40">State</Label>
                                    {/* <Input className="h-7 bg-muted/50" name="state" defaultValue={addressFormEdit.editingIndex !== null ? addressDetails[addressFormEdit.editingIndex]?.state || '' : ''}
                                      onChange={(e) => handleAChange(addressFormEdit.editingIndex, 'state', e.target.value)} /> */}
                                    <Select name="state" defaultValue={addressFormEdit.editingIndex !== null ? addressDetails[addressFormEdit.editingIndex]?.state || '' : ''}
                                      onValueChange={(e) => handleAChange(addressFormEdit.editingIndex, 'state', e)}>
                                      <SelectTrigger className="w-full h-7 bg-muted/50 focus:ring-transparent">
                                        <SelectValue />
                                      </SelectTrigger>
                                      <SelectContent>
                                        <SelectGroup>
                                          {stateItems.map(item => (
                                            <SelectItem key={item} value={item}>
                                              {item}
                                            </SelectItem>
                                          ))}
                                        </SelectGroup>
                                      </SelectContent>
                                    </Select>
                                  </div>
                                  <div className="flex flex-row items-center gap-2">
                                    <Label className="w-40">City</Label>
                                    <Input className="h-7 bg-muted/50" name="city" defaultValue={addressFormEdit.editingIndex !== null ? addressDetails[addressFormEdit.editingIndex]?.city || '' : ''}
                                      onChange={(e) => handleAChange(addressFormEdit.editingIndex, 'city', e.target.value)} />
                                  </div>
                                  <div className="flex flex-row items-center gap-2">
                                    <Label className="w-40">Type</Label>
                                    <Input className="h-7 bg-muted/50" name="type" defaultValue={addressFormEdit.editingIndex !== null ? addressDetails[addressFormEdit.editingIndex]?.type || '' : ''}
                                      onChange={(e) => handleAChange(addressFormEdit.editingIndex, 'type', e.target.value)} />
                                  </div>
                                </div>
                                <div className="flex flex-row col-span-4 px-4 my-4">
                                  <Button variant="outline" className="ml-auto mr-3 bg-primary/30 hover:bg-primary/40" onClick={handleAddressFormSave}>save</Button>
                                  <Button variant="outline" className="bg-primary/30 hover:bg-primary/40" onClick={(e) => { setAddressFormEdit(prevState => ({ ...prevState, visible: !prevState.visible, editingIndex: null })); e.preventDefault(); }}>Close</Button>
                                </div>
                              </TableCell>
                            </TableRow>
                          )}
                        </React.Fragment>
                      )) : (
                        <TableRow>
                          <TableCell colSpan={10} className="text-center">No Addresses</TableCell>
                        </TableRow>
                      )}
                  </TableBody>
                </Table>
                {addressFormEdit.visible && addressFormEdit.editingIndex === null && (
                  <div>
                    <Separator />
                    <div className="grid grid-cols-1 gap-4 px-4 my-4 md:grid-cols-2 lg:grid-cols-3 ">
                      <div className="flex flex-row items-center gap-3">
                        <Label className="w-40">Address Line</Label>
                        <Input className="h-7 bg-muted/50" name="addressLine" defaultValue={addressFormEdit.editingIndex !== null ? addressDetails[addressFormEdit.editingIndex]?.addressLine || '' : ''}
                          onChange={(e) => handleAChange(addressFormEdit.editingIndex, 'addressLine', e.target.value)} />
                      </div>
                      <div className="flex flex-row items-center gap-2">
                        <Label className="w-40">Country</Label>
                        {/* <Input className="h-7 bg-muted/50" name="country" defaultValue={addressFormEdit.editingIndex !== null ? addressDetails[addressFormEdit.editingIndex]?.country || '' : ''}
                            onChange={(e) => handleAChange(addressFormEdit.editingIndex, 'country', e.target.value)} /> */}
                        <Select name="country" defaultValue={addressFormEdit.editingIndex !== null ? addressDetails[addressFormEdit.editingIndex]?.country || '' : ''}
                          onValueChange={(e) => handleAChange(addressFormEdit.editingIndex, 'country', e)}>
                          <SelectTrigger className="w-full h-7 bg-muted/50 focus:ring-transparent">
                            <SelectValue />
                          </SelectTrigger>
                          <SelectContent>
                            <SelectGroup>
                              {countryItems.map(item => (
                                <SelectItem key={item} value={item}>
                                  {item}
                                </SelectItem>
                              ))}
                            </SelectGroup>
                          </SelectContent>
                        </Select>
                      </div>
                      <div className="flex flex-row items-center gap-2">
                        <Label className="w-40">State</Label>
                        {/* <Input className="h-7 bg-muted/50" name="state" defaultValue={addressFormEdit.editingIndex !== null ? addressDetails[addressFormEdit.editingIndex]?.state || '' : ''}
                            onChange={(e) => handleAChange(addressFormEdit.editingIndex, 'state', e.target.value)} /> */}
                        <Select name="state" defaultValue={addressFormEdit.editingIndex !== null ? addressDetails[addressFormEdit.editingIndex]?.state || '' : ''}
                          onValueChange={(e) => handleAChange(addressFormEdit.editingIndex, 'state', e)}>
                          <SelectTrigger className="w-full h-7 bg-muted/50 focus:ring-transparent">
                            <SelectValue />
                          </SelectTrigger>
                          <SelectContent>
                            <SelectGroup>
                              {stateItems.map(item => (
                                <SelectItem key={item} value={item}>
                                  {item}
                                </SelectItem>
                              ))}
                            </SelectGroup>
                          </SelectContent>
                        </Select>
                      </div>
                      <div className="flex flex-row items-center gap-2">
                        <Label className="w-40">City</Label>
                        <Input className="h-7 bg-muted/50" name="city" defaultValue={addressFormEdit.editingIndex !== null ? addressDetails[addressFormEdit.editingIndex]?.city || '' : ''}
                          onChange={(e) => handleAChange(addressFormEdit.editingIndex, 'city', e.target.value)} />
                      </div>
                      <div className="flex flex-row items-center gap-2">
                        <Label className="w-40">Type</Label>
                        <Input className="h-7 bg-muted/50" name="type" defaultValue={addressFormEdit.editingIndex !== null ? addressDetails[addressFormEdit.editingIndex]?.type || '' : ''}
                          onChange={(e) => handleAChange(addressFormEdit.editingIndex, 'type', e.target.value)} />
                      </div>
                    </div>
                    <div className="flex flex-row col-span-4 px-4 my-4">
                      <Button variant="outline" className="ml-auto mr-3 bg-primary/30 hover:bg-primary/40" onClick={handleAddressFormSave}>save</Button>
                      <Button variant="outline" className="bg-primary/30 hover:bg-primary/40" onClick={(e) => { setAddressFormEdit(prevState => ({ ...prevState, visible: !prevState.visible, editingIndex: null })); e.preventDefault(); }}>Close</Button>
                    </div>
                  </div>
                )}
              </CardContent>
            </Card>
          </div>
          <div className='w-full px-4 py-3'>
            <div className="flex flex-row items-center justify-between gap-4 my-2">
              <CardDescription className='font-bold text-gray-700'>Bank Details:</CardDescription>
              <Button size='sm' variant="secondary" onClick={(e) => { setBankFormEdit(prevState => ({ ...prevState, visible: !prevState.visible, editingIndex: null })); e.preventDefault(); }}>
                Add Bank<CirclePlus className="ml-2 size-4" />
              </Button>
            </div>
            <Card className="my-3">
              <CardContent className="p-0">
                <Table>
                  <TableHeader className="bg-secondary/60">
                    <TableRow>
                      <TableHead className="rounded-tl-md"></TableHead>
                      <TableHead>Account Number</TableHead>
                      <TableHead>Branch</TableHead>
                      <TableHead>Account Type</TableHead>
                      <TableHead>IFSC Code</TableHead>
                      <TableHead>Role</TableHead>
                      <TableHead className="rounded-tr-md"><span className="sr-only">Actions</span></TableHead>
                    </TableRow>
                  </TableHeader>
                  <TableBody>
                    {editingBankForm.length > 0 ? (
                      editingBankForm.map((item, index) => (
                        <React.Fragment key={index}>
                          <TableRow>
                            <TableCell></TableCell>
                            <TableCell>{item.accountName}</TableCell>
                            <TableCell>{item.branch}</TableCell>
                            <TableCell>{item.accountType}</TableCell>
                            <TableCell>{item.ifscCode}</TableCell>
                            <TableCell>{item.role}</TableCell>
                            <TableCell>
                              <div className="flex flex-row items-center gap-4">
                                <Edit onClick={() => setBankFormEdit(prevState => ({ ...prevState, visible: !prevState.visible, editingIndex: index }))} className="cursor-pointer text-primary size-4" />
                                <Trash2 onClick={() => handleDeleteB(index)} className="cursor-pointer text-destructive size-4" />
                              </div>
                            </TableCell>
                          </TableRow>
                          {bankFormEdit.visible && bankFormEdit.editingIndex === index && (
                            <TableRow>
                              <TableCell colSpan={11}>
                                <div className="grid grid-cols-1 gap-4 px-4 my-4 md:grid-cols-2 lg:grid-cols-3">
                                  <div className="flex flex-row items-center gap-3">
                                    <Label className="w-40">Account Number</Label>
                                    <Input className="h-7 bg-muted/50" name="accountName" defaultValue={bankFormEdit.editingIndex !== null ? bankDetail[bankFormEdit.editingIndex]?.accountName || '' : ''}
                                      onChange={(e) => handleBChange(bankFormEdit.editingIndex, 'accountName', e.target.value)} />
                                  </div>
                                  <div className="flex flex-row items-center gap-2">
                                    <Label className="w-40">Branch</Label>
                                    <Input className="h-7 bg-muted/50" name="branch" defaultValue={bankFormEdit.editingIndex !== null ? bankDetail[bankFormEdit.editingIndex]?.branch || '' : ''}
                                      onChange={(e) => handleBChange(bankFormEdit.editingIndex, 'branch', e.target.value)} />
                                  </div>
                                  <div className="flex flex-row items-center gap-2">
                                    <Label className="w-40">Account Type</Label>
                                    {/* <Input className="h-7 bg-muted/50" name="accountType" defaultValue={bankFormEdit.editingIndex !== null ? bankDetail[bankFormEdit.editingIndex]?.accountType || '' : ''}
                                      onChange={(e) => handleBChange(bankFormEdit.editingIndex, 'accountType', e.target.value)} /> */}
                                    <Select name="accountType" defaultValue={bankFormEdit.editingIndex !== null ? bankDetail[bankFormEdit.editingIndex]?.accountType || '' : ''}
                                      onValueChange={(e) => handleBChange(bankFormEdit.editingIndex, 'accountType', e)}>
                                      <SelectTrigger className="w-full h-7 bg-muted/50 focus:ring-transparent">
                                        <SelectValue />
                                      </SelectTrigger>
                                      <SelectContent>
                                        <SelectGroup>
                                          {accountTypeItems.map(item => (
                                            <SelectItem key={item} value={item}>
                                              {item}
                                            </SelectItem>
                                          ))}
                                        </SelectGroup>
                                      </SelectContent>
                                    </Select>
                                  </div>
                                  <div className="flex flex-row items-center gap-2">
                                    <Label className="w-40">IFSC Code</Label>
                                    <Input className="h-7 bg-muted/50" name="ifscCode" defaultValue={bankFormEdit.editingIndex !== null ? bankDetail[bankFormEdit.editingIndex]?.ifscCode || '' : ''}
                                      onChange={(e) => handleBChange(bankFormEdit.editingIndex, 'ifscCode', e.target.value)} />
                                  </div>
                                  <div className="flex flex-row items-center gap-2">
                                    <Label className="w-40">Role</Label>
                                    <Input className="h-7 bg-muted/50" name="role" defaultValue={bankFormEdit.editingIndex !== null ? bankDetail[bankFormEdit.editingIndex]?.role || '' : ''}
                                      onChange={(e) => handleBChange(bankFormEdit.editingIndex, 'role', e.target.value)} />
                                  </div>
                                </div>
                                <div className="flex flex-row col-span-4 px-4 my-4">
                                  <Button variant="outline" className="ml-auto mr-3 bg-primary/30 hover:bg-primary/40" onClick={handleBankFormSave}>Save</Button>
                                  <Button variant="outline" className="bg-primary/30 hover:bg-primary/40" onClick={(e) => { setBankFormEdit(prevState => ({ ...prevState, visible: !prevState.visible, editingIndex: null })); e.preventDefault(); }}>Close</Button>
                                </div>
                              </TableCell>
                            </TableRow>
                          )}
                        </React.Fragment>
                      ))
                    ) : (
                      <TableRow>
                        <TableCell colSpan={10} className="text-center">No Bank Details</TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
                {bankFormEdit.visible && bankFormEdit.editingIndex === null && (
                  <div>
                    <Separator />
                    <div className="grid grid-cols-1 gap-4 px-4 my-4 md:grid-cols-2 lg:grid-cols-3">
                      <div className="flex flex-row items-center gap-3">
                        <Label className="w-40">Account Number</Label>
                        <Input className="h-7 bg-muted/50" name="accountName" defaultValue={bankFormEdit.editingIndex !== null ? bankDetail[bankFormEdit.editingIndex]?.accountName || '' : ''}
                          onChange={(e) => handleBChange(bankFormEdit.editingIndex, 'accountName', e.target.value)} />
                      </div>
                      <div className="flex flex-row items-center gap-2">
                        <Label className="w-40">Branch</Label>
                        <Input className="h-7 bg-muted/50" name="branch" defaultValue={bankFormEdit.editingIndex !== null ? bankDetail[bankFormEdit.editingIndex]?.branch || '' : ''}
                          onChange={(e) => handleBChange(bankFormEdit.editingIndex, 'branch', e.target.value)} />
                      </div>
                      <div className="flex flex-row items-center gap-2">
                        <Label className="w-40">Account Type</Label>
                        {/* <Input className="h-7 bg-muted/50" name="accountType" defaultValue={bankFormEdit.editingIndex !== null ? bankDetail[bankFormEdit.editingIndex]?.accountType || '' : ''}
                            onChange={(e) => handleBChange(bankFormEdit.editingIndex, 'accountType', e.target.value)} /> */}
                        <Select name="accountType" defaultValue={bankFormEdit.editingIndex !== null ? bankDetail[bankFormEdit.editingIndex]?.accountType || '' : ''}
                          onValueChange={(e) => handleBChange(bankFormEdit.editingIndex, 'accountType', e)}>
                          <SelectTrigger className="w-full h-7 bg-muted/50 focus:ring-transparent">
                            <SelectValue />
                          </SelectTrigger>
                          <SelectContent>
                            <SelectGroup>
                              {accountTypeItems.map(item => (
                                <SelectItem key={item} value={item}>
                                  {item}
                                </SelectItem>
                              ))}
                            </SelectGroup>
                          </SelectContent>
                        </Select>
                      </div>
                      <div className="flex flex-row items-center gap-2">
                        <Label className="w-40">IFSC Code</Label>
                        <Input className="h-7 bg-muted/50" name="ifscCode" defaultValue={bankFormEdit.editingIndex !== null ? bankDetail[bankFormEdit.editingIndex]?.ifscCode || '' : ''}
                          onChange={(e) => handleBChange(bankFormEdit.editingIndex, 'ifscCode', e.target.value)} />
                      </div>
                      <div className="flex flex-row items-center gap-2">
                        <Label className="w-40">Role</Label>
                        <Input className="h-7 bg-muted/50" name="role" defaultValue={bankFormEdit.editingIndex !== null ? bankDetail[bankFormEdit.editingIndex]?.role || '' : ''}
                          onChange={(e) => handleBChange(bankFormEdit.editingIndex, 'role', e.target.value)} />
                      </div>
                    </div>
                    <div className="flex flex-row col-span-4 px-4 my-4">
                      <Button variant="outline" className="ml-auto mr-3 bg-primary/30 hover:bg-primary/40" onClick={handleBankFormSave}>Save</Button>
                      <Button variant="outline" className="bg-primary/30 hover:bg-primary/40" onClick={(e) => { setBankFormEdit(prevState => ({ ...prevState, visible: !prevState.visible, editingIndex: null })); e.preventDefault(); }}>Close</Button>
                    </div>
                  </div>
                )}
              </CardContent>
            </Card>
          </div>
        </ScrollArea>
        <CardFooter className="py-0 mt-2 mr-2">
          <div className="ml-auto">
            <Button type='submit'>{editservicePro ? 'Edit' : 'Create'}</Button>
          </div>
        </CardFooter>
      </form>
    </div>
  );
}
