import { Button } from 'src/components/ui/button';
import { Label } from 'src/components/ui/label';
import { ScrollArea } from 'src/components/ui/scroll-area';
import { Separator } from 'src/components/ui/separator';
import { DatePicker } from 'src/pages/components/datepicker';
import { CirclePlus, Edit, Info, Trash2, Undo2 } from 'lucide-react';
import React, { useEffect, useState } from 'react'
import { Controller, useForm } from 'react-hook-form';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import { toast } from 'sonner';
import { SearchCustomer } from '../components/search-select';
import { Input } from 'src/components/ui/input';
import { Textarea } from 'src/components/ui/textarea';
import SupplierSelect from 'src/pages/components/supplier-select';
import { Card, CardContent, CardDescription, CardFooter } from 'src/components/ui/card';
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from 'src/components/ui/table';
import { Select, SelectContent, SelectGroup, SelectItem, SelectTrigger, SelectValue } from 'src/components/ui/select';
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from 'src/components/ui/tooltip';
import { Checkbox } from 'src/components/ui/checkbox';

export default function InvcarrierForm() {
  const { id } = useParams();
  const location = useLocation();
  const data = location.state?.item;
  const navigate = useNavigate();
  const [invoiceId, setInvoiceId] = useState('');
  const [customerForm, setCustomerForm] = useState(false);
  const [loading, setLoading] = useState("");
  const [isVisible, setIsVisible] = useState(false);
  const [isError, setIsError] = useState({
    customer: false,
    supplier: false,
    passport: false,
    passenger: false,
    carrier: false

  });
  const { register, handleSubmit, control, reset, watch, setValue, getValues, trigger, formState: { errors }, } = useForm({
    defaultValues: {
      category: "passport",
      createdBy: "amigo2",
      passportProductDetails: [
        {
          id: "",
          code: "",
          name: "passport",
          netAmount: 0,
          state: "",
        },
      ],
      customerRefOrValue: {
        id: "",
        name: "",
        address: "",
        email: "",
        phone: "",
        panNo: "",
        gstNo: "",
      },
    }
  });

  const [titleItems, setTitleItems] = useState([]);
  const [paxTypeItems, setPaxTypeItems] = useState([]);
  const [genderTypeItems, setGenderTypeItems] = useState([]);
  const [applicationTypeItems, setApplicationTypeItems] = useState([]);
  const [carrierForm, setCarrierForm] = useState([]);
  const [editingCarrierForm, setEditingCarrierForm] = useState([]);
  const [carrierFormEdit, setcarrierFormEdit] = useState({ visible: false, editingIndex: null });
  const [passengerForm, setPassengerForm] = useState([]);
  const [editingPassengerForm, setEditingPassengerForm] = useState([]);
  const [passengerFormEdit, setPassengerFormEdit] = useState({ visible: false, editingIndex: null });
  const [custChargeForm, setCustChargeForm] = useState([
    {
      chargeCodeName: "Service Charge",
      calculationMethod: "",
      value: 0,
      totalValue: 0,
      tax: [],
      taxTotal: null,
    },
    {
      chargeCodeName: "Miscellaneous Charges",
      calculationMethod: "",
      value: 0,
      totalValue: 0,
      tax: [],
      taxTotal: null,
    },
    {
      chargeCodeName: "Documentation Charge",
      calculationMethod: "",
      value: 0,
      totalValue: 0,
      tax: [],
      taxTotal: null,
    },
    {
      chargeCodeName: "Management Fees",
      calculationMethod: "",
      value: 0,
      totalValue: 0,
      tax: [],
      taxTotal: null,
    }
  ]);
  const [suppChargeForm, setSuppChargeForm] = useState([
    {
      chargeCodeName: "Commission Charge",
      calculationMethod: "",
      value: 0,
      totalValue: 0,
      tax: [],
      taxTotal: null,
    },
    {
      chargeCodeName: "Miscellaneous Charges",
      calculationMethod: "",
      value: 0,
      totalValue: 0,
      tax: [],
      taxTotal: null,
    },
    {
      chargeCodeName: "Documentation Charge",
      calculationMethod: "",
      value: 0,
      totalValue: 0,
      tax: [],
      taxTotal: null,
    },
    {
      chargeCodeName: "Management Fees",
      calculationMethod: "",
      value: 0,
      totalValue: 0,
      tax: [],
      taxTotal: null,
    }
  ])
  const CUST_CHARGE_TYPES = [
    "Service Charge",
    "Miscellaneous Charges",
    "Documentation Charge",
    "Management Fees"
  ];

  useEffect(() => {
    getLists();
    setValue('invoiceDate', new Date().toISOString());
    if (data && id) {
      setInvoiceId(data.invoiceId);
      reset(data);
      if (data.passportProductDetails && data.passportProductDetails.length > 0 && data.passportProductDetails[0].product && data.passportProductDetails[0].product.passenger) {
        const passengerData = data.passportProductDetails[0].product.passenger;
        setPassengerForm(passengerData);
        setEditingPassengerForm(passengerData);
      } else {
        setPassengerForm([
          {
            title: "",
            firstName: "",
            middleName: "",
            lastName: "",
            fullName: "",
            paxType: ""
          }
        ]);
      } if (data.passportProductDetails && data.passportProductDetails.length > 0 && data.passportProductDetails[0].product && data.passportProductDetails[0].product.charges) {
        const chargeData = data.passportProductDetails[0].product.charges;
        setCustChargeForm(chargeData);
      } else {
        setCustChargeForm([
          {
            chargeCodeName: "",
            calculationMethod: "",
            value: 0,
            totalValue: 0,
            tax: [],
            taxTotal: 0,
          }
        ]);
      }
      if (data.passportProductDetails && data.passportProductDetails.length > 0 && data.passportProductDetails[0].product && data.passportProductDetails[0].product.carriers) {
        const carrierData = data.passportProductDetails[0].product.carriers;
        setCarrierForm(carrierData);
        setEditingCarrierForm(carrierData);

      } else {
        setCarrierForm([
          {
            serviceDetails: "",
            serviceType: "",
            applicationType: "",
            applicationDate: ""
          }
        ]);
      }
      setIsVisible(true);
    }
  }, [data, id, reset, setValue]);

  const getLists = async () => {
    try {
      const response = await fetch(`${(process.env.REACT_APP_API_HOST_MSTR === undefined) ? "" : process.env.REACT_APP_API_HOST_MSTR}/amigo-lekha-api/listManagement/v1/list`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json'
        },
      });
      if (!response.ok) {
        throw new Error('Failed to fetch data');
      }
      const fetchedData = await response.json();
      fetchedData.forEach(item => {
        switch (item.name) {
          case 'Title':
            setTitleItems(item.items);
            break;
          case 'PaxType':
            setPaxTypeItems(item.items);
            break;
          case 'Gender':
            setGenderTypeItems(item.items);
            break;
          case 'PassportApplicationType':
            setApplicationTypeItems(item.items);
            break;
          default:
            break;
        }
      });
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  }

  const calculateTaxForCharge = (totalValue, chargeTaxes, taxValues) => {
    let taxTotal = 0;
    const updatedTaxes = chargeTaxes.map(chargeTax => {
      const matchingTaxValue = taxValues.find(taxValue => taxValue.id === chargeTax.taxCode);
      if (matchingTaxValue) {
        const taxAmount = totalValue * (matchingTaxValue.value / 100);
        taxTotal += taxAmount;
        return { ...chargeTax, value: taxAmount };
      }
      return chargeTax;
    });
    return { tax: updatedTaxes, taxTotal };
  };
  const [taxValues, setTaxValues] = useState([]);

  const handleCustomerSelect = async (customer, customerCharges) => {
    const chrgForm = [{
      chargeCodeName: "Service Charge",
      calculationMethod: "",
      value: 0,
      totalValue: 0,
      tax: [],
      taxTotal: null,
    },
    {
      chargeCodeName: "Miscellaneous Charges",
      calculationMethod: "",
      value: 0,
      totalValue: 0,
      tax: [],
      taxTotal: null,
    },
    {
      chargeCodeName: "Documentation Charge",
      calculationMethod: "",
      value: 0,
      totalValue: 0,
      tax: [],
      taxTotal: null,
    },
    {
      chargeCodeName: "Management Fees",
      calculationMethod: "",
      value: 0,
      totalValue: 0,
      tax: [],
      taxTotal: null,
    }]
    if (!customerCharges || !customerCharges.configDetails || customerCharges.configDetails.length === 0) {
      toast.error(`No charges for ${customer.customerCode}`);
      setValue("passportProductDetails[0].product.charges", chrgForm);
    }
    const passportCharges = customerCharges?.configDetails?.filter(charge => charge.ProductRef.id === "Passport");
    if (passportCharges?.length === 0) {
      toast.error(`No passport charges for ${customer.customerCode}`);
      setValue("passportProductDetails[0].product.charges", chrgForm);
    }
    setIsError(prevState => ({ ...prevState, customer: false }));
    setValue('customerRefOrValue.id', customer.customerCode || '');
    setValue('customerRefOrValue.name', customer.fullName || '');
    setValue('customerRefOrValue.phone', customer.contactNumber || '');
    setValue('customerRefOrValue.email', customer.emailId || '');
    setValue('customerRefOrValue.address', customer.addressId[0]?.addressLine || '');
    setValue('customerRefOrValue.panNo', customer.panNumber || '');
    setValue('customerRefOrValue.gstNo', customer.gstNumber || '');
    setCustChargeForm([]);
    const updatedChargeForm = custChargeForm.map(charge => ({
      ...charge,
      value: 0,
      calculationMethod: '',
      totalValue: 0,
      tax: [{
        taxCode: "",
        value: 0,
      }],
      taxTotal: 0,
    }));
    let fetchedTaxValues;
    const hasPassportCharge = customerCharges.configDetails?.some(charge => charge.ProductRef.id === "Passport");
    if (hasPassportCharge) {
      fetchedTaxValues = await getTaxValues(chrgForm, customerCharges?.configDetails);
    }
    const currentDate = new Date();
    customerCharges?.configDetails
      ?.filter((charge) => charge.ProductRef.id === "Passport")
      .forEach((charge) => {
        const matchingCharge = updatedChargeForm.find(
          (c) => c.chargeCodeName === charge.ledger.id
        );
        if (matchingCharge) {
          matchingCharge.value = charge.amount || 0;
          matchingCharge.calculationMethod = charge.calculationMethod;
          const isWithinDateRange = (
            (charge.effectStartDate === null && charge.effectEndDate === null) ||
            (charge.effectStartDate === null && new Date(charge.effectEndDate) >= currentDate) ||
            (charge.effectEndDate === null && new Date(charge.effectStartDate) <= currentDate) ||
            (new Date(charge.effectStartDate) <= currentDate && new Date(charge.effectEndDate) >= currentDate)
          );
          if (isWithinDateRange) {
            matchingCharge.tax = charge.taxes?.map(taxCode => ({
              taxCode,
              value: 0
            })) || [];
          } else {
            matchingCharge.tax = [];
          }
        }
      });
    setCustChargeForm(updatedChargeForm);
    if (fetchedTaxValues) {
      calcCharge(updatedChargeForm, passengerForm.length, fetchedTaxValues);
    }
  };
  const customerRefOrValue = watch("customerRefOrValue");
  let pLength = 0;

  const getTaxValues = async (chargeForm, configDetails) => {
    let taxIds;
    if (configDetails !== null) {
      taxIds = configDetails
        ?.filter((charge) => charge.ProductRef.id === "Passport")
        ?.flatMap((charge) => charge.taxes?.map((tax) => tax) || [])
        .filter((id, index, self) => self.indexOf(id) === index);
    } else {
      taxIds = chargeForm
        .flatMap((charge) => charge.tax?.map((tax) => tax.taxCode) || [])
        .filter((id, index, self) => self.indexOf(id) === index);
    }
    const taxValues = await Promise.all(
      taxIds.map(async (taxId) => {
        try {
          const response = await fetch(`${(process.env.REACT_APP_API_HOST_MSTR === undefined) ? "" : process.env.REACT_APP_API_HOST_MSTR}/amigo-lekha-api/taxTypeManagement/v1/taxType/${taxId}`);
          if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
          }
          const data = await response.json();
          return { id: taxId, value: data.valueDetails[0].value };
        } catch (error) {
          console.error(`Error fetching tax data for ID ${taxId}:`, error);
          return { id: taxId, value: 0 };
        }
      })
    );
    if (taxValues.length > 0) {
      calcCharge(chargeForm, pLength, taxValues);
    }
    setTaxValues(taxValues);
    return taxValues;
  };

  const calcCharge = (updatedChargeForm, length, taxValues) => {
    if (taxValues.length === 0 && customerRefOrValue.name) {
      getTaxValues(custChargeForm, null);
    }
    if (!length) { length = 0 };
    const charges = updatedChargeForm.map((charge) => {
      let totalValue;
      switch (charge.calculationMethod) {
        case "PerTicket":
          totalValue = charge.value;
          break;
        case "PerPassenger":
          const multi = length === 0 ? 1 : length;
          totalValue = multi * charge.value;
          break;
        default:
          totalValue = charge.value;
      }
      return {
        ...charge,
        totalValue,
      };
    });
    const chargesWithTax = charges.map((charge) => {
      const { tax, taxTotal } = calculateTaxForCharge(charge.totalValue, charge.tax, taxValues);
      const roundedTax = tax.map(t => ({
        ...t,
        value: Math.round(t.value * 100) / 100
      }));
      const roundedTaxTotal = Math.round(taxTotal * 100) / 100;
      return { ...charge, tax: roundedTax, taxTotal: roundedTaxTotal };
    });
    setCustChargeForm(chargesWithTax);
    setValue("passportProductDetails[0].product.charges", chargesWithTax);
  };

  const handleChargeChange = (index, field, value) => {
    if (index === null) {
      index = 0;
    }
    setCustChargeForm((prevCharges) => {
      const updatedCharges = [...prevCharges];
      updatedCharges[index] = {
        ...updatedCharges[index],
        [field]: value
      };
      return updatedCharges;
    });
  };
  const handleChargeFormSave = (e) => {
    e.preventDefault();
    setValue('passportProductDetails[0].product.charges', custChargeForm);
    calcCharge(custChargeForm, editingPassengerForm.length, taxValues);
  };

  const handleSupplierSelect = (supplier) => {
    setIsError(prevState => ({ ...prevState, supplier: false }));
    setValue("passportProductDetails[0].product.agentRefOrValue.name", supplier.fullName);
    setValue("passportProductDetails[0].product.agentRefOrValue.id", supplier.supplierCode);
  }
  const supplierName = watch("passportProductDetails[0].product.agentRefOrValue") || '';

  const [formState, setFormState] = useState('Draft');
  const updateValidationRules = (state) => {
    if (state === 'Submitted') {
      setValue('state', 'Submitted');
      setValue('passportProductDetails[0].state', 'Submitted');
    } else {
      setValue('state', 'Draft');
      setValue('passportProductDetails[0].state', 'Draft');
    }
    trigger();
  }

  const charges = watch("passportProductDetails[0].product.fare[0].charges", 0);
  const markup = watch("passportProductDetails[0].product.fare[0].markup", 0);

  useEffect(() => {
    const parsedcharges = parseInt(charges) || 0;
    const parsedMarkup = parseInt(markup) || 0;

    const netAmount = parsedcharges + parsedMarkup
    setValue("passportProductDetails[0].product.fare[0].netAmount", netAmount);
  }, [charges, markup, setValue]);

  const onSave = () => {
    setFormState('Draft');
    updateValidationRules('Draft');
    const data = getValues();
    setIsError(prevState => ({ ...prevState, passenger: false, passport: false, fare: false, supplier: false }))
    if (!data.customerRefOrValue?.id) {
      setIsError(prevState => ({ ...prevState, customer: true }));
      return;
    }
  }

  const onCreate = (state) => {
    setFormState('Submitted');
    updateValidationRules('Submitted');
    const data = getValues();
    if (state) {
      setValue('state', state);
      setValue('passportProductDetails[0].state', state);
    }
    if (!data.customerRefOrValue?.id) {
      setIsError(prevState => ({ ...prevState, customer: true }));
      if (!data.passportProductDetails[0]?.product.agentRefOrValue?.name) {
        setIsError(prevState => ({ ...prevState, supplier: true }));
      }
      return;
    }
    if (!data.passportProductDetails[0]?.product.agentRefOrValue?.name) {
      setIsError(prevState => ({ ...prevState, supplier: true }));
      return;
    }
    const passengers = data.passportProductDetails[0]?.product?.passenger || [];
    if (passengers.length === 0 || !passengers[0].firstName) {
      setIsError(prevState => ({ ...prevState, passenger: true }));
      setPassengerFormEdit({ visible: true, editingIndex: null })
      toast.error("Passenger is required");
      return;
    }
  }

  const isValidPhone = (phone) => {
    const phoneRegex = /^[0-9]{10}$/;
    return phoneRegex.test(phone);
  };
  const isValidEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };
  const clearPassengerErrors = () => {
    setPassengerForm(prevForm =>
      prevForm.map(passenger => ({
        ...passenger,
        errors: {}
      }))
    );
  };
  // passenger handle
  const handlePChange = (index, field, value) => {
    if (index === null) {
      index = editingPassengerForm.length;
    }
    setPassengerForm((prevPassengers) => {
      const updatedPassengers = [...prevPassengers];
      let errorMessage = '';
      if (field === 'mobile') {
        errorMessage = isValidPhone(value) ? '' : 'Invalid phone number';
      } else if (field === 'email') {
        errorMessage = isValidEmail(value) ? '' : 'Invalid email address';
      }
      updatedPassengers[index] = {
        ...updatedPassengers[index],
        [field]: value,
        errors: {
          ...updatedPassengers[index]?.errors,
          [field]: errorMessage
        }
      };
      if (field === 'firstName' || field === 'middleName' || field === 'lastName') {
        const firstName = field === 'firstName' ? value : updatedPassengers[index].firstName || '';
        const middleName = field === 'middleName' ? value : updatedPassengers[index].middleName || '';
        const lastName = field === 'lastName' ? value : updatedPassengers[index].lastName || '';
        updatedPassengers[index].fullName = `${firstName} ${lastName} ${middleName}`.trim();
      }
      return updatedPassengers;
    });
  };

  const handlePassengerFormSave = (e) => {
    e.preventDefault();
    const updatedPassengerForm = passengerForm.map(passenger => ({
      ...passenger,
      fullName: [passenger.firstName, passenger.middleName, passenger.lastName].filter(Boolean).join(' ')
    }));
    const hasErrors = passengerForm.some(passenger =>
      passenger.errors && Object.values(passenger.errors).some(error => error !== '')
    );
    if (hasErrors) {
      toast.error("Please correct the errors before saving.");
      return;
    }
    setValue('passportProductDetails[0].product.passenger', updatedPassengerForm);
    setEditingPassengerForm(updatedPassengerForm);
    setIsError(prevState => ({ ...prevState, passenger: false }));
    calcCharge(custChargeForm, updatedPassengerForm.length, taxValues);
    setPassengerFormEdit({ visible: false, editingIndex: null });
  };

  const handleDeleteP = (index) => {
    const updatedPassengers = passengerForm.filter((_, i) => i !== index);
    if (updatedPassengers.length <= 1) { setValue("split", false) };
    setValue('passportProductDetails[0].product.passenger', updatedPassengers);
    setEditingPassengerForm(updatedPassengers);
    setPassengerForm(updatedPassengers);
    pLength = updatedPassengers.length;
    calcCharge(custChargeForm, updatedPassengers.length, taxValues);
    setPassengerFormEdit({ visible: false, editingIndex: null });
  }
  const [currentRemark, setCurrentRemark] = useState({ name: '', charge: '' });
  const [remarks, setRemarks] = useState([]);

  const updateCurrentRemark = (field, value) => {
    setCurrentRemark(prev => ({ ...prev, [field]: value }));
  };
  const addRemark = (index) => {
    if (index === null) {
      index = editingPassengerForm.length;
    }
    if (currentRemark.name || currentRemark.charge) {
      if (!remarks.some(r => r.name === currentRemark.name && r.charge === currentRemark.charge)) { setRemarks(prev => [...prev, currentRemark]); }
      setPassengerForm(prevForm => {
        const updatedForm = [...prevForm];
        if (!updatedForm[index]) {
          updatedForm[index] = {};
        }
        if (!updatedForm[index].remark) {
          updatedForm[index].remark = [];
        }
        if (!updatedForm[index].remark.some(r => r.name === currentRemark.name && r.charge === currentRemark.charge)) {
          updatedForm[index].remark.push(currentRemark);
        }
        return updatedForm;
      });
      setCurrentRemark({ name: '', charge: '' });
    }
  };
  const deleteRemark = (remarkIndex, passengerIndex) => {
    if (passengerIndex === null) {
      passengerIndex = editingPassengerForm.length;
    }
    setRemarks(prevRemarks => prevRemarks.filter((_, index) => index !== remarkIndex));
    setPassengerForm(prevForm => {
      const updatedForm = [...prevForm];
      if (updatedForm[passengerIndex] && updatedForm[passengerIndex].remark) {
        updatedForm[passengerIndex].remark = updatedForm[passengerIndex].remark.filter((_, index) => index !== remarkIndex);
      }
      return updatedForm;
    });
  };

  //carrier handle
  const handleCChange = (index, field, value) => {
    if (index === null) {
      index = editingCarrierForm.length;
    }
    setCarrierForm((prevCarrier) => {
      const updatedCarrier = [...prevCarrier];
      updatedCarrier[index] = {
        ...updatedCarrier[index],
        [field]: value
      };
      return updatedCarrier;
    });
  };
  const handleCarrierFormSave = (e) => {
    e.preventDefault();
    const filteredCarrierForm = carrierForm.filter(carrier => Object.values(carrier).some(field => field !== "")
    );
    if (filteredCarrierForm.length === 0) { return; }
    setValue('passportProductDetails[0].product.carriers', carrierForm);
    setEditingCarrierForm(carrierForm);
    setIsError(prevState => ({ ...prevState, carrier: false }));
    setcarrierFormEdit({ visible: false, editingIndex: null });

  };
  const handleDeleteC = (index) => {
    const updatedCarriers = carrierForm.filter((_, i) => i !== index);
    setValue('passportProductDetails[0].product.carriers', updatedCarriers);
    setEditingCarrierForm(updatedCarriers);
    setCarrierForm(updatedCarriers);
    setcarrierFormEdit({ visible: false, editingIndex: null });
  }
  const handleSplitInv = (checked) => {
    setValue('split', checked);
  };

  const onSubmit = (data) => {
    const netAmt = calcNet(data);
    if (data.passportProductDetails && data.passportProductDetails[0]) {
      data.passportProductDetails[0].netAmount = netAmt;
      data.netAmount = netAmt;
    }
    if (data.invoiceId) {
      handleEditInv(data);
    } else {
      handleCreateInv(data);
    }
  };

  const handleCreateInv = async (data) => {
    const { id, ...requestData } = data;
    if (Object.values(isError).some(error => error)) {
      return;
    }
    setLoading("Create");
    try {
      const response = await fetch(`${(process.env.REACT_APP_API_HOST_INV === undefined) ? "" : process.env.REACT_APP_API_HOST_INV}/amigo-lekha-api/invoiceManagement/v1/invoice`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(requestData),
      });
      if (!response.ok) {
        throw new Error('Failed to create invoice');
      }
      const res = await response.json();
      const id = encodeURIComponent(res.invoiceId);
      reset();
      toast.success("Invoice created successfuly!", { duration: "5000", position: "top-right", description: `Invoice Id - ${id}` });
      if (res.state === "Draft") {
        navigate(`/dashboard/invoices/passport/edit/${id}`, { state: { item: res } });
      } else {
        navigate(`/dashboard/invoices/passport`);
      }
    } catch (error) {
      console.error("Error creating invoice:", error);
      toast.error("Failed to create invoice");
    } finally {
      setLoading("");
    }
  };

  const handleEditInv = async (data) => {
    if (Object.values(isError).some(error => error)) {
      return;
    }
    if (data.state === "Draft") {
      setLoading("Edit");
    } else {
      setLoading("Create");
    }
    try {
      const response = await fetch(`${(process.env.REACT_APP_API_HOST_INV === undefined) ? "" : process.env.REACT_APP_API_HOST_INV}/amigo-lekha-api/invoiceManagement/v1/invoice/${data.invoiceId}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(data),
      });
      // ?invoiceId=${data.invoiceId} for query param
      if (!response.ok) {
        throw new Error('Failed to edit invoice');
      }
      const res = await response.json();
      const id = encodeURIComponent(res.invoiceId);
      toast.success(`Invoice ${res.state === "Draft" ? "Edited" : "Submitted"} Successfuly!`, { duration: "5000", position: "top-right", description: `Invoice Id - ${id}` });
      reset(res);
      if (res.state === "Draft") {
        navigate(`/dashboard/invoices/passport/edit/${id}`, { state: { item: res } });
      } else {
        navigate(`/dashboard/invoices/passport`);
      }
    } catch (error) {
      console.error("Error editing invoice:", error);
      toast.error("Failed to edit invoice");
    } finally {
      setLoading("");
    }
  };

  const calcNet = (data) => {
    let fareTotal = data.passportProductDetails[0]?.product?.fare[0]?.netAmount;
    if (isNaN(fareTotal) || fareTotal === undefined) { fareTotal = 0; }
    const chrgTax = data.passportProductDetails[0].product.charges;
    let totalValue = 0;
    let taxTotal = 0;
    if (Array.isArray(chrgTax)) {
      chrgTax.forEach(chargeItem => {
        totalValue += parseFloat(chargeItem.totalValue) || 0;
        taxTotal += parseFloat(chargeItem.taxTotal) || 0;
      });
    }
    const netAmount = fareTotal + totalValue + taxTotal;
    return netAmount;
  }

  return (
    <div className='mx-8 my-5'>
      <Link to={`/dashboard/invoices/passport`} className='absolute mt-1 ml-5'><Button variant="outline" size="icon"><Undo2 className='size-4' /></Button></Link>
      <div className="flex items-center justify-center w-full mb-5 text-[3vh] font-bold">{id ? "Edit" : "New"} Invoice (passport)</div>
      {invoiceId && <div className='flex mx-5 my-0'><div>Invoice Id - <span className='text-primary/70'>{invoiceId}</span></div><div className='ml-auto mr-2'>Total NetAmount : ₹{data?.passportProductDetails[0].netAmount}&ensp;|</div><div>Created By : {data?.createdBy}</div></div>}
      <Separator />
      <form onSubmit={handleSubmit(onSubmit)} className='mt-2'>
        <ScrollArea className="h-[calc(100vh-200px)] lg:h-[70vh]">
          <div className="grid gap-4 px-5 py-2">
            <div className="grid grid-cols-1 gap-4 md:grid-cols-2 lg:grid-cols-5">
              <div className="flex flex-row items-center gap-2">
                <Label className="flex w-40">Invoice Date{errors.invoiceDate && <p className="ml-1 text-destructive">*</p>}</Label>
                <Controller control={control} name="invoiceDate" rules={{ required: true }} {...register("invoiceDate")}
                  render={({ field }) => (
                    <DatePicker value={field.value} onChange={(date) => field.onChange(date)} disableDate={false} current={true} error={errors.invoiceDate} />
                  )}
                />
              </div>
              <div className="flex gap-2">
                <div className="flex flex-row items-center gap-2">
                  <Label className="flex w-32">Customer Name{isError.customer && <p className="ml-1 text-destructive">*</p>}</Label>
                  <SearchCustomer onCustomerSelect={handleCustomerSelect} value={customerRefOrValue?.id} isVisible={isVisible} isError={isError.customer} />
                  {/* {isError.customer && <p className="text-xs text-red-500">Select Customer!</p>} */}
                </div>
                <Button variant='secondary' className="w-20 h-7" onClick={(e) => { setCustomerForm(!customerForm); e.preventDefault() }}>{customerForm ? "Hide" : "More"} Info</Button>
              </div>
            </div>
            {customerForm && (
              <div className="grid grid-cols-1 gap-2 md:grid-cols-2 lg:grid-cols-4">
                <div className="flex flex-row items-center gap-2">
                  <Label className="w-40">Phone No</Label>
                  <Input className="h-7 bg-muted/50" disabled={true} name="phone" value={customerRefOrValue?.phone} {...register("customerRefOrValue.phone")} />
                </div>
                <div className="flex flex-row items-center gap-2">
                  <Label className="w-40">Email</Label>
                  <Input className="h-7 bg-muted/50" disabled={true} name="email" value={customerRefOrValue?.email} {...register("customerRefOrValue.email")} />
                </div>
                <div className="flex flex-row items-center gap-2">
                  <Label className="w-40">Print In Name</Label>
                  <Input className="h-7 bg-muted/50" disabled={true} name="printInName" {...register("printInName")} />
                </div>
                <div className="flex flex-row items-center gap-2">
                  <Label className="w-36">Address</Label>
                  <Textarea className="h-16 bg-muted/50" disabled={true} name="address" value={customerRefOrValue?.address} {...register("customerRefOrValue.address")} />
                </div>
                <div className="flex flex-row items-center gap-2">
                  <Label className="w-40">PAN No</Label>
                  <Input className="h-7 bg-muted/50" disabled={true} name="panNo" value={customerRefOrValue?.panNo} {...register("customerRefOrValue.panNo")} />
                </div>
                <div className="flex flex-row items-center gap-2">
                  <Label className="w-40">GST Number</Label>
                  <Input className="h-7 bg-muted/50" disabled={true} name="gstNo" value={customerRefOrValue?.gstNo} {...register("customerRefOrValue.gstNo")} />
                </div>
              </div>
            )}
            <div className="grid grid-cols-1 gap-x-4 gap-y-2 md:grid-cols-2 lg:grid-cols-5">
              <div className="flex flex-row items-center gap-2">
                <Label className="w-40">Due Date</Label>
                <Controller control={control} name="dueDate" {...register("dueDate")}
                  render={({ field }) => (
                    <DatePicker value={field.value} onChange={(date) => field.onChange(date)} disableDate={false} />
                  )}
                />
              </div>
              <div className="flex flex-row items-center gap-2">
                <Label className="flex w-40 lg:w-52">Supplier Name{isError.supplier && <p className="ml-1 text-destructive">*</p>}</Label>
                <SupplierSelect onSupplierSelect={handleSupplierSelect} value={supplierName.id} isVisible={isVisible} isError={isError.supplier} />
              </div>
              <div className="flex flex-row items-center gap-2">
                <Label className="flex w-40 lg:w-48">Supplier Amount</Label>
                <Input className="h-7 bg-muted/50" name="supplierNetAmount" {...register("passportProductDetails[0].supplierNetAmount")} />
              </div>
            </div>
            <div className="grid grid-cols-1 gap-x-4 gap-y-2 md:grid-cols-2 lg:grid-cols-5">
              <div className="flex flex-row items-center gap-2">
                <Label className="flex w-40">Passport{errors.passportProductDetails?.[0]?.product?.passportDetails && <p className="ml-1 text-destructive">*</p>}</Label>
                <Input className={`h-7 bg-muted/50 ${errors.passportProductDetails?.[0]?.product?.passportDetails ? 'border-destructive focus-visible:ring-0' : ''}`} name="passportDetails" {...register("passportProductDetails[0].product.passportDetails", { required: formState === "Submitted" })} />
              </div>
              <div className="flex flex-row items-center gap-2">
                <Label className="flex w-40 lg:w-44">Through Agent{errors.passportProductDetails?.[0]?.product?.throughAgent && <p className="ml-1 text-destructive">*</p>}</Label>
                <Input className={`h-7 bg-muted/50 ${errors.passportProductDetails?.[0]?.product?.throughAgent ? 'border-destructive focus-visible:ring-0' : ''}`} name="throughAgent" {...register("passportProductDetails[0].product.throughAgent", { required: formState === "Submitted" })} />
              </div>
              <div className="flex flex-row items-center gap-2">
                <Label className="flex w-40 lg:w-44">Requisition By{errors.passportProductDetails?.[0]?.product?.requisitionBy && <p className="ml-1 text-destructive">*</p>}</Label>
                <Input className={`h-7 bg-muted/50 ${errors.passportProductDetails?.[0]?.product?.requisitionBy ? 'border-destructive focus-visible:ring-0' : ''}`} name="requisitionBy" {...register("passportProductDetails[0].product.requisitionBy", { required: formState === "Submitted" })} />
              </div>
              <div className="flex flex-row items-center gap-2">
                <Label className="flex w-40 lg:w-44">Handled By{errors.passportProductDetails?.[0]?.product?.handledBy && <p className="ml-1 text-destructive">*</p>}</Label>
                <Input className={`h-7 bg-muted/50 ${errors.passportProductDetails?.[0]?.product?.handledBy ? 'border-destructive focus-visible:ring-0' : ''}`} name="handledBy" {...register("passportProductDetails[0].product.handledBy", { required: formState === "Submitted" })} />
              </div>
            </div>
            <Separator />
            <div className="flex flex-row items-center justify-between gap-4 mr-2">
              <CardDescription>Applicant Details :</CardDescription>
              <div className='flex items-center gap-4'>{editingPassengerForm.length > 1 && <div className='flex items-center gap-2'>Split Invoice -<Checkbox onCheckedChange={(checked) => { handleSplitInv(checked) }} /></div>}
                <Button size='sm' variant="secondary" onClick={(e) => { setPassengerFormEdit(prevState => ({ ...prevState, visible: !prevState.visible, editingIndex: null })); e.preventDefault(); setCurrentRemark({ name: '', charge: '' }); setRemarks([]); }}>Add Applicant <CirclePlus className="ml-2 size-4" /></Button>
              </div>
            </div>
            <Card>
              <CardContent className="p-0">
                <Table>
                  <TableHeader className="bg-secondary/60">
                    <TableRow>
                      <TableHead className="rounded-tl-md"></TableHead>
                      <TableHead></TableHead>
                      <TableHead>Title</TableHead>
                      <TableHead>Full Name</TableHead>
                      <TableHead>Pax Type</TableHead>
                      <TableHead>Mobile</TableHead>
                      <TableHead>Email</TableHead>
                      <TableHead className="rounded-tr-md"><span className="sr-only">Actions</span></TableHead>
                    </TableRow>
                  </TableHeader>
                  <TableBody>
                    {editingPassengerForm.length > 0 ? (
                      editingPassengerForm.map((passenger, index) => (
                        <React.Fragment key={index}>
                          <TableRow>
                            <TableCell></TableCell>
                            <TableCell>{index + 1}</TableCell>
                            <TableCell>{passenger.title}</TableCell>
                            <TableCell>{passenger.fullName}</TableCell>
                            <TableCell>{passenger.paxType}</TableCell>
                            <TableCell>{passenger.mobile}</TableCell>
                            <TableCell>{passenger.email}</TableCell>
                            <TableCell>
                              <div className="flex flex-row items-center gap-4">
                                <Edit onClick={() => setPassengerFormEdit(prevState => ({ ...prevState, visible: !prevState.visible, editingIndex: index }))} className="cursor-pointer text-primary size-4" />
                                <Trash2 onClick={() => handleDeleteP(index)} className="cursor-pointer text-destructive size-4" />
                              </div>
                            </TableCell>
                          </TableRow>
                          {passengerFormEdit.visible && passengerFormEdit.editingIndex === index && (
                            <TableRow>
                              <TableCell colSpan={11}>
                                <div className="grid gap-3 mx-6 my-4 lg:grid-cols-5 sm:grid-cols-1 md:grid-cols-2">
                                  <div className="flex flex-row items-center gap-2">
                                    <Label className="flex w-40 lg:w-32">Title{isError.passenger && <p className="ml-1 text-destructive">*</p>}</Label>
                                    <Select name="title" defaultValue={passengerFormEdit.editingIndex !== null ? passengerForm[passengerFormEdit.editingIndex]?.title || '' : ''}
                                      onValueChange={(e) => handlePChange(passengerFormEdit.editingIndex, 'title', e)}>
                                      <SelectTrigger className="w-full h-7 bg-muted/50 focus:ring-transparent">
                                        <SelectValue />
                                      </SelectTrigger>
                                      <SelectContent>
                                        <SelectGroup>
                                          {titleItems.map(item => (
                                            <SelectItem key={item} value={item}>
                                              {item}
                                            </SelectItem>
                                          ))}
                                        </SelectGroup>
                                      </SelectContent>
                                    </Select>
                                  </div>
                                  <div className="flex flex-row items-center gap-2">
                                    <Label className="flex w-40 lg:w-32">First Name{isError.passenger && <p className="ml-1 text-destructive">*</p>}</Label>
                                    <Input className="h-7 bg-muted/50" name="firstName" defaultValue={passengerFormEdit.editingIndex !== null ? passengerForm[passengerFormEdit.editingIndex]?.firstName || '' : ''}
                                      onChange={(e) => handlePChange(passengerFormEdit.editingIndex, 'firstName', e.target.value)} />
                                  </div>
                                  <div className="flex flex-row items-center gap-2">
                                    <Label className="flex w-40 lg:w-32">Middle Name{isError.passenger && <p className="ml-1 text-destructive">*</p>}</Label>
                                    <Input className="h-7 bg-muted/50" name="middleName" defaultValue={passengerFormEdit.editingIndex !== null ? passengerForm[passengerFormEdit.editingIndex]?.middleName || '' : ''}
                                      onChange={(e) => handlePChange(passengerFormEdit.editingIndex, 'middleName', e.target.value)} />
                                  </div>
                                  <div className="flex flex-row items-center gap-2">
                                    <Label className="flex w-40 lg:w-32">Last Name{isError.passenger && <p className="ml-1 text-destructive">*</p>}</Label>
                                    <Input className="h-7 bg-muted/50" name="lastName" defaultValue={passengerFormEdit.editingIndex !== null ? passengerForm[passengerFormEdit.editingIndex]?.lastName || '' : ''}
                                      onChange={(e) => handlePChange(passengerFormEdit.editingIndex, 'lastName', e.target.value)} />
                                  </div>
                                  <div className="flex flex-row items-center gap-2">
                                    <Label className="flex w-40 lg:w-32">Pax Type{isError.passenger && <p className="ml-1 text-destructive">*</p>}</Label>
                                    <Select name="paxType" defaultValue={passengerFormEdit.editingIndex !== null ? passengerForm[passengerFormEdit.editingIndex]?.paxType || '' : ''}
                                      onValueChange={(e) => handlePChange(passengerFormEdit.editingIndex, 'paxType', e)}>
                                      <SelectTrigger className="w-full h-7 bg-muted/50 focus:ring-transparent">
                                        <SelectValue />
                                      </SelectTrigger>
                                      <SelectContent>
                                        <SelectGroup>
                                          {paxTypeItems.map(item => (
                                            <SelectItem key={item} value={item}>
                                              {item}
                                            </SelectItem>
                                          ))}
                                        </SelectGroup>
                                      </SelectContent>
                                    </Select>
                                  </div>
                                  <div className="flex flex-row items-center gap-2">
                                    <Label className="flex w-40 lg:w-32">Gender{isError.passenger && <p className="ml-1 text-destructive">*</p>}</Label>
                                    <Select name="gender" defaultValue={passengerFormEdit.editingIndex !== null ? passengerForm[passengerFormEdit.editingIndex]?.gender || '' : ''}
                                      onValueChange={(e) => handlePChange(passengerFormEdit.editingIndex, 'gender', e)}>
                                      <SelectTrigger className="w-full h-7 bg-muted/50 focus:ring-transparent">
                                        <SelectValue />
                                      </SelectTrigger>
                                      <SelectContent>
                                        <SelectGroup>
                                          {genderTypeItems.map(item => (
                                            <SelectItem key={item} value={item}>
                                              {item}
                                            </SelectItem>
                                          ))}
                                        </SelectGroup>
                                      </SelectContent>
                                    </Select>
                                  </div>
                                  <div className="flex flex-col">
                                    <div className="flex flex-row items-center gap-2">
                                      <Label className="flex w-40 lg:w-32">Mobile{isError.passenger && <p className="ml-1 text-destructive">*</p>}</Label>
                                      <Input className="h-7 bg-muted/50" name="mobile" defaultValue={passengerFormEdit.editingIndex !== null ? passengerForm[passengerFormEdit.editingIndex]?.mobile || '' : ''}
                                        onChange={(e) => handlePChange(passengerFormEdit.editingIndex, 'mobile', e.target.value)} />
                                    </div>
                                    {passengerForm[passengerFormEdit.editingIndex]?.errors?.mobile && (
                                      <p className="text-xs text-destructive">{passengerForm[passengerFormEdit.editingIndex].errors.mobile}</p>
                                    )}
                                  </div>
                                  <div className="flex flex-col">
                                    <div className="flex flex-row items-center gap-2">
                                      <Label className="flex w-40 lg:w-32">Email{isError.passenger && <p className="ml-1 text-destructive">*</p>}</Label>
                                      <Input className="h-7 bg-muted/50" name="email" defaultValue={passengerFormEdit.editingIndex !== null ? passengerForm[passengerFormEdit.editingIndex]?.email : ''}
                                        onChange={(e) => handlePChange(passengerFormEdit.editingIndex, 'email', e.target.value)} />
                                    </div>
                                    {passengerForm[passengerFormEdit.editingIndex]?.errors?.email && (
                                      <p className="text-xs text-destructive">{passengerForm[passengerFormEdit.editingIndex].errors.email}</p>
                                    )}
                                  </div>
                                  <div className="flex flex-row items-center col-span-2 gap-2">
                                    <Label className="flex w-40 lg:w-28">Address{isError.passenger && <p className="ml-1 text-destructive">*</p>}</Label>
                                    <Input className="h-7 bg-muted/50" name="address" defaultValue={passengerFormEdit.editingIndex !== null ? passengerForm[passengerFormEdit.editingIndex]?.address || '' : ''}
                                      onChange={(e) => handlePChange(passengerFormEdit.editingIndex, 'address', e.target.value)} />
                                  </div>
                                  <div>
                                    <Card className="shadow-none">
                                      <CardContent className="px-4 py-2 text-sm rounded-md bg-muted/50">
                                        {passengerForm[passengerFormEdit.editingIndex]?.remark?.length > 0 ? (
                                          passengerForm[passengerFormEdit.editingIndex]?.remark.map((remark, index) => (
                                            <div key={index} className="flex justify-between mb-1">
                                              <span>{remark.name}</span>-
                                              <span>₹{remark.charge}</span>
                                              <div className='cursor-pointer' onClick={(e) => { deleteRemark(index, passengerFormEdit.editingIndex); e.preventDefault(); e.stopPropagation(); }}><Trash2 className='text-destructive size-4' /></div>
                                            </div>
                                          ))
                                        ) : (
                                          <div>No additional Charge</div>
                                        )}
                                      </CardContent>
                                    </Card>
                                  </div>
                                  <div className="flex flex-row items-center gap-2">
                                    <Label className="flex w-40 lg:w-32">Remarks{isError.passenger && <p className="ml-1 text-destructive">*</p>}</Label>
                                    <Input
                                      className="h-7 bg-muted/50"
                                      name="remarkName"
                                      value={currentRemark.name}
                                      onChange={(e) => updateCurrentRemark('name', e.target.value)}
                                    />
                                  </div>
                                  <div className='flex items-center gap-2'>
                                    <div className="flex flex-row items-center gap-2">
                                      <Label className="flex w-40">Additional Charge{isError.passenger && <p className="ml-1 text-destructive">*</p>}</Label>
                                      <Input
                                        className="h-7 bg-muted/50"
                                        name="remarkCharge"
                                        value={currentRemark.charge}
                                        onChange={(e) => updateCurrentRemark('charge', e.target.value)}
                                      />
                                    </div>
                                    <div className="flex items-center p-2 rounded-md cursor-pointer h-7 bg-secondary" onClick={(e) => { addRemark(passengerFormEdit.editingIndex); e.preventDefault(); }}><CirclePlus className='size-4' /></div>
                                  </div>
                                </div>
                                <div className="flex flex-row col-span-4 mb-4 mr-6">
                                  <Button variant="outline" className="ml-auto mr-3 bg-primary/30 hover:bg-primary/40" onClick={handlePassengerFormSave}>{passengerFormEdit.editingIndex !== null ? "Save" : "Add"}</Button>
                                  <Button variant="outline" className="bg-primary/30 hover:bg-primary/40" onClick={() => { setPassengerFormEdit({ visible: false, editingIndex: null }); setCurrentRemark({ name: '', charge: '' }); }}>Close</Button>
                                </div>
                              </TableCell>
                            </TableRow>
                          )}
                        </React.Fragment>
                      ))
                    ) : (
                      <TableRow>
                        <TableCell colSpan={10} className="text-center">No Applicant</TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
                {passengerFormEdit.visible && passengerFormEdit.editingIndex === null && (
                  <>
                    <Separator />
                    <div className="grid gap-3 mx-6 my-4 lg:grid-cols-5 sm:grid-cols-1 md:grid-cols-2">
                      <div className="flex flex-row items-center gap-2">
                        <Label className="flex w-40 lg:w-32">Title{isError.passenger && <p className="ml-1 text-destructive">*</p>}</Label>
                        <Select name="title" defaultValue={passengerFormEdit.editingIndex !== null ? passengerForm[passengerFormEdit.editingIndex]?.title || '' : ''}
                          onValueChange={(e) => handlePChange(passengerFormEdit.editingIndex, 'title', e)}>
                          <SelectTrigger className="w-full h-7 bg-muted/50 focus:ring-transparent">
                            <SelectValue />
                          </SelectTrigger>
                          <SelectContent>
                            <SelectGroup>
                              {titleItems.map(item => (
                                <SelectItem key={item} value={item}>
                                  {item}
                                </SelectItem>
                              ))}
                            </SelectGroup>
                          </SelectContent>
                        </Select>
                      </div>
                      <div className="flex flex-row items-center gap-2">
                        <Label className="flex w-40 lg:w-32">First Name{isError.passenger && <p className="ml-1 text-destructive">*</p>}</Label>
                        <Input className="h-7 bg-muted/50" name="firstName" defaultValue={passengerFormEdit.editingIndex !== null ? passengerForm[passengerFormEdit.editingIndex]?.firstName || '' : ''}
                          onChange={(e) => handlePChange(passengerFormEdit.editingIndex, 'firstName', e.target.value)} />
                      </div>
                      <div className="flex flex-row items-center gap-2">
                        <Label className="flex w-40 lg:w-32">Middle Name{isError.passenger && <p className="ml-1 text-destructive">*</p>}</Label>
                        <Input className="h-7 bg-muted/50" name="middleName" defaultValue={passengerFormEdit.editingIndex !== null ? passengerForm[passengerFormEdit.editingIndex]?.middleName || '' : ''}
                          onChange={(e) => handlePChange(passengerFormEdit.editingIndex, 'middleName', e.target.value)} />
                      </div>
                      <div className="flex flex-row items-center gap-2">
                        <Label className="flex w-40 lg:w-32">Last Name{isError.passenger && <p className="ml-1 text-destructive">*</p>}</Label>
                        <Input className="h-7 bg-muted/50" name="lastName" defaultValue={passengerFormEdit.editingIndex !== null ? passengerForm[passengerFormEdit.editingIndex]?.lastName || '' : ''}
                          onChange={(e) => handlePChange(passengerFormEdit.editingIndex, 'lastName', e.target.value)} />
                      </div>
                      <div className="flex flex-row items-center gap-2">
                        <Label className="flex w-40 lg:w-32">Pax Type{isError.passenger && <p className="ml-1 text-destructive">*</p>}</Label>
                        <Select name="paxType" defaultValue={passengerFormEdit.editingIndex !== null ? passengerForm[passengerFormEdit.editingIndex]?.paxType || '' : ''}
                          onValueChange={(e) => handlePChange(passengerFormEdit.editingIndex, 'paxType', e)}>
                          <SelectTrigger className="w-full h-7 bg-muted/50 focus:ring-transparent">
                            <SelectValue />
                          </SelectTrigger>
                          <SelectContent>
                            <SelectGroup>
                              {paxTypeItems.map(item => (
                                <SelectItem key={item} value={item}>
                                  {item}
                                </SelectItem>
                              ))}
                            </SelectGroup>
                          </SelectContent>
                        </Select>
                      </div>
                      <div className="flex flex-row items-center gap-2">
                        <Label className="flex w-40 lg:w-32">Gender{isError.passenger && <p className="ml-1 text-destructive">*</p>}</Label>
                        <Select name="gender" defaultValue={passengerFormEdit.editingIndex !== null ? passengerForm[passengerFormEdit.editingIndex]?.gender || '' : ''}
                          onValueChange={(e) => handlePChange(passengerFormEdit.editingIndex, 'gender', e)}>
                          <SelectTrigger className="w-full h-7 bg-muted/50 focus:ring-transparent">
                            <SelectValue />
                          </SelectTrigger>
                          <SelectContent>
                            <SelectGroup>
                              {genderTypeItems.map(item => (
                                <SelectItem key={item} value={item}>
                                  {item}
                                </SelectItem>
                              ))}
                            </SelectGroup>
                          </SelectContent>
                        </Select>
                      </div>
                      <div className="flex flex-col">
                        <div className="flex flex-row items-center gap-2">
                          <Label className="flex w-40 lg:w-32">Mobile{isError.passenger && <p className="ml-1 text-destructive">*</p>}</Label>
                          <Input className="h-7 bg-muted/50" name="mobile" defaultValue={passengerFormEdit.editingIndex !== null ? passengerForm[passengerFormEdit.editingIndex]?.mobile || '' : ''}
                            onChange={(e) => handlePChange(passengerFormEdit.editingIndex, 'mobile', e.target.value)} />
                        </div>
                        {passengerForm[editingPassengerForm.length]?.errors?.mobile && (
                          <p className="text-xs text-destructive">{passengerForm[editingPassengerForm.length].errors.mobile}</p>
                        )}
                      </div>
                      <div className="flex flex-col">
                        <div className="flex flex-row items-center gap-2">
                          <Label className="flex w-40 lg:w-32">Email{isError.passenger && <p className="ml-1 text-destructive">*</p>}</Label>
                          <Input className="h-7 bg-muted/50" name="email" defaultValue={passengerFormEdit.editingIndex !== null ? passengerForm[passengerFormEdit.editingIndex]?.email : ''}
                            onChange={(e) => handlePChange(passengerFormEdit.editingIndex, 'email', e.target.value)} />
                        </div>
                        {passengerForm[editingPassengerForm.length]?.errors?.email && (
                          <p className="text-xs text-destructive">{passengerForm[editingPassengerForm.length].errors.email}</p>
                        )}
                      </div>
                      <div className="flex flex-row items-center col-span-2 gap-2">
                        <Label className="flex w-40 lg:w-28">Address{isError.passenger && <p className="ml-1 text-destructive">*</p>}</Label>
                        <Input className="h-7 bg-muted/50" name="address" defaultValue={passengerFormEdit.editingIndex !== null ? passengerForm[passengerFormEdit.editingIndex]?.address || '' : ''}
                          onChange={(e) => handlePChange(passengerFormEdit.editingIndex, 'address', e.target.value)} />
                      </div>
                      <div>
                        <Card className="shadow-none">
                          <CardContent className="px-4 py-2 text-sm rounded-md bg-muted/50">
                            {remarks.length > 0 ? (
                              remarks.map((remark, index) => (
                                <div key={index} className="flex justify-between mb-1">
                                  <span>{remark.name}</span>-
                                  <span>₹{remark.charge}</span>
                                  <div className='cursor-pointer' onClick={(e) => { deleteRemark(index, passengerFormEdit.editingIndex); e.preventDefault(); e.stopPropagation(); }}><Trash2 className='text-destructive size-4' /></div>
                                </div>
                              ))
                            ) : (
                              <div>No additional Charge</div>
                            )}
                          </CardContent>
                        </Card>
                      </div>
                      <div className="flex flex-row items-center gap-2">
                        <Label className="flex w-40 lg:w-32">Remarks{isError.passenger && <p className="ml-1 text-destructive">*</p>}</Label>
                        <Input
                          className="h-7 bg-muted/50"
                          name="remarkName"
                          value={currentRemark.name}
                          onChange={(e) => updateCurrentRemark('name', e.target.value)}
                        />
                      </div>
                      <div className='flex items-center gap-2'>
                        <div className="flex flex-row items-center gap-2">
                          <Label className="flex w-40">Additional Charge{isError.passenger && <p className="ml-1 text-destructive">*</p>}</Label>
                          <Input
                            className="h-7 bg-muted/50"
                            name="remarkCharge"
                            value={currentRemark.charge}
                            onChange={(e) => updateCurrentRemark('charge', e.target.value)}
                          />
                        </div>
                        <div className="flex items-center p-2 rounded-md cursor-pointer h-7 bg-secondary" onClick={(e) => { addRemark(passengerFormEdit.editingIndex); e.preventDefault(); }}><CirclePlus className='size-4' /></div>
                      </div>
                    </div>
                    <div className="flex flex-row col-span-4 mb-4 mr-6">
                      <Button variant="outline" className="ml-auto mr-3 bg-primary/30 hover:bg-primary/40" onClick={handlePassengerFormSave}>{passengerFormEdit.editingIndex !== null ? "Save" : "Add"}</Button>
                      <Button variant="outline" className="bg-primary/30 hover:bg-primary/40" onClick={() => { clearPassengerErrors(); setPassengerFormEdit({ visible: false, editingIndex: null }); setCurrentRemark({ name: '', charge: '' }); }}>Close</Button>
                    </div>
                  </>
                )}
              </CardContent>
            </Card>
            <Separator />
            <div className="flex flex-row items-center justify-between gap-4 mr-2">
              <CardDescription>Passport details :</CardDescription>
              <Button size='sm' variant="secondary" onClick={(e) => { setcarrierFormEdit(prevState => ({ ...prevState, visible: !prevState.visible, editingIndex: null })); e.preventDefault(); }}>Add Passport <CirclePlus className="ml-2 size-4" /></Button>
            </div>
            <Card>
              <CardContent className="p-0">
                <Table>
                  <TableHeader className="bg-secondary/60">
                    <TableRow>
                      <TableHead className="rounded-tl-md"></TableHead>
                      <TableHead></TableHead>
                      <TableHead>Passport Details</TableHead>
                      {/* <TableHead>Passport For</TableHead> */}
                      <TableHead>Passport Type</TableHead>
                      <TableHead>Application Type</TableHead>
                      <TableHead>Application Date</TableHead>
                      <TableHead className="rounded-tr-md"><span className="sr-only">Actions</span></TableHead>
                    </TableRow>
                  </TableHeader>
                  <TableBody>
                    {editingCarrierForm.length > 0 ? (
                      editingCarrierForm.map((carriers, index) => (
                        <React.Fragment key={index}>
                          <TableRow>
                            <TableCell></TableCell>
                            <TableCell>{index + 1}</TableCell>
                            <TableCell>{carriers.serviceDetails}</TableCell>
                            <TableCell>{carriers.serviceType}</TableCell>
                            <TableCell>{carriers.applicationType}</TableCell>
                            <TableCell>{carriers.applicationDate ? new Date(carriers.applicationDate).toLocaleDateString() : ""}</TableCell>
                            <TableCell>
                              <div className="flex flex-row items-center gap-4">
                                <Edit onClick={() => setcarrierFormEdit(prevState => ({ ...prevState, visible: !prevState.visible, editingIndex: index }))} className="cursor-pointer text-primary size-4" />
                                <Trash2 onClick={() => handleDeleteC(index)} className="cursor-pointer text-destructive size-4" />
                              </div>
                            </TableCell>
                          </TableRow>
                          {carrierFormEdit.visible && carrierFormEdit.editingIndex === index && (
                            <TableRow>
                              <TableCell colSpan={11}>
                                <div className="grid gap-3 mx-6 my-4 lg:grid-cols-4 sm:grid-cols-1 md:grid-cols-2">
                                  <div className="flex flex-row items-center gap-2">
                                    <Label className="flex w-40 lg:w-32">Passport Details{isError.carrier && <p className="ml-1 text-destructive">*</p>}</Label>
                                    <Input className="h-7 bg-muted/50" name="serviceDetails" defaultValue={carrierFormEdit.editingIndex !== null ? carrierForm[carrierFormEdit.editingIndex]?.serviceDetails || '' : ''}
                                      onChange={(e) => handleCChange(carrierFormEdit.editingIndex, 'serviceDetails', e.target.value)} />
                                  </div>
                                  <div className="flex flex-row items-center gap-2">
                                    <Label className="flex w-40 lg:w-32">Passport For{isError.carrier && <p className="ml-1 text-destructive">*</p>}</Label>
                                    <Input className="h-7 bg-muted/50" name="serviceType" defaultValue={carrierFormEdit.editingIndex !== null ? carrierForm[carrierFormEdit.editingIndex]?.serviceType || '' : ''}
                                      onChange={(e) => handleCChange(carrierFormEdit.editingIndex, 'serviceType', e.target.value)} />
                                  </div>
                                  <div className="flex flex-row items-center gap-2">
                                    <Label className="flex w-40 lg:w-32">Passport Type{isError.carrier && <p className="ml-1 text-destructive">*</p>}</Label>
                                    <Input className="h-7 bg-muted/50" name="serviceType" defaultValue={carrierFormEdit.editingIndex !== null ? carrierForm[carrierFormEdit.editingIndex]?.serviceType || '' : ''}
                                      onChange={(e) => handleCChange(carrierFormEdit.editingIndex, 'serviceType', e.target.value)} />
                                  </div>
                                  {/* <div className="flex flex-row items-center gap-2">
                                    <Label className="flex w-40 lg:w-32">Application Type{isError.carrier && <p className="ml-1 text-destructive">*</p>}</Label>
                                    <Input className="h-7 bg-muted/50" name="applicationType" defaultValue={carrierFormEdit.editingIndex !== null ? carrierForm[carrierFormEdit.editingIndex]?.applicationType : ''}
                                      onChange={(e) => handleCChange(carrierFormEdit.editingIndex, 'applicationType', e.target.value)} />
                                  </div> */}
                                  <div className="flex flex-row items-center gap-2">
                                    <Label className="flex w-40 lg:w-32">Application Type{isError.carrier && <p className="ml-1 text-destructive">*</p>}</Label>
                                    <Select name="applicationType" defaultValue={carrierFormEdit.editingIndex !== null ? carrierForm[carrierFormEdit.editingIndex]?.applicationType || '' : ''}
                                      onValueChange={(e) => handleCChange(carrierFormEdit.editingIndex, 'applicationType', e)}>
                                      <SelectTrigger className="w-full h-7 bg-muted/50 focus:ring-transparent">
                                        <SelectValue />
                                      </SelectTrigger>
                                      <SelectContent>
                                        <SelectGroup>
                                          {applicationTypeItems.map(item => (
                                            <SelectItem key={item} value={item}>
                                              {item}
                                            </SelectItem>
                                          ))}
                                        </SelectGroup>
                                      </SelectContent>
                                    </Select>
                                  </div>
                                  <div className="flex flex-row items-center gap-2">
                                    <Label className="flex w-40 lg:w-32">Application Date{isError.carrier && <p className="ml-1 text-destructive">*</p>}</Label>
                                    <DatePicker className="h-7 bg-muted/50" defaultValue={carrierFormEdit.editingIndex !== null ? carrierForm[carrierFormEdit.editingIndex].applicationDate : ''} onChange={(date) => handleCChange(carrierFormEdit.editingIndex, 'applicationDate', date)} />
                                  </div>
                                </div>
                                <div className="flex flex-row col-span-4 mb-4 mr-6">
                                  <Button variant="outline" className="ml-auto mr-3 bg-primary/30 hover:bg-primary/40" onClick={handleCarrierFormSave}>{carrierFormEdit.editingIndex !== null ? "Save" : "Add"}</Button>
                                  <Button variant="outline" className="bg-primary/30 hover:bg-primary/40" onClick={() => setcarrierFormEdit({ visible: false, editingIndex: null })}>Close</Button>
                                </div>
                              </TableCell>
                            </TableRow>
                          )}
                        </React.Fragment>
                      ))
                    ) : (
                      <TableRow>
                        <TableCell colSpan={10} className="text-center">No Carrier</TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
                {carrierFormEdit.visible && carrierFormEdit.editingIndex === null && (
                  <>
                    <Separator />
                    <div className="grid gap-3 mx-6 my-4 lg:grid-cols-4 sm:grid-cols-1 md:grid-cols-2">
                      <div className="flex flex-row items-center gap-2">
                        <Label className="flex w-40 lg:w-32">passport Details{isError.carrier && <p className="ml-1 text-destructive">*</p>}</Label>
                        <Input className="h-7 bg-muted/50" name="serviceDetails" defaultValue={carrierFormEdit.editingIndex !== null ? carrierForm[carrierFormEdit.editingIndex]?.serviceDetails || '' : ''}
                          onChange={(e) => handleCChange(carrierFormEdit.editingIndex, 'serviceDetails', e.target.value)} />
                      </div>
                      <div className="flex flex-row items-center gap-2">
                        <Label className="flex w-40 lg:w-32">passport For{isError.carrier && <p className="ml-1 text-destructive">*</p>}</Label>
                        <Input className="h-7 bg-muted/50" name="serviceType" defaultValue={carrierFormEdit.editingIndex !== null ? carrierForm[carrierFormEdit.editingIndex]?.serviceType || '' : ''}
                          onChange={(e) => handleCChange(carrierFormEdit.editingIndex, 'serviceType', e.target.value)} />
                      </div>
                      <div className="flex flex-row items-center gap-2">
                        <Label className="flex w-40 lg:w-32">passport Type{isError.carrier && <p className="ml-1 text-destructive">*</p>}</Label>
                        <Input className="h-7 bg-muted/50" name="serviceType" defaultValue={carrierFormEdit.editingIndex !== null ? carrierForm[carrierFormEdit.editingIndex]?.serviceType || '' : ''}
                          onChange={(e) => handleCChange(carrierFormEdit.editingIndex, 'serviceType', e.target.value)} />
                      </div>
                      <div className="flex flex-row items-center gap-2">
                        <Label className="flex w-40 lg:w-32">Application Type{isError.carrier && <p className="ml-1 text-destructive">*</p>}</Label>
                        <Select name="applicationType" defaultValue={carrierFormEdit.editingIndex !== null ? carrierForm[carrierFormEdit.editingIndex]?.applicationType || '' : ''}
                          onValueChange={(e) => handleCChange(carrierFormEdit.editingIndex, 'applicationType', e)}>
                          <SelectTrigger className="w-full h-7 bg-muted/50 focus:ring-transparent">
                            <SelectValue />
                          </SelectTrigger>
                          <SelectContent>
                            <SelectGroup>
                              {applicationTypeItems.map(item => (
                                <SelectItem key={item} value={item}>
                                  {item}
                                </SelectItem>
                              ))}
                            </SelectGroup>
                          </SelectContent>
                        </Select>
                      </div>
                      <div className="flex flex-row items-center gap-2">
                        <Label className="flex w-40 lg:w-32">Application Date{isError.carrier && <p className="ml-1 text-destructive">*</p>}</Label>
                        <DatePicker className="h-7 bg-muted/50" defaultValue={carrierFormEdit.editingIndex !== null ? carrierForm[carrierFormEdit.editingIndex].applicationDate : ''} onChange={(date) => handleCChange(carrierFormEdit.editingIndex, 'applicationDate', date)} />
                      </div>
                    </div>
                    <div className="flex flex-row col-span-4 mb-4 mr-6">
                      <Button variant="outline" className="ml-auto mr-3 bg-primary/30 hover:bg-primary/40" onClick={handleCarrierFormSave}>{carrierFormEdit.editingIndex !== null ? "Save" : "Add"}</Button>
                      <Button variant="outline" className="bg-primary/30 hover:bg-primary/40" onClick={() => setcarrierFormEdit({ visible: false, editingIndex: null })}>Close</Button>
                    </div>
                  </>
                )}
              </CardContent>
            </Card>
            <Separator />
            <CardDescription>Fare details :</CardDescription>
            <div className="grid grid-cols-1 gap-x-4 gap-y-2 md:grid-cols-2 lg:grid-cols-4">
              <div className="flex flex-row items-center gap-2">
                <Label className="flex w-40">Passport Charges{isError.passportProductDetails?.[0]?.product.fare?.[0].charges && <p className="ml-1 text-destructive">*</p>}</Label>
                <Input className="h-7 bg-muted/50" name="charges" {...register("passportProductDetails[0].product.fare[0].charges", { required: formState === "Submitted" })} />
              </div>
              <div className="flex flex-row items-center gap-2">
                <Label className="flex w-40">Markup{isError.passportProductDetails?.[0]?.product.fare?.[0].markup && <p className="ml-1 text-destructive">*</p>}</Label>
                <Input className="h-7 bg-muted/50" name="markup" {...register("passportProductDetails[0].product.fare[0].markup", { required: formState === "Submitted" })} />
              </div>
              <div className="flex flex-row items-center gap-2">
                <Label className="flex w-40">Net Amount{isError.passportProductDetails?.[0]?.product.fare?.[0].netAmount && <p className="ml-1 text-destructive">*</p>}</Label>
                <Input className="h-7 bg-muted/50" name="netAmount" {...register("passportProductDetails[0].product.fare[0].netAmount", { required: formState === "Submitted" })} />
              </div>
            </div>
            <Separator />
            <div className='grid grid-cols-1 gap-5 lg:grid-cols-2'>
              <div>
                <div className="flex flex-row items-center justify-between mb-3">
                  <CardDescription>Customer Charges Details :</CardDescription>
                </div>
                <Card>
                  <CardContent className="p-0">
                    <Table>
                      <TableHeader className="bg-secondary/60">
                        <TableRow>
                          <TableHead className="rounded-tl-md"></TableHead>
                          <TableHead></TableHead>
                          <TableHead>Charge Name</TableHead>
                          <TableHead>Charge ₹</TableHead>
                          <TableHead className="hidden md:table-cell">Total Charge ₹</TableHead>
                          <TableHead>Total Tax</TableHead>
                          <TableHead className="rounded-tr-md"><span className="sr-only">Actions</span></TableHead>
                        </TableRow>
                      </TableHeader>
                      <TableBody>
                        {CUST_CHARGE_TYPES.map((chargeType, index) => {
                          const charge = custChargeForm.find(c => c.chargeCodeName === chargeType) || {
                            chargeCodeName: chargeType,
                            calculationMethod: "",
                            value: "",
                            totalValue: "",
                            tax: [],
                            taxTotal: null
                          };
                          return (
                            <TableRow key={index}>
                              <TableCell></TableCell>
                              <TableCell></TableCell>
                              <TableCell>{charge.chargeCodeName}<span className='ml-2 text-xs'>{charge.calculationMethod ? `(${charge.calculationMethod})` : "(PerTicket)"}</span>
                              </TableCell>
                              <TableCell>
                                <Input
                                  type="number"
                                  className="w-40 h-6"
                                  value={charge.value || ""}
                                  onChange={(e) => handleChargeChange(index, 'value', e.target.value)}
                                  onBlur={handleChargeFormSave}
                                />
                              </TableCell>
                              <TableCell>{charge.totalValue || ""}</TableCell>
                              <TableCell className="cursor-pointer">
                                <span className='flex items-center'>{charge.taxTotal || ""}
                                  <TooltipProvider delayDuration={100}>
                                    <Tooltip>
                                      <TooltipTrigger asChild>
                                        {charge.taxTotal && <Info className='ml-1 size-4' />}
                                      </TooltipTrigger>
                                      <TooltipContent className="mb-2">
                                        {charge.tax && charge.tax.length > 0 && charge.tax.some(t => t.taxCode !== "") ? charge.tax.map((tax) => (<div key={tax.taxCode}>{`${tax.taxCode}: ${tax.value}`}</div>)) : 'No Taxes'}
                                      </TooltipContent>
                                    </Tooltip>
                                  </TooltipProvider>
                                </span>
                              </TableCell>
                            </TableRow>
                          );
                        })}
                      </TableBody>
                    </Table>
                  </CardContent>
                </Card>
              </div>
              <Separator className="lg:sr-only" />
              <div>
                <div className="flex flex-row items-center justify-between mb-3">
                  <CardDescription>Supplier Charges Details :</CardDescription>
                </div>
                <Card>
                  <CardContent className="p-0">
                    <Table>
                      <TableHeader className="bg-secondary/60">
                        <TableRow>
                          <TableHead className="rounded-tl-md"></TableHead>
                          <TableHead></TableHead>
                          <TableHead>Charge Name</TableHead>
                          <TableHead>Charge ₹</TableHead>
                          <TableHead className="hidden md:table-cell">Total Charge ₹</TableHead>
                          <TableHead>Total Tax</TableHead>
                          <TableHead className="rounded-tr-md"><span className="sr-only">Actions</span></TableHead>
                        </TableRow>
                      </TableHeader>
                      <TableBody>
                        {suppChargeForm.map((charge, index) => (
                          <TableRow key={index}>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell>{charge.chargeCodeName}<span className='ml-2 text-xs'>{charge.calculationMethod ? `(${charge.calculationMethod})` : "(PerTicket)"}</span></TableCell>
                            <TableCell><Input className="w-40 h-6" value={charge.value} readOnly /></TableCell>
                            <TableCell>{charge.totalValue || ""}</TableCell>
                            <TableCell className="cursor-pointer">
                              <span className='flex items-center'>{charge.taxTotal || ""}
                                <TooltipProvider delayDuration={100}>
                                  <Tooltip>
                                    <TooltipTrigger asChild>{charge.taxTotal && <Info className='ml-1 size-4' />}</TooltipTrigger>
                                    <TooltipContent className="mb-2">
                                      {charge.tax && charge.tax.length > 0 && charge.tax.some(t => t.taxCode !== "") ? charge.tax.map((tax) => (<div key={tax.taxCode}>{`${tax.taxCode}: ${tax.value}`}</div>)) : 'No Taxes'}
                                    </TooltipContent>
                                  </Tooltip>
                                </TooltipProvider>
                              </span>
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </CardContent>
                </Card>
              </div>
            </div>
          </div>
        </ScrollArea>
        <CardFooter className="py-0 mt-2 mr-2">
          {
            !id ? (
              <div className="ml-auto">
                <Button onClick={onSave} className="mr-2" disabled={loading}>
                  {loading === "Save" ? 'Saving...' : 'Save draft'}
                </Button>
                <Button onClick={() => onCreate('Submitted')} disabled={loading}>
                  {loading === "Create" ? 'Creating...' : 'Create invoice'}
                </Button>
              </div>
            ) : (
              <div className='ml-auto'>
                {(data && data.state === "Draft") &&
                  <Button onClick={onSave} disabled={loading}>
                    {loading === "Edit" ? 'Editing...' : 'Save Changes'}
                  </Button>}
                {(data && data.state === "Draft") &&
                  <Button onClick={() => onCreate('Submitted')} className="ml-2" disabled={loading}>
                    {loading === "Create" ? 'Creating...' : 'Create invoice'}
                  </Button>
                }
              </div>
            )
          }
        </CardFooter>
      </form>
    </div>
  )
}
