import keycloak from "../keycloak";

export const getRoles = () => {
  if (keycloak.authenticated) {
    const tokenParsed = keycloak.tokenParsed;

    const realmRoles = tokenParsed.realm_access?.roles || [];
    const clientRoles = tokenParsed.resource_access?.[keycloak.clientId]?.roles || [];
    
    return [...realmRoles, ...clientRoles];
  }
  return [];
};

export const hasRole = (role) => {
  const roles = getRoles();
  return roles.includes(role);
};

export const hasAnyRole = (roleArray) => {
  const roles = getRoles();
  return roleArray.some(role => roles.includes(role));
};

export const getUserInfo = () => {
  if (keycloak.authenticated) {
    return {
      username: keycloak.tokenParsed.preferred_username,
      fullName: keycloak.tokenParsed.name,
      email: keycloak.tokenParsed.email,
      firstName: keycloak.tokenParsed.given_name,
      lastName: keycloak.tokenParsed.family_name,
    };
  }
  return null;
};