import { Button } from 'src/components/ui/button'
import { Command, CommandEmpty, CommandGroup, CommandInput, CommandItem, CommandList } from 'src/components/ui/command';
import { Popover, PopoverContent, PopoverTrigger } from 'src/components/ui/popover'
import { cn } from 'src/lib/utils';
import { Check, ChevronsDownUp } from 'lucide-react';
import React from 'react'

export default function AirlineSelect({ onAirlineSelect, type, value, isVisible, isError }) {
    const [open, setOpen] = React.useState(false)
    const [loading, setLoading] = React.useState(false);
    const [airlines, setAirline] = React.useState([]);
    const [selectedAirline, setSelectedAirline] = React.useState(null);
    const getAirlines = async () => {
        setLoading(true);
        try {
            const response = await fetch(`${(process.env.REACT_APP_API_HOST_MSTR === undefined) ? "" : process.env.REACT_APP_API_HOST_MSTR}/amigo-lekha-api/${type}Management/v1/${type}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json'
                },
            });
            if (!response.ok) {
                throw new Error('Failed to fetch data');
            }
            const fetchedData = await response.json();
            setAirline(fetchedData);
        } catch (error) {
            console.error("Error fetching data:", error);
        } finally {
            setLoading(false);
        }
    }
    const handleSelectAirline = async (airlineId) => {
        const airline = airlines.find((arln) => arln.code === airlineId);
        setSelectedAirline(airline);
        onAirlineSelect(airline);
        setOpen(false);
    };
    React.useEffect(() => {
        if (value) {
            const airline = airlines.find((arln) => arln.name === value);
            if (airline) {
                setSelectedAirline(airline);
            }
        }
    }, [value, airlines, onAirlineSelect]);

    React.useEffect(() => {
        if (isVisible) {
            getAirlines();
        }
    }, [isVisible]);
    return (
        <Popover open={open} onOpenChange={setOpen} modal={true}>
            <PopoverTrigger asChild onClick={getAirlines}>
                <Button
                    variant="outline"
                    role="combobox"
                    aria-expanded={open}
                    className={`${type === "supplier" ? "lg:w-[30vh] w-[45vh]" : "w-[42vh]"} bg-muted/50 h-7 justify-between ${isError && "border-destructive"}`}
                >
                    {selectedAirline?.name || `Select ${type}...`}
                    <ChevronsDownUp className="w-4 h-4 ml-2 opacity-50 shrink-0" />
                </Button>
            </PopoverTrigger>
            <PopoverContent className="w-[28vh] h-[30vh] p-0">
                <Command>
                    <CommandInput placeholder="Search airline..." className="h-8" />
                    <CommandList>
                        <CommandEmpty>{loading ? "loading..." : `No ${type} found.`}</CommandEmpty>
                        <CommandGroup>
                            {airlines.map((airline) => (
                                <CommandItem
                                    key={airline.code}
                                    value={airline.name}
                                    onSelect={() => handleSelectAirline(airline.code)}
                                >
                                    {airline.name}
                                    <Check
                                        className={cn(
                                            "ml-auto h-4 w-4",
                                            selectedAirline?.code === airline.code ? "opacity-100" : "opacity-0"
                                        )}
                                    />
                                </CommandItem>
                            ))}
                        </CommandGroup>
                    </CommandList>
                </Command>
            </PopoverContent>
        </Popover>
    )
}
