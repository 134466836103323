import { Button } from 'src/components/ui/button'
import { Edit, Trash2 } from 'lucide-react'
import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form';
import { Dialog, DialogContent, DialogFooter, DialogHeader, DialogTitle, DialogTrigger } from 'src/components/ui/dialog';
import { Label } from 'src/components/ui/label';
import { Input } from 'src/components/ui/input';
import { Card } from 'src/components/ui/card';
import { ScrollArea } from 'src/components/ui/scroll-area';

export default function GenericForm({ data, generic, type, onSuccess }) {
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const { register, reset, handleSubmit, watch } = useForm();
  const [listData, setListData] = useState({});
  const [showInput, setShowInput] = useState(false);
  const [newItem, setNewItem] = useState('');

  const handleAddItems = () => {
    setShowInput(true);
  };

  const handleNewItemChange = (value) => {
    setNewItem(value);
  };

  const handleAddNewItem = () => {
    if (newItem.trim()) {
      const updatedListData = { ...listData, items: [newItem, ...(listData.items || [])] };
      setListData(updatedListData);
      setNewItem('');
      setShowInput(false);
    }
  };

  const handleDelete = (index) => {
    const updatedItems = [...listData.items];
    updatedItems.splice(index, 1);
    const updatedListData = { ...listData, items: updatedItems };
    setListData(updatedListData);
  };

  useEffect(() => {
    if (data) {
      reset(data);
      setListData(data);
    }
  }, [data, reset]);

  useEffect(() => {
    const subscription = watch(() => {
      setShowInput(false);
    });
    return () => subscription.unsubscribe();
  }, [watch]);

  const onSubmit = (formData) => {
    const finalData = { ...formData, items: listData.items };
    if (type === "new") {
      handleCreateGeneric(finalData);
    } else {
      handleEditGeneric(finalData);
    }
    setLoading(false);
  };



  const handleCreateGeneric = async (formData) => {
    const formBody = {
      ...formData,
      id: formData.code,
      status: 'Active',
    };
    setLoading(true);
    try {
      const response = await fetch(`${(process.env.REACT_APP_API_HOST_MSTR === undefined) ? "" : process.env.REACT_APP_API_HOST_MSTR}/amigo-lekha-api/${generic.title.toLowerCase()}Management/v1/${generic.title.toLowerCase()}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formBody),
      });
      if (!response.ok) {
        throw new Error('Failed to fetch data');
      }
      reset();
      onSuccess();
      setOpen(false);
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleEditGeneric = async (formData) => {
    setLoading(true);
    try {
      const response = await fetch(`${(process.env.REACT_APP_API_HOST_MSTR === undefined) ? "" : process.env.REACT_APP_API_HOST_MSTR}/amigo-lekha-api/${generic.title.toLowerCase()}Management/v1/${generic.title.toLowerCase()}/${formData.code}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      });
      if (!response.ok) {
        throw new Error('Failed to fetch data');
      }
      reset();
      onSuccess();
      setOpen(false);
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      <Dialog open={open} onOpenChange={setOpen}>
        <DialogTrigger asChild>
          {!data ?
            <Button size="sm" className="h-10 gap-1 px-4">
              <span className="sr-only lg:not-sr-only md:sr-only sm:whitespace-nowrap">
                Add {generic?.title}
              </span>
            </Button>
            :
            <Edit className="text-primary/90 size-[1.9vh]" />
          }
        </DialogTrigger>
        <DialogContent onOpenAutoFocus={(e) => e.preventDefault()} className="lg:w-[40vw] md:w-[60vw] w-[80vw] p-5">
          <DialogHeader className='gap-3'>
            <DialogTitle>{data ? `Edit ${generic?.title}` : `New ${generic?.title}`}</DialogTitle>
          </DialogHeader>
          <form onSubmit={handleSubmit(onSubmit)} className='mt-2'>
            <div className='grid grid-cols-1 gap-4 lg:grid-cols-2'>
              {generic?.viewConfig?.fields?.map((field, index) => (
                <div key={index} className="flex flex-row gap-2">
                  <Label className="w-40 mt-2">{field.label}</Label>
                  {field.type === 'GenericText' &&
                    <Input className="h-7 bg-muted/50" {...register(field.dataField, { required: true })} />}
                  {field.type === 'GenericList' &&
                    <Input className="h-7 bg-muted/50" {...register(field.dataField, { required: true })} />}
                  {field.type === 'GenericArray' && (
                    <>
                      <Card className="px-3 py-3 ml-16">
                        <ScrollArea className="overflow-y-auto min-w-52 max-h-40">
                          {listData[field.dataField]?.length > 0 ? (
                            listData[field.dataField].map((item, index) => (
                              <div key={index} className="flex items-center justify-between">
                                <div className='my-1 text-sm'>{item}</div>
                                <div className="flex gap-1 mx-3">
                                  <Trash2 onClick={() => handleDelete(index)} className="w-4 h-4 text-red-500 cursor-pointer" />
                                </div>
                              </div>
                            ))
                          ) : (
                            <div className='flex'>No items</div>
                          )}
                        </ScrollArea>
                      </Card>
                        {showInput && (
                          <div className="p-2">
                            <Input className="w-auto" type="text" value={newItem} onChange={(e) => handleNewItemChange(e.target.value)} placeholder="Enter item" />
                            <Button onClick={handleAddNewItem} variant="outline" className="my-2 ml-auto bg-primary/30 hover:bg-primary/40">Add</Button>
                          </div>
                        )}
                        <div>
                          {!showInput && (
                            <Button variant="outline" size="sm" className="bg-primary/30 hover:bg-primary/40" placeholder="Enter item" onClick={handleAddItems}>Add Items</Button>
                          )}
                      </div>
                    </>
                  )}
                </div>
              ))}
            </div>
            <DialogFooter className="mt-3">
              <Button type="submit" className="ml-auto">
                {type === "new" ? <>{loading ? 'Creating...' : 'Create'}</> : <>{loading ? 'Saving...' : 'Save'}</>}
              </Button>
            </DialogFooter>
          </form>
        </DialogContent>
      </Dialog>
    </div>
  );
}