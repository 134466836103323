import { Card, CardContent, CardDescription, CardFooter } from 'src/components/ui/card'
import React, { useEffect, useState } from 'react'
import { Button } from 'src/components/ui/button'
import { Link, useNavigate } from 'react-router-dom'
import { toast } from 'sonner';
import { useParams, useLocation } from 'react-router-dom';
import { Controller, useForm } from 'react-hook-form';
import { Label } from 'src/components/ui/label';
import { Input } from 'src/components/ui/input';
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "src/components/ui/select"
import { Separator } from 'src/components/ui/separator';
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from "src/components/ui/table";
import { SearchCustomer } from '../components/search-select';
import { Textarea } from 'src/components/ui/textarea';
import { DatePicker } from '../../../components/datepicker';
import AirlineSelect from '../components/airline-select';
import { CirclePlus, Edit, Info, Trash2, Undo2 } from 'lucide-react';
import { ScrollArea } from 'src/components/ui/scroll-area';
import CarrierSelect from '../components/carrier-select';
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from 'src/components/ui/tooltip';
import SupplierSelect from '../../../components/supplier-select';
import { Checkbox } from 'src/components/ui/checkbox';
import { splitInvoiceAirline } from '../components/invoice-spliter';
import SplitDialog from 'src/pages/components/split-confirm-dialog';
import { hasRole } from 'src/helper/user-data';

export default function InvAirlineForm() {
  const { productType } = useParams();
  const [loading, setLoading] = useState("");
  const [passengerFormEdit, setpassengerFormEdit] = useState({ visible: false, editingIndex: null });
  const [carrierFormEdit, setcarrierFormEdit] = useState({ visible: false, editingIndex: null });
  const [customerForm, setCustomerForm] = useState(false);
  const [invoiceId, setInvoiceId] = useState('');
  const [isVisible, setIsVisible] = useState(false);
  const [isError, setIsError] = useState({
    customer: false,
    airline: false,
    passenger: false,
    carrier: false,
    fare: false,
    supplier: false,
  });
  const [selectedFromLocation, setSelectedFromLocation] = useState('');
  const [selectedToLocation, setSelectedToLocation] = useState('');
  const { register, handleSubmit, control, reset, watch, setValue, getValues, trigger, formState: { errors }, } = useForm({
    defaultValues: {
      id: 0,
      invoiceId: "",
      invoiceDate: "",
      category: `airline-${productType}`,
      dueDate: "",
      agent: "",
      split: false,
      createdBy: "amigo2",
      printInName: "",
      manualBillNo: "",
      referredBy: "",
      state: "",
      productDetails: [
        {
          id: "",
          code: "",
          name: "airline",
          category: productType,
          netAmount: 0,
          product: {
            gds: "a",
            gdsNo: "",
            airline: "",
            airlinePNR: "",
            requisitionNo: "",
            requisitionBy: "",
            requisitionDate: "",
            validationDate: "",
            passenger: [],
            carriers: [],
            fare: [],
            charges: [],
          },
          state: "",
        },
      ],
      customerRefOrValue: {
        id: "",
        name: "",
        address: "",
        email: "",
        phone: "",
        panNo: "",
        gstNo: "",
      },
    }
  });
  // passenger states
  const [passengerForm, setPassengerForm] = useState([]);
  const [editingPassengerForm, setEditingPassengerForm] = useState([]);
  // carrier states
  const [carrierForm, setCarrierForm] = useState([]);
  const [editingCarrierForm, setEditingCarrierForm] = useState([]);
  // fare states
  const [fareForm, setFareForm] = useState([
    {
      fare: 0,
      baseFare: 0,
      totalFare: 0,
      comTax: 0,
      nonComTax: 0,
      k3: 0,
      markup: 0,
      baggageAllowance: 0,
      netAmount: 0,
    }
  ]);
  const [custChargeForm, setCustChargeForm] = useState([
    {
      chargeCodeName: "Service Charge",
      calculationMethod: "",
      value: 0,
      totalValue: 0,
      tax: [],
      taxTotal: null,
    },
    {
      chargeCodeName: "Miscellaneous Charges",
      calculationMethod: "",
      value: 0,
      totalValue: 0,
      tax: [],
      taxTotal: null,
    },
    {
      chargeCodeName: "Documentation Charge",
      calculationMethod: "",
      value: 0,
      totalValue: 0,
      tax: [],
      taxTotal: null,
    },
    {
      chargeCodeName: "Management Fees",
      calculationMethod: "",
      value: 0,
      totalValue: 0,
      tax: [],
      taxTotal: null,
    }
  ])
  const [suppChargeForm, setSuppChargeForm] = useState([
    {
      chargeCodeName: "Commission Charge",
      calculationMethod: "",
      value: 0,
      totalValue: 0,
      tax: [],
      taxTotal: null,
    },
    {
      chargeCodeName: "Miscellaneous Charges",
      calculationMethod: "",
      value: 0,
      totalValue: 0,
      tax: [],
      taxTotal: null,
    },
    {
      chargeCodeName: "Documentation Charge",
      calculationMethod: "",
      value: 0,
      totalValue: 0,
      tax: [],
      taxTotal: null,
    },
    {
      chargeCodeName: "Management Fees",
      calculationMethod: "",
      value: 0,
      totalValue: 0,
      tax: [],
      taxTotal: null,
    }
  ])
  const CUST_CHARGE_TYPES = [
    "Service Charge",
    "Miscellaneous Charges",
    "Documentation Charge",
    "Management Fees"
  ];
  const agentComm = watch("agent");

  const [titleItems, setTitleItems] = useState([]);
  const [paxTypeItems, setPaxTypeItems] = useState([]);
  const [classItems, setClassItems] = useState([]);
  const [gdsItems, setGdsItems] = useState([]);
  const [splitInv, setSplitInv] = useState(false);

  const { id } = useParams();
  const location = useLocation();
  const data = location.state?.item;
  const navigate = useNavigate();

  useEffect(() => {
    getLists();
    setValue('invoiceDate', new Date().toISOString());
    if (data && id) {
      setInvoiceId(data.invoiceId);
      reset(data);
      if (data.productDetails && data.productDetails.length > 0 && data.productDetails[0].product && data.productDetails[0].product.passenger) {
        const passengerData = data.productDetails[0].product.passenger;
        setPassengerForm(passengerData);
        setEditingPassengerForm(passengerData);
      } else {
        setPassengerForm([
          {
            title: "",
            firstName: "",
            lastName: "",
            paxType: "",
            ticketNo: "",
          }
        ]);
      }
      if (data.productDetails && data.productDetails.length > 0 && data.productDetails[0].product && data.productDetails[0].product.carriers) {
        const carriersData = data.productDetails[0].product.carriers;
        setCarrierForm(carriersData);
        setEditingCarrierForm(carriersData);
      } else {
        setCarrierForm([
          {
            fromLocation: "",
            toLocation: "",
            carrier: "",
            carrierType: "",
            airlineClass: "",
            departureDate: "",
          }
        ]);
      }
      if (data.productDetails && data.productDetails.length > 0 && data.productDetails[0].product && data.productDetails[0].product.fare) {
        const fareData = data.productDetails[0].product.fare;
        setFareForm(fareData);
      } else {
        setFareForm([
          {
            fare: 0,
            baseFare: 0,
            totalFare: 0,
            comTax: 0,
            nonComTax: 0,
            markup: 0,
            baggageAllowance: 0,
            netAmount: 0,
          }
        ]);
      }
      if (data.productDetails && data.productDetails.length > 0 && data.productDetails[0].product && data.productDetails[0].product.charges) {
        const chargeData = data.productDetails[0].product.charges;
        if (chargeData.length === 0) {
          setCustChargeForm(custChargeForm);
        } else {
          setCustChargeForm(chargeData);
        }
      } else {
        setCustChargeForm([
          {
            chargeCodeName: "",
            calculationMethod: "",
            value: 0,
            totalValue: 0,
            tax: [],
            taxTotal: 0,
          }
        ]);
      }
      if (data.productDetails && data.productDetails.length > 0 && data.productDetails[0].product && data.productDetails[0].product.supplierCharges) {
        const suppChargeData = data.productDetails[0].product.supplierCharges;
        setSuppChargeForm(suppChargeData);
      }
      setIsVisible(true);
    }
  }, [data, id, reset, setValue]);

  const getLists = async () => {
    try {
      const response = await fetch(`${(process.env.REACT_APP_API_HOST_MSTR === undefined) ? "" : process.env.REACT_APP_API_HOST_MSTR}/amigo-lekha-api/listManagement/v1/list`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json'
        },
      });
      if (!response.ok) {
        throw new Error('Failed to fetch data');
      }
      const fetchedData = await response.json();
      fetchedData.forEach(item => {
        switch (item.name) {
          case 'Title':
            setTitleItems(item.items);
            break;
          case 'PaxType':
            setPaxTypeItems(item.items);
            break;
          case 'Class':
            setClassItems(item.items);
            break;
          case 'GDS':
            setGdsItems(item.items);
            break;
          default:
            break;
        }
      });
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  }

  const calculateTaxForCharge = (totalValue, chargeTaxes, taxValues) => {
    let taxTotal = 0;
    const updatedTaxes = chargeTaxes.map(chargeTax => {
      const matchingTaxValue = taxValues.find(taxValue => taxValue.id === chargeTax.taxCode);
      if (matchingTaxValue) {
        const taxAmount = totalValue * (matchingTaxValue.value / 100);
        taxTotal += taxAmount;
        return { ...chargeTax, value: taxAmount };
      }
      return chargeTax;
    });
    return { tax: updatedTaxes, taxTotal };
  };

  const [taxValues, setTaxValues] = useState([]);

  const handleCustomerSelect = async (customer, customerCharges) => {
    const chrgForm = [{
      chargeCodeName: "Service Charge",
      calculationMethod: "",
      value: 0,
      totalValue: 0,
      tax: [],
      taxTotal: null,
    },
    {
      chargeCodeName: "Miscellaneous Charges",
      calculationMethod: "",
      value: 0,
      totalValue: 0,
      tax: [],
      taxTotal: null,
    },
    {
      chargeCodeName: "Documentation Charge",
      calculationMethod: "",
      value: 0,
      totalValue: 0,
      tax: [],
      taxTotal: null,
    },
    {
      chargeCodeName: "Management Fees",
      calculationMethod: "",
      value: 0,
      totalValue: 0,
      tax: [],
      taxTotal: null,
    }]
    if (!customerCharges || !customerCharges.configDetails || customerCharges.configDetails.length === 0) {
      toast.error(`No charges for ${customer.customerCode}`);
      setValue("productDetails[0].product.charges", chrgForm);
    }
    const cabCharges = customerCharges?.configDetails?.filter(charge => charge.ProductRef.id === "Cab");
    if (cabCharges?.length === 0) {
      toast.error(`No cab charges for ${customer.customerCode}`);
      setValue("productDetails[0].product.charges", chrgForm);
    }
    setIsError(prevState => ({ ...prevState, customer: false }));
    setValue('customerRefOrValue.id', customer.customerCode || '');
    setValue('customerRefOrValue.name', customer.fullName || '');
    setValue('customerRefOrValue.phone', customer.contactNumber || '');
    setValue('customerRefOrValue.email', customer.emailId || '');
    setValue('customerRefOrValue.address', customer.addressId[0]?.addressLine || '');
    setValue('customerRefOrValue.panNo', customer.panNumber || '');
    setValue('customerRefOrValue.gstNo', customer.gstNumber || '');
    setCustChargeForm([]);
    const updatedChargeForm = custChargeForm.map(charge => ({
      ...charge,
      value: 0,
      calculationMethod: '',
      totalValue: 0,
      tax: [{
        taxCode: "",
        value: 0,
      }],
      taxTotal: 0,
    }));
    let fetchedTaxValues;
    const hasAirlineCharge = customerCharges.configDetails?.some(charge => charge.ProductRef.id === "Airline");
    if (hasAirlineCharge) {
      fetchedTaxValues = await getTaxValues(chrgForm, customerCharges?.configDetails);
    }
    const currentDate = new Date();
    customerCharges?.configDetails
      ?.filter((charge) => charge.ProductRef.id === "Airline")
      .forEach((charge) => {
        const matchingCharge = updatedChargeForm.find(
          (c) => c.chargeCodeName === charge.ledger.id
        );
        if (matchingCharge) {
          matchingCharge.value = charge.amount || 0;
          matchingCharge.calculationMethod = charge.calculationMethod;
          const isWithinDateRange = (
            (charge.effectStartDate === null && charge.effectEndDate === null) ||
            (charge.effectStartDate === null && new Date(charge.effectEndDate) >= currentDate) ||
            (charge.effectEndDate === null && new Date(charge.effectStartDate) <= currentDate) ||
            (new Date(charge.effectStartDate) <= currentDate && new Date(charge.effectEndDate) >= currentDate)
          );
          if (isWithinDateRange) {
            matchingCharge.tax = charge.taxes?.map(taxCode => ({
              taxCode,
              value: 0
            })) || [];
          } else {
            matchingCharge.tax = [];
          }
        }
      });
    setCustChargeForm(updatedChargeForm);
    if (fetchedTaxValues) {
      calcCharge(updatedChargeForm, passengerForm.length, fetchedTaxValues);
    }
  };
  const customerRefOrValue = watch("customerRefOrValue");
  let pLength = 0;

  const getTaxValues = async (chargeForm, configDetails) => {
    let taxIds;
    if (configDetails !== null) {
      taxIds = configDetails
        ?.filter((charge) => charge.ProductRef.id === "Airline")
        ?.flatMap((charge) => charge.taxes?.map((tax) => tax) || [])
        .filter((id, index, self) => self.indexOf(id) === index);
    } else {
      taxIds = chargeForm
        .flatMap((charge) => charge.tax?.map((tax) => tax.taxCode) || [])
        .filter((id, index, self) => self.indexOf(id) === index);
    }
    const taxValues = await Promise.all(
      taxIds.map(async (taxId) => {
        try {
          const response = await fetch(`${(process.env.REACT_APP_API_HOST_MSTR === undefined) ? "" : process.env.REACT_APP_API_HOST_MSTR}/amigo-lekha-api/taxTypeManagement/v1/taxType/${taxId}`);
          if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
          }
          const data = await response.json();
          return { id: taxId, value: data.valueDetails[0].value };
        } catch (error) {
          console.error(`Error fetching tax data for ID ${taxId}:`, error);
          return { id: taxId, value: 0 };
        }
      })
    );
    if (taxValues.length > 0) {
      calcCharge(chargeForm, pLength, taxValues);
    }
    setTaxValues(taxValues);
    return taxValues;
  };

  const handleAirlineSelect = (airline) => {
    setIsError(prevState => ({ ...prevState, airline: false }));
    setValue("productDetails[0].product.airline", airline.name);
  }
  const airlineName = watch("productDetails[0].product.airline") || '';

  const handleSupplierSelect = (supplier) => {
    setIsError(prevState => ({ ...prevState, supplier: false }));
    setValue("productDetails[0].product.agentRefOrValue.name", supplier.fullName);
    setValue("productDetails[0].product.agentRefOrValue.id", supplier.supplierCode);
  }
  const supplierName = watch("productDetails[0].product.agentRefOrValue") || '';

  const handleFlightSelect = (flight, index) => {
    handleCChange(index, 'carrier', flight.name);
    setSelectedFromLocation(flight.fromLocation);
    setSelectedToLocation(flight.toLocation);
    handleCChange(index, 'fromLocation', flight.fromLocation);
    handleCChange(index, 'toLocation', flight.toLocation);
  }

  // passenger handle
  const handlePChange = (index, field, value) => {
    if (index === null) {
      index = editingPassengerForm.length;
    }
    setPassengerForm((prevPassengers) => {
      const updatedPassengers = [...prevPassengers];
      updatedPassengers[index] = {
        ...updatedPassengers[index],
        [field]: value
      };
      if (field === 'firstName' || field === 'middleName' || field === 'lastName') {
        const firstName = field === 'firstName' ? value : updatedPassengers[index].firstName || '';
        const middleName = field === 'middleName' ? value : updatedPassengers[index].middleName || '';
        const lastName = field === 'lastName' ? value : updatedPassengers[index].lastName || '';
        updatedPassengers[index].fullName = `${firstName} ${lastName} ${middleName}`.trim();
      }
      return updatedPassengers;
    });
  };
  const handlePassengerFormSave = (e) => {
    e.preventDefault();
    const isPassengerEmpty = (passenger) =>
      Object.entries(passenger).every(([key, value]) =>
        key === 'fare'
          ? Object.values(value).every(v => v === 0 || v === "")
          : value === ""
      );
    if (passengerForm.every(isPassengerEmpty)) { return; }

    setValue('productDetails[0].product.passenger', passengerForm);
    setEditingPassengerForm(passengerForm);
    setIsError(prevState => ({ ...prevState, passenger: false }));
    calcfare(fareForm, passengerForm);
    calcCharge(custChargeForm, passengerForm.length, taxValues);
    setpassengerFormEdit({ visible: false, editingIndex: null });
  };

  const handleDeleteP = (index) => {
    const updatedPassengers = passengerForm.filter((_, i) => i !== index);
    setValue('productDetails[0].product.passenger', updatedPassengers);
    if (updatedPassengers.length <= 1) { setValue("split", false) };
    setEditingPassengerForm(updatedPassengers);
    setPassengerForm(updatedPassengers);
    calcfare(fareForm, updatedPassengers);
    pLength = updatedPassengers.length;
    calcCharge(custChargeForm, updatedPassengers.length, taxValues);
    setpassengerFormEdit({ visible: false, editingIndex: null });
  }

  const calcCharge = (updatedChargeForm, length, taxValues) => {
    if (taxValues.length === 0 && customerRefOrValue.name) {
      getTaxValues(custChargeForm, null);
    }
    if (!length) { length = 0 };
    const charges = updatedChargeForm.map((charge) => {
      let totalValue;
      switch (charge.calculationMethod) {
        case "PerTicket":
          totalValue = charge.value;
          break;
        case "PerPassenger":
          const multi = length === 0 ? 1 : length;
          totalValue = multi * charge.value;
          break;
        default:
          totalValue = charge.value;
      }
      return {
        ...charge,
        totalValue,
      };
    });
    const chargesWithTax = charges.map((charge) => {
      const { tax, taxTotal } = calculateTaxForCharge(charge.totalValue, charge.tax, taxValues);
      const roundedTax = tax.map(t => ({
        ...t,
        value: Math.round(t.value * 100) / 100
      }));
      const roundedTaxTotal = Math.round(taxTotal * 100) / 100;
      return { ...charge, tax: roundedTax, taxTotal: roundedTaxTotal };
    });
    setCustChargeForm(chargesWithTax);
    setValue("productDetails[0].product.charges", chargesWithTax);
  };

  //carrier handle
  const handleCChange = (index, field, value) => {
    if (index === null) {
      index = editingCarrierForm.length;
    }
    setCarrierForm((prevCarrier) => {
      const updatedCarrier = [...prevCarrier];
      updatedCarrier[index] = {
        ...updatedCarrier[index],
        [field]: value
      };
      return updatedCarrier;
    });
  };
  const handleCarrierFormSave = (e) => {
    e.preventDefault();
    const filteredCarrierForm = carrierForm.filter(carrier => Object.values(carrier).some(field => field !== "")
    );
    if (filteredCarrierForm.length === 0) { return; }
    setValue('productDetails[0].product.carriers', carrierForm);
    setEditingCarrierForm(carrierForm);
    setIsError(prevState => ({ ...prevState, carrier: false }));
    setcarrierFormEdit({ visible: false, editingIndex: null });
    setSelectedFromLocation("");
    setSelectedToLocation("");
  };
  const handleDeleteC = (index) => {
    const updatedCarriers = carrierForm.filter((_, i) => i !== index);
    setValue('productDetails[0].product.carriers', updatedCarriers);
    setEditingCarrierForm(updatedCarriers);
    setCarrierForm(updatedCarriers);
    setcarrierFormEdit({ visible: false, editingIndex: null });
  }

  // fare handle
  const handleFChange = (index, field, value) => {
    setFareForm((prevFare) => {
      const updatedFare = [...prevFare];
      updatedFare[index] = {
        ...updatedFare[index],
        [field]: value
      };
      const fare = parseFloat(updatedFare[index].fare) || 0;
      const totalFare = parseFloat(updatedFare[index].totalFare) || 0;
      const baggageAllowance = parseFloat(updatedFare[index].baggageAllowance) || 0;
      const markup = parseFloat(updatedFare[index].markup) || 0;

      // Calculate fields
      if (field === "fare" || field === "comTax") {
        const isComTaxChecked = field === "comTax" ? value : updatedFare[index].comTax !== 0;
        updatedFare[index].comTax = isComTaxChecked ? 236 : 0;
        updatedFare[index].k3 = isComTaxChecked ? fare * 0.05 : 0;
      }
      const comTax = parseFloat((updatedFare[index].comTax).toFixed(2)) || 0;
      const k3 = parseFloat((updatedFare[index].k3).toFixed(2)) || 0;
      updatedFare[index].baseFare = parseFloat((fare - comTax).toFixed(2));
      updatedFare[index].yq = parseFloat((comTax).toFixed(2));
      const nonComTax = totalFare - (fare + k3);
      updatedFare[index].nonComTax = parseFloat((nonComTax).toFixed(2)) || 0;

      // Calculate net amount
      const net = totalFare + baggageAllowance + markup;
      updatedFare[index].netAmount = parseFloat((net).toFixed(2));
      const commissionCharge = parseFloat(((fare) * 0.015).toFixed(2));
      setValue("productDetails[0].product.supplierCharges[0].value", commissionCharge);
      setValue("productDetails[0].product.supplierCharges[0].totalValue", commissionCharge);
      setValue("productDetails[0].product.supplierCharges[0].chargeCodeName", "Commission Charge");
      setSuppChargeForm(prevState => prevState.map((item, index) => index === 0 ? { ...item, chargeCodeName: "Commission Charge", value: commissionCharge, totalValue: commissionCharge } : item));
      const suppAmount = fare + commissionCharge;
      setValue("productDetails[0].supplierNetAmount", suppAmount);
      return updatedFare;
    });
  };
  const handleFareFormSave = (e) => {
    e?.preventDefault();
    const isFormEmpty = fareForm.every(item => Object.values(item).every(value => value === 0 || value === "0" || value === "" || value == null));
    if (!isFormEmpty) {
      setValue('productDetails[0].product.fare', fareForm);
    }
    calcfare(fareForm, editingPassengerForm);
    setIsError(prevState => ({ ...prevState, fare: isFormEmpty }));
  };

  const calcfare = (fareForm, form) => {
    const length = form.length;
    if (length > 0) {
      const fare = fareForm[0];
      const toNumber = (value) => Number(value) || 0;
      const roundToTwoDecimals = (num) => {
        return Math.round(num * 100) / 100;
      };
      const farePerPassenger = {
        baseFare: roundToTwoDecimals(toNumber(fare?.baseFare) / length),
        comTax: roundToTwoDecimals(toNumber(fare?.comTax) / length),
        nonComTax: roundToTwoDecimals(toNumber(fare?.nonComTax) / length),
        markup: roundToTwoDecimals(toNumber(fare?.markup) / length),
        k3: roundToTwoDecimals(toNumber(fare?.k3) / length),
        baggageAllowance: roundToTwoDecimals(toNumber(fare?.baggageAllowance) / length),
        netAmount: roundToTwoDecimals((toNumber(fare?.baseFare) + toNumber(fare?.nonComTax) + toNumber(fare?.comTax) + toNumber(fare?.markup) + toNumber(fare?.k3) + toNumber(fare?.baggageAllowance)) / length),
      };
      const updatedPassengerForm = form.map(passenger => ({
        ...passenger,
        fare: farePerPassenger
      }));
      setPassengerForm(updatedPassengerForm);
      setEditingPassengerForm(updatedPassengerForm);
      setValue('productDetails[0].product.passenger', updatedPassengerForm);
    }
  }
  const handleChargeChange = (index, field, value) => {
    if (index === null) {
      index = 0;
    }
    setCustChargeForm((prevCharges) => {
      const updatedCharges = [...prevCharges];
      updatedCharges[index] = {
        ...updatedCharges[index],
        [field]: value
      };
      return updatedCharges;
    });
  };
  const handleChargeFormSave = (e) => {
    e.preventDefault();
    setValue('productDetails[0].product.charges', custChargeForm);
    calcCharge(custChargeForm, editingPassengerForm.length, taxValues);
  };

  const handleSplitInv = (checked) => {
    setValue('split', checked);
    setSplitInv(checked);
  };

  const updateValidationRules = (state) => {
    if (state === 'Submitted') {
      setValue('state', 'Submitted');
      setValue('productDetails[0].state', 'Submitted');
    } else {
      setValue('state', 'Draft');
      setValue('productDetails[0].state', 'Draft');
    }
    trigger();
  }

  const [formState, setFormState] = useState('Draft');

  const onSave = () => {
    setFormState('Draft');
    updateValidationRules('Draft');
    const data = getValues();
    setIsError(prevState => ({ ...prevState, airline: false, passenger: false, carrier: false, fare: false, supplier: false }))
    if (!data.customerRefOrValue.id) {
      setIsError(prevState => ({ ...prevState, customer: true }));
      return;
    }
  }
  const onError = (errors) => {
    if (Object.keys(errors).length > 0) {
      toast.error("Please fill in all required fields.");
    }
  };

  const onCreate = (state) => {
    setFormState(state);
    updateValidationRules(state);
    const data = getValues();
    if (state) {
      setValue('state', state);
      setValue('productDetails[0].state', state);
    }
    const newErrors = {};
    if (!data.customerRefOrValue.id) {
      newErrors.customer = true;
    }
    if (!data.productDetails[0]?.product.airline) {
      newErrors.airline = true;
    }
    if (!data.productDetails[0]?.product.agentRefOrValue?.name) {
      newErrors.supplier = true;
    }
    if (Object.keys(newErrors).length > 0) {
      setIsError(prevState => ({ ...prevState, ...newErrors }));
      return;
    }
    const passengers = data.productDetails[0]?.product?.passenger || [];
    if (passengers.length === 0 && !passengers[0]?.firstName) {
      setIsError(prevState => ({ ...prevState, passenger: true }));
      setpassengerFormEdit({ visible: true, editingIndex: null })
      toast.error("Passenger is required");
      return;
    }
    const carriers = data.productDetails[0]?.product?.carriers || [];
    if (carriers.length === 0 && !carriers[0]?.carrier) {
      setIsError(prevState => ({ ...prevState, carrier: true }));
      setcarrierFormEdit({ visible: true, editingIndex: null })
      toast.error("Carrier is required");
      return;
    }
    const fares = data.productDetails[0]?.product?.fare || [];
    if (fares.length === 0 && !fares[0]?.baseFare) {
      setIsError(prevState => ({ ...prevState, fare: true }));
      toast.error("Fare is required");
      return;
    }
  }

  const onSubmit = (data) => {
    const netAmt = calcNet(data);
    if (data.productDetails && data.productDetails[0]) {
      data.productDetails[0].netAmount = netAmt;
      data.netAmount = netAmt;
    }
    let invData = data;
    if (data.split && data.state === "Submitted") {
      invData = splitInvoiceAirline(data);
    }
    if (Array.isArray(invData)) {
      handleSplitInvCall(invData, data.invoiceId || null);
    } else if (data.invoiceId) {
      handleEditInv(invData);
    } else {
      handleCreateInv(invData);
    }
  };

  const handleCreateInv = async (data) => {
    const { id, ...requestData } = data;
    if (Object.values(isError).some(error => error)) {
      return;
    }
    setLoading("Create");
    try {
      const response = await fetch(`${(process.env.REACT_APP_API_HOST_INV === undefined) ? "" : process.env.REACT_APP_API_HOST_INV}/amigo-lekha-api/invoiceManagement/v1/invoice`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(requestData),
      });
      if (!response.ok) {
        throw new Error('Failed to create invoice');
      }
      const res = await response.json();
      const id = encodeURIComponent(res.invoiceId);
      reset();
      clear();
      toast.success("Invoice created successfuly!", { duration: "5000", position: "top-right", description: `Invoice Id - ${id}` });
      if (res.state === "Draft") {
        navigate(`/dashboard/invoices/airline/${productType}/edit/${id}`, { state: { item: res } });
      } else {
        navigate(`/dashboard/invoices/airline/${productType}`);
      }
    } catch (error) {
      console.error("Error creating invoice:", error);
      toast.error("Failed to create invoice");
    } finally {
      setLoading("");
    }
  };

  const handleEditInv = async (data) => {
    if (Object.values(isError).some(error => error)) {
      return;
    }
    if (data.state === "Draft") {
      setLoading("Edit");
    } else {
      setLoading("Create");
    }
    try {
      const response = await fetch(`${(process.env.REACT_APP_API_HOST_INV === undefined) ? "" : process.env.REACT_APP_API_HOST_INV}/amigo-lekha-api/invoiceManagement/v1/invoice/${data.invoiceId}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(data),
      });
      if (!response.ok) {
        throw new Error('Failed to edit invoice');
      }
      const res = await response.json();
      const id = encodeURIComponent(res.invoiceId);
      clear();
      toast.success(`Invoice ${res.state === "Draft" ? "Edited" : "Submitted"} Successfuly!`, { duration: "5000", position: "top-right", description: `Invoice Id - ${id}` });
      if (res.state === "Draft") {
        navigate(`/dashboard/invoices/airline/${productType}/edit/${id}`, { state: { item: res } });
      } else {
        navigate(`/dashboard/invoices/airline/${productType}`);
      }
    } catch (error) {
      console.error("Error editing invoice:", error);
      toast.error("Failed to edit invoice");
    } finally {
      setLoading("");
    }
  };

  const handleSplitInvCall = async (data, Id) => {
    if (Object.values(isError).some(error => error)) {
      return;
    }
    setLoading("Create");
    try {
      const response = await fetch(`${(process.env.REACT_APP_API_HOST_INV === undefined) ? "" : process.env.REACT_APP_API_HOST_INV}/amigo-lekha-api/invoiceManagement/v1/invoice/bulk`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(data),
      });
      if (!response.ok) {
        throw new Error('Failed to create invoice');
      }
      if (Id) {
        const deleteResponse = await fetch(`${(process.env.REACT_APP_API_HOST_INV === undefined) ? "" : process.env.REACT_APP_API_HOST_INV}/amigo-lekha-api/invoiceManagement/v1/invoice/${Id}`, {
          method: 'DELETE',
          headers: {
            'Content-Type': 'application/json'
          },
        });
        if (!deleteResponse.ok) {
          throw new Error(`Failed to delete invoice`);
        }
      }
      const res = await response.json();
      reset();
      clear();
      const invoiceIds = res.map(res => encodeURIComponent(res.invoiceId)).join(', ');
      toast.success(`Split invoices created successfully! Invoice IDs: ${invoiceIds}`, { duration: "5000", position: "top-right" });
      navigate(`/dashboard/invoices/airline/${productType}`);
    } catch (error) {
      console.error("Error creating invoice:", error);
      toast.error("Failed to create invoice");
    } finally {
      setLoading("");
    }
  };

  const clear = () => {
    setSelectedFromLocation("");
    setSelectedToLocation("");
    setcarrierFormEdit({ visible: false, editingIndex: null });
    setpassengerFormEdit({ visible: false, editingIndex: null });
  }

  const calcNet = (data) => {
    let fareTotal = data.productDetails[0]?.product?.fare[0]?.netAmount;
    if (isNaN(fareTotal) || fareTotal === undefined) { fareTotal = 0; }
    const chrgTax = data.productDetails[0].product.charges;
    let totalValue = 0;
    let taxTotal = 0;
    if (Array.isArray(chrgTax)) {
      chrgTax.forEach(chargeItem => {
        totalValue += parseFloat(chargeItem.totalValue) || 0;
        taxTotal += parseFloat(chargeItem.taxTotal) || 0;
      });
    }
    const netAmount = fareTotal + totalValue + taxTotal;
    return netAmount;
  }

  return (
    <div className='mx-8 my-5'>
      <Link to={`/dashboard/invoices/airline/${productType}`} className='absolute mt-1 ml-5'><Button variant="outline" size="icon"><Undo2 className='size-4' /></Button></Link>
      <div className="flex items-center justify-center w-full mb-5 text-[3vh] font-bold">{id ? "Edit" : "New"} Invoice (airline)</div>
      {/* <Card className="pt-4 mx-5">
        <CardContent> */}
      {invoiceId && <div className='flex mx-5 my-0'><div>Invoice Id - <span className='text-primary/70'>{invoiceId}</span></div><div className='ml-auto mr-2'>Total NetAmount : <span className='font-bold'>₹{data?.productDetails[0].netAmount}</span>&ensp;|</div><div>Created By : <span className='font-bold'>{data?.createdBy}</span></div></div>}
      <Separator />
      <form onSubmit={handleSubmit(onSubmit, onError)} className='mt-2'>
        <ScrollArea className="h-[calc(100vh-200px)] lg:h-[70vh]">
          <div className="grid gap-4 px-5 py-2">
            <div className="grid grid-cols-1 gap-4 md:grid-cols-2 lg:grid-cols-4">
              <div className="flex flex-row items-center gap-2">
                <Label className="flex w-40">Invoice Date{errors.invoiceDate && <p className="ml-1 text-destructive">*</p>}</Label>
                <Controller control={control} name="invoiceDate" rules={{ required: true }} {...register("invoiceDate")}
                  render={({ field }) => (
                    <DatePicker value={field.value} onChange={(date) => field.onChange(date)} disableDate={false} current={true} error={errors.invoiceDate} />
                  )}
                />
              </div>
              <div className="flex gap-2">
                <div className="flex flex-row items-center gap-2">
                  <Label className="flex w-32">Customer Name{isError.customer && <p className="ml-1 text-destructive">*</p>}</Label>
                  <SearchCustomer onCustomerSelect={handleCustomerSelect} value={customerRefOrValue.id} isVisible={isVisible} isError={isError.customer} />
                  {/* {isError.customer && <p className="text-xs text-red-500">Select Customer!</p>} */}
                </div>
                <Button variant='secondary' className="w-20 h-7" onClick={(e) => { setCustomerForm(!customerForm); e.preventDefault() }}>{customerForm ? "Hide" : "More"} Info</Button>
              </div>
            </div>
            {customerForm && (
              <div className="grid grid-cols-1 gap-2 md:grid-cols-2 lg:grid-cols-4">
                <div className="flex flex-row items-center gap-2">
                  <Label className="w-40">Phone No</Label>
                  <Input className="h-7 bg-muted/50" disabled={true} name="phone" value={customerRefOrValue.phone} {...register("customerRefOrValue.phone")} />
                </div>
                <div className="flex flex-row items-center gap-2">
                  <Label className="w-40">Email</Label>
                  <Input className="h-7 bg-muted/50" disabled={true} name="email" value={customerRefOrValue.email} {...register("customerRefOrValue.email")} />
                </div>
                <div className="flex flex-row items-center gap-2">
                  <Label className="w-40">Print In Name</Label>
                  <Input className="h-7 bg-muted/50" disabled={true} name="printInName" {...register("printInName")} />
                </div>
                <div className="flex flex-row items-center gap-2">
                  <Label className="w-36">Address</Label>
                  <Textarea className="h-16 bg-muted/50" disabled={true} name="address" value={customerRefOrValue.address} {...register("customerRefOrValue.address")} />
                </div>
                <div className="flex flex-row items-center gap-2">
                  <Label className="w-40">PAN No</Label>
                  <Input className="h-7 bg-muted/50" disabled={true} name="panNo" value={customerRefOrValue.panNo} {...register("customerRefOrValue.panNo")} />
                </div>
                <div className="flex flex-row items-center gap-2">
                  <Label className="w-40">GST Number</Label>
                  <Input className="h-7 bg-muted/50" disabled={true} name="gstNo" value={customerRefOrValue.gstNo} {...register("customerRefOrValue.gstNo")} />
                </div>
              </div>
            )}
            <div className="grid grid-cols-1 gap-x-4 gap-y-2 md:grid-cols-2 lg:grid-cols-4">
              <div className="flex flex-row items-center gap-2">
                <Label className="w-40">Due Date</Label>
                <Controller control={control} name="dueDate" {...register("dueDate")}
                  render={({ field }) => (
                    <DatePicker value={field.value} onChange={(date) => field.onChange(date)} disableDate={false} />
                  )}
                />
              </div>
              <div className="flex flex-row items-center gap-2">
                <Label className="flex w-40 lg:w-44">Supplier Name{isError.supplier && <p className="ml-1 text-destructive">*</p>}</Label>
                <SupplierSelect onSupplierSelect={handleSupplierSelect} value={supplierName.id} isVisible={isVisible} isError={isError.supplier} />
              </div>
              <div className="flex flex-row items-center gap-2">
                <Label className="flex w-40 lg:w-48">Supplier Amount{errors.productDetails?.[0]?.supplierNetAmount && <p className="ml-1 text-destructive">*</p>}</Label>
                <Input className={`h-7 bg-muted/50 ${errors.productDetails?.[0]?.supplierNetAmount ? 'border-destructive focus-visible:ring-0' : ''}`} name="supplierNetAmount" {...register("productDetails[0].supplierNetAmount")} />
              </div>
            </div>
            {/* <Separator />
            <CardDescription>Product Details :</CardDescription> */}
            <div className="grid grid-cols-1 gap-x-4 gap-y-2 md:grid-cols-2 lg:grid-cols-4">
              <div className="flex flex-row items-center gap-2">
                <Label className="flex w-40 lg:w-44">GDS{errors.productDetails?.[0]?.product?.gds && <p className="ml-1 text-destructive">*</p>}</Label>
                <Controller name="gds" defaultValue="Amadeus" control={control} rules={{ required: true }} render={({ field }) => (
                  <Select onValueChange={field.onChange} value={field.value}>
                    <SelectTrigger className="w-full h-7 bg-muted/50 focus:ring-transparent">
                      <SelectValue />
                    </SelectTrigger>
                    <SelectContent>
                      {gdsItems.map((item) => (
                        <SelectItem key={item} value={item}>
                          {item}
                        </SelectItem>
                      ))}
                    </SelectContent>
                  </Select>
                )}
                />
              </div>
              <div className="flex flex-row items-center gap-2">
                <Label className="flex w-40 lg:w-44">GDS PNR{errors.productDetails?.[0]?.product?.gdsNo && <p className="ml-1 text-destructive">*</p>}</Label>
                <Input className={`h-7 bg-muted/50 ${errors.productDetails?.[0]?.product?.gdsNo ? 'border-destructive focus-visible:ring-0' : ''}`} name="phone" {...register("productDetails[0].product.gdsNo", { required: formState === "Submitted" || formState === "Approved" })} />
              </div>
              <div className="flex flex-row items-center gap-2">
                <Label className="flex w-40 lg:w-48">Airline Name{isError.airline && <p className="ml-1 text-destructive">*</p>}</Label>
                <AirlineSelect onAirlineSelect={handleAirlineSelect} type="airline" value={airlineName} isVisible={isVisible} isError={isError.airline} />
              </div>
              <div className="flex flex-row items-center gap-2">
                <Label className="flex w-40 lg:w-56">Airline PNR{errors.productDetails?.[0]?.product?.airlinePNR && <p className="ml-1 text-destructive">*</p>}</Label>
                <Input className={`h-7 bg-muted/50 ${errors.productDetails?.[0]?.product?.airlinePNR ? 'border-destructive focus-visible:ring-0' : ''}`} name="airlinePNR" {...register("productDetails[0].product.airlinePNR", { required: formState === "Submitted" || formState === "Approved" })} />
              </div>
              <div className="flex flex-row items-center gap-2">
                <Label className="flex w-40 lg:w-44">Requisition By{errors.productDetails?.[0]?.product?.requisitionBy && <p className="ml-1 text-destructive">*</p>}</Label>
                <Input className={`h-7 bg-muted/50 ${errors.productDetails?.[0]?.product?.requisitionBy ? 'border-destructive focus-visible:ring-0' : ''}`} name="requisitionBy" {...register("productDetails[0].product.requisitionBy", { required: formState === "Submitted" || formState === "Approved" })} />
              </div>
              <div className="flex flex-row items-center gap-2">
                <Label className="flex w-40 lg:w-44">Handled By{errors.productDetails?.[0]?.product?.handledBy && <p className="ml-1 text-destructive">*</p>}</Label>
                <Input className={`h-7 bg-muted/50 ${errors.productDetails?.[0]?.product?.handledBy ? 'border-destructive focus-visible:ring-0' : ''}`} name="handledBy" {...register("productDetails[0].product.handledBy", { required: formState === "Submitted" || formState === "Approved" })} />
              </div>
              <div className="flex flex-row items-center gap-2">
                <Label className="flex w-40 lg:w-44">Through Agent</Label>
                <Input className="h-7 bg-muted/50" name="agent" {...register("agent")} />
              </div>
              {(agentComm !== "" || null) &&
                <div className="flex flex-row items-center gap-2">
                  <Label className="flex w-40 lg:w-44">Commission Amt.</Label>
                  <Input className="h-7 bg-muted/50" name="agentCommissionAmount" value={fareForm[0]?.agentCommissionAmount} onChange={(e) => handleFChange(0, 'agentCommissionAmount', e.target.value)} onBlur={handleFareFormSave} />
                </div>
              }
            </div>
            <Separator />
            <div className="flex flex-row items-center justify-between gap-4 mr-2">
              <CardDescription>Passenger Details :</CardDescription>
              <div className='flex items-center gap-4'>
                {editingPassengerForm.length > 1 && <div className='flex items-center gap-2'>Split Invoice -<Checkbox onCheckedChange={(checked) => { handleSplitInv(checked) }} checked={splitInv} /></div>}
                <Button size='sm' variant="secondary" onClick={(e) => { setpassengerFormEdit(prevState => ({ ...prevState, visible: !prevState.visible, editingIndex: null })); e.preventDefault(); }}>Add Passenger <CirclePlus className="ml-2 size-4" /></Button>
              </div>
            </div>
            <Card>
              <CardContent className="p-0">
                <Table>
                  <TableHeader className="bg-secondary/60">
                    <TableRow>
                      <TableHead className="rounded-tl-md"></TableHead>
                      <TableHead></TableHead>
                      <TableHead>Name</TableHead>
                      <TableHead className="hidden md:table-cell">Pax Type</TableHead>
                      <TableHead>Ticket No</TableHead>
                      <TableHead>Base Fare</TableHead>
                      <TableHead>Com Tax</TableHead>
                      <TableHead>NonCom Tax</TableHead>
                      <TableHead className="rounded-tr-md"><span className="sr-only">Actions</span></TableHead>
                    </TableRow>
                  </TableHeader>
                  <TableBody>
                    {editingPassengerForm.length > 0 ? (
                      editingPassengerForm.map((passenger, index) => (
                        <React.Fragment key={index}>
                          <TableRow>
                            <TableCell></TableCell>
                            <TableCell>{index + 1}</TableCell>
                            <TableCell>{passenger.title} {passenger.firstName} {passenger.middleName} {passenger.lastName}</TableCell>
                            <TableCell className="hidden md:table-cell">{passenger.paxType}</TableCell>
                            <TableCell>{passenger.ticketNo}</TableCell>
                            <TableCell>{passenger.fare?.baseFare}</TableCell>
                            <TableCell>{passenger.fare?.comTax}</TableCell>
                            <TableCell>{passenger.fare?.nonComTax}</TableCell>
                            <TableCell>
                              <div className="flex flex-row items-center gap-4">
                                <Edit onClick={() => setpassengerFormEdit(prevState => ({ ...prevState, visible: !prevState.visible, editingIndex: index }))} className="cursor-pointer text-primary size-4" />
                                <Trash2 onClick={() => handleDeleteP(index)} className="cursor-pointer text-destructive size-4" />
                              </div>
                            </TableCell>
                          </TableRow>
                          {passengerFormEdit.visible && passengerFormEdit.editingIndex === index && (
                            <TableRow>
                              <TableCell colSpan={11}>
                                <div className="grid gap-3 mx-6 my-4 lg:grid-cols-4 sm:grid-cols-1 md:grid-cols-2">
                                  <div className="flex flex-row items-center gap-2">
                                    <Label className="flex w-40">Title{isError.passenger && <p className="ml-1 text-destructive">*</p>}</Label>
                                    <Select name="title" defaultValue={passengerFormEdit.editingIndex !== null ? passengerForm[passengerFormEdit.editingIndex]?.title || '' : ''}
                                      onValueChange={(e) => handlePChange(passengerFormEdit.editingIndex, 'title', e)}>
                                      <SelectTrigger className="w-full h-7 bg-muted/50 focus:ring-transparent">
                                        <SelectValue />
                                      </SelectTrigger>
                                      <SelectContent>
                                        <SelectGroup>
                                          {titleItems.map(item => (
                                            <SelectItem key={item} value={item}>
                                              {item}
                                            </SelectItem>
                                          ))}
                                        </SelectGroup>
                                      </SelectContent>
                                    </Select>
                                  </div>
                                  <div className="flex flex-row items-center gap-2">
                                    <Label className="flex w-40">First Name{isError.passenger && <p className="ml-1 text-destructive">*</p>}</Label>
                                    <Input className="h-7 bg-muted/50" name="firstName" defaultValue={passengerFormEdit.editingIndex !== null ? passengerForm[passengerFormEdit.editingIndex]?.firstName || '' : ''}
                                      onChange={(e) => handlePChange(passengerFormEdit.editingIndex, 'firstName', e.target.value)} />
                                  </div>
                                  <div className="flex flex-row items-center gap-2">
                                    <Label className="flex w-40">Middle Name{isError.passenger && <p className="ml-1 text-destructive">*</p>}</Label>
                                    <Input className="h-7 bg-muted/50" name="middleName" defaultValue={passengerFormEdit.editingIndex !== null ? passengerForm[passengerFormEdit.editingIndex]?.middleName || '' : ''}
                                      onChange={(e) => handlePChange(passengerFormEdit.editingIndex, 'middleName', e.target.value)} />
                                  </div>
                                  <div className="flex flex-row items-center gap-2">
                                    <Label className="flex w-40">Last Name{isError.passenger && <p className="ml-1 text-destructive">*</p>}</Label>
                                    <Input className="h-7 bg-muted/50" name="lastName" defaultValue={passengerFormEdit.editingIndex !== null ? passengerForm[passengerFormEdit.editingIndex]?.lastName || '' : ''}
                                      onChange={(e) => handlePChange(passengerFormEdit.editingIndex, 'lastName', e.target.value)} />
                                  </div>
                                  <div className="flex flex-row items-center gap-2">
                                    <Label className="flex w-40">Pax Type{isError.passenger && <p className="ml-1 text-destructive">*</p>}</Label>
                                    <Select name="paxType" defaultValue={passengerFormEdit.editingIndex !== null ? passengerForm[passengerFormEdit.editingIndex]?.paxType || '' : ''}
                                      onValueChange={(e) => handlePChange(passengerFormEdit.editingIndex, 'paxType', e)}>
                                      <SelectTrigger className="w-full h-7 bg-muted/50 focus:ring-transparent">
                                        <SelectValue />
                                      </SelectTrigger>
                                      <SelectContent>
                                        <SelectGroup>
                                          {paxTypeItems.map(item => (
                                            <SelectItem key={item} value={item}>
                                              {item}
                                            </SelectItem>
                                          ))}
                                        </SelectGroup>
                                      </SelectContent>
                                    </Select>
                                  </div>
                                  <div className="flex flex-row items-center gap-2">
                                    <Label className="flex w-40">Ticket No{isError.passenger && <p className="ml-1 text-destructive">*</p>}</Label>
                                    <Input className="h-7 bg-muted/50" name="ticketNo" defaultValue={passengerFormEdit.editingIndex !== null ? passengerForm[passengerFormEdit.editingIndex]?.ticketNo : ''}
                                      onChange={(e) => handlePChange(passengerFormEdit.editingIndex, 'ticketNo', e.target.value)} />
                                  </div>
                                </div>
                                <div className="flex flex-row col-span-4 mb-4 mr-6">
                                  <Button variant="outline" className="ml-auto mr-3 bg-primary/30 hover:bg-primary/40" onClick={handlePassengerFormSave}>{passengerFormEdit.editingIndex !== null ? "Save" : "Add"}</Button>
                                  <Button variant="outline" className="bg-primary/30 hover:bg-primary/40" onClick={() => setpassengerFormEdit({ visible: false, editingIndex: null })}>Close</Button>
                                </div>
                              </TableCell>
                            </TableRow>
                          )}
                        </React.Fragment>
                      ))
                    ) : (
                      <TableRow>
                        <TableCell colSpan={10} className="text-center">No Passenger</TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
                {passengerFormEdit.visible && passengerFormEdit.editingIndex === null && (
                  <>
                    <Separator />
                    <div className="grid gap-3 mx-6 my-4 lg:grid-cols-4 sm:grid-cols-1 md:grid-cols-2">
                      <div className="flex flex-row items-center gap-2">
                        <Label className="flex w-40">Title{isError.passenger && <p className="ml-1 text-destructive">*</p>}</Label>
                        <Select name="title" defaultValue={passengerFormEdit.editingIndex !== null ? passengerForm[passengerFormEdit.editingIndex]?.title || '' : ''}
                          onValueChange={(e) => handlePChange(passengerFormEdit.editingIndex, 'title', e)}>
                          <SelectTrigger className="w-full h-7 bg-muted/50 focus:ring-transparent">
                            <SelectValue />
                          </SelectTrigger>
                          <SelectContent>
                            <SelectGroup>
                              {titleItems.map(item => (
                                <SelectItem key={item} value={item}>
                                  {item}
                                </SelectItem>
                              ))}
                            </SelectGroup>
                          </SelectContent>
                        </Select>
                      </div>
                      <div className="flex flex-row items-center gap-2">
                        <Label className="flex w-40">First Name{isError.passenger && <p className="ml-1 text-destructive">*</p>}</Label>
                        <Input className="h-7 bg-muted/50" name="firstName" defaultValue={passengerFormEdit.editingIndex !== null ? passengerForm[passengerFormEdit.editingIndex]?.firstName || '' : ''}
                          onChange={(e) => handlePChange(passengerFormEdit.editingIndex, 'firstName', e.target.value)} />
                      </div>
                      <div className="flex flex-row items-center gap-2">
                        <Label className="flex w-40">Middle Name{isError.passenger && <p className="ml-1 text-destructive">*</p>}</Label>
                        <Input className="h-7 bg-muted/50" name="middleName" defaultValue={passengerFormEdit.editingIndex !== null ? passengerForm[passengerFormEdit.editingIndex]?.middleName || '' : ''}
                          onChange={(e) => handlePChange(passengerFormEdit.editingIndex, 'middleName', e.target.value)} />
                      </div>
                      <div className="flex flex-row items-center gap-2">
                        <Label className="flex w-40">Last Name{isError.passenger && <p className="ml-1 text-destructive">*</p>}</Label>
                        <Input className="h-7 bg-muted/50" name="lastName" defaultValue={passengerFormEdit.editingIndex !== null ? passengerForm[passengerFormEdit.editingIndex]?.lastName || '' : ''}
                          onChange={(e) => handlePChange(passengerFormEdit.editingIndex, 'lastName', e.target.value)} />
                      </div>
                      <div className="flex flex-row items-center gap-2">
                        <Label className="flex w-40">Pax Type{isError.passenger && <p className="ml-1 text-destructive">*</p>}</Label>
                        <Select name="paxType" defaultValue={passengerFormEdit.editingIndex !== null ? passengerForm[passengerFormEdit.editingIndex]?.paxType || '' : ''}
                          onValueChange={(e) => handlePChange(passengerFormEdit.editingIndex, 'paxType', e)}>
                          <SelectTrigger className="w-full h-7 bg-muted/50 focus:ring-transparent">
                            <SelectValue />
                          </SelectTrigger>
                          <SelectContent>
                            <SelectGroup>
                              {paxTypeItems.map(item => (
                                <SelectItem key={item} value={item}>
                                  {item}
                                </SelectItem>
                              ))}
                            </SelectGroup>
                          </SelectContent>
                        </Select>
                      </div>
                      <div className="flex flex-row items-center gap-2">
                        <Label className="flex w-40">Ticket No{isError.passenger && <p className="ml-1 text-destructive">*</p>}</Label>
                        <Input className="h-7 bg-muted/50" name="ticketNo" defaultValue={passengerFormEdit.editingIndex !== null ? passengerForm[passengerFormEdit.editingIndex]?.ticketNo : ''}
                          onChange={(e) => handlePChange(passengerFormEdit.editingIndex, 'ticketNo', e.target.value)} />
                      </div>
                    </div>
                    <div className="flex flex-row col-span-4 mb-4 mr-6">
                      <Button variant="outline" className="ml-auto mr-3 bg-primary/30 hover:bg-primary/40" onClick={handlePassengerFormSave}>{passengerFormEdit.editingIndex !== null ? "Save" : "Add"}</Button>
                      <Button variant="outline" className="bg-primary/30 hover:bg-primary/40" onClick={() => setpassengerFormEdit({ visible: false, editingIndex: null })}>Close</Button>
                    </div>
                  </>
                )}
              </CardContent>
            </Card>
            <Separator />
            <div className="flex flex-row items-center justify-between gap-4 mr-2">
              <CardDescription>Carrier Details :</CardDescription>
              <Button size='sm' variant="secondary" onClick={(e) => { setcarrierFormEdit(prevState => ({ ...prevState, visible: !prevState.visible, editingIndex: null })); e.preventDefault(); }}>Add carrier <CirclePlus className="ml-2 size-4" /></Button>
            </div>
            <Card>
              <CardContent className="p-0">
                <Table>
                  <TableHeader className="bg-secondary/60">
                    <TableRow>
                      <TableHead className="rounded-tl-md"></TableHead>
                      <TableHead></TableHead>
                      <TableHead className="hidden md:table-cell">Carrier</TableHead>
                      <TableHead>From Location</TableHead>
                      <TableHead>To Location</TableHead>
                      <TableHead>Class</TableHead>
                      <TableHead>Departure / Arrival</TableHead>
                      <TableHead className="rounded-tr-md"><span className="sr-only">Actions</span></TableHead>
                    </TableRow>
                  </TableHeader>
                  <TableBody>
                    {editingCarrierForm.length > 0 ? (
                      editingCarrierForm.map((carriers, index) => (
                        <React.Fragment key={index}>
                          <TableRow>
                            <TableCell></TableCell>
                            <TableCell>{index + 1}</TableCell>
                            <TableCell className="hidden md:table-cell">{carriers.carrier}</TableCell>
                            <TableCell>{carriers.fromLocation}</TableCell>
                            <TableCell>{carriers.toLocation}</TableCell>
                            <TableCell>{carriers.airlineClass}</TableCell>
                            <TableCell>{carriers.departureDate ? new Date(carriers.departureDate).toLocaleDateString() : ""} - {carriers.arrivalDate ? new Date(carriers.arrivalDate).toLocaleDateString() : ""}</TableCell>
                            <TableCell>
                              <div className="flex flex-row items-center gap-4">
                                <Edit onClick={() => setcarrierFormEdit(prevState => ({ ...prevState, visible: !prevState.visible, editingIndex: index }))} className="cursor-pointer text-primary size-4" />
                                <Trash2 onClick={() => handleDeleteC(index)} className="cursor-pointer text-destructive size-4" />
                              </div>
                            </TableCell>
                          </TableRow>
                          {carrierFormEdit.visible && carrierFormEdit.editingIndex === index && (
                            <TableRow>
                              <TableCell colSpan={11}>
                                <div className="grid grid-cols-1 gap-3 mx-6 my-4 md:grid-cols-2 lg:grid-cols-4">
                                  <div className="flex flex-row items-center gap-2">
                                    <Label className="flex w-40 lg:w-44">Flight No.{isError.carrier && <p className="ml-1 text-destructive">*</p>}</Label>
                                    <CarrierSelect onFlightSelect={(flight) => handleFlightSelect(flight, carrierFormEdit.editingIndex)} value={carrierFormEdit.editingIndex !== null ? carrierForm[carrierFormEdit.editingIndex].carrier : ''}
                                      isVisible={true} isError={isError.airline} />
                                  </div>
                                  <div className="flex flex-row items-center gap-2">
                                    <Label className="flex w-40">From Location{isError.carrier && <p className="ml-1 text-destructive">*</p>}</Label>
                                    <Input className="h-7 bg-muted/50" name="fromLocation" value={selectedFromLocation || carrierForm[carrierFormEdit.editingIndex]?.fromLocation} placeholder="select flight no."
                                      onChange={(e) => handleCChange(carrierFormEdit.editingIndex, 'fromLocation', e.target.value)} readOnly />
                                  </div>
                                  <div className="flex flex-row items-center gap-2">
                                    <Label className="flex w-40">To Location{isError.carrier && <p className="ml-1 text-destructive">*</p>}</Label>
                                    <Input className="h-7 bg-muted/50" name="toLocation" value={selectedToLocation || carrierForm[carrierFormEdit.editingIndex]?.toLocation} placeholder="select flight no."
                                      onChange={(e) => handleCChange(carrierFormEdit.editingIndex, 'toLocation', e.target.value)} readOnly />
                                  </div>
                                  <div className="flex flex-row items-center gap-2">
                                    <Label className="flex w-40 lg:w-32">Class{isError.carrier && <p className="ml-1 text-destructive">*</p>}</Label>
                                    <Select name="airlineClass" defaultValue={carrierFormEdit.editingIndex !== null ? carrierForm[carrierFormEdit.editingIndex].airlineClass : ''}
                                      onValueChange={(e) => handleCChange(carrierFormEdit.editingIndex, 'airlineClass', e)}>
                                      <SelectTrigger className="w-full h-7 bg-muted/50 focus:ring-transparent">
                                        <SelectValue />
                                      </SelectTrigger>
                                      <SelectContent>
                                        <SelectGroup>
                                          {classItems.map(item => (
                                            <SelectItem key={item} value={item}>
                                              {item}
                                            </SelectItem>
                                          ))}
                                        </SelectGroup>
                                      </SelectContent>
                                    </Select>
                                  </div>
                                  <div className="flex flex-row items-center gap-2">
                                    <Label className="flex w-40">Departure Date{isError.carrier && <p className="ml-1 text-destructive">*</p>}</Label>
                                    <DatePicker className="h-7 bg-muted/50" value={carrierFormEdit.editingIndex !== null ? carrierForm[carrierFormEdit.editingIndex].departureDate : ''}
                                      onChange={(date) => handleCChange(carrierFormEdit.editingIndex, 'departureDate', date)} />
                                  </div>
                                  <div className="flex flex-row items-center gap-2">
                                    <Label className="flex w-40">Arrival Date{isError.carrier && <p className="ml-1 text-destructive">*</p>}</Label>
                                    <DatePicker className="h-7 bg-muted/50" value={carrierFormEdit.editingIndex !== null ? carrierForm[carrierFormEdit.editingIndex].arrivalDate : ''}
                                      onChange={(date) => handleCChange(carrierFormEdit.editingIndex, 'arrivalDate', date)} />
                                  </div>
                                </div>
                                <div className="flex flex-row col-span-4 mb-4 mr-6">
                                  <Button variant="outline" onClick={handleCarrierFormSave} className="ml-auto mr-3 bg-primary/30 hover:bg-primary/40">{carrierFormEdit.editingIndex !== null ? "Save" : "Add"}</Button>
                                  <Button variant="outline" className="bg-primary/30 hover:bg-primary/40" onClick={() => setcarrierFormEdit({ visible: false, editingIndex: null })}>Close</Button>
                                </div>
                              </TableCell>
                            </TableRow>
                          )}
                        </React.Fragment>
                      ))
                    ) : (
                      <TableRow>
                        <TableCell colSpan={9} className="text-center">No Carrier</TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
                {carrierFormEdit.visible && carrierFormEdit.editingIndex === null && (
                  <>
                    <Separator />
                    <div className="grid grid-cols-1 gap-3 mx-6 my-4 md:grid-cols-2 lg:grid-cols-4">
                      <div className="flex flex-row items-center gap-2">
                        <Label className="flex w-40 lg:w-44">Flight No.{isError.carrier && <p className="ml-1 text-destructive">*</p>}</Label>
                        <CarrierSelect onFlightSelect={(flight) => handleFlightSelect(flight, carrierFormEdit.editingIndex)} value={carrierFormEdit.editingIndex !== null ? carrierForm[carrierFormEdit.editingIndex].carrier : ''}
                          isVisible={true} isError={isError.airline} />
                      </div>
                      <div className="flex flex-row items-center gap-2">
                        <Label className="flex w-40">From Location{isError.carrier && <p className="ml-1 text-destructive">*</p>}</Label>
                        <Input className="h-7 bg-muted/50" name="fromLocation" value={selectedFromLocation || carrierForm[carrierFormEdit.editingIndex]?.fromLocation} placeholder="select flight no."
                          onChange={(e) => handleCChange(carrierFormEdit.editingIndex, 'fromLocation', e.target.value)} readOnly />
                      </div>
                      <div className="flex flex-row items-center gap-2">
                        <Label className="flex w-40">To Location{isError.carrier && <p className="ml-1 text-destructive">*</p>}</Label>
                        <Input className="h-7 bg-muted/50" name="toLocation" value={selectedToLocation || carrierForm[carrierFormEdit.editingIndex]?.toLocation} placeholder="select flight no."
                          onChange={(e) => handleCChange(carrierFormEdit.editingIndex, 'toLocation', e.target.value)} readOnly />
                      </div>
                      <div className="flex flex-row items-center gap-2">
                        <Label className="flex w-40 lg:w-32">Class{isError.carrier && <p className="ml-1 text-destructive">*</p>}</Label>
                        <Select name="airlineClass" defaultValue={carrierFormEdit.editingIndex !== null ? carrierForm[carrierFormEdit.editingIndex].airlineClass : ''}
                          onValueChange={(e) => handleCChange(carrierFormEdit.editingIndex, 'airlineClass', e)}>
                          <SelectTrigger className="w-full h-7 bg-muted/50 focus:ring-transparent">
                            <SelectValue />
                          </SelectTrigger>
                          <SelectContent>
                            <SelectGroup>
                              {classItems.map(item => (
                                <SelectItem key={item} value={item}>
                                  {item}
                                </SelectItem>
                              ))}
                            </SelectGroup>
                          </SelectContent>
                        </Select>
                      </div>
                      <div className="flex flex-row items-center gap-2">
                        <Label className="flex w-40">Departure Date{isError.carrier && <p className="ml-1 text-destructive">*</p>}</Label>
                        <DatePicker className="h-7 bg-muted/50" value={carrierFormEdit.editingIndex !== null ? carrierForm[carrierFormEdit.editingIndex].departureDate : ''}
                          onChange={(date) => handleCChange(carrierFormEdit.editingIndex, 'departureDate', date)} />
                      </div>
                      <div className="flex flex-row items-center gap-2">
                        <Label className="flex w-40">Arrival Date{isError.carrier && <p className="ml-1 text-destructive">*</p>}</Label>
                        <DatePicker className="h-7 bg-muted/50" value={carrierFormEdit.editingIndex !== null ? carrierForm[carrierFormEdit.editingIndex].arrivalDate : ''}
                          onChange={(date) => handleCChange(carrierFormEdit.editingIndex, 'arrivalDate', date)} />
                      </div>
                    </div>
                    <div className="flex flex-row col-span-4 mb-4 mr-6">
                      <Button variant="outline" onClick={handleCarrierFormSave} className="ml-auto mr-3 bg-primary/30 hover:bg-primary/40">{carrierFormEdit.editingIndex !== null ? "Save" : "Add"}</Button>
                      <Button variant="outline" className="bg-primary/30 hover:bg-primary/40" onClick={() => setcarrierFormEdit({ visible: false, editingIndex: null })}>Close</Button>
                    </div>
                  </>
                )}
              </CardContent>
            </Card>
            <Separator />
            <div className="flex flex-row items-center justify-between">
              <CardDescription>Fare Details :</CardDescription>
              {/* <div className='mr-2'>Total Fare : <span className='font-bold'>₹{fareForm[0]?.netAmount}</span></div> */}
            </div>
            <div className="grid grid-cols-1 gap-x-4 gap-y-2 md:grid-cols-2 lg:grid-cols-5">
              <div className="flex flex-row items-center gap-2">
                <Label className="flex w-40 lg:w-28">Fare{isError.fare && <p className="ml-1 text-destructive">*</p>}</Label>
                <Input className="h-7 bg-muted/50" type="number" name="fare" value={fareForm[0]?.fare}
                  onChange={(e) => handleFChange(0, 'fare', e.target.value)} onBlur={handleFareFormSave} />
              </div>
              <div className="flex flex-row items-center gap-2">
                <Label className="flex w-40">Total Fare{isError.fare && <p className="ml-1 text-destructive">*</p>}</Label>
                <Input className="h-7 bg-muted/50" type="number" name="totalFare" value={fareForm[0]?.totalFare}
                  onChange={(e) => handleFChange(0, 'totalFare', e.target.value)} onBlur={handleFareFormSave} />
              </div>
              <div className="flex flex-row items-center gap-2">
                <Label className="flex w-40 lg:w-52">Baggage Allow.{isError.fare && <p className="ml-1 text-destructive">*</p>}</Label>
                <Input className="h-7 bg-muted/50" type="number" name="baggageAllowance" value={fareForm[0]?.baggageAllowance}
                  onChange={(e) => handleFChange(0, 'baggageAllowance', e.target.value)} onBlur={handleFareFormSave} />
              </div>
              <div className="flex flex-row items-center gap-2">
                <Label className="flex w-40">Markup{isError.fare && <p className="ml-1 text-destructive">*</p>}</Label>
                <Input className="h-7 bg-muted/50" type="number" name="markup" value={fareForm[0]?.markup}
                  onChange={(e) => handleFChange(0, 'markup', e.target.value)} onBlur={handleFareFormSave} />
              </div>
              <div className="flex flex-row items-center gap-4">
                YQ?
                <Checkbox checked={fareForm[0]?.comTax !== 0} onCheckedChange={(checked) => { handleFChange(0, "comTax", checked); }} onBlur={handleFareFormSave} />
              </div>
              <div className="flex flex-row items-center gap-2">
                <Label className="flex w-40 lg:w-28">Base Fare</Label>
                <Input className="h-7 bg-muted/50" type="number" disabled name="baseFare" value={fareForm[0]?.baseFare} />
              </div>
              <div className="flex flex-row items-center gap-2">
                <Label className="flex w-40">Com Tax</Label>
                <Input className="h-7 bg-muted/50" type="number" name="comTax" disabled value={fareForm[0]?.comTax} />
              </div>
              <div className="flex flex-row items-center gap-2">
                <Label className="flex w-40 lg:w-52">Non Com Tax</Label>
                <Input className="h-7 bg-muted/50" type="number" name="nonComTax" disabled value={fareForm[0]?.nonComTax} />
              </div>
              <div className="flex flex-row items-center gap-2">
                <Label className="flex w-40">K3 Tax</Label>
                <Input className="h-7 bg-muted/50" type="number" name="k3" disabled value={fareForm[0]?.k3} />
              </div>
            </div>
            <Separator />
            <div className='grid grid-cols-1 gap-5 lg:grid-cols-2'>
              <div>
                <div className="flex flex-row items-center justify-between mb-3">
                  <CardDescription>Customer Charges Details :</CardDescription>
                </div>
                <Card>
                  <CardContent className="p-0">
                    <Table>
                      <TableHeader className="bg-secondary/60">
                        <TableRow>
                          <TableHead className="rounded-tl-md"></TableHead>
                          <TableHead></TableHead>
                          <TableHead>Charge Name</TableHead>
                          <TableHead>Charge ₹</TableHead>
                          <TableHead className="hidden md:table-cell">Total Charge ₹</TableHead>
                          <TableHead>Total Tax</TableHead>
                          <TableHead className="rounded-tr-md"><span className="sr-only">Actions</span></TableHead>
                        </TableRow>
                      </TableHeader>
                      {/* <TableBody>
                    {chargeForm.map((charge, index) => (
                      <TableRow key={index}>
                        <TableCell></TableCell>
                        <TableCell></TableCell>
                        <TableCell>{charge.chargeCodeName}<span className='ml-2 text-xs'>{charge.calculationMethod ? `(${charge.calculationMethod})` : "(PerTicket)"}</span></TableCell>
                        <TableCell><Input className="w-40 h-6" value={charge.value || ""} onChange={(e) => handleChargeChange(index, 'value', e.target.value)} onBlur={handleChargeFormSave} /></TableCell>
                        <TableCell>{charge.totalValue || ""}</TableCell>
                        <TableCell className="cursor-pointer">
                          <span className='flex items-center'>{charge.taxTotal || ""}
                            <TooltipProvider delayDuration={100}>
                              <Tooltip>
                                <TooltipTrigger asChild>{charge.taxTotal && <Info className='ml-1 size-4' />}</TooltipTrigger>
                                <TooltipContent className="mb-2">
                                  {charge.tax && charge.tax.length > 0 && charge.tax.some(t => t.taxCode !== "") ? charge.tax.map((tax) => (<div key={tax.taxCode}>{`${tax.taxCode}: ${tax.value}`}</div>)) : 'No Taxes'}
                                </TooltipContent>
                              </Tooltip>
                            </TooltipProvider>
                          </span>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody> */}
                      <TableBody>
                        {CUST_CHARGE_TYPES.map((chargeType, index) => {
                          const charge = custChargeForm.find(c => c.chargeCodeName === chargeType) || {
                            chargeCodeName: chargeType,
                            calculationMethod: "",
                            value: "",
                            totalValue: "",
                            tax: [],
                            taxTotal: null
                          };
                          return (
                            <TableRow key={index}>
                              <TableCell></TableCell>
                              <TableCell></TableCell>
                              <TableCell>{charge.chargeCodeName}<span className='ml-2 text-xs'>{charge.calculationMethod ? `(${charge.calculationMethod})` : "(PerTicket)"}</span>
                              </TableCell>
                              <TableCell>
                                <Input
                                  type="number"
                                  className="w-40 h-6"
                                  value={charge.value || ""}
                                  onChange={(e) => handleChargeChange(index, 'value', e.target.value)}
                                  onBlur={handleChargeFormSave}
                                />
                              </TableCell>
                              <TableCell>{charge.totalValue || ""}</TableCell>
                              <TableCell className="cursor-pointer">
                                <span className='flex items-center'>{charge.taxTotal || ""}
                                  <TooltipProvider delayDuration={100}>
                                    <Tooltip>
                                      <TooltipTrigger asChild>
                                        {charge.taxTotal && <Info className='ml-1 size-4' />}
                                      </TooltipTrigger>
                                      <TooltipContent className="mb-2">
                                        {charge.tax && charge.tax.length > 0 && charge.tax.some(t => t.taxCode !== "") ? charge.tax.map((tax) => (<div key={tax.taxCode}>{`${tax.taxCode}: ${tax.value}`}</div>)) : 'No Taxes'}
                                      </TooltipContent>
                                    </Tooltip>
                                  </TooltipProvider>
                                </span>
                              </TableCell>
                            </TableRow>
                          );
                        })}
                      </TableBody>
                    </Table>
                  </CardContent>
                </Card>
              </div>
              <Separator className="lg:sr-only" />
              <div>
                <div className="flex flex-row items-center justify-between mb-3">
                  <CardDescription>Supplier Charges Details :</CardDescription>
                </div>
                <Card>
                  <CardContent className="p-0">
                    <Table>
                      <TableHeader className="bg-secondary/60">
                        <TableRow>
                          <TableHead className="rounded-tl-md"></TableHead>
                          <TableHead></TableHead>
                          <TableHead>Charge Name</TableHead>
                          <TableHead>Charge ₹</TableHead>
                          <TableHead className="hidden md:table-cell">Total Charge ₹</TableHead>
                          <TableHead>Total Tax</TableHead>
                          <TableHead className="rounded-tr-md"><span className="sr-only">Actions</span></TableHead>
                        </TableRow>
                      </TableHeader>
                      <TableBody>
                        {suppChargeForm.map((charge, index) => (
                          <TableRow key={index}>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell>{charge.chargeCodeName}<span className='ml-2 text-xs'>{charge.calculationMethod ? `(${charge.calculationMethod})` : "(PerTicket)"}</span></TableCell>
                            <TableCell><Input className="w-40 h-6" value={charge.value} readOnly /></TableCell>
                            <TableCell>{charge.totalValue || ""}</TableCell>
                            <TableCell className="cursor-pointer">
                              <span className='flex items-center'>{charge.taxTotal || ""}
                                <TooltipProvider delayDuration={100}>
                                  <Tooltip>
                                    <TooltipTrigger asChild>{charge.taxTotal && <Info className='ml-1 size-4' />}</TooltipTrigger>
                                    <TooltipContent className="mb-2">
                                      {charge.tax && charge.tax.length > 0 && charge.tax.some(t => t.taxCode !== "") ? charge.tax.map((tax) => (<div key={tax.taxCode}>{`${tax.taxCode}: ${tax.value}`}</div>)) : 'No Taxes'}
                                    </TooltipContent>
                                  </Tooltip>
                                </TooltipProvider>
                              </span>
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </CardContent>
                </Card>
              </div>
            </div>
          </div>
        </ScrollArea>
        {/* <CardFooter className="py-0 mt-2 mr-2">
          {(!data || (data && data?.state !== "Submitted")) && <>
            {
              !id ? (
                <div className="flex items-center gap-2 ml-auto">
                  {hasRole("Admin") && <Button disabled>Approve</Button>}
                  <Button onClick={onSave} disabled={loading}>
                    {loading === "Save" ? 'Saving...' : 'Save draft'}
                  </Button>
                  {splitInv ? (
                    <SplitDialog onCreate={(formData) => { onCreate('Submitted'); onSubmit(formData); }} loading={loading} type="invoice" getFormValues={getValues} />
                  ) : (
                    <Button onClick={() => onCreate('Submitted')} disabled={loading}>
                      {loading === "Create" ? 'Creating...' : 'Create invoice'}
                    </Button>
                  )}
                </div>
              ) : (
                <div className='flex items-center gap-2 ml-auto'>
                  <Button onClick={onSave} disabled={loading}>
                    {loading === "Edit" ? 'Editing...' : 'Save Changes'}
                  </Button>
                  {splitInv ? (
                    <SplitDialog onCreate={(formData) => { onCreate('Submitted'); onSubmit(formData); }} loading={loading} type="invoice" getFormValues={getValues} />
                  ) : (
                    <Button onClick={() => onCreate('Submitted')} disabled={loading}>
                      {loading === "Create" ? 'Creating...' : 'Create invoice'}
                    </Button>
                  )}
                </div>
              )
            }</>}
        </CardFooter> */}
        <CardFooter className="py-0 mt-2 mr-2">
          {(!data || data?.state === "Draft") && (
            <div className="flex items-center gap-2 ml-auto">
              {hasRole("Admin") && <Button disabled={loading} onClick={() => onCreate('Approved')}>Approve</Button>}
              <Button onClick={onSave} disabled={loading}>
                {loading === "Save" ? 'Saving...' : id ? 'Save Changes' : 'Save draft'}
              </Button>
              {splitInv ? (
                <SplitDialog onCreate={(formData) => { onCreate('Submitted'); onSubmit(formData); }} loading={loading} type="invoice" getFormValues={getValues} />
              ) : (
                <Button onClick={() => onCreate('Submitted')} disabled={loading}>
                  {loading === "Create" ? 'Creating...' : 'Create invoice'}
                </Button>
              )}
            </div>
          )}
          {(hasRole("Admin") && data?.state === "Submitted") && <Button disabled={loading} className="ml-auto" onClick={() => onCreate('Approved')}>Approve</Button>}
        </CardFooter>
      </form>
      {/* </CardContent>
      </Card> */}
    </div>
  )
}
