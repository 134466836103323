import { Label } from 'src/components/ui/label';
import { Select, SelectContent, SelectGroup, SelectItem, SelectTrigger, SelectValue } from 'src/components/ui/select';
import React, { useEffect, useMemo, useState } from 'react';
import { Outlet, useNavigate, useParams } from 'react-router-dom';
import masterConfig from "src/masterConfig/master-config.json";

export default function MasterPage() {
    const navigate = useNavigate();
    const masterLists = useMemo(() => Object.keys(masterConfig).map(key => masterConfig[key]), []);
    const { masterType } = useParams();
    const [selectedMaster, setSelectedMaster] = useState(masterType);

    useEffect(() => {
        setSelectedMaster(masterType);
    }, [masterType]);

    const handleSelectChange = (value) => {
        navigate(`/dashboard/masters/${value.toLowerCase()}`);
    };

    return (
        <div className='m-5'>
            <div className="flex items-center justify-center w-full mb-5 text-[3vh] font-bold">Masters ({masterType})</div>
            <div className="flex items-center">
                <Label className="ml-6 font-bold w-28">Select Master -</Label>
                <Select value={selectedMaster} onValueChange={handleSelectChange}>
                    <SelectTrigger className="w-36 h-7 bg-muted/50 focus:ring-transparent">
                        <SelectValue />
                    </SelectTrigger>
                    <SelectContent>
                        <SelectGroup>
                            {masterLists.map((masters, index) => (
                                <SelectItem key={index} value={masters.title.toLowerCase()}>{masters.title}</SelectItem>
                            ))}
                        </SelectGroup>
                    </SelectContent>
                </Select>
            </div>
            <Outlet />
        </div>
    );
}