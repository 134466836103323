import { Button } from 'src/components/ui/button';
import { Input } from 'src/components/ui/input';
import { Label } from 'src/components/ui/label';
import { User } from 'lucide-react';
import React, { useState } from 'react'
import { toast } from 'sonner';

export default function ProfilePage() {
    const [previewImage, setPreviewImage] = useState();
    const handleChangePass = () => {
        toast.info("Mail sent", { description: "You can change password through the link provided in mail!", position:"top-center" });
    };
    return (
        <div className="max-h-screen mx-auto">
            <div className="flex justify-center mt-5 text-[3vh] font-bold">Profile</div>
            <div className="max-w-4xl p-4 mx-auto">
                <div className="grid grid-cols-1 gap-6">
                    <div className="grid items-center gap-2">
                        <div className="flex items-center gap-8">
                            <Label className="ml-1 text-lg">Profile picture </Label>
                            <div className="flex items-center rounded-full outline outline-secondary size-20">
                                {previewImage ? (
                                    <img alt="demo" src={previewImage} className="object-cover rounded-full size-20" width={100} height={100} />
                                ) : (
                                    <User className="mx-auto size-10 text-muted-foreground" />
                                )}
                            </div>
                        </div>
                        <Input type="file" accept="image/*" id="image" name="image" className="cursor-pointer"
                            onChange={(event) => {
                                if (event?.target?.files?.[0]) {
                                    const file = event.target.files[0];
                                    const reader = new FileReader();
                                    reader.onloadend = () => {
                                        setPreviewImage(reader.result);
                                    };
                                    reader.readAsDataURL(file);
                                } else {
                                    setPreviewImage(undefined);
                                }
                            }}
                        />
                    </div>
                    <div className="grid items-center gap-2">
                        <Label className="ml-1 text-lg">Username</Label>
                        <Input type="text" placeholder="What should we call you!" />
                    </div>
                    <div className="grid items-center gap-2">
                        <Label className="ml-1 text-lg">Email</Label>
                        <Input placeholder="example@xyz.xyz" type="email" />
                    </div>
                    <div className="grid items-center gap-2">
                        <Label className="ml-1 text-lg">NickName</Label>
                        <Input type="text" />
                    </div>
                    <div className="grid items-center gap-2">
                        <Label className="ml-1 text-lg">Bio</Label>
                        <Input type="text" />
                    </div>
                    <div className="flex flex-row items-center gap-2">
                        <Label className="ml-1 text-lg">Change password</Label>
                        <Button variant="outline" onClick={handleChangePass}>Change</Button>
                    </div>
                    <Button type="submit" className="w-32">
                        Save changes
                    </Button>
                    <div>This page is not yet functioning.</div>
                </div>
            </div>
        </div>
    );
}
