import { Button } from 'src/components/ui/button'
import { Command, CommandEmpty, CommandGroup, CommandInput, CommandItem, CommandList } from 'src/components/ui/command';
import { Popover, PopoverContent, PopoverTrigger } from 'src/components/ui/popover'
import { cn } from 'src/lib/utils';
import { Check, ChevronsDownUp } from 'lucide-react';
import React from 'react'

export default function SupplierSelect({ onSupplierSelect, value, isVisible, isError }) {
    const [open, setOpen] = React.useState(false)
    const [loading, setLoading] = React.useState(false);
    const [suppliers, setSupplier] = React.useState([]);
    const [selectedSupplier, setSelectedSupplier] = React.useState(null);
    const commandListRef = React.useRef(null);
    React.useEffect(() => {
        if (commandListRef.current) {
            commandListRef.current.scrollTop = 0;
        }
    }, [suppliers]);
    const getSuppliers = async () => {
        setLoading(true);
        try {
            const response = await fetch(`${(process.env.REACT_APP_API_HOST_MSTR === undefined) ? "" : process.env.REACT_APP_API_HOST_MSTR}/amigo-lekha-api/supplierManagement/v1/supplier`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json'
                },
            });
            if (!response.ok) {
                throw new Error('Failed to fetch data');
            }
            const fetchedData = await response.json();
            setSupplier(fetchedData);
        } catch (error) {
            console.error("Error fetching data:", error);
        } finally {
            setLoading(false);
        }
    }
    const handleSelectSupplier = async (supplierId) => {
        const supplier = suppliers.find((supp) => supp.supplierCode === supplierId);
        setSelectedSupplier(supplier);
        onSupplierSelect(supplier);
        setOpen(false);
    };
    React.useEffect(() => {
        if (value) {
            const supplier = suppliers.find((supp) => supp.supplierCode === value);
            if (supplier) {
                setSelectedSupplier(supplier);
            }
        }
    }, [value, suppliers, onSupplierSelect]);

    React.useEffect(() => {
        if (isVisible) {
            getSuppliers();
        }
    }, [isVisible]);
    return (
        <Popover open={open} onOpenChange={setOpen} modal={true}>
            <PopoverTrigger asChild onClick={getSuppliers}>
                <Button
                    variant="outline"
                    role="combobox"
                    aria-expanded={open}
                    className={`lg:w-[40vh] w-[45vh] bg-muted/50 h-7 justify-between ${isError && "border-destructive"}`}
                >
                    <span className="w-32 truncate">{selectedSupplier?.fullName || `Select supplier...`}</span>
                    <ChevronsDownUp className="w-4 h-4 ml-2 opacity-50 shrink-0" />
                </Button>
            </PopoverTrigger>
            <PopoverContent className="w-[28vh] h-[30vh] p-0">
                <Command>
                    <CommandInput placeholder="Search supplier..." className="h-8" />
                    <CommandList ref={commandListRef}>
                        <CommandEmpty>{loading ? "loading..." : `No supplier found.`}</CommandEmpty>
                        <CommandGroup>
                            {suppliers.map((supplier) => (
                                <CommandItem
                                    key={supplier.supplierCode}
                                    value={supplier.name}
                                    onSelect={() => handleSelectSupplier(supplier.supplierCode)}
                                >
                                    {supplier.fullName}
                                    <Check
                                        className={cn(
                                            "ml-auto h-4 w-4",
                                            selectedSupplier?.supplierCode === supplier.supplierCode ? "opacity-100" : "opacity-0"
                                        )}
                                    />
                                </CommandItem>
                            ))}
                        </CommandGroup>
                    </CommandList>
                </Command>
            </PopoverContent>
        </Popover>
    )
}
