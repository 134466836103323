import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Input } from 'src/components/ui/input.jsx';
import { Button } from 'src/components/ui/button.jsx';
import {
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableHeader,
    TableRow,
} from "src/components/ui/table"
import { Card, CardContent } from 'src/components/ui/card';
import { Calendar, ChevronLeft, ChevronRight, CirclePlus, Edit, Search } from 'lucide-react'
import { Link, useParams } from 'react-router-dom';
import { cn } from "src/lib/utils.js"
import {
    Tooltip,
    TooltipContent,
    TooltipProvider,
    TooltipTrigger,
} from "src/components/ui/tooltip"
import { DatePickerWithRange } from '../../components/datepicker';
import Deletedialog from '../../components/delete-dialog';
import ViewPayment from './payment-view';
import GenericAmountSeparator from '../../components/genric-amountseperator';

export default function PaymentPage() {
    const [data, setData] = useState();
    const [loading, setLoading] = useState(true);
    const [searchQuery, setSearchQuery] = useState('');
    const [dateRange, setDateRange] = useState(null);
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 10;
    let totalPages;
    const { paymentType } = useParams();

    useEffect(() => {
        fetchData();
    }, []);

    const fetchData = async () => {
        try {
            const response = await fetch(`${(process.env.REACT_APP_API_HOST_INV === undefined) ? "" : process.env.REACT_APP_API_HOST_INV}/amigo-lekha-api/paymentManagement/v1/payment?createdBy=amigo2&subType=${paymentType}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json'
                },
            });
            if (!response.ok) {
                throw new Error('Failed to fetch data');
            }
            const fetchedData = await response.json();
            fetchedData.sort((a, b) => {
                return new Date(b.paymentDate) - new Date(a.paymentDate);
            });
            setData(fetchedData);
        } catch (error) {
            console.error("Error fetching data:", error);
        } finally {
            setLoading(false);
        }
    };

    const sortData = useCallback((data, searchQuery, dateRange) => {
        if (!data) {
            return [];
        }
        const query = searchQuery.toLowerCase();
        const endDate = dateRange?.to ? new Date(dateRange.to) : null;
        if (endDate) {
            endDate.setDate(endDate.getDate() + 1);
        }

        const filteredData = data.filter((item) => {
            const itemDate = new Date(item.paymentDate);
            const customerName = item.PaidTo?.name || '';
            return (
                (Object.values(item)
                    .join(' ')
                    .toLowerCase()
                    .includes(query) ||
                    customerName.toLowerCase().includes(query)) &&
                (!dateRange ||
                    (dateRange.from <= itemDate && itemDate < endDate))
            );
        });
        const indexOfLastItem = currentPage * itemsPerPage;
        const indexOfFirstItem = indexOfLastItem - itemsPerPage;
        const currentPageData = filteredData.slice(indexOfFirstItem, indexOfLastItem);

        return currentPageData;
    }, [currentPage, itemsPerPage]);

    const handlePreviousPage = () => {
        if (currentPage > 1) {
            setCurrentPage(currentPage - 1);
        }
    };

    const totalItems = data ? data.length : 0;
    totalPages = data ? Math.ceil(totalItems / itemsPerPage) : 0;

    const handleNextPage = () => {
        if (currentPage < totalPages) {
            setCurrentPage(currentPage + 1);
        }
    };

    const handleRefresh = () => {
        fetchData();
    };


    const statusCol = {
        Draft: "bg-stay/20 text-stay",
        Submitted: "bg-success/25 text-success",
        Deleted: "bg-destructive/30 text-rose-700",
        Approved: "bg-primary/25 text-primary",
    };

    const sortedData = useMemo(() => sortData(data, searchQuery, dateRange), [sortData, data, searchQuery, dateRange]);

    return (
        <div className="flex flex-col items-center w-full h-full">
            <div className="flex items-center justify-center w-full h-10 text-[3vh] mt-5 font-bold">Payment ({paymentType})</div>
            <main className="grid items-start w-full gap-4 px-6 py-2 bg-re lg:px-10 lg:py-5 md:gap-4">
                <div className="flex items-center">
                    <div className='relative'>
                        <Input type="text" placeholder="Search by Field..." className="lg:w-[28vh] md:w-[25vh] w-36 hover:bg-muted transition-all" onChange={(e) => setSearchQuery(e.target.value)} />
                        <div className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                            <Search className='size-5 text-muted-foreground' />
                        </div>
                    </div>
                    <div className='relative'>
                        <DatePickerWithRange className="ml-6 lg:w-[28vh] md:w-[25vh] w-36" onDateChange={setDateRange} />
                        <div className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                            <Calendar className='size-5 text-muted-foreground' />
                        </div>
                    </div>
                    <div className="flex items-center ml-auto">
                        <Link to={`/dashboard/payment/${paymentType}/add`}><Button className="h-10 gap-1">
                            <CirclePlus className="size-4" />
                            <span className="sr-only lg:not-sr-only md:sr-only sm:whitespace-nowrap">
                                Add Payment
                            </span>
                        </Button></Link>
                    </div>
                </div>
                <Card>
                    <CardContent className="mt-3">
                        <Table>
                            <TableHeader className="bg-secondary/60">
                                <TableRow>
                                    <TableHead></TableHead>
                                    <TableHead>Payment No</TableHead>
                                    <TableHead>Paid To</TableHead>
                                    <TableHead className="hidden md:table-cell">Created at</TableHead>
                                    <TableHead className="hidden md:table-cell">Status</TableHead>
                                    <TableHead>Amount</TableHead>
                                    <TableHead><span className="sr-only">Actions</span></TableHead>
                                </TableRow>
                            </TableHeader>
                            <TableBody>
                                {loading ? (
                                    <TableRow>
                                        <TableCell colSpan={7} className="text-center">Loading...</TableCell>
                                    </TableRow>
                                ) : (
                                    sortedData.length > 0 ? (
                                        sortedData.map((item, index) => (
                                            <TableRow key={index}>
                                                <TableCell></TableCell>
                                                <TableCell>
                                                    <ViewPayment data={item} icon={false} onViewSuccess={() => handleRefresh()} />
                                                </TableCell>
                                                <TableCell>{item.PaidTo.name || ""}</TableCell>
                                                <TableCell className="hidden md:table-cell">{item.paymentDate ? new Date(item.paymentDate).toLocaleDateString() : ""}</TableCell>
                                                <TableCell className="hidden md:table-cell">
                                                    <div className={cn("w-fit px-2 py-[2px] rounded-md", statusCol[item.state])}>{item.state || ""}</div>
                                                </TableCell>
                                                {/* <TableCell> {item.paymentDetails ? "₹" + item.paymentDetails.amount : '-' || ""}</TableCell> */}
                                                <TableCell>{item.paymentDetails ? <GenericAmountSeparator value={item.paymentDetails.amount} viewMode={true} /> : '-'}
                                                </TableCell>
                                                <TableCell className="w-[20vh]">
                                                    <div className="flex items-center gap-3">
                                                        <TooltipProvider delayDuration={100}>
                                                            <Tooltip>
                                                                <TooltipTrigger>
                                                                    <ViewPayment data={item} icon={true} onViewSuccess={() => handleRefresh()} />
                                                                </TooltipTrigger>
                                                                <TooltipContent className="mb-2">
                                                                    <p>View Payment</p>
                                                                </TooltipContent>
                                                            </Tooltip>
                                                        </TooltipProvider>
                                                        {/* {item.state === "Draft" &&  */}
                                                        <TooltipProvider delayDuration={100}>
                                                            <Tooltip>
                                                                <TooltipTrigger>
                                                                    {item.state === "Draft" ?
                                                                        <Link to={`/dashboard/payment/${paymentType}/edit/${encodeURIComponent(item.paymentNo)}`} state={{ item: item }}><Edit className='size-[1.95vh] text-primary/90' /></Link>
                                                                        :
                                                                        <Edit className='size-[1.95vh] text-primary/50' />
                                                                    }
                                                                </TooltipTrigger>
                                                                <TooltipContent className="mb-2">
                                                                    <p>Edit Payment</p>
                                                                </TooltipContent>
                                                            </Tooltip>
                                                        </TooltipProvider>
                                                        <TooltipProvider delayDuration={100}>
                                                            <Tooltip>
                                                                <TooltipTrigger>
                                                                    <Deletedialog Id={item.paymentNo} onDeleteSuccess={() => handleRefresh()} type="payment" disable={item.state === "Deleted" && true} />
                                                                </TooltipTrigger>
                                                                <TooltipContent className="mb-2">
                                                                    <p>Delete Payment</p>
                                                                </TooltipContent>
                                                            </Tooltip>
                                                        </TooltipProvider>
                                                    </div>
                                                </TableCell>
                                            </TableRow>
                                        ))
                                    ) : (
                                        <TableRow>
                                            <TableCell colSpan={7} className="text-center">No data available</TableCell>
                                        </TableRow>
                                    )
                                )}
                            </TableBody>
                        </Table>
                        <div className="flex items-center justify-end mt-2 space-x-2">
                            <div className="mr-4 text-[1.6vh] text-muted-foreground">Pages {currentPage} of {totalPages}</div>
                            <Button variant="outline" size="sm" onClick={handlePreviousPage} disabled={currentPage === 1}>
                                <ChevronLeft className="mr-1 size-4" />Previous
                            </Button>
                            <Button variant="outline" size="sm" onClick={handleNextPage} disabled={!data || currentPage === totalPages || (totalItems <= itemsPerPage)}>
                                Next<ChevronRight className="ml-1 size-4" />
                            </Button>
                        </div>
                    </CardContent>
                </Card>
            </main>
        </div>
    );
}
