import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Input } from 'src/components/ui/input.jsx';
import { Button } from 'src/components/ui/button.jsx';
import {
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableHeader,
    TableRow,
} from "src/components/ui/table"
import { Card, CardContent } from 'src/components/ui/card';
import { Calendar, ChevronDownIcon, ChevronLeft, ChevronRight, CirclePlus, Edit, Search } from 'lucide-react'
import { Link, useParams } from 'react-router-dom';
import { cn } from "src/lib/utils.js"
import {
    Tooltip,
    TooltipContent,
    TooltipProvider,
    TooltipTrigger,
} from "src/components/ui/tooltip"
import { DatePickerWithRange } from '../../components/datepicker';
import Deletedialog from '../../components/delete-dialog';
import GenericAmountSeparator from '../../components/genric-amountseperator';
import ViewCreditNoteAirline from './airline/creditnote-airline-view';
import { toast } from 'sonner';
import { DropdownMenu, DropdownMenuCheckboxItem, DropdownMenuContent, DropdownMenuTrigger } from 'src/components/ui/dropdown-menu.jsx';
import ViewCreditNoteHotel from './hotel/creditnote-hotel-view';

export default function CreditNotePage() {
    const [data, setData] = useState();
    const [loading, setLoading] = useState(true);
    const [searchQuery, setSearchQuery] = useState('');
    const [dateRange, setDateRange] = useState(null);
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 10;
    let totalPages;
    const { creditType } = useParams();
    const searchInputRef = useRef(null);
    const columnConfigs = {
        common: [
            { key: 'creditNoteId', label: 'Credit Note Id', default: true },
            { key: 'invoiceId', label: 'Invoice Id', default: true },
            { key: 'customerName', label: 'Customer Name', default: false },
            { key: 'createdAt', label: 'Created at', default: true },
            { key: 'status', label: 'Status', default: true },
            { key: 'amount', label: 'Amount', default: true },
            { key: 'passengers', label: 'Passengers', default: false },
        ],
        airline: [
            { key: 'airlinePNR', label: 'Airline PNR', default: true },
        ],
        hotel: [
            // { key: 'checkIn', label: 'Check-in Date', default: true },
            // { key: 'checkOut', label: 'Check-out Date', default: true },
        ],
    };
    const getDefaultColumns = (creditType) => {
        const defaultColumns = [...columnConfigs.common, ...(columnConfigs[creditType] || [])];
        return defaultColumns.reduce((acc, column) => {
            acc[column.key] = column.default;
            return acc;
        }, {});
    };
    const getVisibleColumns = (creditType) => {
        const storedColumns = localStorage.getItem('visibleColumnsCn');
        if (storedColumns) {
            const parsedColumns = JSON.parse(storedColumns);
            if (parsedColumns[creditType] && Object.keys(parsedColumns[creditType]).length > 0) {
                return parsedColumns[creditType];
            }
        }
        return getDefaultColumns(creditType);
    };
    const [visibleColumns, setVisibleColumns] = useState(() => getVisibleColumns(creditType));

    useEffect(() => {
        fetchData();
        if (searchInputRef.current) {
            searchInputRef.current.value = "";
        }
        setVisibleColumns(getVisibleColumns(creditType));
    }, [creditType]);

    const fetchData = async () => {
        try {
            const response = await fetch(`${(process.env.REACT_APP_API_HOST_INV === undefined) ? "" : process.env.REACT_APP_API_HOST_INV}/amigo-lekha-api/creditNoteManagement/v1/creditNote?createdBy=amigo2&subType=${creditType}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json'
                },
            });
            if (!response.ok) {
                throw new Error('Failed to fetch data');
            }
            const fetchedData = await response.json();
            fetchedData.sort((a, b) => {
                return new Date(b.creditNoteDate) - new Date(a.creditNoteDate);
            });
            setData(fetchedData);
        } catch (error) {
            console.error("Error fetching data:", error);
        } finally {
            setLoading(false);
        }
    };

    const getProductDetails = (item, creditType) => {
        switch (creditType) {
            case 'airline':
                return item.productDetails?.[0]?.product;
            case 'cab':
                return item.cabProductDetails?.[0]?.product;
            case 'hotel':
                return item.hotelProductDetails?.[0]?.product;
            case 'railway':
                return item.railwayProductDetails?.[0]?.product;
            case 'bus':
                return item.busProductDetails?.[0]?.product;
            case 'passport':
                return item.passportProductDetails?.[0]?.product;
            case 'visa':
                return item.visaProductDetails?.[0]?.product;
            case 'insurance':
                return item.insuranceProductDetails?.[0]?.product;
            case 'eurail':
                return item.eurailProductDetails?.[0]?.product;
            case 'cruise':
                return item.cruiseProductDetails?.[0]?.product;
            case 'tour-package':
                return item.tourPackageProductDetails?.[0]?.product;
            case 'miscellaneous':
                return item.miscProductDetails?.[0]?.product;
            default:
                return null;
        }
    };

    const sortData = useCallback((data, searchQuery, dateRange) => {
        searchQuery = searchInputRef.current?.value || '';
        if (!data) {
            return [];
        }
        const query = searchQuery.toLowerCase();
        const endDate = dateRange?.to ? new Date(dateRange.to) : null;
        if (endDate) {
            endDate.setDate(endDate.getDate() + 1);
        }
        const filteredData = data.filter((item) => {
            const itemDate = new Date(item.creditNoteDate);
            const customerName = item.customerRefOrValue?.name || '';
            const airlinePNR = item.productDetails?.[0]?.product?.airlinePNR || '';
            const productDetailsKey = getProductDetails(item, creditType);
            let passengers;
            if (creditType === "Cab") {
                passengers = productDetailsKey?.rideDetails?.flatMap(ride => ride.passenger) || [];
            } else { passengers = productDetailsKey?.passenger || []; }
            const passengerNames = passengers.map(p => p.fullName || '').join(' ').toLowerCase();
            return (
                (Object.values(item)
                    .join(' ')
                    .toLowerCase()
                    .includes(query) ||
                    customerName.toLowerCase().includes(query) ||
                    airlinePNR.toLowerCase().includes(query) ||
                    passengerNames.includes(query)) &&
                (!dateRange ||
                    (dateRange.from <= itemDate && itemDate < endDate))
            );
        });
        if (filteredData.length === 0) {
            if (dateRange && (dateRange.from || dateRange.to)) {
                toast.info("No data found for the selected date range.");
            } else if (searchQuery) {
                toast.info("No matching data found for your search.");
            } else {
                toast.info("No data available.");
            }
        }
        const indexOfLastItem = currentPage * itemsPerPage;
        const indexOfFirstItem = indexOfLastItem - itemsPerPage;
        const currentPageData = filteredData.slice(indexOfFirstItem, indexOfLastItem);

        return currentPageData;
    }, [currentPage, itemsPerPage, creditType]);


    const handlePreviousPage = () => {
        if (currentPage > 1) {
            setCurrentPage(currentPage - 1);
        }
    };

    const totalItems = data ? data.length : 0;
    totalPages = data ? Math.ceil(totalItems / itemsPerPage) : 0;

    const handleNextPage = () => {
        if (currentPage < totalPages) {
            setCurrentPage(currentPage + 1);
        }
    };

    const handleRefresh = () => {
        fetchData();
    };


    const statusCol = {
        Draft: "bg-stay/20 text-stay",
        Submitted: "bg-success/25 text-success",
        Deleted: "bg-destructive/30 text-rose-700",
        Approved: "bg-primary/25 text-primary",
    };

    const sortedData = useMemo(() => sortData(data, searchQuery, dateRange), [sortData, data, searchQuery, dateRange]);

    const renderCreditNoteComponent = (item, icon) => {
        switch (creditType) {
            case 'airline':
                return <ViewCreditNoteAirline data={item} icon={icon} onViewSuccess={() => handleRefresh()} />;
            // case 'cab':
            //     return <ViewInvoiceCab data={item} icon={icon} onViewSuccess={() => handleRefresh()} />;
            case 'hotel':
                return <ViewCreditNoteHotel data={item} icon={icon} onViewSuccess={() => handleRefresh()} />;
            // case 'railway':
            //     return <ViewInvoiceRailway data={item} icon={icon} onViewSuccess={() => handleRefresh()} />;
            // case 'bus':
            //     return <ViewInvoiceBus data={item} icon={icon} onViewSuccess={() => handleRefresh()} />;
            // case 'eurail':
            //     return <ViewInvoiceEURail data={item} icon={icon} onViewSuccess={() => handleRefresh()} />;
            // case 'insurance':
            //     return <ViewInvoiceInsurance data={item} icon={icon} onViewSuccess={() => handleRefresh()} />;
            // case 'passport':
            //     return <ViewInvoicePassport data={item} icon={icon} onViewSuccess={() => handleRefresh()} />;
            // case 'visa':
            //     return <ViewInvoiceVisa data={item} icon={icon} onViewSuccess={() => handleRefresh()} />;
            // case 'tour-package':
            //     return <ViewInvoiceTourPackage data={item} icon={icon} onViewSuccess={() => handleRefresh()} />;
            // case 'miscellaneous':
            //     return <ViewInvoiceMiscellaneous data={item} icon={icon} onViewSuccess={() => handleRefresh()} />;
            default:
                return <ViewCreditNoteAirline data={item} icon={icon} onViewSuccess={() => handleRefresh()} />;
        }
    };
    const renderCellContent = (key, item) => {
        const productDetails = getProductDetails(item, creditType);
        switch (key) {
            case 'creditNoteId':
                return renderCreditNoteComponent(item, false);
            case 'invoiceId':
                return item.invoiceRefOrValue.invoiceId;
            case 'airlinePNR':
                return item.productDetails?.[0].product.airlinePNR;
            case 'customerName':
                return item.customerRefOrValue?.name || "";
            case 'createdAt':
                return item.creditNoteDate ? new Date(item.creditNoteDate).toLocaleDateString() : "";
            case 'status':
                return <div className={cn("w-fit px-2 py-[2px] rounded-md", statusCol[item.state])}>{item.state || ""}</div>;
            case 'amount':
                return item ? <GenericAmountSeparator value={item.creditNoteNetAmount} viewMode={true} /> : '-';
            case 'passengers':
                if (creditType === "cab") {
                    return productDetails?.rideDetails?.flatMap(ride => ride.passenger?.map(p => p.fullName)).filter(Boolean).join(', ') || '-';
                } else {
                    return productDetails?.passenger?.map(p => p.fullName).filter(Boolean).join(', ') || '-';
                }
            default:
                return '';
        }
    };
    const updateVisibleColumns = (key, checked) => {
        const newVisibleColumns = { ...visibleColumns, [key]: checked };
        setVisibleColumns(newVisibleColumns);

        const storedColumns = JSON.parse(localStorage.getItem('visibleColumnsCn') || '{}');
        storedColumns[creditType] = newVisibleColumns;
        localStorage.setItem('visibleColumnsCn', JSON.stringify(storedColumns));
    };

    return (
        <div className="flex flex-col items-center w-full h-full">
            <div className="flex items-center justify-center w-full h-10 text-[3vh] mt-5 font-bold">Credit Note ({creditType})</div>
            <main className="grid items-start w-full gap-4 px-6 py-2 bg-re lg:px-10 lg:py-5 md:gap-4">
                <div className="flex items-center">
                    <div className='relative'>
                        <Input type="text" placeholder="Search by Field..." className="lg:w-[28vh] md:w-[25vh] w-36 hover:bg-muted transition-all" onChange={(e) => setSearchQuery(e.target.value)} />
                        <div className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                            <Search className='size-5 text-muted-foreground' />
                        </div>
                    </div>
                    <div className='relative'>
                        <DatePickerWithRange className="ml-6 lg:w-[28vh] md:w-[25vh] w-36" onDateChange={setDateRange} />
                        <div className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                            <Calendar className='size-5 text-muted-foreground' />
                        </div>
                    </div>
                    <div className='ml-4'>
                        <DropdownMenu>
                            <DropdownMenuTrigger asChild>
                                <Button variant="outline" className="ml-auto">
                                    Columns <ChevronDownIcon className="w-4 h-4 ml-2" />
                                </Button>
                            </DropdownMenuTrigger>
                            <DropdownMenuContent className="w-56">
                                {[...columnConfigs.common, ...(columnConfigs[creditType] || [])].map(({ key, label }) => (
                                    <DropdownMenuCheckboxItem
                                        key={key}
                                        checked={visibleColumns[key]}
                                        onCheckedChange={(checked) => updateVisibleColumns(key, checked)}
                                    >
                                        {label}
                                    </DropdownMenuCheckboxItem>
                                ))}
                            </DropdownMenuContent>
                        </DropdownMenu>
                    </div>
                    <div className="flex items-center ml-auto">
                        <Link to={`/dashboard/credit-note/${creditType}/add`}><Button className="h-10 gap-1">
                            <CirclePlus className="size-4" />
                            <span className="sr-only lg:not-sr-only md:sr-only sm:whitespace-nowrap">
                                Add Credit Note
                            </span>
                        </Button></Link>
                    </div>
                </div>
                <Card>
                    <CardContent className="mt-3">
                        <Table>
                            <TableHeader className="bg-secondary/60">
                                <TableRow>
                                    <TableHead></TableHead>
                                    {[...columnConfigs.common, ...(columnConfigs[creditType] || [])].map(({ key, label }) => (
                                        visibleColumns[key] && <TableHead key={key}>{label}</TableHead>
                                    ))}
                                    <TableHead><span className="sr-only">Actions</span></TableHead>
                                </TableRow>
                            </TableHeader>
                            <TableBody>
                                {loading ? (
                                    <TableRow>
                                        <TableCell colSpan={7} className="text-center">Loading...</TableCell>
                                    </TableRow>
                                ) : (
                                    sortedData.length > 0 ? (
                                        sortedData.map((item, index) => (
                                            <TableRow key={index}>
                                                <TableCell></TableCell>
                                                {[...columnConfigs.common, ...(columnConfigs[creditType] || [])].map(({ key }) => (
                                                    visibleColumns[key] && (
                                                        <TableCell key={key} className="max-w-52">
                                                            {renderCellContent(key, item)}
                                                        </TableCell>
                                                    )
                                                ))}
                                                <TableCell className="w-[20vh]">
                                                    <div className="flex items-center gap-3">
                                                        <TooltipProvider delayDuration={100}>
                                                            <Tooltip>
                                                                <TooltipTrigger>
                                                                    {renderCreditNoteComponent(item, true)}
                                                                </TooltipTrigger>
                                                                <TooltipContent className="mb-2">
                                                                    <p>View Credit Note</p>
                                                                </TooltipContent>
                                                            </Tooltip>
                                                        </TooltipProvider>
                                                        <TooltipProvider delayDuration={100}>
                                                            <Tooltip>
                                                                <TooltipTrigger>
                                                                    {item.state === "Draft" ?
                                                                        <Link to={`/dashboard/credit-note/${creditType}/edit/${encodeURIComponent(item.creditNoteId)}`} state={{ item: item }}><Edit className='size-[1.95vh] text-primary/90' /></Link>
                                                                        :
                                                                        <Edit className='size-[1.95vh] text-primary/50' />
                                                                    }
                                                                </TooltipTrigger>
                                                                <TooltipContent className="mb-2">
                                                                    <p>Edit Credit Note</p>
                                                                </TooltipContent>
                                                            </Tooltip>
                                                        </TooltipProvider>
                                                        <TooltipProvider delayDuration={100}>
                                                            <Tooltip>
                                                                <TooltipTrigger>
                                                                    <Deletedialog Id={item.creditNoteId} onDeleteSuccess={() => handleRefresh()} type="creditNote" disable={item.state === "Deleted" && true} />
                                                                </TooltipTrigger>
                                                                <TooltipContent className="mb-2">
                                                                    <p>Delete Credit Note</p>
                                                                </TooltipContent>
                                                            </Tooltip>
                                                        </TooltipProvider>
                                                    </div>
                                                </TableCell>
                                            </TableRow>
                                        ))
                                    ) : (
                                        <TableRow>
                                            <TableCell colSpan={7} className="text-center">No data available</TableCell>
                                        </TableRow>
                                    )
                                )}
                            </TableBody>
                        </Table>
                        <div className="flex items-center justify-end mt-2 space-x-2">
                            <div className="mr-4 text-[1.6vh] text-muted-foreground">Pages {currentPage} of {totalPages}</div>
                            <Button variant="outline" size="sm" onClick={handlePreviousPage} disabled={currentPage === 1}>
                                <ChevronLeft className="mr-1 size-4" />Previous
                            </Button>
                            <Button variant="outline" size="sm" onClick={handleNextPage} disabled={!data || currentPage === totalPages || (totalItems <= itemsPerPage)}>
                                Next<ChevronRight className="ml-1 size-4" />
                            </Button>
                        </div>
                    </CardContent>
                </Card>
            </main>
        </div>
    );
}
