import { Button } from 'src/components/ui/button'
import { Command, CommandEmpty, CommandGroup, CommandInput, CommandItem, CommandList } from 'src/components/ui/command';
import { Popover, PopoverContent, PopoverTrigger } from 'src/components/ui/popover'
import { cn } from 'src/lib/utils';
import { Check, ChevronsDownUp } from 'lucide-react';
import React from 'react'
import { useParams } from 'react-router-dom';

export default function PNRSelect({ onPNRSelect, value, isVisible, isError, disable }) {
    const [open, setOpen] = React.useState(false)
    const [loading, setLoading] = React.useState(false);
    const [invoices, setInvoice] = React.useState([]);
    const [selectedInvoice, setSelectedInvoice] = React.useState(null);
    const getInvoices = async () => {
        setLoading(true);
        try {
            let domesticData = [];
            let internationalData = [];
            let combinedData = [];
            const domesticResponse = await fetch(`${(process.env.REACT_APP_API_HOST_INV === undefined) ? "" : process.env.REACT_APP_API_HOST_INV}/amigo-lekha-api/invoiceManagement/v1/invoice?state=Submitted&subType=airline-domestic`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json'
                },
            });
            if (!domesticResponse.ok) {
                throw new Error('Failed to fetch data');
            }
            domesticData = await domesticResponse.json();
            const internationalResponse = await fetch(`${(process.env.REACT_APP_API_HOST_INV === undefined) ? "" : process.env.REACT_APP_API_HOST_INV}/amigo-lekha-api/invoiceManagement/v1/invoice?state=Submitted&subType=airline-international`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json'
                },
            });
            if (!internationalResponse.ok) {
                throw new Error('Failed to fetch data');
            }
            internationalData = await internationalResponse.json();
            combinedData = [...domesticData, ...internationalData];
            setInvoice(combinedData);
        } catch (error) {
            console.error("Error fetching data:", error);
        } finally {
            setLoading(false);
        }
    }
    const handleSelectInvoice = async (pnr) => {
        const invoice = invoices.find((inv) => inv.productDetails[0].product.airlinePNR === pnr);
        setSelectedInvoice(invoice);
        onPNRSelect(invoice);
        setOpen(false);
    };
    React.useEffect(() => {
        if (value) {
            const invoice = invoices.find((inv) => inv.productDetails[0].product.airlinePNR === value);
            if (invoice) {
                setSelectedInvoice(invoice);
            }
        }
    }, [value, invoices, onPNRSelect]);

    React.useEffect(() => {
        if (isVisible) {
            getInvoices();
        }
    }, [isVisible]);
    return (
        <Popover open={open} onOpenChange={setOpen} modal={true}>
            <PopoverTrigger asChild onClick={getInvoices}>
                <Button
                    variant="outline"
                    role="combobox"
                    aria-expanded={open}
                    className={`lg:w-[40vh] w-[45vh] bg-muted h-7 justify-between ${isError && "border-destructive"}`}
                    disabled={disable}
                >
                    {selectedInvoice?.productDetails[0].product.airlinePNR || `Select PNR...`}
                    <ChevronsDownUp className="w-4 h-4 ml-2 opacity-50 shrink-0" />
                </Button>
            </PopoverTrigger>
            <PopoverContent className="w-[28vh] h-[30vh] p-0">
                <Command>
                    <CommandInput placeholder="Search PNR..." className="h-8" />
                    <CommandList>
                        <CommandEmpty>{loading ? "loading..." : `No invoice found.`}</CommandEmpty>
                        <CommandGroup>
                            {invoices.map((invoice) => (
                                <CommandItem
                                    key={invoice.productDetails[0].product.airlinePNR}
                                    value={invoice.name}
                                    onSelect={() => handleSelectInvoice(invoice.productDetails[0].product.airlinePNR)}
                                >
                                    {invoice.productDetails[0].product.airlinePNR}
                                    <Check
                                        className={cn(
                                            "ml-auto h-4 w-4",
                                            selectedInvoice?.productDetails[0].product.airlinePNR === invoice.productDetails[0].product.airlinePNR ? "opacity-100" : "opacity-0"
                                        )}
                                    />
                                </CommandItem>
                            ))}
                        </CommandGroup>
                    </CommandList>
                </Command>
            </PopoverContent>
        </Popover>
    )
}
