import { format, subDays } from 'date-fns';
import UserInvStatus from './Graphs/user-inv-status';
import AllUserInvStatus from './Graphs/alluser-inv-status';
import InvPayCreditGraph from './Graphs/inv-pay-credit';
import { useEffect, useState } from 'react';
import { getUserInfo } from 'src/helper/user-data';

export default function AnalyticPage() {
  const[userData, setUserData] = useState(null);
    
    useEffect(() => {
        const userData = getUserInfo();
        setUserData(userData);
    }, []);
  return (
    <div className='m-5'>
      <div className="flex items-center justify-center w-full mb-2 text-[3vh] font-bold">Analytics</div>
      <div className='grid grid-cols-1 gap-8 mx-5 my-8 lg:grid-cols-4 md:grid-cols-2'>
        <div className='pt-5 font-bold text-center rounded-md shadow-md h-36 bg-primary/30'>
          Welcome {userData?.fullName}!<br/>
          <span className='ml-2 text-sm font-normal'>Last Invoice Created - {format(new Date(), 'do MMMM, h:mm a')}</span><br/>
          <span className='ml-2 text-sm font-normal'>Last Customer Added - {format(new Date(), 'do MMMM, h:mm a')}</span>
        </div>
        <div className='pt-2 font-bold text-center rounded-md shadow-md h-36 bg-stay/45'>
          <div className='mb-3 ml-2 text-xs font-normal text-start'>({format(subDays(new Date(), 30), 'do MMMM')} - {format(new Date(), 'do MMMM')})</div>Total Invoice Status<div className='flex justify-center gap-20 m-5 font-normal'><span>Draft: 20</span><span>Submitted: 16</span></div>
        </div>
        <div class='pt-2 text-center rounded-md shadow-md h-36 bg-destructive/45'>
          <span class='font-bold'>Users Invoice status <span className='text-xs font-normal'>(Top 3)</span></span>
          <table className='w-full mt-2'>
            <thead className='border-y border-y-destructive/40'>
              <tr className='text-sm'>
                <th class="text-secondary-foreground">Name</th>
                <th class="text-secondary-foreground">Draft</th>
                <th class="text-secondary-foreground">Submitted</th>
              </tr>
            </thead>
            <tbody >
              <tr class="text-sm">
                <td className='truncate max-w-16'>Karan Pawar</td>
                <td>10</td>
                <td>15</td>
              </tr>
              <tr class="text-sm">
                <td className='truncate max-w-16'>Bhushan Mahajan</td>
                <td>5</td>
                <td>12</td>
              </tr>
              <tr class="text-sm">
                <td className='truncate max-w-16'>Ajay Kewat</td>
                <td>22</td>
                <td>6</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className='pt-2 text-center rounded-md shadow-md h-36 bg-success/30'>
          <span class='font-bold'>Product Invoice status <span className='text-xs font-normal'>({format(new Date(), 'do MMMM')})</span></span>
          <table className='w-full mt-2'>
            <thead className='border-y border-y-success/40'>
              <tr className='text-sm'>
                <th class="text-secondary-foreground">Name</th>
                <th class="text-secondary-foreground">Draft</th>
                <th class="text-secondary-foreground">Submitted</th>
              </tr>
            </thead>
            <tbody >
              <tr class="text-sm">
                <td className='truncate max-w-16'>Airline</td>
                <td>10</td>
                <td>15</td>
              </tr>
              <tr class="text-sm">
                <td className='truncate max-w-16'>Cab</td>
                <td>5</td>
                <td>12</td>
              </tr>
              <tr class="text-sm">
                <td className='truncate max-w-16'>Product</td>
                <td>22</td>
                <td>6</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div className='grid grid-cols-1 gap-8 mx-5 lg:grid-cols-3 md:grid-cols-2'>
        <UserInvStatus />
        <AllUserInvStatus />
        <InvPayCreditGraph />
      </div>
    </div>
  )
}
