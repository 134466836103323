import { Button } from 'src/components/ui/button';
import { Dialog, DialogContent, DialogHeader, DialogTitle, DialogTrigger } from 'src/components/ui/dialog';
import { ScrollArea } from 'src/components/ui/scroll-area';
import { Separator } from 'src/components/ui/separator';
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from 'src/components/ui/table';
import Deletedialog from 'src/pages/components/delete-dialog';
import GenericAmountSeparator from 'src/pages/components/genric-amountseperator';
import { format } from 'date-fns';
import { BadgeInfo, Scroll } from 'lucide-react';
import React, { useRef, useState } from 'react'
import { Link } from 'react-router-dom';
import { useReactToPrint } from 'react-to-print';

export default function ViewInvoiceEURail({ data, icon, onViewSuccess }) {
  const [open, setOpen] = useState(false);
  const item = data;
  const componentRef = useRef();
  const handlePrint = useReactToPrint({
      content: () => componentRef.current,
  });
  const handleSuccess = () => {
      setOpen(false);
      onViewSuccess();
  }
  const formatDate = (dateString) => {
      if (!dateString) {
          return '';
      }
      const date = new Date(dateString);
      return format(date, 'dd-MM-yyyy');
  };
  return (
      <div>
          <style>
              {`
                  @media print {
                      .print-exclude {
                          display: none;
                      }
                      .print-content {
                          width: 95vw;
                          height: 95vh;
                          margin: 2vh;
                          overflow: hidden;
                          page-break-inside: avoid;
                          box-shadow: none;
                      }
                      .print-content * {
                          page-break-inside: avoid;
                      }
                  }
                  @media screen {
                      .print-only {
                          display: none;
                          margin-top: 10px;
                      }
                  }
              `}
          </style>
          <Dialog open={open} onOpenChange={setOpen}>
              <DialogTrigger asChild>
                  {icon ? <BadgeInfo className="text-stay/90 py-0 size-[2.1vh] hover:text-stay" /> : <span className='transition-all cursor-pointer hover:text-primary/70'>{item.invoiceId}</span>}
              </DialogTrigger>
              <DialogContent className="lg:w-[75vw] w-[100vw]">
                  <DialogHeader className='flex flex-row items-center'>
                      <DialogTitle className="flex items-center gap-2 ml-5">Invoice Details <Scroll /></DialogTitle>
                      {item.state === "Draft" && <Link className='ml-auto mr-4' to={`/dashboard/invoices/eurail/edit/${item.invoiceId}`} state={{ item: item }}><Button size="sm" className="text-slate-100">Edit</Button></Link>}
                      <Button size="sm" className={`${item.state === "Submitted" && "ml-auto"} mr-4 text-slate-100 bg-stay/75 hover:bg-stay/90`} onClick={handlePrint}>Print</Button>
                      <div><Deletedialog deleteCall="Inside" onDeleteSuccess={handleSuccess} invoiceId={item.invoiceId} disable={item.state === "Deleted" && true} /></div>
                  </DialogHeader>
                  <ScrollArea className="h-[calc(100vh-200px)] lg:h-[65vh]">
                      <div ref={componentRef} className='flex flex-col justify-between mx-5 print-content' >
                          <div>
                              <div className='flex justify-between print-only'>
                                  <div className='flex gap-1'>
                                      <img src='' alt="alt" className='w-20 h-20 bg-secondary/50' />
                                      <div className='flex flex-col text-[1.5vh]]'>
                                          <span className='font-bold'>RAFFINATO TOURS AND TRAVELS</span>
                                          <span className='w-[50vh] text-xs'> Office No A16, 131 Ground Floor, Goodwill Square, Survey No 17/1A/1,<br />
                                              Lohegaon Dhanori Road, Dhanori Lohegaon Road Near C.T Hospital, Dhanori
                                              Pune 411015.</span>
                                      </div>
                                  </div>
                                  <div className='text-xs'> Tel: +91 8308864745<br />
                                      email: info@raffinato.in,<br />
                                      robert.lazarus@raffinato.in<br />
                                      PAN No: AAZFR2544R<br />
                                      IATA No : 14344595<br />
                                      GSTIN: 27AAZFR2544R1Z2<br />
                                      State: Maharashtra (27)<br />
                                  </div>
                              </div>
                              <Separator />
                              <div className='flex w-full font-bold justify-evenly'>TAX  INVOICE</div>
                              <Separator />
                              <div className='flex justify-between my-1 text-xs'>
                                  <div>
                                      To,<br />{data.customerRefOrValue.name}<br />
                                      {data.customerRefOrValue.address}<br />
                                      <div className='flex'><div className='w-12'>PAN No.</div>: {data.customerRefOrValue.panNo}</div>
                                      <div className='flex'><div className='w-12'>GSTIN</div>: {data.customerRefOrValue.gstNo}</div>
                                      <div className='flex'><div className='w-12'>Phone</div>: {data.customerRefOrValue.phone}</div>
                                  </div>
                                  <div>
                                      <div className='flex'><div className='w-20'>Invoice No.</div>:<span className="font-bold">&nbsp;{data.invoiceId}</span></div>
                                      <div className='flex'><div className='w-20'>Invoice Date</div>: {formatDate(data.invoiceDate)}</div>
                                      <div className='flex'><div className='w-20'>Due Date</div>: {formatDate(data.dueDate ? data.dueDate : 0)}</div>
                                  </div>
                              </div>
                              <div className='flex pl-1 text-xs font-bold bg-secondary border-[1px] border-secondary-foreground/40 border-b-0'>
                                  Railway : {data.euRailProductDetails?.[0]?.product.railway} ,
                                  Railway PNR No. : {data.euRailProductDetails?.[0]?.product.railwayPNRNo} ,
                                  Handled By: {data.euRailProductDetails?.[0]?.product.handledBy},
                                  Requisition By: {data.euRailProductDetails?.[0]?.product.requisitionBy}
                              </div>
                              <div>
                                  <Table>
                                      <TableHeader className="bg-secondary">
                                          <TableRow className="text-xs">
                                              <TableHead className="border-[1px] border-secondary-foreground/40 text-secondary-foreground">Passenger / Type / Age  / Gender</TableHead>
                                              <TableHead className="border-[1px] border-secondary-foreground/40 text-center text-secondary-foreground">Train No / Name</TableHead>
                                              <TableHead className="border-[1px] border-secondary-foreground/40 text-center text-secondary-foreground">From - To /Dep - Arv Date</TableHead>
                                              <TableHead className="border-[1px] border-secondary-foreground/40 text-center text-secondary-foreground">Class / Quota</TableHead>
                                              <TableHead className="border-[1px] border-secondary-foreground/40 text-center text-secondary-foreground">Seat No / Status</TableHead>
                                              <TableHead className="border-[1px] border-secondary-foreground/40 text-center text-secondary-foreground"> Ticket Fare</TableHead>
                                              <TableHead className="border-[1px] border-secondary-foreground/40 text-center text-secondary-foreground"> Delivery Charges</TableHead>
                                              <TableHead className="border-[1px] border-secondary-foreground/40 text-center text-secondary-foreground">IRCTC Charges</TableHead>
                                              <TableHead className="border-[1px] border-secondary-foreground/40 text-center text-secondary-foreground">Total</TableHead>
                                          </TableRow>
                                      </TableHeader>
                                      <TableBody>
                                          {data.euRailProductDetails?.map((productDetail, index) => (
                                              <TableRow key={index} className="lg:text-xs text-[1vh]">
                                                  <TableCell className="border-[1px] p-0 border-secondary-foreground/40">
                                                      {productDetail.product.passenger?.map((passenger, passengerIndex) => (
                                                          <div key={passengerIndex} className={`${passengerIndex !== productDetail.product.passenger?.length - 1 && "border-b-[1px]"} lg:py-2 py-0 pl-2 border-secondary-foreground/40`}>
                                                              {passengerIndex + 1 + "."} {passenger.title + "."} {passenger.firstName} {passenger.lastName} / {passenger.paxType} / {passenger.gender}
                                                          </div>
                                                      ))}
                                                  </TableCell>
                                                  <TableCell className="border-[1px] p-0 text-center border-secondary-foreground/40">
                                                      {productDetail.product.passenger?.map((passenger, pIndex) => (
                                                          <div key={pIndex} className={`${pIndex !== productDetail.product.passenger?.length - 1 && "border-b-[1px]"} lg:py-2 py-0 border-secondary-foreground/40`}>
                                                              {passenger.ticketNo} / {passenger.trainName}
                                                          </div>
                                                      ))}
                                                  </TableCell>
                                                  <TableCell className="border-[1px] p-0 text-center border-secondary-foreground/40">
                                                      {productDetail.product.carriers?.map((carrier, cIndex) => (
                                                          <div key={cIndex} className="py-0 lg:py-2 border-secondary-foreground/40">
                                                              {carrier.fromLocation} - {carrier.toLocation} /{" "}
                                                              {formatDate(carrier.departureDate)}
                                                          </div>
                                                      ))}
                                                  </TableCell>
                                                  <TableCell className="border-[1px] p-0 text-center border-secondary-foreground/40">
                                                      {productDetail.product.carriers?.map((carrier, cIndex) => (
                                                          <div key={cIndex} className="py-0 lg:py-2 border-secondary-foreground/40">
                                                              {carrier.class} / {carrier.quota}
                                                          </div>
                                                      ))}
                                                  </TableCell>
                                                  <TableCell className="border-[1px] p-0 text-center border-secondary-foreground/40">
                                                      {productDetail.product.fare.map((fare, fareIndex) => (
                                                          <div key={fareIndex} className={fareIndex !== productDetail.product.fare?.length - 1 ? "border-b-[1px] lg:py-1 py-0 my-1 border-secondary-foreground/40" : ""
                                                          }><GenericAmountSeparator value={fare.totalBaseFare} viewMode={true} icon={true} /></div>
                                                      ))}
                                                  </TableCell>
                                                  <TableCell className="border-[1px] p-0 text-center border-secondary-foreground/40">
                                                      {productDetail.product.fare.map((fare, fareIndex) => (
                                                          <div key={fareIndex} className={fareIndex !== productDetail.product.fare?.length - 1 ? "border-b-[1px] lg:py-1 py-0 my-1 border-secondary-foreground/40" : ""
                                                          }><GenericAmountSeparator value={fare.ticketAmount} viewMode={true} icon={true} /></div>
                                                      ))}
                                                  </TableCell>
                                                  <TableCell className="border-[1px] p-0 text-center border-secondary-foreground/40">
                                                      {productDetail.product.fare.map((fare, fareIndex) => {
                                                          const calculatedValue = (fare.totalBaseFare + fare.comTax) * 0.05;
                                                          return (
                                                              <div key={fareIndex} className={fareIndex !== productDetail.product.fare?.length - 1 ? "border-b-[1px] lg:py-1 py-0 my-1 border-secondary-foreground/40" : ""}>
                                                                  <GenericAmountSeparator value={calculatedValue} viewMode={true} icon={true} />
                                                              </div>
                                                          );
                                                      })}
                                                  </TableCell>
                                                  <TableCell className="border-[1px] p-0 text-center border-secondary-foreground/40">
                                                      {productDetail.product.fare.map((fare, fareIndex) => {
                                                          const calculatedValue = (fare.totalBaseFare + fare.comTax) * 0.05;
                                                          return (
                                                              <div key={fareIndex} className={fareIndex !== productDetail.product.fare?.length - 1 ? "border-b-[1px] lg:py-1 py-0 my-1 border-secondary-foreground/40" : ""}>
                                                                  <GenericAmountSeparator value={fare.totalNonComTax - calculatedValue} viewMode={true} icon={true} />
                                                              </div>
                                                          );
                                                      })}
                                                  </TableCell>
                                                  <TableCell className="border-[1px] p-0 text-center border-secondary-foreground/40">
                                                      {productDetail.product.fare.map((fare, fareIndex) => (
                                                          <div key={fareIndex} className={fareIndex !== productDetail.product.fare?.length - 1 ? "border-b-[1px] lg:py-1 py-0 my-1 border-secondary-foreground/40" : ""
                                                          }><GenericAmountSeparator value={fare.netAmount} viewMode={true} icon={true} /></div>
                                                      ))}
                                                  </TableCell>
                                              </TableRow>
                                          )
                                          )}
                                          <TableRow>
                                              <TableCell colSpan={2} className="border-[1px] p-0 lg:text-xs text-[1vh] border-secondary-foreground/40"></TableCell>
                                              <TableCell colSpan={3} className="border-[1px] p-0 lg:text-xs text-[1vh] text-end pr-2 border-secondary-foreground/40"></TableCell>
                                              <TableCell colSpan={3} className="border-[1px] p-0 lg:text-xs text-[1vh] font-bold pl-2 border-secondary-foreground/40">Gross Total</TableCell>
                                              <TableCell className="border-[1px] p-0 text-center lg:text-xs text-[1vh] font-bold border-secondary-foreground/40"><GenericAmountSeparator value={data.euRailProductDetails?.[0]?.product.fare[0]?.netAmount} viewMode={true} icon={true} /></TableCell>
                                          </TableRow>
                                          <TableRow>
                                              <TableCell colSpan={5} className="border-l-[1px] p-0 lg:text-xs text-[1vh] border-secondary-foreground/40"></TableCell>
                                              <TableCell colSpan={3} className="border-[1px] p-0 lg:text-[1.3vh] text-[1vh] border-secondary-foreground/40">
                                                  {data.euRailProductDetails?.[0]?.product.charges?.filter(charge => Number(charge.totalValue) !== 0).map((charge, chargeIndex, filteredCharges) => (
                                                      <div key={chargeIndex} className={`${chargeIndex !== filteredCharges.length - 1 && "border-b-[1px]"} lg:py-2 py-0 lg:flex block border-secondary-foreground/40`
                                                      }>&nbsp;(+) {charge.chargeCodeName}
                                                          <div className='flex mt-[-2px] lg:m-0'>{charge.tax.map((tax, index) => (<div className="lg:text-[1vh] text-[.9vh]" key={index}>&nbsp;+ {tax.taxCode}({tax.value})</div>))}</div>
                                                      </div>
                                                  ))}
                                              </TableCell>
                                              <TableCell className="border-[1px] p-0 text-end lg:text-[1.3vh] text-[1vh] border-secondary-foreground/40">
                                                  {data.euRailProductDetails?.[0]?.product.charges?.filter(charge => Number(charge.totalValue) !== 0).map((charge, cIndex, filteredCharges) => (
                                                      <div key={cIndex} className={`${cIndex !== filteredCharges.length - 1 && "border-b-[1px]"} lg:py-2 py-0 border-secondary-foreground/40`
                                                      }><GenericAmountSeparator value={Number(charge.totalValue) + Number(charge.taxTotal)} viewMode={true} icon={true} />&ensp;<div className='mt-[-2px] lg:m-0'><br className='visible lg:hidden' /></div></div>
                                                  ))}
                                              </TableCell>
                                          </TableRow>
                                          <TableRow>
                                              <TableCell colSpan={5} className="border-b-[1px] border-l-[1px] p-0 text-xs border-secondary-foreground/40"></TableCell>
                                              <TableCell colSpan={4} className="border-[1px] p-0 text-xs font-bold border-secondary-foreground/40 bg-secondary" >
                                                  <div className='flex justify-between'>
                                                      <div>&ensp;Total INR</div>
                                                      <div><GenericAmountSeparator value={data.euRailProductDetails?.[0]?.netAmount} viewMode={true} icon={true} />&ensp;</div>
                                                  </div>
                                              </TableCell>
                                          </TableRow>
                                      </TableBody>
                                  </Table>
                              </div>
                              {/* <div className='w-full text-sm font-bold text-end'> Net Total INR</div> */}
                              <div className='mt-2 text-sm'>FARES ARE NOT PAYABLE UNDER REVERSE CHARGE</div>
                          </div>
                          <div>
                              <div className='flex mt-10 border-t-2 print-only'>
                                  <div className='text-[.9vh]  lg:w-[30vw] w-[60vw]'>
                                      E. & O.E.<br />
                                      Terms & Conditions :<br />
                                      CASH : Payment To Be Made To The Cashier & Printed Official Receipt Must Be Obtained<br />
                                      CHEQUE : All Cheque/Demand Draft In Payment Of Bills Must Be Crossed 'A/C Payee' & Should Be Drawn In<br />
                                      Favour Of 'RAFFINATO TOURS AND TRAVELS'<br />
                                      LATE PAYMENT : Interest @ 24% Per Annum Will Be Charged On Outstanding Bill After Due Date<br />
                                      VERY IMPORTANT : Kindly Check All Details Carefully To Avoid Unnecessary Complications<br /><br />
                                      Visa Grant is a Sole Discretion by the Consulate<br /><br />
                                      'SUPPLY MEANT FOR SEZ/SEZ DEVELOPER UNDER LUT WITHOUT PAYMENT OF INTEGRATED TAX'<br /><br />
                                      Bank Details :<br />
                                      Bank Name: ICICI Bank, Account No: 397705000631, Account Type: Current account, IFSC Code: ICICI0003977,<br />
                                      Branch: Dhanori, Pune 411015<br />
                                      We are not liable for any third party(Airlines/Hotels) GST input tax credit or GST Invoice<br /><br />
                                      <span className='text-[.9vh]'>This Is Computer Generated Invoice, Requires No Signature</span>
                                  </div>
                                  <div className='w-[30vw] flex items-center justify-center'>
                                      Authorised signature here
                                  </div>
                              </div>
                          </div>
                      </div>
                  </ScrollArea>
              </DialogContent>
          </Dialog>
      </div>
  )
}
