import React, { useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Scroll, Settings, Moon, Sun, PanelsTopLeft, SlidersHorizontal, LogOut, ChevronLast, UserCog, ChevronDown, ReceiptText, ReceiptIndianRupee, Banknote, Combine } from 'lucide-react'
import { useTheme } from "next-themes";
import { Popover, PopoverContent, PopoverTrigger } from "src/components/ui/popover";
import keycloak from "src/keycloak";
import { getRoles, hasRole } from "src/helper/user-data";

const Sidebar = ({ isOpen }) => {
  const location = useLocation();
  const navigate = new useNavigate();
  const menus = [
    { name: "Admin", link: "/dashboard/admin", icon: UserCog },
    { name: "Analytics", link: "/dashboard/analytics", icon: PanelsTopLeft },
    {
      name: "Invoices",
      subMenu: [
        // {
        //   name: "Airline",
        //   subSubMenu: [
        //     { name: "Domestic", link: "/dashboard/invoices/airline/domestic" },
        //     { name: "International", link: "/dashboard/invoices/airline/international" }
        //   ]
        // },
        // {
        //   name: "Hotel",
        //   subSubMenu: [
        //     { name: "Domestic", link: "/dashboard/invoices/hotel/domestic" },
        //     { name: "International", link: "/dashboard/invoices/hotel/international" }
        //   ]
        // },
        { name: "Cab", link: "/dashboard/invoices/cab" },
        // { name: "Railway", link: "/dashboard/invoices/railway" },
        // { name: "Bus", link: "/dashboard/invoices/bus" },
        // { name: "Passport", link: "/dashboard/invoices/passport" },
        // { name: "Visa", link: "/dashboard/invoices/visa" },
        // { name: "Insurance", link: "/dashboard/invoices/insurance" },
        // { name: "Eurail", link: "/dashboard/invoices/eurail" },
        // { name: "Cruise", link: "/dashboard/invoices/cruise" },
        // {
        //   name: "Tour Package",
        //   subSubMenu: [
        //     { name: "Domestic", link: "/dashboard/invoices/tour-package/domestic" },
        //     { name: "International", link: "/dashboard/invoices/tour-package/international" }
        //   ]
        // },
        { name: "Miscellaneous", link: "/dashboard/invoices/miscellaneous" },
      ],
      icon: Scroll
    },
    {
      name: "Credit Note", subMenu: [
        // { name: "Airline", link: "/dashboard/credit-note/airline" },
        // { name: "Hotel", link: "/dashboard/credit-note/hotel" },
        { name: "Cab", link: "/dashboard/credit-note/cab" },
        // { name: "Railway", link: "/dashboard/credit-note/railway" },
        // { name: "Bus", link: "/dashboard/credit-note/bus" },
        // { name: "Passport", link: "/dashboard/credit-note/passport" },
        // { name: "Visa", link: "/dashboard/credit-note/visa" },
        // { name: "Insurance", link: "/dashboard/credit-note/insurance" },
        // { name: "Eurail", link: "/dashboard/credit-note/eurail" },
        // { name: "Cruise", link: "/dashboard/credit-note/cruise" },
        // { name: "Tour Package", link: "/dashboard/credit-note/tour-package" },
        { name: "Miscellaneous", link: "/dashboard/credit-note/miscellaneous" },
      ], icon: Banknote
    },
    // {
    //   name: "Payment", subMenu: [
    //     { name: "Bank", link: "/dashboard/payment/bank" },
    //   ], icon: ReceiptIndianRupee
    // },
    // {
    //   name: "Receipt", subMenu: [
    //     { name: "Bank", link: "/dashboard/receipt/bank" },
    //   ], icon: ReceiptText
    // },
    // {
    //   name: "Split / Merge", subMenu: [
    //     { name: "Invoice", link: "/dashboard/split-merge/invoice" },
    //     { name: "Credit Note", link: "/dashboard/split-merge/credit-note" },
    //   ], icon: Combine
    // },
    { name: "Master", link: "/dashboard/masters", icon: SlidersHorizontal },
    { name: "Settings", link: "/dashboard", icon: Settings, bottom: true },
    { name: "Theme", Moon: Moon, Sun: Sun, bottom: true },
    { name: "Log-out", link:"/login", icon: LogOut, bottom: true },
  ];
  const filterMenusByRole = (menus) => {
    // const roles = getRoles();
    // console.log(keycloak.tokenParsed)
    return menus.filter(menu => {
      let shouldShow = true;
      switch (menu.name) {
        case "Admin":
          shouldShow = hasRole("Admin");
          break;
        // case "Analytics":
        //   shouldShow = hasAnyRole(["Admin", "Analyst"]);
        //   break;
        // case "Invoices":
        // case "Credit Note":
        // case "Payment":
        // case "Receipt":
        // case "Split / Merge":
        //   shouldShow = hasAnyRole(["Admin", "AccountManager", "FinanceManager"]);
        //   break;
        case "Master":
          shouldShow = hasRole("Manager");
          break;
        default:
          shouldShow = true;
      }
      if (menu.subMenu) {
        menu.subMenu = filterMenusByRole(menu.subMenu);
        return shouldShow && menu.subMenu.length > 0;
      }

      return shouldShow;
    });
  };
  // const filterSubMenu = (subMenu) => {
  //   return subMenu.filter(item => {
  //     return true;
  //   });
  // };

  const filteredMenus = React.useMemo(() => {
    return filterMenusByRole(menus);
  }, []);

  // const normalMenus = filteredMenus.filter(menu => !menu.bottom);
  // const bottomMenus = filteredMenus.filter(menu => menu.bottom);

  const normalMenus = menus.filter(menu => !menu.bottom);
  const bottomMenus = menus.filter(menu => menu.bottom);

  const [open, setOpen] = useState(true);
  const [theme, setThemeValue] = useState();
  const { setTheme } = useTheme();

  const [openSubmenu, setOpenSubmenu] = useState(null);
  const [popoverStates, setPopoverStates] = useState({});
  const [expandedSubMenu, setExpandedSubMenu] = useState(null);


  const toggleSubmenu = (index, forceClose = false) => {
    if (forceClose) {
      setOpenSubmenu(null);
    } else {
      setOpenSubmenu(prevOpenSubmenu => prevOpenSubmenu === index ? null : index);
    }
  };
  const openPopover = (index) => {
    setPopoverStates(prev => ({ ...prev, [index]: true }));
    setOpenSubmenu(index);
  };
  const closePopover = (index) => {
    setPopoverStates(prev => ({ ...prev, [index]: false }));
    setOpenSubmenu(null);
    setExpandedSubMenu(null);
  };

  const toggleExpandSubMenu = (index, subIndex) => {
    setExpandedSubMenu(prev => prev === `${index}-${subIndex}` ? null : `${index}-${subIndex}`);
  };

  const handleThemeToggle = () => {
    const savedTheme = localStorage.getItem('theme');
    const newTheme = savedTheme === 'light' ? 'dark' : 'light';
    setTheme(newTheme);
    setThemeValue(newTheme);
  };

  const isMenuActive = (menu) => {
    if (location.pathname.includes(menu.link)) {
      return true;
    }
    if (menu.subMenu) {
      return menu.subMenu.some(subItem => {
        if (location.pathname.includes(subItem.link)) {
          return true;
        }
        if (subItem.subSubMenu) {
          return subItem.subSubMenu.some(subSubItem =>
            location.pathname.includes(subSubItem.link)
          );
        }
        return false;
      });
    }
    return false;
  };

  return (
    <section className={` gap-6 bg-secondary ${!isOpen ? "hidden" : "absolute z-10"} lg:static lg:z-10 lg:flex`}>
      <div
        className={` min-h-[92vh] ${open ? "w-60" : "w-16"
          } duration-500 px-4 flex flex-col justify-between border-r-[1px] border-secondary shadow-none lg:shadow-[rgba(50,50,93,0.25)_8px_0px_16px_-5px,rgba(0,0,0,0.3)_5px_0px_10px_-10px]`}
      >
        <div>
          <div className={`hidden justify-end py-4 lg:visible lg:flex`}>
            <ChevronLast
              size={26}
              className={`cursor-pointer transition-transform duration-300 ${open ? 'rotate-180' : ''}`}
              onClick={() => setOpen(!open)}
            />
          </div>
          <div className="relative flex flex-col gap-3">
            {normalMenus.map((menu, i) => (
              <div key={i} className="relative">
                {menu.subMenu ? (
                  <Popover open={popoverStates[i]} onOpenChange={(open) => open ? openPopover(i) : closePopover(i)}>
                    <PopoverTrigger asChild>
                      <button
                        className={`w-full group flex items-center text-sm gap-4 font-medium px-1.5 py-2 hover:bg-primary/20 transition-all rounded-md ${isMenuActive(menu) ? "bg-primary/20" : ""}`}
                        onClick={() => toggleSubmenu(i)}
                      >
                        <div>{React.createElement(menu.icon, { size: "20" })}</div>
                        <h2 className={`whitespace-pre duration-500 ${!open && "opacity-0 overflow-hidden"}`}>
                          {menu.name}
                        </h2>
                        <h2 className={`${open && "hidden"} absolute z-10 left-48 bg-primary text-muted font-semibold whitespace-pre rounded-md drop-shadow-lg px-0 py-0 w-0 overflow-hidden group-hover:px-3 group-hover:py-1 group-hover:left-[4rem] group-hover:duration-200 group-hover:w-fit`}>
                          {menu.name}
                        </h2>
                        <ChevronDown
                          size={16}
                          className={`ml-auto transform transition-transform duration-200 ${openSubmenu === i ? '-rotate-90' : ''}`}
                        />
                      </button>
                    </PopoverTrigger>
                    <PopoverContent className="p-1 ml-4 bg-secondary w-44" align="start" side="right">
                      {menu.subMenu.map((subItem, subIndex) => (
                        <div key={subIndex}>
                          {subItem.subSubMenu ? (
                            <button
                              className="flex items-center w-full px-3 py-[6px] text-sm transition-all rounded-sm hover:bg-primary/20"
                              onClick={() => toggleExpandSubMenu(i, subIndex)}
                            >
                              {subItem.name}
                              <ChevronDown
                                size={16}
                                className={`ml-auto transform transition-transform duration-200 ${expandedSubMenu === `${i}-${subIndex}` ? 'rotate-180' : ''}`}
                              />
                            </button>
                          ) : (
                            <Link
                              to={subItem.link}
                              className="flex items-center w-full px-3 py-[6px] text-sm transition-all rounded-sm hover:bg-primary/20"
                              onClick={() => { toggleSubmenu(i, true); closePopover(i); }}
                            >
                              {subItem.name}
                            </Link>
                          )}
                          {subItem.subSubMenu && expandedSubMenu === `${i}-${subIndex}` && (
                            <div className="pl-4">
                              {subItem.subSubMenu.map((subSubItem, subSubIndex) => (
                                <Link
                                  key={subSubIndex}
                                  to={subSubItem.link}
                                  className="block px-3 py-[6px] text-sm transition-all rounded-sm hover:bg-primary/20"
                                  onClick={() => { toggleSubmenu(i, true); closePopover(i); }}
                                >
                                  {subSubItem.name}
                                </Link>
                              ))}
                            </div>
                          )}
                        </div>
                      ))}
                    </PopoverContent>
                  </Popover>
                ) : (
                  <Link
                    to={`${menu.link}${menu.name === "Master" ? "/customer" : ""}`}
                    className={`group flex items-center text-sm gap-4 font-medium px-1.5 py-2 hover:bg-primary/20 transition-all rounded-md ${isMenuActive(menu) ? "bg-primary/20" : ""}`}
                    onClick={() => setOpenSubmenu(null)}
                  >
                    <div>{React.createElement(menu.icon, { size: "20" })}</div>
                    <h2 className={`whitespace-pre duration-500 ${!open && "opacity-0 overflow-hidden"}`}>
                      {menu.name}
                    </h2>
                    <h2 className={`${open && "hidden"} absolute z-10 left-48 bg-primary text-muted font-semibold whitespace-pre rounded-md drop-shadow-lg px-0 py-0 w-0 overflow-hidden group-hover:px-3 group-hover:py-1 group-hover:left-[4rem] group-hover:duration-200 group-hover:w-fit`}>
                      {menu.name}
                    </h2>
                  </Link>
                )}
              </div>
            ))}
          </div>
        </div>
        <div>
          {bottomMenus.map((menu, i) => (
            <Link
              to={menu.link}
              key={i}
              className={`${menu.name === "Log-out" && "text-red-600"
                } group flex items-center text-sm gap-4 mb-3 font-medium px-1.5 py-2 hover:bg-primary/20 transition-all rounded-md
                ${location.pathname === menu.link ? "bg-primary/20" : ""
                }`}
              onClick={() => {
                if (menu.name === "Theme") {
                  handleThemeToggle();
                // } else if (menu.name === "Log-out") {
                //   keycloak.logout();
                } else if (menu.link) {
                  navigate(menu.link);
                }
              }}
            >
              <div>{menu.name === "Theme" ? (theme !== 'dark' ? <Sun size={20} /> : <Moon size={20} />) : <menu.icon size={20} />}</div>
              <h2
                className={`whitespace-pre duration-500 ${!open && "opacity-0 overflow-hidden"
                  }`}
              >
                {menu.name}
              </h2>
              <h2
                className={`${open && "hidden"
                  } absolute z-10 left-48 bg-primary text-muted font-semibold whitespace-pre rounded-md drop-shadow-lg px-0 py-0 w-0 overflow-hidden group-hover:px-3 group-hover:py-1 group-hover:left-[4.5rem] group-hover:duration-200 group-hover:w-fit`}
              >
                {menu.name}
              </h2>
            </Link>
          ))}
        </div>
      </div>
    </section>
  );
};


export default Sidebar;