import { Button } from 'src/components/ui/button';
import { Dialog, DialogContent, DialogDescription, DialogFooter, DialogHeader, DialogTitle, DialogTrigger } from 'src/components/ui/dialog';
import { Trash2 } from 'lucide-react';
import React, { useState, useCallback, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { toast } from 'sonner';

export default function DeleteBox({ onDeleteSuccess, code }) {
    const [loading, setLoading] = useState(false);
    const [open, setOpen] = useState(false);
    const { masterType } = useParams();

    let type;
    switch (masterType) {
        case "serviceprovider":
            type = "serviceProvider";
            break;
        case "productchargeconfig":
            type = "customerProductChargeConfig";
            break;
        default:
            type = masterType;
    }

    const handleDelete = useCallback(async () => {
        setLoading(true);
        try {
            const url = `${(process.env.REACT_APP_API_HOST_MSTR === undefined) ? "" : process.env.REACT_APP_API_HOST_MSTR}/amigo-lekha-api/${type}Management/v1/${type}/${code}`;
            const response = await fetch(url, {
                method: 'DELETE',
                headers: { 'Content-Type': 'application/json' }
            });

            if (!response.ok) {
                throw new Error(`Failed to delete ${masterType}: ${response.statusText}`);
            }

            setOpen(false);
            toast.success(`${masterType} deleted successfully!`);
            onDeleteSuccess();
        } catch (error) {
            console.error("Error deleting item:", error);
            toast.error(`Error deleting ${masterType}: ${error.message}`);
        } finally {
            setLoading(false);
        }
    }, [type, code, masterType, onDeleteSuccess]);

    const dialogTrigger = useMemo(() => (
        <DialogTrigger className='flex' asChild>
            <Trash2 className="text-destructive size-[2vh]" />
        </DialogTrigger>
    ), []);

    const dialogContent = useMemo(() => (
        <DialogContent className="max-w-lg">
            <DialogHeader className='gap-3'>
                <DialogTitle>Are you absolutely sure?</DialogTitle>
                <DialogDescription>
                    This will delete your {masterType} - {code}.
                </DialogDescription>
            </DialogHeader>
            <DialogFooter className="sm:justify-start">
                <Button className="bg-destructive/80 hover:bg-destructive/100 text-slate-100" onClick={handleDelete} disabled={loading}>
                    {loading ? 'Deleting...' : 'Delete'}
                </Button>
            </DialogFooter>
        </DialogContent>
    ), [handleDelete, loading, masterType, code]);

    return (
        <div>
            <Dialog open={open} onOpenChange={setOpen}>
                {dialogTrigger}
                {dialogContent}
            </Dialog>
        </div>
    );
}
