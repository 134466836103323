import { Separator } from 'src/components/ui/separator';
import React, { useRef, useState } from 'react'
import { Button } from 'src/components/ui/button'
import { Dialog, DialogContent, DialogHeader, DialogTitle, DialogTrigger } from 'src/components/ui/dialog'
import { format } from 'date-fns';
import { BadgeInfo, Banknote } from 'lucide-react';
import { useReactToPrint } from 'react-to-print';
import { ScrollArea } from 'src/components/ui/scroll-area';
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from 'src/components/ui/table';
import { Link } from 'react-router-dom';
import Deletedialog from '../../../components/delete-dialog';
import GenericAmountSeparator from '../../../components/genric-amountseperator';

export default function ViewCreditNoteAirline({ data, icon, onViewSuccess }) {
    const [open, setOpen] = useState(false);
    const item = data;
    const componentRef = useRef();
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    });
    const handleSuccess = () => {
        setOpen(false);
        onViewSuccess();
    }
    const formatDate = (dateString) => {
        if (!dateString) {
            return '';
        }
        const date = new Date(dateString);
        return format(date, 'dd-MM-yyyy');
    };
    return (
        <div>
            <style>
                {`
                    @media print {
                        .print-exclude {
                            display: none;
                        }
                        .print-content {
                            width: 95vw;
                            height: 95vh;
                            margin: 2vh;
                            overflow: hidden;
                            page-break-inside: avoid;
                            box-shadow: none;
                        }
                        .print-content * {
                            page-break-inside: avoid;
                        }
                    }
                    @media screen {
                        .print-only {
                            display: none;
                            margin-top: 10px;
                        }
                    }
                `}
            </style>
            <Dialog open={open} onOpenChange={setOpen}>
                <DialogTrigger asChild>
                    {icon ? <BadgeInfo className="text-stay/90 py-0 size-[2.1vh] hover:text-stay" /> : <span className='transition-all cursor-pointer hover:text-primary/70'>{item.creditNoteId}</span>}
                </DialogTrigger>
                <DialogContent className="lg:w-[75vw] w-[100vw]">
                    <DialogHeader className='flex flex-row items-center'>
                        <DialogTitle className="flex items-center gap-2 ml-5">Credit Note Details <Banknote /></DialogTitle>
                        {item.state === "Draft" && <Link className='ml-auto mr-4' to={`/dashboard/credit-note/airline/edit/${item.creditNoteId}`} state={{ item: item }}><Button size="sm" className="text-slate-100">Edit</Button></Link>}
                        <Button size="sm" className={`${item.state === "Submitted" && "ml-auto"} mr-4 text-slate-100 bg-stay/75 hover:bg-stay/90`} onClick={handlePrint}>Print</Button>
                        <div><Deletedialog deleteCall="Inside" onDeleteSuccess={handleSuccess} type="creditNote" Id={item.creditNoteId} disable={item.state === "Deleted" && true} /></div>
                    </DialogHeader>
                    <ScrollArea className="h-[calc(100vh-200px)] lg:h-[65vh]">
                        <div ref={componentRef} className='flex flex-col justify-between mx-5 print-content' >
                            <div>
                                <div className='flex justify-between print-only'>
                                    <div className='flex gap-1'>
                                        <img src='' alt="alt" className='w-20 h-20 bg-secondary/50' />
                                        <div className='flex flex-col text-[1.5vh]]'>
                                            <span className='font-bold'>RAFFINATO TOURS AND TRAVELS</span>
                                            <span className='w-[50vh] text-xs'> Office No A16, 131 Ground Floor, Goodwill Square, Survey No 17/1A/1,<br />
                                                Lohegaon Dhanori Road, Dhanori Lohegaon Road Near C.T Hospital, Dhanori
                                                Pune 411015.</span>
                                        </div>
                                    </div>
                                    <div className='text-xs'> Tel: +91 8308864745<br />
                                        email: info@raffinato.in,<br />
                                        robert.lazarus@raffinato.in<br />
                                        PAN No: AAZFR2544R<br />
                                        IATA No : 14344595<br />
                                        GSTIN: 27AAZFR2544R1Z2<br />
                                        State: Maharashtra (27)<br />
                                    </div>
                                </div>
                                <Separator />
                                <div className='flex w-full font-bold justify-evenly'>CREDIT NOTE</div>
                                <Separator />
                                <div className='flex justify-between my-1 text-xs'>
                                    <div>
                                        To,<br />{data.customerRefOrValue.name}<br />
                                        {data.customerRefOrValue.address}<br />
                                        <div className='flex'><div className='w-12'>PAN No.</div>: {data.customerRefOrValue.panNo}</div>
                                        <div className='flex'><div className='w-12'>GSTIN</div>: {data.customerRefOrValue.gstNo}</div>
                                        <div className='flex'><div className='w-12'>Phone</div>: {data.customerRefOrValue.phone}</div>
                                    </div>
                                    <div>
                                        <div className='flex'><div className='w-20'>Cr.Note No.</div>:<span className="font-bold">&nbsp;{data.creditNoteId}</span></div>
                                        <div className='flex'><div className='w-20'>Cr.Note Date</div>: {formatDate(data.creditNoteDate)}</div>
                                        <div className='flex'><div className='w-20'>Invoice No.</div>: {data.invoiceRefOrValue.invoiceId}</div>
                                        <div className='flex'><div className='w-20'>Due Date</div>: {formatDate(data.dueDate ? data.dueDate : 0)}</div>
                                    </div>
                                </div>
                                <div className='flex pl-1 text-xs font-bold bg-secondary border-[1px] border-secondary-foreground/40 border-b-0'>
                                    Airline : {data.productDetails?.[0]?.product.airline} ,
                                    Airline PNR No. : {data.productDetails?.[0]?.product.airlinePNR} ,
                                    GDS PNR No. : {data.productDetails?.[0]?.product.gdsNo} ,
                                    Cancelled By: {data.productDetails?.[0]?.product.customerCancellationBy}
                                </div>
                                <div>
                                    <Table>
                                        <TableHeader className="bg-secondary">
                                            <TableRow className="text-xs">
                                                <TableHead className="border-[1px] border-secondary-foreground/40 text-secondary-foreground">Passenger Name / Pax Type </TableHead>
                                                <TableHead className="border-[1px] border-secondary-foreground/40 text-center text-secondary-foreground">Ticket No.</TableHead>
                                                <TableHead className="border-[1px] border-secondary-foreground/40 text-center text-secondary-foreground"> Sector / Dep Date </TableHead>
                                                <TableHead className="border-[1px] border-secondary-foreground/40 text-center text-secondary-foreground">Carrier / Class</TableHead>
                                                <TableHead className="border-[1px] border-secondary-foreground/40 text-center text-secondary-foreground">Basic Fare</TableHead>
                                                <TableHead className="border-[1px] border-secondary-foreground/40 text-center text-secondary-foreground">YQ/YR Tax</TableHead>
                                                <TableHead className="border-[1px] border-secondary-foreground/40 text-center text-secondary-foreground">GST/K3 Tax</TableHead>
                                                <TableHead className="border-[1px] border-secondary-foreground/40 text-center text-secondary-foreground">Other Taxes</TableHead>
                                                <TableHead className="border-[1px] border-secondary-foreground/40 text-center text-secondary-foreground">Total</TableHead>
                                            </TableRow>
                                        </TableHeader>
                                        <TableBody>
                                            {data.productDetails?.map((productDetail, index) => (
                                                <TableRow key={index} className="lg:text-xs text-[1vh]">
                                                    <TableCell className="border-[1px] p-0 border-secondary-foreground/40">
                                                        {/* {(() => {
                                                            const cancelledPassengers = productDetail.product.passenger.filter(passenger => passenger.status === "Cancelled");
                                                            return cancelledPassengers.map((passenger, passengerIndex) => (
                                                                <div key={passengerIndex} className={`${passengerIndex !== cancelledPassengers.length - 1 ? "border-b-[1px]" : ""} lg:py-2 py-0 pl-2 border-secondary-foreground/40`}>
                                                                    {passengerIndex + 1 + "."} {passenger.title + "."} {passenger.firstName} {passenger.lastName} / {passenger.paxType}
                                                                </div>
                                                            ));
                                                        })()} */}
                                                        {productDetail.product.passenger?.filter(passenger => passenger.status === "Cancelled").map((passenger, passengerIndex, filteredPassengers) => (
                                                            <div key={passengerIndex} className={`${passengerIndex !== filteredPassengers.length - 1 && "border-b-[1px]"} lg:py-2 py-0 pl-2 border-secondary-foreground/40`}>
                                                                {passengerIndex + 1 + "."} {passenger.title + "."} {passenger.firstName} {passenger.lastName} / {passenger.paxType}
                                                            </div>
                                                        ))}
                                                    </TableCell>
                                                    <TableCell className="border-[1px] p-0 text-center border-secondary-foreground/40">
                                                        {productDetail.product.passenger?.filter(passenger => passenger.status === "Cancelled").map((passenger, pIndex, filteredPassengers) => (
                                                                <div key={pIndex} className={`${pIndex !== filteredPassengers.length - 1 ? "border-b-[1px]" : ""} lg:py-2 py-0 border-secondary-foreground/40`}>
                                                                    {passenger.ticketNo}
                                                                </div>
                                                             ))}
                                                    </TableCell>
                                                    <TableCell className="border-[1px] p-0 text-center border-secondary-foreground/40">
                                                        {productDetail.product.carriers?.map((carrier, cIndex) => (
                                                            <div key={cIndex} className="py-0 lg:py-2 border-secondary-foreground/40">
                                                                {carrier.fromLocation} - {carrier.toLocation} /{" "}
                                                                {formatDate(carrier.departureDate)}
                                                            </div>
                                                        ))}
                                                    </TableCell>
                                                    <TableCell className="border-[1px] p-0 text-center border-secondary-foreground/40">
                                                        {productDetail.product.carriers?.map((carrier, cIndex) => (
                                                            <div key={cIndex} className="py-0 lg:py-2 border-secondary-foreground/40">
                                                                {carrier.carrier} / {carrier.airlineClass}
                                                            </div>
                                                        ))}
                                                    </TableCell>
                                                    <TableCell className="border-[1px] p-0 text-center border-secondary-foreground/40">
                                                        {(() => {
                                                            const cancelledPassengers = productDetail.product.passenger?.filter(passenger => passenger.status === "Cancelled");
                                                            const totalBaseFare = cancelledPassengers?.reduce((sum, passenger) => sum + passenger.fare.baseFare, 0);
                                                            return (
                                                                <div className="py-0 lg:py-2 border-secondary-foreground/40">
                                                                    <GenericAmountSeparator value={totalBaseFare} viewMode={true} icon={true} />
                                                                </div>
                                                            );
                                                        })()}
                                                    </TableCell>
                                                    <TableCell className="border-[1px] p-0 text-center border-secondary-foreground/40">
                                                        {(() => {
                                                            const cancelledPassengers = productDetail.product.passenger?.filter(passenger => passenger.status === "Cancelled");
                                                            const totalComTax = cancelledPassengers?.reduce((sum, passenger) => sum + passenger.fare.comTax, 0);
                                                            return (
                                                                <div className="py-0 lg:py-2 border-secondary-foreground/40">
                                                                    <GenericAmountSeparator value={totalComTax} viewMode={true} icon={true} />
                                                                </div>
                                                            );
                                                        })()}
                                                    </TableCell>
                                                    <TableCell className="border-[1px] p-0 text-center border-secondary-foreground/40">
                                                        {(() => {
                                                            const cancelledPassengers = productDetail.product.passenger?.filter(passenger => passenger.status === "Cancelled");
                                                            const totalCalculatedTax = cancelledPassengers?.reduce((sum, passenger) => {
                                                                const calculatedValue = passenger.fare.k3;
                                                                return sum + calculatedValue;
                                                            }, 0);
                                                            return (
                                                                <div className="py-0 lg:py-2 border-secondary-foreground/40">
                                                                    <GenericAmountSeparator value={totalCalculatedTax} viewMode={true} icon={true} />
                                                                </div>
                                                            );
                                                        })()}
                                                    </TableCell>
                                                    <TableCell className="border-[1px] p-0 text-center border-secondary-foreground/40">
                                                        {(() => {
                                                            const cancelledPassengers = productDetail.product.passenger?.filter(passenger => passenger.status === "Cancelled");
                                                            const totalNonComTax = cancelledPassengers?.reduce((sum, passenger) => sum + passenger.fare.nonComTax, 0);
                                                            const totalMarkup = cancelledPassengers?.reduce((sum, passenger) => sum + passenger.fare.markup, 0);
                                                            return (
                                                                <div className="py-0 lg:py-2 border-secondary-foreground/40">
                                                                    <GenericAmountSeparator value={totalNonComTax + totalMarkup} viewMode={true} icon={true} />
                                                                </div>
                                                            );
                                                        })()}
                                                    </TableCell>
                                                    <TableCell className="border-[1px] p-0 text-center border-secondary-foreground/40">
                                                        {(() => {
                                                            const cancelledPassengers = productDetail.product.passenger?.filter(passenger => passenger.status === "Cancelled");
                                                            const totalNetAmount = cancelledPassengers?.reduce((sum, passenger) => sum + passenger.fare.netAmount, 0);
                                                            return (
                                                                <div className="py-0 lg:py-2 border-secondary-foreground/40">
                                                                    <GenericAmountSeparator value={totalNetAmount} viewMode={true} icon={true} />
                                                                </div>
                                                            );
                                                        })()}
                                                    </TableCell>
                                                </TableRow>
                                            )
                                            )}
                                            <TableRow>
                                                <TableCell colSpan={2} className="border-[1px] p-0 lg:text-xs text-[1vh] border-secondary-foreground/40"></TableCell>
                                                <TableCell colSpan={3} className="border-[1px] p-0 lg:text-xs text-[1vh] text-end pr-2 border-secondary-foreground/40">Reimbursement of Air ticket issued by the Airlines</TableCell>
                                                <TableCell colSpan={3} className="border-[1px] p-0 lg:text-xs text-[1vh] font-bold pl-2 border-secondary-foreground/40">Gross Total</TableCell>
                                                <TableCell className="border-[1px] p-0 text-center lg:text-xs text-[1vh] font-bold border-secondary-foreground/40">
                                                    {(() => {
                                                        const cancelledPassengers = data.productDetails?.[0].product.passenger?.filter(passenger => passenger.status === "Cancelled");
                                                        const totalNetAmount = cancelledPassengers?.reduce((sum, passenger) => sum + passenger.fare.netAmount, 0);
                                                        return (<GenericAmountSeparator value={totalNetAmount} viewMode={true} icon={true} />);
                                                    })()}
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell colSpan={5} className="border-l-[1px] p-0 lg:text-xs text-[1vh] border-secondary-foreground/40"></TableCell>
                                                <TableCell colSpan={3} className="border-[1px] p-0 lg:text-[1.3vh] text-[1vh] border-secondary-foreground/40">
                                                    {data.productDetails?.[0]?.product.charges?.filter(charge => Number(charge.totalValue) !== 0).map((charge, chargeIndex, filteredCharges) => (
                                                        <div key={chargeIndex} className={`${chargeIndex !== filteredCharges.length - 1 && "border-b-[1px]"} lg:py-2 py-0 lg:flex block border-secondary-foreground/40`
                                                        }>&nbsp;(-) {charge.chargeCodeName}
                                                            <div className='flex mt-[-2px] lg:m-0'>{charge.tax.map((tax, index) => (<div className="lg:text-[1vh] text-[.9vh]" key={index}>&nbsp;+ {tax.taxCode}({tax.value})</div>))}</div>
                                                        </div>
                                                    ))}
                                                </TableCell>
                                                <TableCell className="border-[1px] p-0 text-end lg:text-[1.3vh] text-[1vh] border-secondary-foreground/40">
                                                    {data.productDetails?.[0]?.product.charges?.filter(charge => Number(charge.totalValue) !== 0).map((charge, cIndex, filteredCharges) => (
                                                        <div key={cIndex} className={`${cIndex !== filteredCharges.length - 1 && "border-b-[1px]"} lg:py-2 py-0 border-secondary-foreground/40`
                                                        }><GenericAmountSeparator value={Number(charge.totalValue) + Number(charge.taxTotal)} viewMode={true} icon={true} />&ensp;<div className='mt-[-2px] lg:m-0'><br className='visible lg:hidden' /></div></div>
                                                    ))}
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell colSpan={5} className="border-b-[1px] border-l-[1px] p-0 text-xs border-secondary-foreground/40"></TableCell>
                                                <TableCell colSpan={4} className="border-[1px] p-0 text-xs font-bold border-secondary-foreground/40 bg-secondary" >
                                                    <div className='flex justify-between'>
                                                        <div>&ensp;Total INR</div>
                                                        <div><GenericAmountSeparator value={data.productDetails?.[0]?.creditNoteNetAmount} viewMode={true} icon={true} />&ensp;</div>
                                                    </div>
                                                </TableCell>
                                            </TableRow>
                                        </TableBody>
                                    </Table>
                                </div>
                                {/* <div className='w-full text-xs font-bold lg:text-sm text-end'> Net Total INR</div> */}
                                <div className='grid grid-cols-5 mt-2 text-sm'>
                                    <div>Invoice</div>
                                    <div className='flex justify-between w-40 ml-auto'>
                                        <div>INR</div>
                                        <div><GenericAmountSeparator value={data.invoiceNetAmount} viewMode={true} icon={true} /></div>
                                    </div>
                                    <div className="col-span-3"></div>
                                    <div>( - ) Credit Note</div>
                                    <div className='flex justify-between w-40 ml-auto'>
                                        <div>INR</div>
                                        <div><GenericAmountSeparator value={data.creditNoteNetAmount} viewMode={true} icon={true} /></div>
                                    </div>
                                    <div className="col-span-3"></div>
                                    <div className='col-span-2 my-0 border border-dashed lg:my-1 border-secondary-foreground'></div>
                                    <div className="col-span-3"></div>
                                    <div>Net Receivable</div>
                                    <div className='flex justify-between w-40 ml-auto'>
                                        <div>INR</div>
                                        <div><GenericAmountSeparator value={data.invoiceNetAmount - data.creditNoteNetAmount} viewMode={true} icon={true} /></div>
                                    </div>
                                </div>
                                <div className='mt-2 text-sm'>FARES ARE NOT PAYABLE UNDER REVERSE CHARGE</div>
                            </div>
                            <div>
                                <div className='flex mt-10 border-t-2 print-only'>
                                    <div className='text-[.9vh]  lg:w-[30vw] w-[60vw]'>
                                        E. & O.E.<br />
                                        Terms & Conditions :<br />
                                        CASH : Payment To Be Made To The Cashier & Printed Official Receipt Must Be Obtained<br />
                                        CHEQUE : All Cheque/Demand Draft In Payment Of Bills Must Be Crossed 'A/C Payee' & Should Be Drawn In<br />
                                        Favour Of 'RAFFINATO TOURS AND TRAVELS'<br />
                                        LATE PAYMENT : Interest @ 24% Per Annum Will Be Charged On Outstanding Bill After Due Date<br />
                                        VERY IMPORTANT : Kindly Check All Details Carefully To Avoid Unnecessary Complications<br /><br />
                                        Visa Grant is a Sole Discretion by the Consulate<br /><br />
                                        'SUPPLY MEANT FOR SEZ/SEZ DEVELOPER UNDER LUT WITHOUT PAYMENT OF INTEGRATED TAX'<br /><br />
                                        Bank Details :<br />
                                        Bank Name: ICICI Bank, Account No: 397705000631, Account Type: Current account, IFSC Code: ICICI0003977,<br />
                                        Branch: Dhanori, Pune 411015<br />
                                        We are not liable for any third party(Airlines/Hotels) GST input tax credit or GST Invoice<br /><br />
                                        <span className='text-[.9vh]'>This Is Computer Generated Invoice, Requires No Signature</span>
                                    </div>
                                    <div className='w-[30vw] flex items-center justify-center'>
                                        Authorised signature here
                                    </div>
                                </div>
                            </div>
                        </div>
                    </ScrollArea>
                </DialogContent>
            </Dialog>

        </div>
    )
}
