import { Card, CardContent, CardTitle } from 'src/components/ui/card'
import React, { useEffect, useState } from 'react'
import Chart from "react-apexcharts";
import { useTheme } from 'next-themes';

function getLast10Days() {
    const dates = [];
    for (let i = 9; i >= 0; i--) {
        const d = new Date();
        d.setDate(d.getDate() - i);
        const day = d.getDate();
        let suffix = 'th';
        if (day === 1 || day === 21 || day === 31) suffix = 'st';
        else if (day === 2 || day === 22) suffix = 'nd';
        else if (day === 3 || day === 23) suffix = 'rd';
        dates.push(day + suffix);
    }
    return dates;
}


export default function UserInvStatus() {
    const { theme } = useTheme();
    const [key, setKey] = useState(0);

    useEffect(() => {
        setChartOptions(prevOptions => ({
            ...prevOptions,
            theme: {
                mode: theme,
            }
        }));
        setKey(prevKey => prevKey + 1);
    }, [theme]);

    const [chartOptions, setChartOptions] = useState({
        chart: {
            toolbar: { show: false },
            fontFamily: "Nunito, sans-serif",
            zoom: { enabled: false },
            background: 'transparent',
        },
        stroke: {
            curve: 'smooth',
        },
        theme: {
            mode: theme,
        },
        grid: { show: false },
        colors: ["#e98b43", "#5fb68a"],
        xaxis: {
            categories: getLast10Days()
        },
        legend: {
            labels: {
                colors: ["#e98b43", "#5fb68a"],
            },
            markers: {
                width: 8,
                height: 8,
            }
        }
    });

    const [chartSeries, setChartSeries] = useState([
        {
            name: "Draft",
            data: [30, 40, 35, 50, 49, 60, 70, 91, 125, 150]
        },
        {
            name: "Submitted",
            data: [20, 35, 40, 45, 55, 65, 75, 80, 100, 120]
        }
    ]);


    return (
        <Card>
            <CardContent className="px-4">
                <div className="py-2 pl-4 mt-3 text-xl font-bold rounded-md bg-secondary">User Invoice Status <span className='text-xs font-normal'>(Last 10 days)</span></div>
                <Chart
                    key={key}
                    options={chartOptions}
                    series={chartSeries}
                    type="line"
                    width="100%"
                    height="300px"
                />
            </CardContent>
        </Card>
    )
}
