import { Dialog, DialogContent, DialogDescription, DialogFooter, DialogHeader, DialogTitle, DialogTrigger } from 'src/components/ui/dialog';
import { BadgeInfo } from 'lucide-react';
import React, { useState } from 'react'
import { Separator } from 'src/components/ui/separator';
import { Button } from 'src/components/ui/button';
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from 'src/components/ui/table';

export default function ProductChargeConfigView({ chargeConfig }) {
    const [open, setOpen] = useState(false);

    return (
        <Dialog open={open} onOpenChange={setOpen}>
            <DialogTrigger asChild>
                <BadgeInfo className="text-stay/90 py-0 size-[2vh] hover:text-stay" />
            </DialogTrigger>
            <DialogContent className="w-[80vw] lg:w-[55vw]">
                <DialogHeader>
                    <DialogTitle className="mb-2">Charge Configuration Details</DialogTitle>
                    <Separator />
                    <div className="grid gap-3">
                        <div className='grid gap-2 text-sm lg:grid-cols-3 md:grid-cols-3 sm:grid-cols-2'>
                            <div>Name: {chargeConfig.name}</div>
                            <div>Code: {chargeConfig.code}</div>
                            <div>Customer ID: {chargeConfig.customer?.id || 'N/A'}</div>
                        </div>
                        <Separator />
                        <DialogDescription>Config Details</DialogDescription>
                        <Table>
                            <TableHeader className="bg-gray-200">
                                <TableRow>
                                    <TableHead>Product ID</TableHead>
                                    <TableHead>Ledger ID</TableHead>
                                    <TableHead>Amount</TableHead>
                                    <TableHead>Calculation Method</TableHead>
                                    <TableHead>Roundoff Type</TableHead>
                                    <TableHead>Effect Start Date</TableHead>
                                    <TableHead>Effect End Date</TableHead>
                                    <TableHead>Taxes</TableHead>
                                </TableRow>
                            </TableHeader>
                            <TableBody>
                                {chargeConfig.configDetails?.length > 0 ? (
                                    chargeConfig.configDetails.map((configDetails, index) => (
                                        <TableRow key={index}>
                                            <TableCell>{configDetails.ProductRef?.id || 'N/A'}</TableCell>
                                            <TableCell>{configDetails.ledger?.id || 'N/A'}</TableCell>
                                            <TableCell>{configDetails.amount || 'N/A'}</TableCell>
                                            <TableCell>{configDetails.calculationMethod || 'N/A'}</TableCell>
                                            <TableCell>{configDetails.roundoffType || 'N/A'}</TableCell>
                                            <TableCell>{configDetails.effectStartDate || 'N/A'}</TableCell>
                                            <TableCell>{configDetails.effectEndDate || 'N/A'}</TableCell>
                                            <TableCell>{Array.isArray(configDetails.taxes) ? configDetails.taxes.join(', ') : ''}</TableCell>
                                        </TableRow>
                                    ))
                                ) : (
                                    <TableRow>
                                        <TableCell colSpan={8} className="text-center">No Details</TableCell>
                                    </TableRow>
                                )}
                            </TableBody>
                        </Table>
                    </div>
                    <DialogFooter>
                        <Button type="button" className="ml-2" onClick={() => setOpen(false)}>Close</Button>
                    </DialogFooter>
                </DialogHeader>
            </DialogContent>
        </Dialog>
    );
};

