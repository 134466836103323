import { Bolt, KeyRound, Sparkle, User, Wrench } from 'lucide-react'
import React from 'react'
import { Link } from 'react-router-dom'

export default function AdminPage() {
    const items = [
        { name: "Reset My Password", logo: KeyRound, link: "/dashboard/admin", color: "bg-primary/30", desc: "Reset your password securely and conveniently for seamless access." },
        { name: "Manage Roles", logo: Sparkle, link: "/dashboard/admin", color: "bg-stay/55", desc: "Efficiently manage roles and permissions to streamline organizational structure." },
        { name: "Manage Users", logo: User, link: "/dashboard/admin", color: "bg-destructive/60", desc: "Control user access and permissions effortlessly with intuitive management tools." },
        { name: "One Time Configurations", logo: Wrench, link: "/dashboard/admin", color: "bg-primary/80", desc: "Set up configurations quickly and securely for one-time setup needs." },
        { name: "Template Configurations", logo: Bolt, link: "/dashboard/admin", color: "bg-success/50", desc: "Customize templates with ease and precision to tailor your workflows." },
    ];
       
    return (
        <div className='m-10'>
            <div className='grid grid-cols-2 lg:grid-cols-4 md:grid-cols-3'>
                {items.map((item, index) => (
                    <Link key={index} to={item.link} className='m-5 rounded-sm shadow-lg lg:duration-300 lg:hover:shadow-primary/20 lg:hover:-translate-y-2 lg:hover:transform lg:transition-transform'>
                        <div className='flex flex-col items-center rounded-sm bg-secondary/40 text-[2vh] h-28 lg:h-[25vh] md:h-[20vh] '>
                            <div className={`flex items-center justify-center w-full h-full rounded-sm ${item.color}`}><div className="rounded-full size-[6vh] flex items-center justify-center bg-accent">{React.createElement(item.logo, { size: "3.5vh" })}</div></div>
                            <div className='my-3 font-bold text-center hover:underline'>{item.name}</div>
                            <div className='hidden mx-5 mb-2 text-[1.5vh] text-center lg:block'>{item.desc}</div>
                        </div>
                    </Link>
                ))}
            </div>
        </div>
    )
}
