import { Button } from 'src/components/ui/button'
import { Command, CommandEmpty, CommandGroup, CommandInput, CommandItem, CommandList } from 'src/components/ui/command';
import { Popover, PopoverContent, PopoverTrigger } from 'src/components/ui/popover'
import { cn } from 'src/lib/utils';
import { Check, ChevronsDownUp } from 'lucide-react';
import React, { useState, useEffect, useMemo, useCallback } from 'react'

export default function CarrierSelect({ onFlightSelect, value, isVisible, isError }) {
    const [open, setOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const [flights, setFlights] = useState([]);
    const [selectedFlight, setSelectedFlight] = useState(null);
    const [searchTerm, setSearchTerm] = useState('');

    const getFlights = useCallback(async () => {
        if (flights.length > 0) return;

        setLoading(true);
        try {
            const response = await fetch(`${process.env.REACT_APP_API_HOST_MSTR || ''}/amigo-lekha-api/flightManagement/v1/flight`, {
                method: 'GET',
                headers: { 'Content-Type': 'application/json' },
            });
            if (!response.ok) throw new Error('Failed to fetch data');
            const fetchedData = await response.json();
            setFlights(fetchedData);
        } catch (error) {
            console.error("Error fetching data:", error);
        } finally {
            setLoading(false);
        }
    }, [flights]);

    useEffect(() => {
        if (isVisible && open) {
            getFlights();
        }
    }, [isVisible, open, getFlights]);

    useEffect(() => {
        if (value) {
            const flight = flights.find((flight) => flight.name === value);
            if (flight) setSelectedFlight(flight);
        }
    }, [value, flights]);

    const filteredFlights = useMemo(() => 
        flights.filter(flight => 
            flight.name.toLowerCase().includes(searchTerm.toLowerCase())
        ),
        [flights, searchTerm]
    );

    const handleSelectFlight = (flightId) => {
        const flight = flights.find((flight) => flight.code === flightId);
        setSelectedFlight(flight);
        onFlightSelect(flight);
        setOpen(false);
    };

    return (
        <Popover open={open} onOpenChange={setOpen} modal={true}>
            <PopoverTrigger asChild>
                <Button
                    variant="outline"
                    role="combobox"
                    aria-expanded={open}
                    className={`w-[42vh] bg-muted h-7 justify-between ${isError ? "border-destructive" : ""}`}
                >
                    {selectedFlight?.name || "Select flight..."}
                    <ChevronsDownUp className="w-4 h-4 ml-2 opacity-50 shrink-0" />
                </Button>
            </PopoverTrigger>
            <PopoverContent className="w-[28vh] h-[30vh] p-0">
                <Command>
                    <CommandInput 
                        placeholder="Search flight..." 
                        className="h-8"
                        value={searchTerm}
                        onValueChange={setSearchTerm}
                    />
                    <CommandList>
                        <CommandEmpty>{loading ? "Loading..." : "No flight found."}</CommandEmpty>
                        <CommandGroup>
                            {filteredFlights.map((flight) => (
                                <CommandItem
                                    key={flight.code}
                                    value={flight.name}
                                    onSelect={() => handleSelectFlight(flight.code)}
                                >
                                    {flight.name}
                                    <Check
                                        className={cn(
                                            "ml-auto h-4 w-4",
                                            selectedFlight?.code === flight.code ? "opacity-100" : "opacity-0"
                                        )}
                                    />
                                </CommandItem>
                            ))}
                        </CommandGroup>
                    </CommandList>
                </Command>
            </PopoverContent>
        </Popover>
    )
}