import { Card, CardContent } from 'src/components/ui/card';
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import masterConfig from "src/masterConfig/master-config.json";
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from 'src/components/ui/table';
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from 'src/components/ui/tooltip';
import { useParams } from 'react-router-dom';
import { Calendar, ChevronLeft, ChevronRight, Search } from 'lucide-react';
import { Button } from 'src/components/ui/button';
import { Input } from 'src/components/ui/input';
import { DatePickerWithRange } from '../../../components/datepicker';
import GenericForm from './generic-form';
import DeleteBox from '../components/delete-dialog';

export default function GenericList() {
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState([]);
    const [searchQuery, setSearchQuery] = useState('');
    const [dateRange, setDateRange] = useState(null);
    const [currentPage, setCurrentPage] = useState(1);
    const [masterData, setMasterData] = useState(null);
    const itemsPerPage = 10;
    let totalPages;
    const masterLists = useMemo(() => Object.keys(masterConfig).map(key => masterConfig[key]), []);
    const { masterType } = useParams();

    const fetchData = useCallback(async (master) => {
        setLoading(true);
        try {
            const response = await fetch(`${(process.env.REACT_APP_API_HOST_MSTR === undefined) ? "" : process.env.REACT_APP_API_HOST_MSTR}/amigo-lekha-api/${master.title.toLowerCase()}Management/v1/${master.title.toLowerCase()}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                },
            });
            if (!response.ok) {
                throw new Error('Failed to fetch data');
            }
            const fetchedData = await response.json();
            setData(fetchedData);
        } catch (error) {
            console.error('Error fetching data:', error);
        } finally {
            setLoading(false);
        }
    }, []);

    useEffect(() => {
        const selectedMaster = masterLists.find((master) => master.title.toLowerCase() === masterType.toLowerCase());
        setMasterData(selectedMaster);
        if (selectedMaster) {
            fetchData(selectedMaster);
        }
        setSearchQuery("");
        setCurrentPage(1);
    }, [masterType, masterLists, fetchData]);

    const handleSuccess = () => {
        fetchData(masterData);
    }

    const sortData = useCallback((data, searchQuery, dateRange) => {
        if (!data) {
            return [];
        }
        const query = searchQuery.toLowerCase();
        // const endDate = dateRange?.to ? new Date(dateRange.to) : null;
        // if (endDate) {
        //     endDate.setDate(endDate.getDate() + 1);
        // }

        const filteredData = data.filter((item) => {
            // const itemDate = new Date(item.invoiceDate);
            const customerName = item.customerRefOrValue?.name || '';
            return (
                (Object.values(item)
                    .join(' ')
                    .toLowerCase()
                    .includes(query) ||
                    customerName.toLowerCase().includes(query))
                //      &&
                // (!dateRange ||
                //     (dateRange.from <= itemDate && itemDate < endDate))
            );
        });
        const indexOfLastItem = currentPage * itemsPerPage;
        const indexOfFirstItem = indexOfLastItem - itemsPerPage;
        const currentPageData = filteredData.slice(indexOfFirstItem, indexOfLastItem);

        return currentPageData;
    }, [currentPage, itemsPerPage]);

    const handlePreviousPage = () => {
        if (currentPage > 1) {
            setCurrentPage(currentPage - 1);
        }
    };

    const totalItems = data ? data.length : 0;
    totalPages = data ? Math.ceil(totalItems / itemsPerPage) : 0;

    const handleNextPage = () => {
        if (currentPage < totalPages) {
            setCurrentPage(currentPage + 1);
        }
    };

    const sortedData = useMemo(() => sortData(data, searchQuery, dateRange), [sortData, data, searchQuery, dateRange]);

    return (
        <div className='px-5 py-2 lg:py-5'>
            <div className="flex items-center mb-4">
                <div className="relative">
                    <Input
                        type="text"
                        placeholder="Search by Field..."
                        className="lg:w-[28vh] md:w-[25vh] w-36 hover:bg-muted transition-all" onChange={(e) => setSearchQuery(e.target.value)} value={searchQuery}
                    />
                    <div className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                        <Search className="size-5 text-muted-foreground" />
                    </div>
                </div>
                {/* <div className='relative'>
                    <DatePickerWithRange className="ml-6 lg:w-[28vh] md:w-[25vh] w-36" onDateChange={setDateRange}/>
                    <div className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                        <Calendar className='size-5 text-muted-foreground' />
                    </div>
                </div> */}
                <div className="flex items-center ml-auto">
                    {/* <Link to="new" state={{ item: masterData }}><Button ><CirclePlus className='mr-1 size-4' />Add {masterData?.title}</Button></Link> */}
                    <GenericForm generic={masterData} type="new" onSuccess={handleSuccess} />
                </div>
            </div>
            <Card>
                <CardContent className="mt-3">
                    <Table>
                        <TableHeader className="bg-secondary/60">
                            <TableRow>
                                <TableHead></TableHead>
                                {masterData?.viewConfig?.fields.map((fields, index) => (
                                    <TableHead key={index}>{fields.label}</TableHead>
                                ))}
                                <TableHead>
                                    <span className="sr-only">Actions</span>
                                </TableHead>
                            </TableRow>
                        </TableHeader>
                        <TableBody>
                            {loading ? (
                                <TableRow>
                                    <TableCell colSpan={7} className="text-center">
                                        Loading...
                                    </TableCell>
                                </TableRow>
                            ) : sortedData.length > 0 ? (
                                sortedData.map((master) => (
                                    <TableRow key={master.code}>
                                        <TableCell></TableCell>
                                        {masterData &&
                                            masterData.viewConfig.fields.map((field) => (
                                                <TableCell key={`${master.code}-${field.dataField}`}>
                                                    {field.type === 'GenericArray' && Array.isArray(master[field.dataField])
                                                        ? master[field.dataField].slice(0, 4).join(', ') +
                                                        (master[field.dataField].length > 4 ? ', ...' : '')
                                                        : master[field.dataField]}
                                                </TableCell>
                                            ))}
                                        <TableCell className="flex items-center gap-3 py-2">
                                            {/* <TooltipProvider delayDuration={100}>
                                                <Tooltip>
                                                    <TooltipTrigger>
                                                        <BadgeInfo className='size-[2vh] text-stay' />
                                                    </TooltipTrigger>
                                                    <TooltipContent className="mb-2">
                                                        <p>View {masterData?.title}</p>
                                                    </TooltipContent>
                                                </Tooltip>
                                            </TooltipProvider> */}
                                            <TooltipProvider delayDuration={100}>
                                                <Tooltip>
                                                    <TooltipTrigger>
                                                        {/* <Link to="edit" state={{ item: master }}><Edit className="text-primary/90 size-[1.9vh]" /></Link> */}
                                                        <GenericForm data={master} type="edit" generic={masterData} onSuccess={handleSuccess} />
                                                    </TooltipTrigger>
                                                    <TooltipContent className="mb-2"><p>Edit {masterData?.title}</p></TooltipContent>
                                                </Tooltip>
                                            </TooltipProvider>
                                            <TooltipProvider delayDuration={100}>
                                                <Tooltip>
                                                    <TooltipTrigger>
                                                        <DeleteBox onDeleteSuccess={handleSuccess} code={master.code} />
                                                    </TooltipTrigger>
                                                    <TooltipContent className="mb-2">
                                                        <p>Delete {masterData?.title}</p>
                                                    </TooltipContent>
                                                </Tooltip>
                                            </TooltipProvider>
                                        </TableCell>
                                    </TableRow>
                                ))
                            ) : (
                                <TableRow>
                                    <TableCell colSpan={7} className="text-center">
                                        No data available
                                    </TableCell>
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                    <div className="flex items-center justify-end mt-2 space-x-2">
                        <div className="mr-4 text-[1.6vh] text-muted-foreground">Pages {currentPage} of {totalPages}</div>
                        <Button variant="outline" size="sm" onClick={handlePreviousPage} disabled={currentPage === 1}>
                            <ChevronLeft className="mr-1 size-4" />Previous
                        </Button>
                        <Button variant="outline" size="sm" onClick={handleNextPage} disabled={!data || currentPage === totalPages || (totalItems <= itemsPerPage)}>
                            Next<ChevronRight className="ml-1 size-4" />
                        </Button>
                    </div>
                </CardContent>
            </Card>
        </div>
    )
}
